exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ListGridCoverPhoto-displayGrid-1-0Hn {\n\tdisplay: grid;\n\tgrid-template-columns: 50% 50%;\n}\n\n.ListGridCoverPhoto-firstImage-7_XtN {\n\theight: 300px;\n    margin-right: 10px;\n\tborder-radius: 12px 0px 0px 12px;\n}\n\n.ListGridCoverPhoto-secondImage-1Pc1p {\n\theight: 150px;\n    margin-bottom: 10px;\n\tborder-radius: 0px 12px 0px 0px;\n}\n\n/* .secondImage::before{\n\tcontent: \" \";\n\tdisplay: block;\n\theight: 100%;\n\tbackground-color: rgba(26, 25, 25, 0.262);\n}\n.secondImage:hover + .secondImage::before {\n\tdisplay: none;\n} */\n\n.ListGridCoverPhoto-thiredImage-3Xupo {\n\t/* height: 220px; */\n\theight: 140px;\n    margin-right: 5px;\n}\n\n.ListGridCoverPhoto-fouthImage-2LuEi {\n\t/* height: 220px; */\n\theight: 140px;\n    margin-left: 5px;\n\tborder-radius: 0px 0px 12px 0px;\n}", ""]);

// exports
exports.locals = {
	"displayGrid": "ListGridCoverPhoto-displayGrid-1-0Hn",
	"firstImage": "ListGridCoverPhoto-firstImage-7_XtN",
	"secondImage": "ListGridCoverPhoto-secondImage-1Pc1p",
	"thiredImage": "ListGridCoverPhoto-thiredImage-3Xupo",
	"fouthImage": "ListGridCoverPhoto-fouthImage-2LuEi"
};