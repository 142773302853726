import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm,change } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { createNumberMask } from 'redux-form-input-masks';
import {  compose } from 'react-apollo';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {FormGroup,Row,ControlLabel,Col,TabContainer,TabContent,TabPane,Nav, NavItem} from 'react-bootstrap';
import s from './ListPlaceStep1.css';
import cs from '../commonStyle.css';
import CustomCheckbox from '../CustomCheckbox';
import PolicyDocumentUpload from '../PolicyDocumentUpload/PolicyDocumentUpload';
import FooterButtonPolicy from './FooterButtonPolicy';
import SidePanel from './SidePanel';
import PolicyReviewListing from './PolicyReviewListing';
import PolicyPaymentReviewListing from './PolicyPaymentReviewListing';
import infoIcon from '/public/SiteIcons/priceHoverIcon.svg';
import locationIcon from '/public/SiteIcons/locationIdea.svg';
import CommonFormComponent from '../CommonField/CommonFormComponent';
import updateStep4 from './updateStep4';
import {convert} from '../../helpers/currencyConvertion';
import messages from '../../locale/messages';
import responsabilityIcon from '/public/Insurance/responsability.svg';
import deducibleIcon from '/public/Insurance/deducible.svg';
import car from '/public/Insurance/car.svg';

  // Extract plan type configurations
  const PLAN_TYPES = {
    montly: {
      rateValue: 25,
      monthsQT: 1,
      qtPayment: 1
    },
    quarterly: {
      rateValue: 40, 
      monthsQT: 1,
      qtPayment: 1
    },
    biannual: {
      rateValue: 70,
      monthsQT: 1, 
      qtPayment: 1
    },
    yearly: {
      rateValue: 100,
      monthsQT: 12,
      qtPayment: 6
    }
  };


  const FIXED_LEGAL_INSURANCE_COST = 1810;

class FormPolicyInsurance extends Component {

  static propTypes = {
    listId: PropTypes.number.isRequired,
    listingStepsData: PropTypes.object,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
    listingPolicy: PropTypes.shape({
      listYear: PropTypes.number,
      planType: PropTypes.string,
      paymentDate: PropTypes.string,
      expirationDate: PropTypes.string,
      dueFirst: PropTypes.number,
      duesAmount: PropTypes.number,
      policyInsuranceCode: PropTypes.string,
      paymentType: PropTypes.number,
      paymentAmountPayPal: PropTypes.number,
      paymentCost: PropTypes.number,
      paymentSubNet: PropTypes.number,
      paymentNet: PropTypes.number,
      policyInsuranceCode: PropTypes.string,
      paymentCurrencyPayPal: PropTypes.string,
      paymentStatusPayPal: PropTypes.string,
      policyInsuranceStatus: PropTypes.string,
      policyInsuranceDateApproved: PropTypes.string,
    })
  };
  static defaultProps = {
    listingPolicy: {
      listYear: 0,
      planType: null,
      paymentDate: null,
      expirationDate: null,
      dueFirst: 0,
      duesAmount: 0,
      policyInsuranceCode: null,
      paymentType: 0,
      paymentAmountPayPal: 0,
      paymentCost: 0,
      paymentSubNet: 0,
      paymentNet: 0,
      policyInsuranceCode: null,
      paymentCurrencyPayPal: null,
      paymentStatusPayPal: null,
      policyInsuranceStatus: null,
      policyInsuranceDateApproved: null,
    }
  };
  constructor(props) {
    super(props);
    const { formatMessage } = props.intl;
    
    this.state = {
      isDisabledFields: false,
      isDisabled: true,
      policyStep1: true,
      policyStep2: false,
      policyStep3: false,
      policyStep4: false,
      tabsStepper: [
        { label: 'Welcome', completed: false },
        { label: formatMessage(messages.policyInsuranceStep1), completed: false },
        { label: formatMessage(messages.policyInsuranceStep2), completed: false }, 
        { label: formatMessage(messages.policyInsuranceStep3), completed: false },
        { label: formatMessage(messages.policyInsuranceStep4), completed: false },
      ],
      tabsStepperStep: 0,
      tabPolicyCurrent: 'policy_welcome',
      tabPolicy1: 'active',
      tabPolicy2: '',
      tabPolicy3: '',
      tabPolicy4: '',
      setVehicleTitle: null,
      setYear: 0,
      setPolicyDocUploaded: false,
      setPolicyRateValue: 0,
      setPolicySubNet: 0,
      setPolicyExtraCost: 1810,
      planQTMonth: 0,
      setPolicyNet: 0,
      setPolicyNetUSD: 0,
      setPolicyQTPayment: 1,
      setPolicyFirstDues:0,
      setPolicyDues:0,
      setPolicyPaymentOption: 1,
      setPolicyPlan: 'montly',
      setPolicyExpire: moment().add(1, 'month').format('MMM DD, YYYY').toString(),
    },
    this.onUpdatePaymentOption = this.onUpdatePaymentOption.bind(this);
    this.handleCalculatePrice = this.handleCalculatePrice.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.onCheckDocUploaded = this.onCheckDocUploaded.bind(this);
    this.onGetCalculateValue = this.onGetCalculateValue.bind(this);
    this.onHandleConfirm = this.onHandleConfirm.bind(this);
    this.toNextTab = this.toNextTab.bind(this);
    this.toBackTab = this.toBackTab.bind(this);
    this.onSetUploaded = this.onSetUploaded.bind(this);
    this.offSetUploaded = this.offSetUploaded.bind(this);
    this.onHandleUpdateStepsOnStepper = this.onHandleUpdateStepsOnStepper.bind(this);
    this.calculateYearlyRate = this.calculateYearlyRate.bind(this);
  }


  UNSAFE_componentWillMount() {
    const { listingStepsData } = this.props;
    const { listingSteps:{listingExtDT,policyPayments}} = this.props;
    const { formatMessage } = this.props.intl;
    let policyInsurancePayedAndActive = false,liData={},liInsurance={};

    if(policyPayments?.length > 0) {
      let DTToday = moment();
      let expirationDateDT = policyPayments[0]?.expirationDate ? moment(policyPayments[0].expirationDate) : null;
      let dayDiffD = expirationDateDT ? DTToday.isBefore(expirationDateDT) : false;
      liData = listingExtDT;
      liInsurance = policyPayments;
      policyInsurancePayedAndActive = policyPayments[0].paymentStatusPayPal == 'completed' && dayDiffD == true ? true : false;
      this.setState({isDisabledFields: policyInsurancePayedAndActive});
    }

    const setYear = Number(listingExtDT.year);
    const setVehicleTitle = listingStepsData?.listing?.title;
    const setPolicyRateValue = Number(listingExtDT.policyRateValue);
    const setPolicyPlan = listingExtDT.policyPlan;
    const setPolicyExpire = policyPayments[0]?.expirationDate ? 
      moment(policyPayments[0].expirationDate).format('MMM DD, YYYY').toString() :
      moment().add(1, 'month').format('MMM DD, YYYY').toString();
    const setPolicyExtraCost = parseFloat(listingExtDT.policyExtraCost).toFixed(2);
    const setPolicyNet = parseFloat(listingExtDT.policyNet).toFixed(2);
    const setPolicyNetUSD = parseFloat(policyPayments[0]?.paymentAmountPayPal || 0).toFixed(2);
    const setPolicySubNet = parseFloat(listingExtDT.policySubNet).toFixed(2);
    const setPolicyQTPayment = policyPayments[0]?.paymentQt || 1;
    const setPolicyFirstDues = parseFloat(policyPayments[0]?.duesAmount || 0).toFixed(2);
    const setPolicyDues = parseFloat(policyPayments[0]?.duesAmount || 0).toFixed(2);


   

    if( policyInsurancePayedAndActive === true){
      this.setState({
        isDisabledFields: true,
        tabsStepperStep: 4,
        tabPolicyCurrent:'policy_review',
        tabsStepper: [
          { label: 'Welcome', completed: true },
          { label: formatMessage(messages.policyInsuranceStep1), completed: true },
          { label: formatMessage(messages.policyInsuranceStep2), completed: true },
          { label: formatMessage(messages.policyInsuranceStep3), completed: true },
          { label: formatMessage(messages.policyInsuranceStep4), completed: true },
        ]
      });
      this.setState({
        setYear,
        setVehicleTitle,
        setPolicyRateValue,
        setPolicyPlan,
        setPolicyExpire,
        setPolicyExtraCost,
        setPolicyNet,
        setPolicyNetUSD,
        setPolicySubNet,
        setPolicyQTPayment,
        setPolicyFirstDues,
        setPolicyDues,
      });
    }else{
      this.setState({
        tabsStepper: [
          { label: 'Welcome', completed: false },
          { label: formatMessage(messages.policyInsuranceStep1), completed: false },
          { label: formatMessage(messages.policyInsuranceStep2), completed: false },
          { label: formatMessage(messages.policyInsuranceStep3), completed: false },
          { label: formatMessage(messages.policyInsuranceStep4), completed: false },
        ]
      });
      
      if(setYear !== undefined && listingStepsData !== null){
        const docIsUploaded = listingStepsData.docVerification !=null ? true:false;
        this.setState({
          setYear,
          setVehicleTitle,
          setPolicyRateValue,
          setPolicyPlan,
          setPolicyDocUploaded: docIsUploaded,
          isDisabled: !docIsUploaded
        });
        
        this.onGetCalculateValue(setPolicyPlan);
        
        if (docIsUploaded) {
          this.onHandleUpdateStepsOnStepper(true);
        }
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { formErrors, valid, listingStepsData } = nextProps;
    const { 
      tabPolicyCurrent,
      setPolicyDocUploaded, 
      setPolicyRateValue,
      isDisabledFields 
    } = this.state;
    
    if (isDisabledFields === false) {
      if (tabPolicyCurrent === 'policy_upload_doc') {
        this.setState({ 
          isDisabled: listingStepsData?.docVerification ? false : true 
        });
        this.onHandleUpdateStepsOnStepper(setPolicyDocUploaded);
      } else if (tabPolicyCurrent === 'policy_input_valuation') {
        if (Number(setPolicyRateValue) > 10000) {
          this.setState({ 
            isDisabled: false,
            policyStep2: true 
          });
          this.onHandleUpdateStepsOnStepper(true);
        }
      } else if (tabPolicyCurrent === 'policy_review') {
        if (valid) {
          this.setState({ isDisabled: false });
        } else {
          this.setState({ isDisabled: true });
        }
      }

      if (formErrors !== undefined) {
        if (formErrors.values) {
          const { listingExtData } = formErrors.values;
        
          if (listingExtData && listingExtData.policyInsurance) {
            const typeInsurance = listingExtData.policyInsurance;
            
            this.setState({ policyInsuranceType: typeInsurance });

            if (
              typeInsurance === 'request' && 
              listingExtData.policyRateValue && 
              tabPolicyCurrent === 'policy_input_valuation'
            ) {
              const checkPolicyRateValue = parseFloat(listingExtData.policyRateValue);
              
              this.setState({ 
                isDisabled: checkPolicyRateValue > 10000 ? false : true,
                setPolicyRateValue: checkPolicyRateValue
              });
            }
          }
        }
      }
    }
  }

  onHandleUpdateStepsOnStepper(isCompleted) {
    const { tabPolicyCurrent } = this.state;
    // Map tabs to step indexes
    const tabToStepIndex = {
      'policy_welcome': 0,
      'policy_upload_doc': 1,
      'policy_input_valuation': 2, 
      'policy_coverage': 3,
      'policy_review': 4
    };
    const stepIndex = tabToStepIndex[tabPolicyCurrent];
    
    // Update the specific step and all previous steps
    const updatedSteps = [...this.state.tabsStepper].map((step, index) => {
      if (index <= stepIndex) {
        return {
          ...step,
          completed: isCompleted
        };
      }
      return step;
    });
    this.setState({
      tabsStepper: updatedSteps
    });
  }
  onUpdatePaymentOption(e) {
    const {setPolicyPlan}= this.state;
    this.setState({setPolicyPaymentOption: e});
    this.onGetCalculateValue(setPolicyPlan,e);
  }
  onSetUploaded(){
    this.setState({ 
      isDisabled: false,
      setPolicyDocUploaded: true 
    });
    this.onHandleUpdateStepsOnStepper(true);
  }
  offSetUploaded() {
    this.setState({ 
      isDisabled: true,
      setPolicyDocUploaded: false 
    });
    this.onHandleUpdateStepsOnStepper(false);
  }
  onCheckDocUploaded = (isUploaded) => {
    const { tabPolicyCurrent } = this.state;
    
    this.setState({ 
      isDisabled: !isUploaded,
      setPolicyDocUploaded: isUploaded
    });

    if (tabPolicyCurrent === 'policy_upload_doc') {
      this.onHandleUpdateStepsOnStepper(isUploaded);
    }
  };
  handleTabChange() {
    const { 
      tabPolicyCurrent,
      setPolicyPlan,
      setPolicyRateValue,
      setPolicyDocUploaded 
    } = this.state;

    if (tabPolicyCurrent === 'policy_welcome') {
      this.setState({
        tabPolicyCurrent: 'policy_upload_doc',
        isDisabled: !setPolicyDocUploaded
      });
      this.onHandleUpdateStepsOnStepper(true);
    }
    else if (tabPolicyCurrent === 'policy_upload_doc') {
      if (setPolicyDocUploaded) {
        this.setState({
          isDisabled: Number(setPolicyRateValue) > 10000 ? false : true,
          tabPolicyCurrent: 'policy_input_valuation'
        });
      }
    }
    if (tabPolicyCurrent === 'policy_input_valuation') {
      this.onGetCalculateValue(setPolicyPlan);
      this.setState({tabPolicyCurrent:'policy_coverage'});
    }
    
    if (tabPolicyCurrent === 'policy_coverage') {
      this.setState({ isDisabled: true,
        tabPolicyCurrent:'policy_review'});
    }
  }

  toNextTab(e) {
    const { tabsStepperStep } = this.state;
    e.preventDefault();
    if (tabsStepperStep < 5) {
      this.setState({
        tabsStepperStep: Number(tabsStepperStep) + 1
      });
    }
    this.handleTabChange();
  }
  
  toBackTab(e) {
    e.preventDefault();
    const { 
      tabPolicyCurrent,
      setPolicyDocUploaded,
      setPolicyRateValue,
      tabsStepperStep,
      isDisabledFields
    } = this.state;
    
    let setBtnDisabled = !isDisabledFields && Number(setPolicyRateValue) > 10000 ? false : true;
    if (isDisabledFields === true) { 
      setBtnDisabled = false; 
    }

    if (tabPolicyCurrent === 'policy_upload_doc') {
      this.setState({
        tabPolicyCurrent: 'policy_welcome',
        isDisabled: false
      });
    }
    if (tabPolicyCurrent === 'policy_input_valuation') {
      this.setState({
        tabPolicyCurrent: 'policy_upload_doc',
        isDisabled: setPolicyDocUploaded ? false : true
      });
    }
    if (tabPolicyCurrent === 'policy_coverage') {
      this.setState({
        isDisabled: isDisabledFields,
        tabPolicyCurrent: 'policy_input_valuation'
      });
    }
    
    if (tabPolicyCurrent === 'policy_review') {
      this.setState({
        isDisabled: false,
        tabPolicyCurrent: 'policy_coverage'
      });
    }
    if (tabsStepperStep > 0) {
      this.setState({
        tabsStepperStep: Number(tabsStepperStep) - 1
      });
    }
  }

  // First, let's extract the rate calculations into a separate utility function
   calculateYearlyRate = (year) => {
    const listRateYearly = [
      {years: [2009, 2010], rateValue: 7.24},
      {years: [2011, 2012], rateValue: 6.83}, 
      {years: [2013, 2014], rateValue: 6.41},
      {years: [2015, 2016], rateValue: 5.58},
      {years: [2017, 2018], rateValue: 3.58},
      {years: [2019, 2020, 2021, 2022, 2023], rateValue: 3.31},
      {years: [2024, 2025], rateValue: 3.10},
    ];

    const yearRate = listRateYearly.find(item => item.years.includes(year));
    return yearRate ? yearRate.rateValue : 0;
  };


  // Main calculation function
  onGetCalculateValue(setPlanType, setPaymentOpt = 0) {
    const { isDisabledFields } = this.state;
    const {listId, currencyRates } = this.props;
    const { setYear, setPolicyRateValue, setPolicyPaymentOption } = this.state;

    if (isDisabledFields) return;

    const paymentOption = setPaymentOpt || setPolicyPaymentOption;
    
    // Get yearly rate and calculate yearly price
    const yearlyRate = this.calculateYearlyRate(setYear);
    const yearlyPrice = setPolicyRateValue * yearlyRate / 100;

    // Get plan configuration
    const planConfig = PLAN_TYPES[setPlanType];
    if (!planConfig) return;

    // Calculate plan values
    const planExpireDate = moment().add(planConfig.monthsQT, 'month');
    const planPrice = (planConfig.rateValue * yearlyPrice) / 100;
    
    // Calculate net prices and dues
    let netPrice = planPrice + FIXED_LEGAL_INSURANCE_COST;
    let duesPayments = 0;

    if (setPlanType === 'yearly') {
      duesPayments = (yearlyPrice + FIXED_LEGAL_INSURANCE_COST) / planConfig.qtPayment;
      netPrice = yearlyPrice + FIXED_LEGAL_INSURANCE_COST;
    }

    // Calculate USD conversion if needed
    const amountToConvert = setPlanType === 'yearly' && paymentOption === 1 
      ? duesPayments 
      : netPrice;
    
    const convertedUSDAmount = convert(
      'USD', 
      currencyRates,
      parseFloat(amountToConvert).toFixed(2),
      'DOP',
      'USD'
    );

    // Update form values
    this.updateFormValues({
      listId,
      setYear,
      setPlanType,
      planExpireDate,
      yearlyRate,
      yearlyPrice,
      planConfig,
      netPrice,
      duesPayments,
      convertedUSDAmount
    });

    // Update state
    this.updateStateValues({
      yearlyPrice,
      planExpireDate,
      netPrice,
      convertedUSDAmount,
      planConfig,
      duesPayments
    });
  }

  // Helper method to update form values
  updateFormValues({
    listId,
    setYear,
    setPlanType,
    planExpireDate,
    yearlyRate,
    yearlyPrice,
    planConfig,
    netPrice,
    duesPayments,
    convertedUSDAmount
  }) {
    const { change } = this.props;

    change('listingExtData.listId', listId);
    change('listingExtData.listYear', setYear);
    change('listingExtData.planType', setPlanType);
    change('listingExtData.expirationDate', planExpireDate?.format('YYYY-MM-DD HH:mm:ss'));
    change('listingExtData.rateYearly', yearlyRate);
    change('listingExtData.rateAmountYearly', yearlyPrice);
    change('listingExtData.dueFirst', duesPayments);
    change('listingExtData.duesAmount', duesPayments);
    change('listingExtData.ratePlan', planConfig.rateValue);
    change('listingExtData.rateAmountPlan', yearlyPrice);
    change('listingExtData.planQTMonth', planConfig.monthsQT);
    change('listingExtData.paymentQt', planConfig.qtPayment);
    change('listingExtData.duesQT', planConfig.qtPayment);
    change('listingExtData.paymentType', 1);
    change('listingExtData.policyNet', parseFloat(netPrice).toFixed(2));
    change('listingExtData.paymentNet', parseFloat(netPrice).toFixed(2));
    change('listingExtData.policySubNet', parseFloat(yearlyPrice).toFixed(2));
    change('listingExtData.paymentSubNet', parseFloat(yearlyPrice).toFixed(2));
    change('listingExtData.paymentCost', parseFloat(FIXED_LEGAL_INSURANCE_COST).toFixed(2));
    change('listingExtData.paymentCurrency', 'DOP');
    change('listingExtData.policyExtraCost', parseFloat(FIXED_LEGAL_INSURANCE_COST).toFixed(2));
    change('listingExtData.policyQTPayment', planConfig.qtPayment);
    change('listingExtData.policyExpire', planExpireDate?.format('YYYY-MM-DD HH:mm:ss'));
    change('listingExtData.policyAmountUSD', convertedUSDAmount.toFixed(2));
  }

  // Helper method to update state values 
  updateStateValues({
    yearlyPrice,
    planExpireDate,
    netPrice,
    convertedUSDAmount,
    planConfig,
    duesPayments
  }) {
    this.setState({
      setPolicyExpire: planExpireDate.format('MMM DD, YYYY'),
      setPolicyExtraCost: parseFloat(FIXED_LEGAL_INSURANCE_COST).toFixed(2),
      setPolicyNet: parseFloat(netPrice).toFixed(2),
      setPolicyNetUSD: convertedUSDAmount.toFixed(2),
      setPolicySubNet: parseFloat(yearlyPrice).toFixed(2),
      setPolicyQTPayment: planConfig.qtPayment,
      setPolicyFirstDues: parseFloat(duesPayments).toFixed(2),
      setPolicyDues: parseFloat(duesPayments).toFixed(2),
      policyStep4: false,
      planQTMonth: planConfig.monthsQT
    });
  }

  handleCalculatePrice(e){
    const {change}= this.props;
    const setPlanType = e.target.value;
    
    this.setState({setPolicyPlan:setPlanType});
    this.onGetCalculateValue(setPlanType);
  }
  
  onHandleConfirm(e){
    this.setState({ 
      isDisabled: e ? false : true,
      policyStep4:e
    });
  }



  checkboxGroup = ({ input, name,disabled }) => {
    return (
      <div className={cx(s.checkBoxGrid, cs.paddingBottom4)}>
        <CustomCheckbox
          name={name}
          disabled={disabled}
          className={cx('icheckbox_square-green',s.icheckboxWithBorder,s.isCheckBoxConfirm,cs.displayInlineBlock)}
          checked={input.value == true}
          onChange={event => {
            this.onHandleConfirm(event); return input.onChange(event);
          }}
        />
        <p className={cx(cs.commonMediumText,cs.displayInlineBlock,cs.marginLeftCheckBox,cs.fontWeightNormal, s.checkBoxText, 'signUpCheckBoxTextRTl')}>
          <FormattedMessage {...messages.iAgreePolicyInsurance} />
        </p>
      </div>
    )
  }
  render() {
    const { handleSubmit, previousPage, formPage, step,listId } = this.props;
    const { isDisabledFields, isDisabled,policyStep1,policyStep2,policyStep3,policyStep4,tabPolicyCurrent,tabsStepper,tabsStepperStep } = this.state;
    const { formatMessage } = this.props.intl;
    const { listingSteps:{listingExtDT,policyPayments}} = this.props;

    const {
      setYear,
      setPolicyRateValue,
      setPolicySubNet,
      setPolicyExtraCost,
      setPolicyNet,
      setPolicyQTPayment,
      setPolicyPlan,
      setPolicyExpire,
      setPolicyFirstDues,
      setPolicyDues,
      setPolicyNetUSD,
      setPolicyPaymentOption
    } = this.state;
    const priceMaskDOP = createNumberMask({prefix: 'DOP$ ',decimalPlaces: 2,spaceAfterSign: true,allowNegative: true}); 
    
    return (
      <div className={cx(s.stepGrid, 'stepGridRTL')}>
        <SidePanel
          title={formatMessage(messages.step4Heading)}
          landingContent={formatMessage(messages.policyInsurance)}
          showStepper={true}
          StepperSteps={tabsStepper}
          StepperActive={tabsStepperStep}
        />
        <form onSubmit={handleSubmit} className={cx(s.landingMainContent,s.landingMainContentSmPadding)}>
          <Row className={cx(cs.notMarginLR)}>
            <Col lg={12}>
              <TabContainer 
                id="policy-tabs-center-stepper" 
                activeKey={tabPolicyCurrent}  
                defaultActiveKey="policy_welcome"
                onSelect={(k) => this.handleTabChange}
              >
                <Row className="clearfix">
                  <Col sm={12} className={cx('navStepperNav','isHidden')}>
                    <Nav className={cx(s.NavStepperContainer)}>
                      <NavItem 
                          active={tabPolicyCurrent === 'policy_welcome'}
                          className={cx(s.NavStepContainer)} 
                          key={'id_policy_welcome'} 
                          eventKey={'policy_welcome'}>
                            
                          <div className={cx(s.NavStepMain)}>
                            <div className={cx(s.NavStepButton,'NavStepperButton',tabPolicyCurrent==='policy_welcome'?s.NavStepButtonSelected:'')}>
                              <span className={s.NavStepButtonContent}>1</span>
                            </div>
                            <div className={cx(s.NavLabelContainer)}>
                              <span className={cx(s.NavLabel)}>Welcome</span>
                            </div>
                          </div>
                      </NavItem>

                      <NavItem 
                        active={policyStep1}
                          className={cx(s.NavStepContainer)} 
                          key={'id_policy_upload_doc'} 
                          eventKey={'policy_upload_doc'}>
                            <div className={cx(s.NavConnectorContainer)}><span className={cx(s.NavConnector,'NavStepperConnector')}></span></div>
                            <div className={cx(s.NavStepMain)}>
                              <div className={cx(s.NavStepButton,'NavStepperButton',tabPolicyCurrent==='policy_upload_doc'?s.NavStepButtonSelected:'')} >
                                <span className={s.NavStepButtonContent}>2</span>
                              </div>
                              <div className={cx(s.NavLabelContainer)}>
                                <span className={cx(s.NavLabel)}><FormattedMessage {...messages.policyInsuranceStep1} /></span>
                              </div>
                            </div>
                      </NavItem>

                      <NavItem 
                          active={policyStep2}
                          className={cx(s.NavStepContainer)} 
                          key={'id_policy_input_valuation'} eventKey={'policy_input_valuation'}>
                            <div className={cx(s.NavConnectorContainer)}><span className={cx(s.NavConnector,'NavStepperConnector')}></span></div>
                            <div className={cx(s.NavStepMain)}>
                              <div className={cx(s.NavStepButton,'NavStepperButton',tabPolicyCurrent==='policy_input_valuation'?s.NavStepButtonSelected:'')} >
                                <span className={s.NavStepButtonContent}>3</span>
                              </div>
                              <div className={cx(s.NavLabelContainer)}>
                                <span className={cx(s.NavLabel)}><FormattedMessage {...messages.policyInsuranceStep2} /></span>
                              </div>
                            </div>
                      </NavItem>

                      <NavItem 
                          active={policyStep3}
                          className={cx(s.NavStepContainer)} 
                          key={'id_policy_coverage'} eventKey={'policy_coverage'}>
                            <div className={cx(s.NavConnectorContainer)}><span className={cx(s.NavConnector,'NavStepperConnector')}></span></div>
                            <div className={cx(s.NavStepMain)}>
                              <div className={cx(s.NavStepButton,'NavStepperButton',tabPolicyCurrent==='policy_coverage'?s.NavStepButtonSelected:'')}>
                                <span className={s.NavStepButtonContent}>4</span>
                              </div>
                              <div className={cx(s.NavLabelContainer)}>
                                <span className={cx(s.NavLabel)}><FormattedMessage {...messages.policyInsuranceStep3} /></span>
                              </div>
                            </div>
                      </NavItem>
                      
                      <NavItem 
                          active={policyStep4}
                          className={cx(s.NavStepContainer)} 
                          key={'id_policy_review'} eventKey={'policy_review'}>
                            <div className={cx(s.NavConnectorContainer)}><span className={cx(s.NavConnector,'NavStepperConnector')}></span></div>
                            <div className={cx(s.NavStepMain)}>
                              <div className={cx(s.NavStepButton,'NavStepperButton',tabPolicyCurrent==='policy_review'?s.NavStepButtonSelected:'')}>
                                <span className={s.NavStepButtonContent}>5</span>
                              </div>
                              <div className={cx(s.NavLabelContainer)}>
                                <span className={cx(s.NavLabel)}><FormattedMessage {...messages.policyInsuranceStep4} /></span>
                              </div>
                            </div>
                      </NavItem>
                   </Nav>
                  </Col>

                  <Col sm={12} className={cx(cs.noPadding)}>
                    <TabContent animation>
                      <TabPane key={'panel_policy_welcome'} eventKey={'policy_welcome'}>
                        <div className={s.policyHeroSection}>
                          <h2 className={cx(cs.commonContentText, cs.spaceBottom3, cs.fontWeightExtraBold, s.heroTitle)}>
                            <FormattedMessage {...messages.policyWelcomeTitle} />
                          </h2>
                          
                          <p className={cx(cs.commonMediumText, s.heroParagraph, cs.spaceBottom4)}>
                            <FormattedMessage 
                              {...messages.policyWelcomeDescription} 
                              values={{
                                insuranceLink: <a href="/multiseguros"><strong>MultiSeguros</strong></a>
                              }}
                            />
                          </p>

                          <div className={s.benefitsGrid}>
                            <div className={s.benefitCard}>
                              <img src={responsabilityIcon} className={s.benefitIcon} alt={formatMessage(messages.benefitEarningAlt)} />
                              <h3 className={s.benefitTitle}>
                                <FormattedMessage {...messages.insuranceResponsability} />
                              </h3>
                              <p className={s.benefitText}>
                                <FormattedMessage {...messages.insuranceResponsabilityDescription} />
                              </p>
                            </div>

                            <div className={s.benefitCard}>
                              <img src={deducibleIcon} className={s.benefitIcon} alt={formatMessage(messages.benefitProtectionAlt)} />
                              <h3 className={s.benefitTitle}>
                                <FormattedMessage {...messages.benefitProtectionTitle} />
                              </h3>
                              <p className={s.benefitText}>
                                <FormattedMessage {...messages.benefitProtectionDescription} />
                              </p>
                            </div>

                            <div className={s.benefitCard}>
                              <img src={car} className={s.benefitIcon} alt={formatMessage(messages.benefitSecurityAlt)} />
                              <h3 className={s.benefitTitle}>
                                <FormattedMessage {...messages.benefitSecurityTitle} />
                              </h3>
                              <p className={s.benefitText}>
                                <FormattedMessage {...messages.benefitSecurityDescription} />
                              </p>
                            </div>
                          </div>
                        </div>
                      </TabPane>

                      <TabPane key={'panel_policy_upload_doc'} eventKey={'policy_upload_doc'}>
                        <Row>
                          <Col xs={12}>
                            <div className={s.briefQuestionsContainer}>
                              <h3 className={cx(cs.commonContentText, cs.spaceBottom3)}>
                                <FormattedMessage {...messages.policyGPSOpt} />
                              </h3>

                              <div className={s.gpsOptionsWrapper}>
                                <div className={s.gpsOption}>
                                  <div className={s.radioWrapper}>
                                    <Field 
                                      name="listingExtData.policyGPS" 
                                      disabled={isDisabledFields} 
                                      component="input" 
                                      type="radio" 
                                      value="1" 
                                      className={s.radioInput} 
                                    />
                                    <div className={s.optionContent}>
                                      <span className={s.optionLabel}>
                                        <FormattedMessage {...messages.policyGPSOpt1} />
                                      </span>
                                      <p className={s.optionDescription}>
                                        <FormattedMessage {...messages.policyGPSOpt1} />
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className={s.gpsOption}>
                                  <div className={s.radioWrapper}>
                                    <Field 
                                      name="listingExtData.policyGPS" 
                                      disabled={isDisabledFields} 
                                      component="input" 
                                      type="radio" 
                                      value="0" 
                                      className={s.radioInput}
                                    />
                                    <div className={s.optionContent}>
                                      <span className={s.optionLabel}>
                                        <FormattedMessage {...messages.policyGPSOpt2} />
                                      </span>
                                      <p className={s.optionDescription}>
                                        <FormattedMessage {...messages.policyGPSOpt2Description} />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className={s.uploadSection}>
                              <div className={s.uploadInstructions}>
                                <h3 className={cx(cs.commonContentText, cs.spaceBottom2)}>
                                  <FormattedMessage {...messages.carDocPolicy} />
                                </h3>
                                <p className={s.instructionsText}>
                                  <FormattedMessage {...messages.pleaseClickOn} />
                                  <a href="/policy-form.pdf" target="_blank" className={s.downloadLink} style={{marginLeft: 4, marginRight: 4}}>
                                    <FormattedMessage {...messages.pleaseClickOnLink} />
                                  </a>
                                  <FormattedMessage {...messages.pleaseClickOn2} />
                                </p>
                              </div>

                              <div className={cx(s.uploadContainer, 'stepPhotoUpload', 'stepPhotoUploadDoc')}>
                                <PolicyDocumentUpload 
                                  onSetUploaded={this.onSetUploaded}
                                  offSetUploaded={this.offSetUploaded}
                                  placeHolderClassName={'documentPlaceholderDoc'}
                                  iconClassName={'photoUploadImgDoc'}
                                  dzListPhotoContainer={'listPhotoContainerDoc'} 
                                  dzInputContainerName={'dzInputContainerDoc'} 
                                  maxFilesUpload={1} 
                                  docIdVerification={5} 
                                  listId={listId}
                                  hasDocument={this.state.setPolicyDocUploaded}
                                  onCheckDocUploaded={this.onCheckDocUploaded}
                                  placeholder={formatMessage(messages.policyUploadPlaceholder)} 
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane key={'panel_policy_input_valuation'} eventKey={'policy_input_valuation'}>
                        
                        <Row>
                          <Col xs={12}>
                            <p><FormattedMessage {...messages.pleaseInputRateValueCar} /></p>
                          </Col>
                          
                        </Row>
                        <Row>
                          <Col xs={12} sm={12} md={6} lg={6} >
                            <FormGroup className={cx(s.noMargin)}>
                              <ControlLabel className={s.landingLabel}>
                                <FormattedMessage {...messages.inputValuationRatePolicy} />
                                <div className={s.infoIconCss}>
                                  <img src={infoIcon} className={s.infoIcon} />
                                  <div className={cx(s.infoContent, s.basePriceInfo, 'infoContentRTL')}>{formatMessage(messages.inputValuationRatePolicyInfo)}</div>
                                </div>
                              </ControlLabel>
                              <Field
                                name="listingExtData.policyRateValue"
                                type="text"
                                component={CommonFormComponent}
                                label={formatMessage(messages.inputValuationRatePolicy)}
                                inputClass={cx(s.formControlInput, s.jumboSelect, s.formControlInputMaxWidth)}
                                {...priceMaskDOP}
                              />
                            </FormGroup>
                            <div className={cx(s.searchToolTip, cs.spaceTop2, cs.spaceBottom4)}>
                              <img src={locationIcon} className={'commonIconSpace'} />
                              <span className={cx(s.locationTipCss, cs.commonMediumText)}><FormattedMessage {...messages.inputValuationRatePolicyInfo} /></span>
                            </div>
                          </Col>
                        </Row>
                        
                        
                      </TabPane>

                      <TabPane key={'panel_policy_coverage'} eventKey={'policy_coverage'}>
                        <Row>
                          <Col xs={6} sm={6} lg={3} md={6}>
                              <div className={s.spaceTop3}>
                                <div className={cx(s.checkBoxFlex, s.alignBase)}>
                                  <div>
                                    <Field disabled={isDisabledFields} onChange={this.handleCalculatePrice} name="listingExtData.policyPlan" component="input" type="radio" value="montly" className={cx(s.BookingradioInput,s.radioInputInlineMargin,cs.radioSetBorder)} />
                                  </div>
                                  <div className={cx(s.checkBoxLeft, 'discountRightRTL')}>
                                    <span className={cx(cs.commonMediumText)}><FormattedMessage {...messages.policyInsurancePlan1} /></span>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} sm={6} lg={3} md={6}>
                              <div className={cx(s.spaceTop3)}>
                                <div className={s.checkBoxFlex}>
                                  <div>
                                  <Field onChange={this.handleCalculatePrice} name="listingExtData.policyPlan" component="input" type="radio" value="quarterly" className={cx(s.BookingradioInput,s.radioInputInlineMargin,cs.radioSetBorder)} />
                                  </div>
                                  <div className={cx(s.checkBoxLeft, 'discountRightRTL')}>
                                    <span className={cx(cs.commonMediumText)}><FormattedMessage {...messages.policyInsurancePlan2} /></span>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} sm={6} lg={3} md={6}>
                              <div className={cx(s.spaceTop3)}>
                                <div className={s.checkBoxFlex}>
                                  <div>
                                  <Field onChange={this.handleCalculatePrice} name="listingExtData.policyPlan" component="input" type="radio" value="biannual" className={cx(s.BookingradioInput,s.radioInputInlineMargin,cs.radioSetBorder)} />
                                  </div>
                                  <div className={cx(s.checkBoxLeft, 'discountRightRTL')}>
                                    <span className={cx(cs.commonMediumText)}><FormattedMessage {...messages.policyInsurancePlan3} /></span>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} sm={6} lg={3} md={6}>
                              <div className={cx(s.spaceTop3)}>
                                <div className={s.checkBoxFlex}>
                                  <div>
                                  <Field onChange={this.handleCalculatePrice} name="listingExtData.policyPlan" component="input" type="radio" value="yearly" className={cx(s.BookingradioInput,s.radioInputInlineMargin,cs.radioSetBorder)} />
                                  </div>
                                  <div className={cx(s.checkBoxLeft, 'discountRightRTL')}>
                                    <span className={cx(cs.commonMediumText)}><FormattedMessage {...messages.policyInsurancePlan4} /></span>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <PolicyReviewListing 
                                onUpdatePaymentOption={this.onUpdatePaymentOption}
                                setPolicyPaymentOption={setPolicyPaymentOption}
                                setPolicyPlan={setPolicyPlan}
                                setYear={setYear}
                                setPolicyRateValue={setPolicyRateValue}
                                setPolicyQTPayment={setPolicyQTPayment}
                                planQTMonth={this.state.planQTMonth}
                                setPolicyFirstDues={setPolicyFirstDues}
                                setPolicyDues={setPolicyDues}
                                setPolicyExpire={setPolicyExpire}
                                setPolicySubNet={setPolicySubNet}
                                setPolicyExtraCost={setPolicyExtraCost}
                                setPolicyNet={setPolicyNet}
                                setPolicyNetUSD={setPolicyNetUSD}
                              />
                              
                            </Col>
                          </Row>
                        </TabPane>
                        
                        <TabPane key={'panel_policy_review'} eventKey={'policy_review'}>
                          <Row>
                            <Col xs={12}>
                              <PolicyReviewListing 
                                onUpdatePaymentOption={this.onUpdatePaymentOption}
                                setPolicyPaymentOption={setPolicyPaymentOption}
                                setPolicyPlan={setPolicyPlan}
                                setReadyOnlyPaymentOpt={true}
                                setYear={setYear}
                                setPolicyRateValue={setPolicyRateValue}
                                setPolicyQTPayment={setPolicyQTPayment}
                                setPolicyFirstDues={setPolicyFirstDues}
                                setPolicyDues={setPolicyDues}
                                setPolicyExpire={setPolicyExpire}
                                setPolicySubNet={setPolicySubNet}
                                setPolicyExtraCost={setPolicyExtraCost}
                                setPolicyNet={setPolicyNet}
                                setPolicyNetUSD={setPolicyNetUSD}
                              />
                            </Col>
                          </Row>

                          {!isDisabledFields && <Row>
                            <Col xs={12}>
                              <Field disabled={isDisabledFields} name="confirmationPolicyInsurance" component={this.checkboxGroup} />
                            </Col>
                          </Row>}
                          
                          {isDisabledFields && listingExtDT && policyPayments && <Row>
                            <Col xs={12}>
                              <PolicyPaymentReviewListing
                                policyListing={listingExtDT}
                                policyPayment={policyPayments}
                               />
                              
                            </Col>
                          </Row> }
                        </TabPane>

                      </TabContent>
                    </Col>
                  </Row>

                </TabContainer>
              </Col>
            </Row>
            
            {tabPolicyCurrent !='policy_review' &&
            <FooterButtonPolicy
              isDisabled={tabPolicyCurrent !== 'policy_welcome' ? isDisabled : false}
              nextPage={this.toNextTab}
              previousPage={this.toBackTab}
              exitPage={previousPage}
              nextPagePath={"pricing"}
              previousPagePath={"home"}
              formPage={formPage}
              step={step}
            />
            }
            {tabPolicyCurrent ==='policy_review' &&
            <FooterButtonPolicy
              exitOnly={isDisabledFields}
              isDisabled={isDisabled}
              exitPage={previousPage}
              previousPage={this.toBackTab}
              previousPagePath={"home"}
              formPage={formPage}
              isFinish
              type={"submit"}
              step={step}
            />
            }
        </form>
        
      </div>
    );
  }
}

FormPolicyInsurance = reduxForm({
  form: 'ListPlaceStep3', 
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: updateStep4
})(FormPolicyInsurance);

const mapState = (state) => ({
  listingSteps: state.location.listingSteps,
  formErrors: state.form.ListPlaceStep3,
  listingFields: state.listingFields.data,
  currencyRates: state.currency.rates
});

const mapDispatch = {
  change
};

export default compose(
injectIntl,
connect(mapState, mapDispatch),
withStyles(s,cs),
)(FormPolicyInsurance);
