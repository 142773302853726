// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import fetch from '../../core/fetch';
import submit from './submit';
import update from './update';
import updateStep2 from './updateStep2';
import updateStep3 from './updateStep3';
import { injectIntl,FormattedMessage } from 'react-intl';
import ExistingPage1 from './ExistingPage1';
import Page2 from './Page2';
import Page5 from './Page5';
import Goals from './Goals';
import Page6 from './Page6';
import Page8 from './Page8';
import Photos from './Photos';
import Description from './Description';
import HouseRules from './HouseRules';
import ReviewGuestBook from './ReviewGuestBook';
import MinMaxNights from './MinMaxNights';
import Calendar from './Calendar';
import Pricing from './Pricing';
import PricingPickupAddress from './PricingPickupAddress';
import Discount from './Discount';
import Booking from './Booking';
import LocalLaws from './LocalLaws';
import history from '../../core/history';
import FormPolicyInsurance from './FormPolicyInsurance';
import NotFound from '../../routes/notFound/NotFound';
import { ListIntro } from './ListIntro';
import Cookies from 'js-cookie';
import messages from '../../locale/messages';

import { Modal, Button } from 'react-bootstrap';
class ListPlaceStep1 extends Component {
  static propTypes = {
    listData: PropTypes.object,
    existingList: PropTypes.bool,
    listingSteps: PropTypes.object,
    listId: PropTypes.number,
    formBaseURI: PropTypes.string,
    mode: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      page: 'index',
      step1: null,
      step2: null,
      step3: null,
      formValues: {},
      showPopup: false
    };
  }

  componentDidUpdate() {
    this.checkAndShowPopup();
  }


  checkAndShowPopup() {
    const hasSeenPopup = Cookies.get('hasSeenListPlacePopup');
    if (this.state.page === 'car' && !hasSeenPopup) {
      this.setState({ showPopup: true });
      Cookies.set('hasSeenListPlacePopup', 'true', { expires: 30 });
    }
  }

  UNSAFE_componentWillMount() {
    const { existingList, listingSteps } = this.props;
    if (existingList && listingSteps != undefined) {
      this.setState({
        step1: listingSteps.step1,
        step2: listingSteps.step2,
        step3: listingSteps.step3,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { existingList, listingSteps } = nextProps;
    if (existingList && listingSteps != undefined) {
      this.setState({
        step1: listingSteps.step1,
        step2: listingSteps.step2,
        step3: listingSteps.step3,
      });
    } else {
      this.setState({
        step1: null,
        step2: null,
        step3: null,
      });
    }
  }

  nextPage(formPage) {
    const { listId, existingList, formBaseURI } = this.props;
    let pathName = formBaseURI + formPage;
    if (existingList === true) {
      pathName = formBaseURI + listId + "/" + formPage;
    }
    history.push(pathName);
    this.setState({ page: formPage })
  }

  previousPage(formPage) {
    const { listId, existingList, formBaseURI } = this.props;
    let pathName = formBaseURI + formPage;
    if (existingList === true) {
      pathName = formBaseURI + listId + "/" + formPage;
    }
    history.push(pathName);
    this.setState({ page: formPage })
  }

  closePopup = () => {
    this.setState({ showPopup: false });
  }
  
  render() {
    const { formPage, photosCount, mode, existingList, listId, baseCurrency, step, changeSetDisableForm, listingSteps } = this.props;
    const { page, showPopup } = this.state;

    let currentPage = page;
    if (mode != undefined && mode === "new") {
      currentPage = 'index';
    } else if (formPage != undefined) {
      currentPage = formPage;
    }
    
    const listingPages = [
      'index', 'home', 'car', 'location', 'map', 'features','goals', 'photos','description',
      'car-rules', 'review-how-renters-book', 'min-max-days', 'calendar',
      'pricing','pickup-address', 'discount', 'booking-scenarios','policy-insurance'
    ];

    return (
      <div className={'inputFocusColor'}>
        {showPopup && (
          <Modal show={showPopup} onHide={this.closePopup} centered>
            <Modal.Header>
              <Modal.Title>Requirements for listing your vehicle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{fontSize: '15px', lineHeight: '1.6'}}>
                <p><strong>To list your vehicle, you must meet the following requirements:</strong></p>
                <ul style={{paddingLeft: '20px'}}>
                  <li><strong>Age:</strong> Cannot exceed 12 years</li>
                  <li><strong>Mileage:</strong> Less than 200,000 km</li>
                  <li><strong>Documentation:</strong> Registration linked to the user or person authorized by the owner</li>
                  <li><strong>Condition:</strong> Cannot have a salvage record</li>
                  <li><strong>Insurance:</strong> Must meet our insurance policy requirements</li>
                  <li><strong>Legal:</strong> Vehicle must meet all legal requirements in the country</li>
                  <li><strong>Maintenance:</strong> Up-to-date maintenance and routine checks to ensure <a href="/blog/legal/safety-standards">safety standards</a></li>
                </ul>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={this.closePopup}>
                Understood
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {listingPages.includes(currentPage) ? (
          <>
            {currentPage === "index" && <ListIntro nextPage={this.nextPage} formPage={formPage} step={step} />}
            {currentPage === "home" && <ExistingPage1 listId={listId} nextPage={this.nextPage} photosCount={photosCount} formPage={formPage} step={step} />}
            {currentPage === "car" && <Page2 listId={listId} nextPage={this.nextPage} previousPage={this.previousPage} formPage={formPage} step={step} />}
            {currentPage === "location" && <Page5
              nextPage={this.nextPage}
              previousPage={this.previousPage}
              onSubmit={existingList ? update : submit}
              formPage={formPage} step={step}
              listId={listId}
            />}
            
            {currentPage === "map" && <Page6 nextPage={this.nextPage} previousPage={this.previousPage} formPage={formPage} step={step} />}
            {currentPage === "features" && <Page8 previousPage={this.previousPage} onSubmit={update} formPage={formPage} step={step} nextPage={this.nextPage}/>}
            {currentPage === "goals" && <Goals previousPage={this.previousPage} nextPage={this.nextPage} formPage={formPage} step={step} />}
            {currentPage === "photos" && <Photos previousPage={this.previousPage} listId={listId} nextPage={this.nextPage} formPage={formPage} step={step} />}
            {currentPage === "description" && <Description previousPage={this.previousPage} nextPage={this.nextPage} formPage={formPage} step={step} onSubmit={updateStep2} />}
            {currentPage === "car-rules" && <HouseRules previousPage={this.previousPage} nextPage={this.nextPage} formPage={formPage} step={step} />}
            {currentPage === "review-how-renters-book" && <ReviewGuestBook previousPage={this.previousPage} nextPage={this.nextPage} formPage={formPage} onSubmit={updateStep3}  step={step} />}
            {currentPage === "min-max-days" && <MinMaxNights previousPage={this.previousPage} listId={listId} nextPage={this.nextPage} formPage={formPage} step={step} />}
            {currentPage === "pricing" && <Pricing
              listId={listId}
              previousPage={this.previousPage}
              nextPage={this.nextPage}
              formPage={formPage} step={step}
            />}
            {currentPage === "pickup-address" && <PricingPickupAddress
              listId={listId}
              previousPage={this.previousPage}
              nextPage={this.nextPage}
              formPage={formPage} step={step}
            />}
            {currentPage === "calendar" && <Calendar
              listId={listId}
              previousPage={this.previousPage}
              nextPage={this.nextPage}
              baseCurrency={baseCurrency}
              formPage={formPage} step={step}
            />}
            {currentPage === "discount" && <Discount previousPage={this.previousPage} nextPage={this.nextPage} formPage={formPage} step={step} />}
            {currentPage === "booking-scenarios" && <Booking previousPage={this.previousPage} nextPage={this.nextPage}  formPage={formPage} step={step} />}
            {currentPage === "policy-insurance" && listingSteps && <FormPolicyInsurance 
            listId={listId} 
            listingStepsData={listingSteps}
            previousPage={this.previousPage} 
            nextPage={this.nextPage} 
            formPage={formPage} 
            step={step} />}
          </>
        ) : <NotFound />}
      </div>
    );
  }
}

const mapState = (state) => ({
  existingList: state.location.isExistingList,
  listingSteps: state.location.listingSteps,
  photosCount: state.location.photosCount,
});

const mapDispatch = {
};

export default injectIntl(connect(mapState, mapDispatch)(ListPlaceStep1));
