import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DropzoneComponent from "react-dropzone-component";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
//compose
import { graphql, gql, compose } from "react-apollo";

// Component
import DocumentList from "../../../DocumentList";
import showToaster from "../../../../helpers/toasterMessages/showToaster";

//Image
import PictureImage from "/public/SiteIcons/photoUpload.svg";
// Style
import s from "!isomorphic-style-loader!css-loader!./filepicker.css";
import sc from "./MediaGalleryUpload.css";

const getContractFile = gql`
  query getContractSettings($typesetting: String) {
    getContractSettings(typesetting: $typesetting) {
      id
      name
      type
      value
      isStatus
    }
  }
`;

class MediaGalleryUpload extends Component {
  static propTypes = {
    data: PropTypes.shape({
      // doUploadFileName: PropTypes.string,
      // doUploadConfigType: PropTypes.string,
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      // doUploadFileName: this.props.doUploadFileName
    }
    this.addedfile = this.addedfile.bind(this);
    this.complete = this.complete.bind(this);
    this.dropzone = null;
  }

  componentDidMount() {
    const isBrowser = typeof window !== "undefined";
    const isDocument = typeof document !== undefined;
    if (isBrowser && isDocument) {
      document.querySelector(".dz-hidden-input").style.visibility = "visible";
      document.querySelector(".dz-hidden-input").style.opacity = "0";
      document.querySelector(".dz-hidden-input").style.height = "100%";
      document.querySelector(".dz-hidden-input").style.width = "100%";
      document.querySelector(".dz-hidden-input").style.cursor = "pointer";
    }
  }

  async complete(file) {
    const { mutate } = this.props;
    const {type_setting}= this.props;
    
    let variables = {};
    if (file && file.xhr) {
      const { files } = JSON.parse(file.xhr.response);
      let fileName = files[0].filename;
      let fileType = files[0].mimetype;
      variables = {
        name:fileName,
        description:fileName,
        type:fileType,
        
      };
      
      const { data } = await mutate({
        variables,
        //refetchQueries: [{ query:getContractFile,variables: { typesetting:'contract_cia' } }]
      });
      
      if (data?.uploadDocTemplate) {
        if (data?.uploadDocTemplate?.isStatus === 1) {
          // this.setState({ doUploadFileName: fileName});
          showToaster({ messageId: 'uploadDocument', toasterType: 'success' })
        } else {
          showToaster({ messageId: 'uploadDocumentError', toasterType: 'error' })
        }
      }
    }
    //this.dropzone.removeFile(file);
  }

  async addedfile(file) {
    const { maxUploadSize } = this.props;
    let filetypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf']
    
    if (!filetypes.includes(file.type)) {
      showToaster({ messageId: "invalidImageFile", toasterType: "error" });
      this.dropzone.removeFile(file);
      return;
    }
  }

  render() {
    const { placeholder, className } = this.props;
    const djsConfig = {
      dictDefaultMessage: "",
      addRemoveLinks: true,
      // previewsContainer: ".testPreview",
      // maxFilesize: 10,
      maxFiles: 10,
      multiple: true,
      acceptedFiles: 'image/*,application/pdf',
      hiddenInputContainer: ".dzInputContainerCia",
    };
    const componentConfig = {
      iconFiletypesiconFiletypes: ['.jpg', '.png', '.pdf'],
      showFiletypeIcon: true,
      postUrl: "/photos",
    };
    const eventHandlers = {
      init: (dz) => (this.dropzone = dz),
      complete: this.complete,
      addedfile: this.addedfile,
    };

    return (
      <div className={cx("listPhotoContainer")}>
        <div className={cx("dzInputContainer","dzInputContainerCia")}>
          <div className={className}>
              <DropzoneComponent
                config={componentConfig}
                eventHandlers={eventHandlers}
                djsConfig={djsConfig}
              >
                <img
                  src={PictureImage}
                  className={"photoUploadImg"}
                  alt="PictureImage"
                />
                <span className={cx("documentPlaceholder")}>{placeholder}</span>
              </DropzoneComponent>
          </div>
        </div>

        {/* <div className={cx(sc.fileContent)}>{this.state.doUploadFileName}</div> */}
        {/* <DocumentList /> */}
      </div>
    );
  }
}

const mapState = (state) => ({
  
  maxUploadSize: state.siteSettings.data.maxUploadSize,
  // docFileName: state.ContractsSettingsForm.initialValues.contract_cia,
});

const mapDispatch = {};

export default compose(
  withStyles(s),
  graphql(gql`
    mutation createPhotoLibrary($name: String,$description: String,$type: String) {
      createPhotoLibrary(name:$name,description: $description, type: $type) {
        type
        value
        status
      }
    }
  `),
  connect(mapState, mapDispatch)
)(MediaGalleryUpload);