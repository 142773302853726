import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import ContractsManagement from '../../../components/siteadmin/ContractsManagement';

import contractsQuery from './contractsQuery.graphql';

import s from './Reservations.css';
class ContractsList extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    setContractType: PropTypes.number.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      getAllContractListing: PropTypes.array,
    })
  };

  static defaultProps = {
    getAllConstracts: {
      loading: true,
      getAllContractListing: {
        count: null,
        contractData: []
      }
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchList: '',
    };
  }

  changeState = (variables) => this.setState(variables)

  render() {
    const { getAllConstracts,setContractType } = this.props;

    return <div>
      {setContractType ==1 && <ContractsManagement
        changeStateValues={this.changeState}
        currentPage={this.state.currentPage}
        searchList={this.state.searchList}
        getAllContractListing={getAllConstracts}
      />}
    </div>
  }

}

export default compose(
  withStyles(s),
  graphql(contractsQuery, {
    name: 'getAllConstracts',
    options: (props) => ({
      variables: {
        contractType:props.setContractType,
        currentPage: 1,
        searchList: '',
      },
      fetchPolicy: 'network-only',
    })
  })
)(ContractsList);