import React, { ComponentType, useEffect } from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import CommonTable from "../../../components/CommonTable/CommonTable";
import TableAction from "../../../components/CommonTable/TableAction";
import s from './PolicySettings.css';
import cs from '../../../components/commonStyle.css';
import cx from 'classnames';
import { injectIntl, IntlShape } from "react-intl";
import messages from '../../../locale/messages';
import { compose,gql } from 'react-apollo';
import { connect } from 'react-redux';
import GetAllListingPolicies from '../../../data/queries/getAllListingsPolicies';
import Shimmer from "../../../components/Skeleton/Shimmer";


// import Loader from '../../../components/Loader/Loader';

interface PolicySettingsProps {
  title: string;
  intl: IntlShape;
}

const GET_ALL_LISTING_POLICIES =`
  query GetAllListingPolicies {
    GetAllListingPolicies {
      id
      listId
      duesAmount
      createdAt
      updatedAt
      userId
      paymentDate
      expirationDate
      paymentCost
      paymentNet
      paymentStatus
      policyInsuranceCode
      policyInsuranceStatus
    }
  }
`;

const UPDATE_POLICY_STATUS =`
  mutation PolicyUpdate($listId: Int!, $policyInsuranceStatus: String!) {
    PolicyUpdate(listId: $listId, policyInsuranceStatus: $policyInsuranceStatus) {
      id
      listId
      policyInsuranceStatus
    }
  }
`;

interface Policy {
  id: number;
  listId: number;
  duesAmount: number;
  createdAt: string;
  updatedAt: string;
  policyInsuranceStatus: string;
  userId: string;
  paymentDate: string;
  expirationDate: string;
  paymentCost: number;
  paymentNet: number;
  paymentStatus: string;
  policyInsuranceCode: string;
}

const ignoredField = ['createdAt', 'updatedAt', 'userId'];


const PolicySettings: React.FC<PolicySettingsProps> = ({ title, intl }) => {
  const { formatMessage } = intl;
  const [policies, setPolicies] = React.useState<Array<Policy>>([]);
  const [loading, setLoading] = React.useState(true);
  
  useEffect(() => {
    const fetchPolicies = async () => {
      try {
        setLoading(true);
        const response = await fetch('/graphql', {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            query: GET_ALL_LISTING_POLICIES
          }),
          credentials: 'include',
        });

        const { data } = await response.json();
        setPolicies(data?.GetAllListingPolicies);
      } catch (error) {
        console.error('Error fetching policies:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPolicies();
  }, []);

  const thead = () => {
    return Object.keys(policies[0] || {})
      .filter(key => !ignoredField.includes(key))
      .map(key => ({
        data: formatMessage(messages[key as keyof typeof messages] || { id: key, defaultMessage: key })
      }));
  };

  const handleStatusUpdate = async (listId: number, value: string) => {
    try {
      const response = await fetch('/graphql', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: UPDATE_POLICY_STATUS,
          variables: {
            listId,
            policyInsuranceStatus: value
          }
        }),
        credentials: 'include',
      });

      const { data, errors } = await response.json();
      
      if (errors) {
        console.error('Error updating policy status:', errors);
        return;
      }

      // Update the local state with the new status
      setPolicies(prevPolicies => 
        prevPolicies.map(policy => 
          policy.listId === listId 
            ? { ...policy, policyInsuranceStatus: value }
            : policy
        )
      );
    } catch (error) {
      console.error('Error updating policy status:', error);
    }
  };

  
  const tbody = () => {
    return policies.map((policy, index) => {
      return {
        id: index,
        data: Object.entries(policy)
          .filter(([key]) => !ignoredField.includes(key))
          .map(([key, value]) => ({
            data: <div className={cx(cs.displayFlex, cs.alignCenter, cs.spaceBetween)}>
              {key === 'policyInsuranceStatus' ? (
                <select 
                  value={policy.policyInsuranceStatus} 
                  aria-label={formatMessage(messages.status)}
                  onChange={(e) => handleStatusUpdate(policy.listId, e.target.value)}
                >
                  <option value="0">{formatMessage(messages.messageStatus5)}</option>
                  <option value="1">{formatMessage(messages.approve)}</option>
                </select>
              ) : (
                String(value).substring(0, 50) + (String(value).length > 50 ? '...' : '')
              )}
            </div>
          }))
      };
    });
  };


  return (
    <div className={cx(s.pagecontentWrapper)}>
      {loading ? <Shimmer /> : (
      <CommonTable
        thead={thead}
        tbody={tbody}
          title={formatMessage(messages.policySettings)}
        />
      )}
    </div>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = {
};
export default compose(
    injectIntl,
    connect(mapState, mapDispatch),
    withStyles(s, cs)
  )(PolicySettings as React.ComponentType<any>);