import { gql } from 'react-apollo';
import {
  SEND_FORGOT_PASSWORD_START,
  SEND_FORGOT_PASSWORD_SUCCESS,
  SEND_FORGOT_PASSWORD_ERROR,
} from '../../constants';
import { closeForgotPasswordModal } from '../modalActions';
import { sendEmail } from '../../core/email/sendEmail';
import showToaster from '../../helpers/toasterMessages/showToaster';

export function sendForgotLink(email) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: SEND_FORGOT_PASSWORD_START,
    });

    dispatch(closeForgotPasswordModal());

    try {

      let mutation = gql`
        mutation sendForgotPassword($email: String!) {
          sendForgotPassword (email: $email) {
            id
            email
            token
            userId
            status
            profile {
              firstName
            }
          }
        }
      `;

      // Send Message
      const { data } = await client.mutate({
        mutation,
        variables: {
          email
        }
      });

      if (data?.sendForgotPassword) {

        if (data?.sendForgotPassword?.status === 'notAvailable') {
          showToaster({ messageId: 'forgotPassword', toasterType: 'error' })
          return false;
        }
        if (data?.sendForgotPassword?.status === '400') {
          showToaster({ messageId: 'tryAgain', toasterType: 'error' })
          return false;
        }
        showToaster({ messageId: 'resetPasswordLink', toasterType: 'success' })
        let content = {
          token: data?.sendForgotPassword?.token,
          email: data?.sendForgotPassword?.email,
          name: data?.sendForgotPassword?.profile?.firstName,
        };
        sendEmail(email, 'forgotPasswordLink', content);
        dispatch({
          type: SEND_FORGOT_PASSWORD_SUCCESS,
        });
      }

    } catch (error) {
      dispatch({
        type: SEND_FORGOT_PASSWORD_ERROR,
        payload: {
          error
        }
      });
      return false;
    }

    return true;
  };
}