// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';


import Loader from '../Loader';
import { formattingCurrency } from '../../helpers/admGetDataField';

import s from './ListPlaceStep1.css';
import bt from '../../components/commonStyle.css';
import messages from '../../locale/messages';

class PolicyPaymentReviewListing extends Component {

	static propTypes = {
		policyListing: PropTypes.object,
		policyPayment: PropTypes.array,
	};
	static defaultProps = {
		// setReadyOnlyPaymentOpt:false
	};
	constructor(props) {
        super(props);
        // this.handleChange = this.handleChange.bind(this);
    }

	// async handleChange(e) {
    //     const { change,onUpdatePaymentOption } = this.props;

        
    //     let currentValue = e.target.value;
    //     console.log('currentValue:', currentValue);
        
	// 	onUpdatePaymentOption(Number(currentValue));
        
	// 	// if (formName === 'ListPlaceStep1') {
	// 		await change('ListPlaceStep3', 'listingExtData.policyPaymentOption', Number(currentValue));
	// 	// }

    //     return e;
    // }

	render() {
		const {policyListing,policyPayment} = this.props;
		const paymentInfo = policyPayment[0];
		
		const { formatMessage } = this.props.intl;
		const requestStatus = paymentInfo.policyInsuranceStatus ? formatMessage(messages.messageStatus5):formatMessage(messages.messageStatus4);
		return (
			<>
				<h3 className={cx(s.iprTitle)}><FormattedMessage {...messages.policyPaymentReview}/></h3>
				<ul className={cx(s.calTable)}>
					{/* <li>
						<h3 className={cx(s.iprTitle)}><FormattedMessage {...messages.policyPaymentReview}/></h3>
					</li> */}
					<li>
						<span className={cx(s.calTableItem)} ><FormattedMessage {...messages.policyCode} />: </span>
						<span className={cx(s.calTableItemValue)}>{paymentInfo.policyInsuranceCode} </span>
					</li>
					<li>
						<span className={cx(s.calTableItem)} ><FormattedMessage {...messages.policyDateRequested} />: </span>
						<span className={cx(s.calTableItemValue)}>{moment(paymentInfo.paymentDate).format('MMM DD, YYYY').toString()}</span>
					</li>
					<li>
						<span className={cx(s.calTableItem)} ><FormattedMessage {...messages.policyStatus} />: </span>
						<span className={cx(s.calTableItemValue)}>{requestStatus}</span>
					</li>
					<li>
						<span className={cx(s.calTableItem)} ><FormattedMessage {...messages.PaymentmethodText} />: </span>
						<span className={cx(s.calTableItemValue)}>{'PayPal'}</span>
					</li>
					
					
					
					{/* <li className={cx(s.calTableItemDivider)}></li>
					<li className={cx(s.calTableItemSecondary)}>
						<span className={cx(s.calTableItem)} ><FormattedMessage {...messages.amountPaid} />: </span>
						<span className={cx(s.calTableItemValue)}>USD$ {formattingCurrency(paymentInfo.paymentAmountPayPal)}</span>
					</li> */}
				</ul>
			</>
		)
	}
}

const mapState = (state) => ({
	loading: state.loader.location,
});

const mapDispatch = {
	change
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(PolicyPaymentReviewListing)));