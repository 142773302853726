import { gql } from 'react-apollo';
import { initialize, change } from 'redux-form';
import fetch from '../core/fetch';
import {
  CREATE_LIST_PHOTOS_START,
  CREATE_LIST_PHOTOS_SUCCESS,
  CREATE_LIST_PHOTOS_ERROR,
  REMOVE_LIST_PHOTOS_START,
  REMOVE_LIST_PHOTOS_SUCCESS,
  REMOVE_LIST_PHOTOS_ERROR,
  BOOKING_UPLOAD_PHOTOS_START,
  BOOKING_UPLOAD_PHOTOS_SUCCESS,
  BOOKING_UPLOAD_PHOTOS_ERROR
} from '../constants';
import { getListPhotos } from './getListPhotos';
import { getListingDataStep2 } from './getListingDataStep2';
import showToaster from '../helpers/toasterMessages/showToaster';


const query = gql`
  query ($typeCheck:Int!, $valueCheck:String! ) {
    checkListingExt (typeCheck:$typeCheck, valueCheck: $valueCheck) {
      status
    }
  }
`;

export function createPhotoLibrary(name,description,type) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: CREATE_LIST_PHOTOS_START,
    });

    try {

      let mutation = gql`
        mutation UploadBlogPhoto ($name: String, $description: String,$type: String) {
          createPhotoLibrary (name: $name, description: $description,type: $type) {
            status
            photosCount
          }
        } 
      `;
      // Send Request to create a record for a listing
      const { data } = await client.mutate({
        mutation,
        variables: { name, description,type },
      });

      if (data?.createPhotoLibrary?.status === 'success') {
        // dispatch(getListPhotos(listId));
        dispatch({
          type: CREATE_LIST_PHOTOS_SUCCESS,
          photosCount: data?.createPhotoLibrary?.photosCount
        });
        showToaster({ messageId: 'uploadCarPhoto', toasterType: 'success' })
      }

    } catch (error) {
      dispatch({
        type: CREATE_LIST_PHOTOS_ERROR,
      });
      return false;
    }

    return true;
  };
}
export function createReservationPhoto(bookingId,listId, name, type,photo_type,photo_return) {
  
  return async (dispatch, getState, { client }) => {
    console.log('createPhotoBooking -->',bookingId,'lid:',listId, 'nme: ',name,'type:', type,'photo_type: ', photo_type,'photo_return: ',photo_return);
    dispatch({
      type: BOOKING_UPLOAD_PHOTOS_START,
    });

    try {

      let mutation = gql`
        mutation uploadReservationPhoto($bookingId:Int!, $listId:Int!,$name: String, $type: String, $photo_type: Int,$photo_return: Int) {
          createReservationPhoto (bookingId: $bookingId,listId:$listId, name: $name, type: $type,photo_type: $photo_type,photo_return:$photo_return) {
            status
          }
        } 
      `;
      // Send Request to create a record for a listing
      const { data } = await client.mutate({
        mutation,
        variables: { bookingId, listId, name, type,photo_type,photo_return },
      });
      
      console.log('debug ->',data);
      if (data?.createReservationPhoto?.status === 'success') {
        console.log('debug sucess ->',data);
        // dispatch(getListPhotos(listId));
        // dispatch({
        //   type: CREATE_LIST_PHOTOS_SUCCESS,
        //   photosCount: data?.createReservationPhoto?.photosCount
        // });
        showToaster({ messageId: 'uploadCarPhoto', toasterType: 'success' })
      }

    } catch (error) {
      
      dispatch({
        type: BOOKING_UPLOAD_PHOTOS_ERROR,
      });
      return false;
    }

    return true;
  };
}

export function verificationListingExt(type,typevalue) {
  
  return async (dispatch, getState, { client }) => {

    try {
      // Send Request to get listing data
      const {data} = await client.query({
        query,
        variables: {type, typevalue},
        fetchPolicy: 'network-only'
      });

      if(data && data.checkListingExt){
        // dispatch({
        //   type: MANAGE_LISTING_STEPS_DATA_SUCCESS,
        //   listingSteps: data.ManageListingSteps
        // });       
      } else {
          // dispatch({
          //   type: MANAGE_LISTING_STEPS_DATA_ERROR,
          // });
          // return false;
      }

    } catch (error) {
        // dispatch({
        //   type: MANAGE_LISTING_STEPS_DATA_ERROR,
        //   payload:{
        //     error
        //   }
        // });
        return false;
    }
  };
}

// export function removePhotoLibrary(listId, name, reload) {

//   return async (dispatch, getState, { client }) => {

//     dispatch({
//       type: REMOVE_LIST_PHOTOS_START,
//     });

//     try {

//       let mutation = gql`
//         mutation RemoveListPhotos($listId:Int!, $name:String) {
//           RemoveListPhotos (listId:$listId, name: $name) {
//             status
//             photosCount
//             iscoverPhotoDeleted
//           }
//         }
//       `;

//       let reservationCount = gql`
//       query getUpcomingBookings ($listId: Int!){
//           getUpcomingBookings(listId: $listId){
//             count
//           }
//         }`;

//       const reservationCountData = await client.query({
//         query: reservationCount,
//         variables: { listId },
//       });

//       if (reservationCountData && reservationCountData.data.getUpcomingBookings && reservationCountData.data.getUpcomingBookings.count > 0) {

//         // If reservation found

//         const showListPhotosQuery = gql`
//       query listPhotos($listId:Int!) {
//         ShowListPhotos (listId:$listId) {
//           id
//           listId
//           name
//           type
//           isCover
//         }
//       }
//     `;

//         const showListPhotosData = await client.query({
//           query: showListPhotosQuery,
//           variables: { listId },
//         });


//         if (showListPhotosData?.data?.ShowListPhotos?.length <= 1) {
//           // If length less 
//           showToaster({ messageId: 'removePhotosListing', toasterType: 'error' })

//           dispatch({
//             type: REMOVE_LIST_PHOTOS_ERROR,
//           });

//         }
//         else {
//           // If length more 
//           const { data } = await client.mutate({
//             mutation,
//             variables: { listId, name },
//           });

//           if (data?.RemoveListPhotos?.status === 'success') {
//             await dispatch(getListingDataStep2(listId));
//             if (data?.RemoveListPhotos?.iscoverPhotoDeleted) {
//               await dispatch(change('ListPlaceStep2', 'coverPhoto', null));
//             }
//             dispatch({
//               type: REMOVE_LIST_PHOTOS_SUCCESS,
//               photosCount: data?.RemoveListPhotos?.photosCount
//             });
//             dispatch(getListPhotos(listId));

//             // Remove file physically
//             const resp = await fetch('/deletePhotos', {
//               method: 'post',
//               headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json',
//               },
//               body: JSON.stringify({ fileName: name }),
//               credentials: 'include',
//             });
//             const { status } = await resp.json();
//           }
//         }

//       } else {
//         // no reservation found

//         const { data } = await client.mutate({
//           mutation,
//           variables: { listId, name },
//         });

//         if (data?.RemoveListPhotos?.status === 'success') {
//           await dispatch(getListingDataStep2(listId));
//           if (data?.RemoveListPhotos?.iscoverPhotoDeleted) {
//             await dispatch(change('ListPlaceStep2', 'coverPhoto', null));
//           }
//           dispatch({
//             type: REMOVE_LIST_PHOTOS_SUCCESS,
//             photosCount: data?.RemoveListPhotos?.photosCount
//           });
//           dispatch(getListPhotos(listId));

//           // Remove file physically
//           const resp = await fetch('/deletePhotos', {
//             method: 'post',
//             headers: {
//               Accept: 'application/json',
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ fileName: name }),
//             credentials: 'include',
//           });
//           const { status } = await resp.json();
//         }
//       }

//     } catch (error) {
//       dispatch({
//         type: REMOVE_LIST_PHOTOS_ERROR,
//         payload: {
//           error
//         }
//       });
//       return false;
//     }

//     return true;
//   };
// }
