import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import ListingViewLimit from './ListingViewLimit';
import { restrictUrls } from '../../../helpers/adminPrivileges';

const title = 'Listing View Verification';

export default async function action({ store, params }) {

        // From Redux Store
        let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
        // let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
        // let privileges = store.getState().listSettings && store.getState().listSettings.privileges;
        
        if (!isAdminAuthenticated) {
            return { redirect: '/siteadmin/login' }
        }
        // Admin restriction
        // if (!restrictUrls('/siteadmin/listingView/', adminPrivileges, privileges)) {
        //     return { redirect: '/siteadmin' };
        // }

        // const data = store.getState().account.data;
        let listId = params.listId;
        // let listingId = 0;
        // let isUser = false;
        if(listId === null || listId === undefined) {
          listId = 0;
          // if(data) {
          //   isUser = true;
          // }
        } else {
          listId = Number(listId);
        }
        console.log('listId',listId);
        return {
            title,
            component: <AdminLayout><ListingViewLimit title={title} listId={listId} /></AdminLayout>,
        };
    };
