var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AgreementsPreview-formControlInput-14Wa0 {\n    width: 340px;\n    max-width: 100%;\n    height: 48px;\n    border: 1px solid var(--search-input-border);\n    border: 1px solid var(--search-input-border);\n    border-radius: 6px;\n}\n.AgreementsPreview-containerStatReview-aLDks{\n    border-bottom: 2px solid #062069;\n}\n.AgreementsPreview-locationBgIcon-1YZk_ {\n    background-repeat: no-repeat;\n    background-position: 18px 50%;\n    padding-left: 40px;\n    padding-right: 18px;\n    background-size: 13px;\n    background-image: url(" + escape(require("../../../public/SiteIcons/searchIcon.svg")) + ");\n}\n.AgreementsPreview-agreementInfo-2i4oW{\n    font-weight: 600;\n    color: #ad2222;\n}\n.AgreementsPreview-agreementPDFView-17Gqq{\n    border: 1px solid transparent;\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n}\n.AgreementsPreview-agreementPDFView-17Gqq:hover{\n    border: 1px solid #110e0e;\n}\n.AgreementsPreview-icheckboxWithBorder-Hudi8{\n    border: 1px solid #110e0e !important ;\n}\n.AgreementsPreview-containerAgreement-3WHfk{\n    padding: 4px 4px 20px 4px;\n}\n.AgreementsPreview-agreementView-3GjLj{\n    width: 100%;\n}\n.AgreementsPreview-checkBoxGrid-2cT1J {\n    display: grid;\n    grid-template-columns: 24px auto;\n}\n.AgreementsPreview-checkBoxText-10Azx {\n    padding-left: 7px;\n}", ""]);

// exports
exports.locals = {
	"formControlInput": "AgreementsPreview-formControlInput-14Wa0",
	"containerStatReview": "AgreementsPreview-containerStatReview-aLDks",
	"locationBgIcon": "AgreementsPreview-locationBgIcon-1YZk_",
	"agreementInfo": "AgreementsPreview-agreementInfo-2i4oW",
	"agreementPDFView": "AgreementsPreview-agreementPDFView-17Gqq",
	"icheckboxWithBorder": "AgreementsPreview-icheckboxWithBorder-Hudi8",
	"containerAgreement": "AgreementsPreview-containerAgreement-3WHfk",
	"agreementView": "AgreementsPreview-agreementView-3GjLj",
	"checkBoxGrid": "AgreementsPreview-checkBoxGrid-2cT1J",
	"checkBoxText": "AgreementsPreview-checkBoxText-10Azx"
};