exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".VerificationFallback-accordion-header-2sows {\n  cursor: pointer;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  padding: 10px;\n}\n\n.VerificationFallback-accordion-icon-1X3Fd {\n  font-size: 20px;\n  font-weight: bold;\n}\n\n.VerificationFallback-accordion-content-2A-yU {\n  padding: 10px;\n}\n\n.VerificationFallback-verification-container-3OEJn {\n  width: 100%;\n  max-width: 1080px;\n  padding-top: 20px;\n  margin: 0 auto;\n}\n", ""]);

// exports
exports.locals = {
	"accordion-header": "VerificationFallback-accordion-header-2sows",
	"accordion-icon": "VerificationFallback-accordion-icon-1X3Fd",
	"accordion-content": "VerificationFallback-accordion-content-2A-yU",
	"verification-container": "VerificationFallback-verification-container-3OEJn"
};