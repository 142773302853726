import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import moment from 'moment';

// Styles
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ItemsMedia.css';
import cp from '../../../../components/commonStyle.css';

class ItemsMedia extends PureComponent {
    
    static propTypes = {
        // title: PropTypes.string.isRequired,
        itemsData: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          type: PropTypes.string,
          createdAt: PropTypes.string,
        })),
        
    };
    
    static defaultProps = {
        itemsData: []
    };

    componentDidMount() {

    }

    render() {
        const { itemsData } = this.props;

        return (
            itemsData != null && itemsData.length > 0 && itemsData.map((item,key) => {
                return (
                    <div key={key} className={cx(cp.itemPhotoInline)}>
                        <a href={'/files/'+item.name} target={"_blank"}  className={cx(s.itemMediaFocus,cp.textDecorationNone)}>
                        <div className={cx(s.boxShadow)}>
                            <div className={cx(s.HRCar)} >
                                <img className={cx(s.photoImageGallery)} src={'/files/x_small_'+item.name} />
                            </div>
                            
                            <div className={cx(s.sliderbackground)}>
                                <div className={cx(s.HRDetails)}>
                                    <div className={cx(s.trip,s.hiddentextSlider)}>
                                    <span className={cx(s.carTypeRTL)}>{item.id}: {item.type}</span>
                                    </div>
                                </div>
                                <h5 className={cx(s.titleCss,s.setFontWSize14)}>{moment(item.createdAt).format('MMM DD, YYYY hh:mm A').toString()}</h5>
                            </div>
                        </div>
                        </a>
                    </div>
                
                )
            })
        );
    }
}

ItemsMedia.propTypes = {

};

const mapState = (state) => ({});

const mapDispatch = {
  // deleteMedia,
  // updateBlogStatus
};

export default injectIntl(withStyles(s, cp)(connect(mapState, mapDispatch)(ItemsMedia)));

// export default ItemsMedia;
