import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './ListPlaceStep1.css';
import cs from '../commonStyle.css';
import FooterButton from './FooterButton';
import SidePanel from './SidePanel';
import update from './update';
import messages from '../../locale/messages';
import validate from './validate';


class Goals extends Component {
  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
    formErrors: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
    };
  }


  render() {
    const { handleSubmit, previousPage, nextPage, formPage, step } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx(s.stepGrid, 'stepGridRTL')}>
        <SidePanel
          title={formatMessage(messages.step1HeadingNew)}
          landingContent={formatMessage(messages.goalsQuestionTitle)}
        />
        <form onSubmit={handleSubmit}>
          <div className={s.landingMainContent}>
            <h2 className={cx(cs.commonContentText, cs.spaceBottom4)}>
              {formatMessage(messages.goalsQuestionTitle)}
            </h2>
            <FormGroup className={cx(s.formGroup, cs.spaceBottom4)}>
              <ControlLabel className={cx(s.landingLabel, cs.spaceBottom2)}>
                {formatMessage(messages.primaryGoalLabel)}
              </ControlLabel>
              <Field
                name="primaryGoal"
                component="select"
                className={cx(s.formControlSelect, s.jumboSelect, cs.commonInputPadding)}
              >
                  <option value="">{formatMessage(messages.selectOption)}</option>
                  <option value="scale">{formatMessage(messages.goalScaleOption)}</option>
                  <option value="extra">{formatMessage(messages.goalExtraOption)}</option>
                  <option value="test">{formatMessage(messages.goalTestOption)}</option>
              </Field>
            </FormGroup>

            <FormGroup className={cx(s.formGroup, cs.spaceBottom4)}>
              <ControlLabel className={cx(s.landingLabel, cs.spaceBottom2)}>
                {formatMessage(messages.secondaryGoalLabel)}
              </ControlLabel>
              <Field
                name="secondaryGoal" 
                component="select"
                className={cx(s.formControlSelect, s.jumboSelect, cs.commonInputPadding)}
              >
                <option value="">{formatMessage(messages.selectOption)}</option>
                <option value="rarely">{formatMessage(messages.frequencyRarelyOption)}</option>
                <option value="sometimes">{formatMessage(messages.frequencySometimesOption)}</option>
                <option value="often">{formatMessage(messages.frequencyOftenOption)}</option>
                <option value="always">{formatMessage(messages.frequencyAlwaysOption)}</option>
              </Field>
            </FormGroup>

            <FormGroup className={cx(s.formGroup, cs.spaceBottom4)}>
              <ControlLabel className={cx(s.landingLabel, cs.spaceBottom2)}>
                {formatMessage(messages.tertiaryGoalLabel)}
              </ControlLabel>
              <Field
                name="tertiaryGoal"
                component="select" 
                className={cx(s.formControlSelect, s.jumboSelect, cs.commonInputPadding)}
              >
                <option value="">{formatMessage(messages.selectOption)}</option>
                <option value="rarely">{formatMessage(messages.frequencyRarelyOption)}</option>
                <option value="sometimes">{formatMessage(messages.frequencySometimesOption)}</option>
                <option value="often">{formatMessage(messages.frequencyOftenOption)}</option>
                <option value="always">{formatMessage(messages.frequencyAlwaysOption)}</option>
                </Field>
            </FormGroup>
          </div>
          <FooterButton
            previousPage={previousPage}
            previousPagePath={"features"}
            nextPage={nextPage}
            nextPagePath={"photos"}
            formPage={formPage}
            type="submit"
            step={step}
            isFinish
          />
        </form>
      </div>
    );
  }
}

Goals = reduxForm({
  form: 'ListPlaceStep1',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmit: update
})(Goals);

const mapState = (state) => ({
  formErrors: state.form.ListPlaceStep1
});

export default injectIntl(withStyles(s, cs)(connect(mapState)(Goals)));