import React from 'react';
import UserLayout from '../../components/Layout/UserLayout';
import AgreementContainer from './AgreementContainer';
import NotFound from '../notFound/NotFound';
import Layout from '../../components/Layout/Layout';
import { Store } from 'redux';

const title = 'Agreement';

export default async function action({ store, params }: {
  store: Store<any>;
  params: {
    type: 'overview' | 'info' | 'view';
  };
}) {
  const isAuthenticated = store.getState().runtime.isAuthenticated;
  const type = params.type;
  if (!isAuthenticated) {
    return { redirect: '/login' };
  }
  if ( type === 'view') {
    return {
      title,
      component: <UserLayout ><AgreementContainer  type={type} /></UserLayout>,
    }
  }
  return {
    title,
    // @ts-expect-error
    component: <Layout><NotFound title={title} /></Layout>,
    status: 404
  }

};
