import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, gql, compose } from 'react-apollo';

class FileSingle extends React.Component {

    render(){

        const { data } = this.props;
        let pdf = "PDF";
        let img = "Image";
        let path = "/images/document/";
        let icon = data.fileType == 'application/pdf' ? pdf : (img); 
        return(
            <div>
               {
                    // data.map((item, index) =>{
                        // let icon = item.fileType == 'application/pdf' ? pdf : (img);                                           
                        // return (
                            <div key={data.fileName}>
                                <a href={path + data.fileName} target="_blank">{icon} </a>                                
                            </div>
                        // )                               
                //    })
               }                           
           </div>
           
        )
    }

}
export default FileSingle;

