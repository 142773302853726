import { gql } from 'react-apollo';
import {
    SET_PAYMENT_GATEWAY_STATUS_START,
    SET_PAYMENT_GATEWAY_STATUS_SUCCESS,
    SET_PAYMENT_GATEWAY_STATUS_ERROR
} from '../../constants';
import showToaster from '../../helpers/toasterMessages/showToaster';

export function updatePaymentGatewayStatus(id, isEnable) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: SET_PAYMENT_GATEWAY_STATUS_START
        });
        try {

            let query = gql`
                query getAllPaymentMethods {
                    getAllPaymentMethods {
                        id
                        name
                        paymentName
                        processedIn
                        fees
                        currency
                        details
                        isEnable
                        paymentType
                        createdAt
                        updatedAt
                        status
                    }
                }
            `;

            let mutation = gql`
                mutation updatePaymentGateWayStatus ($id: Int!, $isEnable: Boolean!) {
                    updatePaymentGatewayStatus(id: $id, isEnable: $isEnable) {
                        status
                    }
                }
            `;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    isEnable
                },
                refetchQueries: [{ query }]
            });
            let activeStatus = isEnable ? 'activated' : 'de-activated';
            if (data && data.updatePaymentGatewayStatus && data.updatePaymentGatewayStatus.status == 200) {
                dispatch({
                    type: SET_PAYMENT_GATEWAY_STATUS_SUCCESS
                });
                if (id == 1) {
                    showToaster({ messageId: 'paypalPaymentGateway', toasterType: 'success', requestMessage: activeStatus })
                } else {
                    showToaster({ messageId: 'stripePaymentGateway', toasterType: 'success', requestMessage: activeStatus })
                }

                //Paypal has been activate successfully
                return true;
            } else if (data && data.updatePaymentGatewayStatus && data.updatePaymentGatewayStatus.status == 400) {
                dispatch({
                    type: SET_PAYMENT_GATEWAY_STATUS_ERROR
                });
                showToaster({ messageId: 'paymentGatewayStatusFailed', toasterType: 'error' })
            } else if (data && data.updatePaymentGatewayStatus && data.updatePaymentGatewayStatus.status == 'Atleast one option must be active') {
                dispatch({
                    type: SET_PAYMENT_GATEWAY_STATUS_ERROR
                });
                showToaster({ messageId: 'paymentGatewayStatus', toasterType: 'error' })
            }

        } catch (error) {
            dispatch({
                type: SET_PAYMENT_GATEWAY_STATUS_ERROR
            });
            showToaster({ messageId: 'paymentGatewayStatusFailed', toasterType: 'error' })
        }
    }
}