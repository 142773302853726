import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { closeAdminRolesModal } from '../../../actions/siteadmin/modalActions';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import messages from '../../../locale/messages';
import s from './adminPrivilegesModal.css';

import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';
import { MdOutlineDeleteSweep, MdFormatListBulletedAdd } from "react-icons/md";
import CommonFormComponent from '../../CommonField/CommonFormComponent';
import { compose } from 'redux';

interface AdminPrivilageModalProps {
  showModal: boolean;
  onClose?: () => void;
  privilege: {id: string, privilege: string, permittedUrls: Array<string>} | null;
  createPrivilege: (data: {privilege: string, permittedUrls: string[]}) => void;
  updatePrivilege: (id: string, data: {permittedUrls: string[]}) => void;
  intl: IntlShape;
}

const AdminPrivilageModal: React.FC<AdminPrivilageModalProps> = ({ showModal, onClose, privilege, createPrivilege, updatePrivilege,intl }) => {
  const [localUrls, setLocalUrls] = useState<string[]>([]);
  const [newUrl, setNewUrl] = useState('');
  const [privilegeName, setPrivilegeName] = useState('');
  const [urlError, setUrlError] = useState<string | null>(null);

  useEffect(() => {
    if (showModal) { 
      if (privilege) {
        setLocalUrls(privilege.permittedUrls);
        setPrivilegeName(privilege.privilege);
      } else {
        setLocalUrls([]);
        setPrivilegeName('');
      }
      setNewUrl('');
      setUrlError(null);
    }
  }, [showModal, privilege]);

  const validateUrl = (url: string): boolean => {
    const validPatterns = [
      /^\/siteadmin\/[^/]+\/[^/]+$/,  // /siteadmin/*/*
      /^\/siteadmin\/[^/]+\/$/,       // /siteadmin/*/
      /^\/siteadmin\/[^/]+$/          // /siteadmin/*
    ];

    return validPatterns.some(pattern => pattern.test(url));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewUrl(e.target.value);
    setUrlError(null);
  };

  const handlePrivilegeNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrivilegeName(e.target.value);
  };

  const handleAdd = () => {
    if (newUrl) {
      if (validateUrl(newUrl)) {
        setLocalUrls(prev => [...prev, newUrl]);
        setNewUrl('');
        setUrlError(null);
      } else {
        setUrlError('URL must follow pattern: /siteadmin/*, /siteadmin/*/, or /siteadmin/*/*');
      }
    }
  };

  const handleDelete = (urlIndex: number) => {
    setLocalUrls(prev => prev.filter((_, i) => i !== urlIndex));
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    // Reset all fields when closing the modal
    setLocalUrls([]);
    setNewUrl('');
    setPrivilegeName('');
    setUrlError(null);
  };

  const handleSubmit = () => {
    if (privilege?.id) {
      updatePrivilege(privilege.id, { permittedUrls: localUrls });
    } else {
      createPrivilege({ privilege: privilegeName, permittedUrls: localUrls });
    }
    handleClose();
  };

  return (
    <Modal show={showModal} onHide={handleClose} className={cx('adminModal', 'adminPrivilage')}>
       <Modal.Header closeButton>
                <Modal.Title>
                    {privilege?.id ? 'Editing' : 'Creating'} Privilege
                    {privilege?.id && <strong>: {privilege.privilege}</strong>}
                </Modal.Title>
            </Modal.Header>
      <Modal.Body className={s.loginModalBody}>
        <div className={cx(s.modalRoot, s.modalBorderBottom)}>
          <FormGroup>
            <Field
              name="privilegeName"
              type="text"
              component={CommonFormComponent}
              label="Privilege Name"
              value={privilegeName}
              onChange={handlePrivilegeNameChange}
              placeholder="Enter privilege name"
            />
          </FormGroup>
          <section className={s.privilegeList}>
            <h4 className={s.sectionTitle}>Current Permitted URLs:</h4>
            {localUrls.map((url, urlIndex) => (
              <Row key={urlIndex} className={cx(s.listItem, s.urlRow)}>
                <Col xs={11}>{url}</Col>
                <Col xs={1}>
                  <Button 
                    onClick={() => handleDelete(urlIndex)} 
                    className={cx(s.deleteButton, s.iconButton)}
                    aria-label="Delete URL"
                  >
                    <MdOutlineDeleteSweep size={20} />
                  </Button>
                </Col>
              </Row>
            ))}
          </section>

          <section className={s.addUrlSection}>
            <h4 className={s.sectionTitle}>Add New URL:</h4>
            <form onSubmit={(e) => { e.preventDefault(); handleAdd(); }}>
              <Row className={s.addUrlRow}>
                <Col xs={12} md={10}>
                  <FormGroup>
                    <Field
                      name="url"
                      type="text"
                      component={CommonFormComponent}
                      label="New URL Path"
                      value={newUrl}
                      onChange={handleInputChange}
                      placeholder="Enter new URL path"
                    />
                    {urlError && <div className={s.errorMessage}>{urlError}</div>}
                  </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                  <Button 
                    className={cx(s.btnPrimary, s.btnMedium, s.btnIcon, s.addButton)}
                    disabled={!newUrl}
                    onClick={handleAdd}
                    aria-label="Add URL"
                  >
                    <MdFormatListBulletedAdd size={20} />
                  </Button>
                </Col>
              </Row>
            </form>
          </section>
        </div>
      </Modal.Body>
      <Modal.Footer>
                <Button onClick={handleClose}>Cancel</Button>
                <Button 
                    className={cx(s.btnPrimary)} 
                    onClick={handleSubmit}
                    disabled={!privilegeName || localUrls.length === 0}
                >
                    {privilege?.id ? 'Save Changes' : 'Create Privilege'}
                </Button>
            </Modal.Footer>
    </Modal>
  );
};

const AdminPrivilageModalReduxForm = reduxForm<{}, AdminPrivilageModalProps>({
  form: 'AdminPrivilageForm',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true
})(AdminPrivilageModal);

const mapStateToProps = (state: any) => ({
  adminPrivilageModal: state.adminModalStatus.adminRolesModal,
});

const mapDispatchToProps = {
  closeAdminRolesModal
};

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(s),
)(AdminPrivilageModalReduxForm);
