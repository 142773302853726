import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import ContractsSettings from './ContractsSettings';

const title = 'Contracts Settings';

export default async function action({ store }) {

    // From Redux Store
    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;

    let isSuperAdmin = store.getState().runtime.isSuperAdmin

    if (!isAdminAuthenticated || !isSuperAdmin) {
      return { redirect: '/siteadmin/login' };
    }


    return {
      title,
      component: <AdminLayout><ContractsSettings title={title} /></AdminLayout>,
    };
  };
