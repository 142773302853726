exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SocialLoginRegister-displayFlex-28JgR {\n\tdisplay: -webkit-box;\n\tdisplay: -ms-flexbox;\n\tdisplay: flex;\n\t-webkit-box-align: center;\n\t    -ms-flex-align: center;\n\t        align-items: center;\n}\n\n.SocialLoginRegister-marginRight-2gdTi {\n\tmargin-right: 23px;\n}\n\n.SocialLoginRegister-center-3AoIx{\n\t-webkit-box-pack: center;\n\t    -ms-flex-pack: center;\n\t        justify-content: center;\n}\n\n.SocialLoginRegister-btnSignUpLinkDisabled-3Qo12{\n\topacity: 0.6;\n    cursor: not-allowed;\n}", ""]);

// exports
exports.locals = {
	"displayFlex": "SocialLoginRegister-displayFlex-28JgR",
	"marginRight": "SocialLoginRegister-marginRight-2gdTi",
	"center": "SocialLoginRegister-center-3AoIx",
	"btnSignUpLinkDisabled": "SocialLoginRegister-btnSignUpLinkDisabled-3Qo12"
};