import { gql } from 'react-apollo';
import { initialize, change } from 'redux-form';
import fetch from '../core/fetch';
// import {
//   CREATE_LIST_PHOTOS_START,
//   CREATE_LIST_PHOTOS_SUCCESS,
//   CREATE_LIST_PHOTOS_ERROR,
//   REMOVE_LIST_PHOTOS_START,
//   REMOVE_LIST_PHOTOS_SUCCESS,
//   REMOVE_LIST_PHOTOS_ERROR
// } from '../constants';

import showToaster from '../helpers/toasterMessages/showToaster';

export function updateUserPassword(oldPassword,newPassword,confirmPassword,registeredType) {

  return async (dispatch, getState, { client }) => {

    try {
        
        if (newPassword != confirmPassword) {
            showToaster({ messageId: 'confirmPasswordError', toasterType: 'error' })
            return 'error';
        }

        let mutation = gql`
            mutation (
                $oldPassword: String,
                $newPassword: String,
                $confirmPassword: String,
                $registeredType: String,
            ) {
                ChangePassword (
                    oldPassword: $oldPassword,
                    newPassword: $newPassword,
                    confirmPassword: $confirmPassword,
                    registeredType: $registeredType
                ) {
                    status
                }
        } `;
      
        // Send Request to create a record for a listing
        const { data } = await client.mutate({
            mutation,
            variables: { oldPassword,newPassword,confirmPassword,registeredType },
        });

        if (data?.ChangePassword?.status === 'success') {
            showToaster({ messageId: 'passwordUpdateSuccess', toasterType: 'success' })
            return 'success';
        } else if (data?.ChangePassword?.status === 'WrongPassword') {
            showToaster({ messageId: 'incorrectPassword', toasterType: 'error' })
            return 'error';
        } else if (data?.ChangePassword?.status === 'notLoggedIn') {
            showToaster({ messageId: 'userNotLogin', toasterType: 'error' })
            return 'error';
        } else if (data?.ChangePassword?.status === 'WrongConfirmPassword') {
            showToaster({ messageId: 'currentPassword', toasterType: 'error' })
            return 'error';
        } else {
            showToaster({ messageId: 'changePasswordError', toasterType: 'error' })
            return 'error';
        }

    } catch (error) {
      
      return false;
    }

    return true;
  };
}