// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
// import {Button,} from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';


import Loader from '../Loader';
import { formattingCurrency } from '../../helpers/admGetDataField';

import s from './ListPlaceStep1.css';
import bt from '../../components/commonStyle.css';
import messages from '../../locale/messages';

class PolicyReviewListing extends Component {

	static propTypes = {
		setPolicyPlan: PropTypes.string,
		onUpdatePaymentOption: PropTypes.any,
		setYear: PropTypes.number,
		setPolicyPaymentOption: PropTypes.number,
		setReadyOnlyPaymentOpt:PropTypes.bool,
		setPolicyRateValue: PropTypes.number,
		setPolicyQTPayment: PropTypes.number,
		setPolicyFirstDues: PropTypes.number,
		setPolicyDues: PropTypes.number,
		setPolicyExpire: PropTypes.string,
		setPolicySubNet: PropTypes.number,
		setPolicyExtraCost: PropTypes.number,
		setPolicyNet: PropTypes.number,
		setPolicyNetUSD: PropTypes.number,
	};
	static defaultProps = {
		setReadyOnlyPaymentOpt:false
	};
	constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

	async handleChange(e) {
        const { change,onUpdatePaymentOption } = this.props;

        
        let currentValue = e.target.value;
        console.log('currentValue:', currentValue);
        
		onUpdatePaymentOption(Number(currentValue));
        
		// if (formName === 'ListPlaceStep1') {
			await change('ListPlaceStep3', 'listingExtData.policyPaymentOption', Number(currentValue));
		// }

        return e;
    }

	render() {
		const {setPolicyPlan,setYear,setPolicyRateValue,setPolicyQTPayment,setPolicyFirstDues,setPolicyDues,setPolicyExpire,setPolicySubNet,setPolicyExtraCost,setPolicyNet,setPolicyNetUSD,setPolicyPaymentOption,setReadyOnlyPaymentOpt} = this.props;
		console.log('setPolicyNetUSD:', setPolicyNetUSD);
		const { formatMessage } = this.props.intl;

		return (

			<ul className={cx(s.calTable)}>
				<li>
					<span className={cx(s.calTableItem)} ><FormattedMessage {...messages.policyPlanCoverage} />: </span>
					<span className={cx(s.calTableItemValue)}>
					{setPolicyPlan == 'montly' ? <FormattedMessage {...messages.policyInsurancePlan1} />:''}
					{setPolicyPlan == 'quarterly' ? <FormattedMessage {...messages.policyInsurancePlan2} />:''}
					{setPolicyPlan == 'biannual' ? <FormattedMessage {...messages.policyInsurancePlan3} />:''}
					{setPolicyPlan == 'yearly' ? <FormattedMessage {...messages.policyInsurancePlan4} />:''}
					</span>
				</li>
				{setPolicyPlan == 'yearly' && <li className={cx(s.calTableItemPay,setReadyOnlyPaymentOpt?s.calTableItemPayReadOnly:'')}>
					<span className={cx(s.calTableItem)} ><FormattedMessage {...messages.howPayPolicy} />: </span>
					{!setReadyOnlyPaymentOpt && <span className={cx(s.calTableItemValue)}>
						 <select 
							className={cx(s.formControlSelect,s.formSelectBgTransparent, s.jumboSelect, s.marginBottompageTwo)} 
							onChange={(e) => this.handleChange(e)}
							value={setPolicyPaymentOption}
						>
							<option value={0}>{formatMessage(messages.selectOption)}</option>
							<option value={1}>{formatMessage(messages.howPayPolicyOpt1)}</option>
							<option value={2}>{formatMessage(messages.howPayPolicyOpt2)}</option>
						</select>
					</span>}
					{setReadyOnlyPaymentOpt && <span className={cx(s.calTableItemValue)}>
						{setPolicyPaymentOption ==1 && <FormattedMessage {...messages.howPayPolicyOpt1} />}
						{setPolicyPaymentOption ==2 && <FormattedMessage {...messages.howPayPolicyOpt2} />}
					</span>}
				</li>}
				<li>
					<span className={cx(s.calTableItem)} ><FormattedMessage {...messages.inputValuationRatePolicy} />: </span>
					<span className={cx(s.calTableItemValue)}>DOP$ {formattingCurrency(setPolicyRateValue)}</span>
				</li>
				<li>
					<span className={cx(s.calTableItem)} ><FormattedMessage {...messages.policyVehicleYear} />: </span>
					<span className={cx(s.calTableItemValue)}>{setYear}</span>
				</li>
				<li>
					<span className={cx(s.calTableItem)} ><FormattedMessage {...messages.policyQTPayment} />: </span>
					<span className={cx(s.calTableItemValue)}>{setPolicyQTPayment}</span>
				</li>
				{setPolicyQTPayment>1 &&
				<li>
					<span className={cx(s.calTableItem)} ><FormattedMessage {...messages.policyFirstDues} />: </span>
					<span className={cx(s.calTableItemValue)}>{formattingCurrency(setPolicyFirstDues)}</span>
				</li>
				}
				{setPolicyQTPayment>1 && <li>
					<span className={cx(s.calTableItem)} ><FormattedMessage {...messages.policyDues} />: </span>
					<span className={cx(s.calTableItemValue)}>{setPolicyQTPayment-1} dues of DOP$ {setPolicyDues} monthly</span>
				</li>
				}
				<li className={cx(s.calTableItemNoBorder)}>
					<span className={cx(s.calTableItem)} ><FormattedMessage {...messages.policyExpiration} />: </span>
					<span className={cx(s.calTableItemValue)}>{setPolicyExpire}</span>
				</li>
				<li className={cx(s.calTableItemDivider)}></li>
				<li>
					<span className={cx(s.calTableItem)} ><FormattedMessage {...messages.policySubNet} />: </span>
					<span className={cx(s.calTableItemValue)}>DOP$ {formattingCurrency(setPolicySubNet)}</span>
				</li>
				<li>
					<span className={cx(s.calTableItem)} ><FormattedMessage {...messages.policyExtraCost} />: </span>
					<span className={cx(s.calTableItemValue)}>DOP$ {formattingCurrency(setPolicyExtraCost)}</span>
				</li>
				<li>
					<span className={cx(s.calTableItem)} ><FormattedMessage {...messages.policyNet} />: </span>
					<span className={cx(s.calTableItemValue)}>DOP$ {formattingCurrency(setPolicyNet)}</span>
				</li>
				<li className={cx(s.calTableItemSecondary)}>
					<span className={cx(s.calTableItem)} ><FormattedMessage {...messages.policyPayNow} />: </span>
					<span className={cx(s.calTableItemValue)}>USD$ {formattingCurrency(setPolicyNetUSD)}</span>
				</li>
			</ul>
		)
	}
}

const mapState = (state) => ({
	loading: state.loader.location,
});

const mapDispatch = {
	change
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(PolicyReviewListing)));