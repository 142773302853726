// General
import React from 'react';
import PropTypes from 'prop-types';
import {graphql, compose} from 'react-apollo';

// Styles
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ItineraryContainer.css';

// Component
import ItineraryVehicleStatusPhoto from '../../components/ItineraryVehicleStatusPhoto/ItineraryVehicleStatusPhoto';
// import Itinerary from '../../components/Itinerary';
import Loader from '../../components/Loader';

// Graphql
// import getItineraryQuery from './getItineraryQuery.graphql';
import viewReservationQuery from './viewReservationQuery.graphql';
import NotFound from '../notFound/NotFound';

class ItineraryContainerVehicleStatus extends React.Component {
  static propTypes = {
    reservationId: PropTypes.number.isRequired,
    vehiclePhotoType: PropTypes.number.isRequired,
    viewReservationData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      viewReservationUser: PropTypes.object.isRequired
    })
  };

  static defaultProps = {
    viewReservationData: {
      loading: true,
    }
  };

  render() {
    const { vehiclePhotoType } = this.props;
    const { viewReservationData: {loading, viewReservationUser} } = this.props;
    if(!loading && !viewReservationUser){
      return <NotFound />
    }
    if(loading){
      return (
        <>
          <Loader type={"text"} show={loading} />
        </>
      );
    }

    return (
      <>
        <ItineraryVehicleStatusPhoto vehiclePhotoType={vehiclePhotoType} data={viewReservationUser}  />
      </>
    );
  }
}

export default compose(
    withStyles(s),
    graphql(viewReservationQuery,
      {
        name: 'viewReservationData',
        options: (props) => ({
          variables : {
            reservationId: props.reservationId,
          },
          fetchPolicy: 'network-only',
        })
      }      
    ),
)(ItineraryContainerVehicleStatus);