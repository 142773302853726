import React from 'react';
import { graphql, compose } from 'react-apollo';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Dashboard.css';
import cs from '../../components/commonStyle.css';
import Link from '../Link/Link';
import UnreadMessages from './UnreadMessages';
import UnreadThreadsQuery from './getUnreadThreads.graphql';
import messages from '../../locale/messages';
import arrow from '/public/siteImages/rightSideArrow.svg';
import handIcon from '/public/siteImages/dashBoardHandIcon.png';
import allMessages from '/public/siteImages/messages.svg';
import reviews from '/public/siteImages/reviews.svg';
import youCars from '/public/siteImages/yourCars.svg';
import DashboardSideMenu from './DashboardSideMenu';
import { Account } from '../../types';

function Dashboard({
  account,
  allUnreadThreads,
  ...props
}: {
    account?: Account,
    allUnreadThreads?: {
      loading: boolean,
      getUnreadThreads: Array<any>
    },
    intl?: IntlShape
  }) {
    const { formatMessage } = props.intl || {
      formatMessage: () => ''
    };
    const { loading, getUnreadThreads } = allUnreadThreads || {
      loading: true,
      getUnreadThreads: []
    };
    let newMessages = 0;
    if (!loading) {
      newMessages = getUnreadThreads != null ? getUnreadThreads.length : 0;
    }
    let messageCount = formatMessage(messages.messages) + ` (${newMessages} ` + formatMessage(messages.messagesNew) + ')';

    return (
      <>
        <Col xs={12} sm={12} md={4} lg={4} className={cs.spaceTop6}>
        {/* @ts-expect-error */}
          <DashboardSideMenu isDashboardPage/>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8} className={cs.spaceTop6}>
          <div className={cx(cs.commonBorderSection, cs.spaceBottom3, s.bgColor, s.handIconGrid)}>
            <div>
           { account?.firstName && <h3 className={cx(cs.commonTotalText, cs.fontWeightBold, cs.paddingBottom3)}>{formatMessage(messages.dashBoardHeader1) + ', ' + account.firstName}</h3> }
              <p className={cs.commonMediumText}>
                <FormattedMessage {...messages.dashBoardInfo} />
              </p>
            </div>
            <img src={handIcon} />
          </div>
          <div className={cx(s.btnMainGrid, cs.spaceBottom6)}>
            
            <Link to={'/inbox'} className={cx(cs.commonContentText, cs.fontWeightMedium, cs.siteTextColor,s.dashboardBtnMarginTop, cs.textDecorationNone, s.btnDisplayGrid, 'whiteBgColor')}>
              <img src={allMessages} />
              <div className={s.btnDisplayFlex}>
                <h4 className={cx(cs.commonMediumText, cs.fontWeightNormal)}><FormattedMessage {...messages.allMessages} /></h4>
                <img src={arrow} className={cx(cs.blueLeftArrow, 'loginArrowRTL', 'blueLeftArrowRTL')} />
              </div>
            </Link>

            <Link to={'/user/reviews/about-you'} className={cx(cs.commonContentText, cs.fontWeightMedium,s.dashboardBtnMarginTop, cs.textDecorationNone, s.btnDisplayGrid, cs.siteTextColor, 'whiteBgColor')}>
              <img src={reviews} />
              <div className={s.btnDisplayFlex}>
                <h4 className={cx(cs.commonMediumText, cs.fontWeightNormal)}><FormattedMessage {...messages.reviews} /></h4>
                <img src={arrow} className={cx(cs.blueLeftArrow, 'loginArrowRTL', 'blueLeftArrowRTL')} />
              </div>
            </Link>

            <Link to={'/cars'} className={cx(cs.commonContentText, cs.fontWeightMedium, cs.textDecorationNone,s.dashboardBtnMarginTop, s.btnDisplayGrid, cs.siteTextColor, 'whiteBgColor')}>
              <img src={youCars} />
              <div className={s.btnDisplayFlex}>
                <h4 className={cx(cs.commonMediumText, cs.fontWeightNormal)}><FormattedMessage {...messages.yourLists} /></h4>
                <img src={arrow} className={cx(cs.blueLeftArrow, 'loginArrowRTL', 'blueLeftArrowRTL')} />
              </div>
            </Link>
          </div>
          <div className={cx(cs.commonBorderSection, 'whiteBgColor')}>
            <h4 className={cx(cs.commonContentText, cs.fontWeightBold)}>{messageCount}</h4>
            {/* @ts-expect-error */}
            <UnreadMessages userId={account.userId} loading={loading} getUnreadThreads={getUnreadThreads} />
          </div>
        </Col>
      </>  
    );
  }

const mapState = (state: any) => ({
  account: state.account.data,
  siteName: state.siteSettings.data.siteName
});

const mapDispatch = {
};

export default compose(
  injectIntl,
  withStyles(s, cs),
  connect(mapState, mapDispatch),
  graphql(UnreadThreadsQuery, {
    name: 'allUnreadThreads',
    options: {
      ssr: false,
      pollInterval: 5000,
      fetchPolicy: 'network-only'
    }
  })
)(Dashboard) as unknown as typeof Dashboard;