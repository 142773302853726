import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Layout.css';
import Header from '../Header';
import Footer from '../Footer';
import SubnavBar from '../SubnavBar';
import CookiesDisclaimer from '../CookiesDisclaimer';
import SideMenu from '../ManageListing/SideMenu/SideMenu';
import {
  Grid,
  Row,
  Col
} from 'react-bootstrap';
import cs from '../../components/commonStyle.css'
import VerificationFallback from './VerificationFallback';

const UserLayout = ({ children, showSideMenu }) => {
  return (
    <>
      <Header />
      <div className={s.userLayoutBg}>
        <VerificationFallback>
          <SubnavBar />
          <Grid fluid className={'listingContainer'}>
            <Row>
              {showSideMenu &&
                <Col xs={12} sm={12} md={4} lg={4} className={cs.spaceTop6}>
                  <SideMenu showSideMenu={showSideMenu} />
                </Col>
              }
              {children}
            </Row>
          </Grid>
        </VerificationFallback>
        <Footer whiteBg={true} />
      </div>
      <CookiesDisclaimer />
    </>
  );
};

UserLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withStyles(s)(UserLayout);