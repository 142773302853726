import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ListingViewLimit.css';

// Query 
import listingViewDetail from './listingViewQuery.graphql';

// Component
import ListingVerifiedViewLimit from '../../../components/siteadmin/ListingVerifiedViewLimit';

import Loader from '../../../components/Loader';
import NotFound from '../../notFound/NotFound';

class ListingViewLimit extends React.Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        data: PropTypes.shape({
            loading: PropTypes.bool,
            getListMeta: PropTypes.object,
        })
    };

    static defaultProps = {
        data: {
            loading: true,

        }
    };

    render() {
        const { data: { loading, getListMeta }, title, listId } = this.props;
        // const { data, title, listId } = this.props;

        // console.log('listId -->',listId);
        // console.log('listId -->',loading);
        
        
        if (loading) {
            return <Loader type={"text"} />;
        }

        if (!loading && getListMeta) {
            // if(data){ console.log('data',data); }
            if(getListMeta){ console.log('getListMeta',getListMeta); };
        //     <div>NO FAIL</div>
            return <ListingVerifiedViewLimit
                data={getListMeta}
                title={title}
                listId={listId}
            />;
        }

        if (!loading) {
            return <NotFound />
        }
    }

}

export default compose(
    withStyles(s),
    graphql(listingViewDetail,
        {
            options: (props) => ({
                variables: {
                    listId: props.listId,
                },
                fetchPolicy: 'network-only',
                ssr: false
            })
        }),
)(ListingViewLimit);
