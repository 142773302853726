import { gql } from 'react-apollo';
import {
    ADMIN_DELETE_BlOGDETAILS_START,
    ADMIN_DELETE_BlOGDETAILS_SUCCESS,
    ADMIN_DELETE_BlOGDETAILS_ERROR
} from '../../constants';
// Redirection
import history from '../../core/history';
import showToaster from '../../helpers/toasterMessages/showToaster';

const query = gql`
    query getBlogDetails {
    getBlogDetails{
        id
        metaTitle
        metaDescription
        pageUrl
        pageTitle
        content
        footerCategory
        isEnable
        createdAt
    }
    }
`;

export function deleteBlogDetails(id) {

    return async (dispatch, getState, { client }) => {
        dispatch({
            type: ADMIN_DELETE_BlOGDETAILS_START,
            data: id
        });
        try {

            let mutation = gql`
                mutation deleteBlogDetails ($id: Int!) {
                    deleteBlogDetails (id: $id) {
                        status
                    }
                    }
                `;

            const { data } = await client.mutate({
                mutation,
                variables: { id },
                refetchQueries: [{ query }]
            });


            if (data?.deleteBlogDetails?.status == "200") {
                dispatch({
                    type: ADMIN_DELETE_BlOGDETAILS_SUCCESS,
                });
                showToaster({ messageId: 'deleteBlogDetails', toasterType: 'success' })
                history.push('/siteadmin/content-management');
            } else {
                showToaster({ messageId: 'blogDeletionFailed', toasterType: 'error' })
            }

        } catch (error) {
            dispatch({
                type: ADMIN_DELETE_BlOGDETAILS_ERROR,
                payload: {
                    error
                }
            });

        }

    };
}

export function updateBlogStatus(id, isEnable) {

    return async (dispatch, getState, { client }) => {


        try {
            let mutation = gql`
                  mutation updateBlogStatus ($id: Int, $isEnable: Boolean){
                    updateBlogStatus(id: $id, isEnable: $isEnable){
                          status
                      }
                  }
              `;

            const { data } = await client.mutate({
                mutation,
                variables: { id, isEnable },
                refetchQueries: [{ query }]
            });


            if (data.updateBlogStatus.status === "success") {
                showToaster({ messageId: 'updateBlogStatus', toasterType: 'success' })
            }

        } catch (error) {
            showToaster({ messageId: 'updateBlogStatusFailed', toasterType: 'error' })
            return false;
        }
        return true;
    };
}