exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* @import '../s'; */\n\n.MediaLibraryManagement-paddingRight-22Byz {\n    padding-right: 0px;\n}\n\n.MediaLibraryManagement-iconFilter-33wFX{\n    height: 20px;\n    width: 23px;\n    margin-right: 10px;\n}\n\n.MediaLibraryManagement-displayGrid-iP5yY {\n    display: grid;\n    grid-template-columns: 50% 50%;\n}\n\n.MediaLibraryManagement-paddingRight-22Byz {\n    padding-right: 12px;\n}\n\n.MediaLibraryManagement-paddingLeft-3CZly {\n    padding-left: 12px;\n}\n\n.MediaLibraryManagement-paginationPhoto-2x4yI{\n    padding: 15px;\n    margin: 10px 25px 25px 10px;\n    text-align: center;\n    height: 100px;\n}", ""]);

// exports
exports.locals = {
	"paddingRight": "MediaLibraryManagement-paddingRight-22Byz",
	"iconFilter": "MediaLibraryManagement-iconFilter-33wFX",
	"displayGrid": "MediaLibraryManagement-displayGrid-iP5yY",
	"paddingLeft": "MediaLibraryManagement-paddingLeft-3CZly",
	"paginationPhoto": "MediaLibraryManagement-paginationPhoto-2x4yI"
};