import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resendEmailVerification } from '../../actions/manageUserVerification';

import Meetup from './Meetup';
import EmailVerification from './EmailVerification/EmailVerification';
import VerificationFields from './VerificationFields/VerificationFields';
import Payment from './Payment';
import AvatarUpload from './AvatarUpload';

class Booking extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    bookingData: PropTypes.object.isRequired,
    resendEmailVerification: PropTypes.func.isRequired,
    bookDetails: PropTypes.object.isRequired,
    serviceFees: PropTypes.object.isRequired,
    base: PropTypes.string.isRequired,
    rates: PropTypes.object.isRequired,
    specialPricing: PropTypes.array
  };

  state = {
    page: 'meetup'
  };

  nextPage = (page) => {
    this.setState({ page });
  }

  render() {
    const { page } = this.state;
    const { 
      account, 
      bookingData, 
      resendEmailVerification,
      bookDetails,
      serviceFees,
      base,
      rates,
      specialPricing
    } = this.props;

    const { guests, startDate, endDate, deliveryStatus, newDelivery, idLocation, startTime, endTime } = bookDetails;
    
    const deliveryFee = deliveryStatus === 'include' 
      ? (newDelivery > 0 ? parseFloat(newDelivery) : bookingData.listingData.delivery)
      : 0;

    const commonProps = {
      nextPage: this.nextPage,
      guestEmail: account.email,
      resendEmailVerification
    };

    const renderPage = () => {
      switch (page) {
        case 'meetup':
          return (
            <Meetup
              {...commonProps}
              hostDisplayName={bookingData.user.profile.firstName}
              hostPicture={bookingData.user.profile.picture}
              guestDisplayName={account.firstName}
              guestPicture={account.picture}
              emailVerified={account.verification.isEmailConfirmed || account.verification.isGoogleConnected}
              driverLicense={account.verification.isLicenseVerified}
              idNumberPassport={account.verification.isIdNumberVerification}
              isIdDocVerification={account.verification.isIdVerification}
            />
          );
        case 'emailverification':
          return <EmailVerification {...commonProps} />;
        case 'verificationfields':
          return (
            <VerificationFields
              {...commonProps}
              driverLicense={account.verification.isLicenseVerified}
              idNumberPassport={account.verification.isIdNumberVerification}
              isIdDocVerification={account.verification.isIdVerification}
            />
          );
        case 'avatar':
          return (
            <AvatarUpload
              {...commonProps}
              guestPicture={account.picture}
              guestDisplayName={account.firstName}
              emailVerified={account.verification.isEmailConfirmed}
            />
          );
        case 'payment':
          return (
            <Payment
              {...commonProps}
              {...bookingData}
              {...bookingData.user.profile}
              {...bookingData.listingData}
              {...bookingData.settingsData[0].listsettings}
              guestId={account.userId}
              checkIn={startDate}
              checkOut={endDate}
              guests={guests}
              delivery={deliveryFee}
              newDelivery={newDelivery}
              idLocation={idLocation}
              serviceFees={serviceFees}
              base={base}
              rates={rates}
              specialPricing={specialPricing}
              startTime={startTime}
              endTime={endTime}
            />
          );
        default:
          return null;
      }
    };

    return (
      <div>
        {renderPage()}
      </div>
    );
  }
}

const mapState = (state) => ({
  account: state.account.data,
  bookingData: state.book.data,
  bookDetails: state.book.bookDetails,
  serviceFees: state.book.serviceFees,
  base: state.currency.base,
  rates: state.currency.rates,
  specialPricing: state.viewListing.specialPricing
});

const mapDispatch = {
  resendEmailVerification
};

export default connect(mapState, mapDispatch)(Booking);
