import messages from '../../locale/messages';


const validate = values => {
  
  // const checkValues = values.listingExtData;
  // values.listingExtData.hasOwnProperty("brand")
  const errors = {}
  
  console.log('debug validation -->',values);
  // if (!values.carBrand) {
  //   errors.carBrand = messages.required;
  // }

  // if (!values.carModel) {
  //   errors.carModel = messages.required;
  // }

  // if (!values.color) {
  //   errors.color = messages.required;
  // }
  // if (!values.year) {
  //   errors.year = messages.required;
  // }
  if (!values.plate) {
    errors.plate = messages.required;
  }
  if (!values.chasis) {
    errors.chasis = messages.required;
  }
  if (values.plate && values.plate_duplicate == 1) {
    errors.plate = messages.plateInUsed;
  }
  
  if (values.chasis && values.chasis_duplicate == 1) {
    errors.chasis = messages.chasisInUsed;
  }

  // if (!values.carType) {
  //   errors.carType = messages.required;
  // }

  // if (!values.odometer) {
  //   errors.odometer = messages.required;
  // }

  if (!values.transmission) {
    errors.transmission = messages.required;
  }

  if (!values.country || values.country.toString().trim() == "") {
    errors.country = messages.required;
  }

  if (!values.state || values.state.toString().trim() == "") {
    errors.state = messages.required;
  }

  if (!values.city || values.city.toString().trim() == "") {
    errors.city = messages.required;
  }

  if (!values.street || values.street.toString().trim() == "") {
    errors.street = messages.required;
  }

  if (values.zipcode && values.zipcode.toString().trim() == "") {
    errors.zipcode = messages.required;
  }
  
  if(values.carFeatures && values.carFeatures.length < 4 ){
    errors.carFeatures = messages.carFeaturesMin;
  }
  return errors
}

export default validate
