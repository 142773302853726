import {
    CREATE_ADMIN_USER_START,
    CREATE_ADMIN_USER_SUCCESS,
    CREATE_ADMIN_USER_ERROR,
    DELETE_ADMIN_USER_START,
    DELETE_ADMIN_USER_SUCCESS,
    DELETE_ADMIN_USER_ERROR,
    GET_ADMIN_USER_START,
    GET_ADMIN_USER_SUCCESS,
    GET_ADMIN_USER_ERROR
} from '../../../constants';
import query from '../../../components/siteadmin/AdminUserManagement/adminUserQuery.graphql';
import { setRuntimeVariable } from '../../runtime';
import { closeAdminUserModal } from '../modalActions';
import createAdminUserMutation from './createAdminUser.graphql';
import deleteAdminUserMutation from './deleteAdminUser.graphql';
import getAdminUserQuery from './getAdminUser.graphql';
import getPrivilegesQuery from '../AdminRoles/getPrivileges.graphql';
import showToaster from '../../../helpers/toasterMessages/showToaster';

export function createAdminUser(
    id,
    email,
    password,
    roleId
) {
    return async (dispatch, getState, { client }) => {
        await dispatch({
            type: CREATE_ADMIN_USER_START,
            payload: {
                createAdminUserLoading: true
            }
        });

        try {
            const { data } = await client.mutate({
                mutation: createAdminUserMutation,
                variables: {
                    id,
                    email,
                    password,
                    roleId
                },
                refetchQueries: [{ query, variables: { currentPage: 1 } }]
            });

            if (data && data.createAdminUser && data.createAdminUser.status === 200) {
                await dispatch({
                    type: CREATE_ADMIN_USER_SUCCESS,
                    payload: {
                        createAdminUserLoading: false
                    }
                });
                dispatch(closeAdminUserModal());
                showToaster({ messageId: 'addUpdateAdminUser', toasterType: 'success', requestMessage: id })
                return {
                    status: 200
                };
            } else {
                dispatch(closeAdminUserModal());
                showToaster({ messageId: 'adminUserError', toasterType: 'error', requestMessage: data && data.createAdminUser && data.createAdminUser.errorMessage })
                await dispatch({
                    type: CREATE_ADMIN_USER_ERROR,
                    payload: {
                        createAdminUserLoading: false,
                        error: data && data.createAdminUser && data.createAdminUser.errorMessage
                    }
                });
                return {
                    status: 400
                };
            }
        } catch (error) {
            await dispatch({
                type: CREATE_ADMIN_USER_ERROR,
                payload: {
                    createAdminUserLoading: false,
                    error
                }
            });
            return {
                status: 400
            };
        }
    }
}

export function deleteAdminUser(id) {
    return async (dispatch, getState, { client }) => {
        await dispatch({
            type: DELETE_ADMIN_USER_START,
            payload: {
                deleteAdminUserLoading: true
            }
        });

        try {
            const { data } = await client.mutate({
                mutation: deleteAdminUserMutation,
                variables: {
                    id
                },
                // refetchQueries: [{ query }]
            });

            if (data && data.deleteAdminUser && data.deleteAdminUser.status === 200) {
                await dispatch({
                    type: DELETE_ADMIN_USER_SUCCESS,
                    payload: {
                        deleteAdminUserLoading: false
                    }
                });
                dispatch(closeAdminUserModal());
                showToaster({ messageId: 'deleteAdminUser', toasterType: 'success' })

            } else {
                showToaster({ messageId: 'adminUserError', toasterType: 'error', requestMessage: data && data.deleteAdminUser && data.deleteAdminUser.errorMessage })
                await dispatch({
                    type: DELETE_ADMIN_USER_ERROR,
                    payload: {
                        deleteAdminUserLoading: false,
                        error: data && data.deleteAdminUser && data.deleteAdminUser.errorMessage
                    }
                });
            }
        } catch (error) {
            await dispatch({
                type: DELETE_ADMIN_USER_ERROR,
                payload: {
                    deleteAdminUserLoading: false,
                    error
                }
            });
        }
    }
}
export function getAdminUser() {
    return async (dispatch, getState, { client }) => {
        try {
            await dispatch({ type: GET_ADMIN_USER_START });

            // Separate calls to each client query
            const adminDataResponse = await client.query({ query: getAdminUserQuery });
            const privilegesDataResponse = await client.query({ query: getPrivilegesQuery });

            const adminData = adminDataResponse.data;
            const privilegesData = privilegesDataResponse.data;

            console.log('Admin Data:', adminData);
            console.log('Privileges Data:', privilegesData);

            const adminUser = adminData?.getAdminUser;
            const privileges = privilegesData?.getPrivileges?.results;

            if (adminUser?.id) {
                // Get all permitted URLs for the user's privileges
                let userPermittedUrls = [];
                let userPrivileges = [];

                if (adminUser.isSuperAdmin) {
                    userPermittedUrls = privileges?.reduce((urls, privilege) => [...urls, ...(privilege.permittedUrls || [])], []);
                    userPrivileges = privileges?.map(item => item.id);
                } else {
                    const userPrivilegeIds = adminUser.adminRole?.privileges || [];
                    const userPrivilegeDetails = privileges?.filter(priv => userPrivilegeIds.includes(priv.id));
                    userPermittedUrls = userPrivilegeDetails?.reduce((urls, privilege) => [...urls, ...(privilege.permittedUrls || [])], []);
                    userPrivileges = userPrivilegeIds;
                }

                const adminPrivileges = {
                    id: adminUser.id,
                    email: adminUser.email,
                    isSuperAdmin: adminUser.isSuperAdmin,
                    roleId: adminUser.roleId,
                    privileges: userPrivileges,
                    permittedUrls: userPermittedUrls
                };

                console.log('Admin Privileges:', adminPrivileges);
                dispatch(setRuntimeVariable({
                    name: 'isSuperAdmin',
                    value: adminUser.isSuperAdmin
                }));

                await dispatch({
                    type: GET_ADMIN_USER_SUCCESS,
                    payload: { adminPrivileges }
                });

                return adminPrivileges;
            } else {
                throw new Error(adminUser?.errorMessage || 'Failed to get admin user');
            }
        } catch (error) {
            console.error('Error in getAdminUser:', error);
            await dispatch({
                type: GET_ADMIN_USER_ERROR,
                payload: { error }
            });
            return false;
        }
    }
}