import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
  FormGroup,
} from 'react-bootstrap';
import s from './ListPlaceStep1.css';
import cs from '../commonStyle.css';

// Images
import { MdInfo } from 'react-icons/md';
import Arrow from '/public/siteImages/rightSideArrow.svg';
import arrowIcon from '/public/SiteIcons/paymentArrow.svg';

// Internal Component
import PhotosUpload from '../PhotosUpload';
import FooterButton from './FooterButton';
import SidePanel from './SidePanel';
import updateStep2 from './updateStep2';
// import validateStep2 from './validateStep2';

// Locale
import messages from '../../locale/messages';
class Photos extends Component {

  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
    listId: PropTypes.number.isRequired,
    photosCount: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      isAvailable: true
    };
  }

  UNSAFE_componentWillMount() {
    const { photosCount } = this.props;
    if (photosCount < 5) {
      this.setState({ isAvailable: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { photosCount } = nextProps;
    if (photosCount < 5) {
      this.setState({ isAvailable: true });
    } else {
      this.setState({ isAvailable: false });
    }
  }

  render() {
    const { nextPage, previousPage, listId, formPage, step } = this.props;
    const { formatMessage } = this.props.intl;
    const { isAvailable } = this.state;

    return (
      <div className={cx(s.stepGrid, 'stepGridRTL')}>
        <SidePanel
          title={formatMessage(messages.step2Heading)}
          landingContent={formatMessage(messages.step2PhotoTitle)}
        />
        <form>
          <div className={s.landingMainContent}>
            <div className={s.photoContainerInfo}>
              <h4 className={cx(cs.commonContentText, cs.spaceBottom3)}>
                <FormattedMessage {...messages.carPhotosInfo1} />
                </h4>
              <h5 className={cx(cs.commonContentText, cs.spaceBottom3)}>
                <FormattedMessage {...messages.carPhotosInfo2} />
                <a href={"/"} target={"_blank"} className={cx(cs.spaceTop3, cs.textDecorationNone, cs.siteLinkColor, cs.displayBlock,cs.fontWeightMedium)}>
                  <FormattedMessage {...messages.carPhotosInfo1More} />
                  <img src={Arrow} className={cx(cs.blueLeftArrow)} />
                </a>
              </h5>
              </div>
            <div className={s.uploadVehicleContainer}>
              <h3 className={cx(cs.commonContentText, cs.spaceBottom3)}><FormattedMessage {...messages.carPhotos} /></h3>
              <FormGroup className={cx(s.formGroup,s.setMinHeightSmall, 'stepPhotoUpload')}>
                <PhotosUpload 
                className={'minContainerHeight'}
                dzListPhotoContainer={'listPhotoContainer'}
                iconClassName={'photoUploadImg'}
                placeHolderClassName={'documentPlaceholder'}
                dzInputContainerName={'dzInputContainer'} maxFilesUpload={20} isDocVehicle={1} listId={listId} placeholder={formatMessage(messages.photosPlaceholder)} />
              </FormGroup>
              {isAvailable &&
                <div className={cx(s.notificationContainer)}>
                  <MdInfo size={'1.8em'} className={cx(s.notificationIcon)}/>
                  <span className={cx(s.notificationRequired)}>
                    <FormattedMessage {...messages.carPhotoMin} />
                  </span>
                </div>
              }
            </div>
            <div className={cx(s.uploadVehicleContainer,s.marginTop40)}>
              <h3 className={cx(cs.commonContentText, cs.spaceBottom3)}><FormattedMessage {...messages.carDocPaper} /></h3>
              <FormGroup className={cx(s.formGroup, 'stepPhotoUpload','stepPhotoUploadDoc')}>
                <PhotosUpload 
                placeHolderClassName={'documentPlaceholderDoc'}
                iconClassName={'photoUploadImgDoc'}
                dzListPhotoContainer={'listPhotoContainerDoc'} 
                dzInputContainerName={'dzInputContainerDoc'} maxFilesUpload={1} isDocVehicle={2} listId={listId} placeholder={formatMessage(messages.photosPlaceholder)} />
              </FormGroup>
            </div>
          </div>
          
          <FooterButton
            nextPage={nextPage}
            previousPage={previousPage}
            previousPagePath={"home"}
            nextPagePath={"description"}
            // skipLabel={true}
            // isAvailable={isAvailable}
            isDisabled={isAvailable}
            formPage={formPage}
            step={step}
          />
        </form>
      </div>
    );
  }
}

Photos = reduxForm({
  form: 'ListPlaceStep2', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  // validate: validateStep2,
  onSubmit: updateStep2
})(Photos);

const mapState = (state) => ({
  photosCount: state.location.photosCount
});
const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Photos)));