import { setRuntimeVariable } from '../../actions/runtime';
import { loadAccount } from '../../actions/account';

import { createSetViaFirmaOwner } from '../../core/contracts/contractsHelper';

import { sendEmail } from '../../core/email/sendEmail';
import showToaster from '../../helpers/toasterMessages/showToaster'
import { Action, Dispatch } from 'redux';
import { createAgreement } from './createAgreement';

async function submit(values: {
  loggedinEmail: string;
  loggedinUser: string;
  agreementId: number;
}, dispatch: Dispatch<Action>) {
  try {
    if (!values.agreementId) {
      console.log("values.agreementId", values.agreementId)
    const newAgreementId = await createAgreement();
    console.log("newAgreementId", newAgreementId)
    if (!newAgreementId) return showToaster({ messageId: 'errorCreatingAgreement', toasterType: 'error', requestMessage: 'errorCreatingAgreement', language: 'en' });
    values.agreementId = newAgreementId;
  }
  const { loggedinEmail, loggedinUser, agreementId } = values;

  const query = `query (
    $isAgreementSigned:Int,
    $isAgreementStatus:Int,
  ) {
    userEditProfileAgreement (
      isAgreementSigned:$isAgreementSigned,
      isAgreementStatus:$isAgreementStatus,
    ) {
        status
        profileid
      }
    }`;
  const queryUpdate = `query (
    $agreementId:Int,
    $fieldsUpdate:String,
    $updateProfile:Int,
    
  ) {
    updateAgreementUser (
      agreementId:$agreementId,
      fieldsUpdate:$fieldsUpdate,
      updateProfile:$updateProfile,
        
      ) {
        status
        profileid
      }
    }`;




  const params = {
    isAgreementSigned: 1,
    isAgreementStatus: 1
  };

  const resp = await fetch('/graphql', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: query,
      variables: params
    }),
    credentials: 'include'
  });

  const { data } = await resp.json() as {
    data: {
      userEditProfileAgreement: {
        status: string;
        profileid: number;
      };
    };
  };

  if (data.userEditProfileAgreement.status == "success") {


    const resVF = await createSetViaFirmaOwner(data.userEditProfileAgreement.profileid) as {
      status: string;
      code: string;
      messages: {
        code: string;
      }[];
      links: {
        link: string;
        token: string;
      }[];
    };
    if (resVF) {
      await dispatch(loadAccount() as unknown as Action);
      showToaster({ messageId: 'updateProfile', toasterType: 'success', requestMessage: 'updateProfile', language: 'en' })
      const { status, code, messages, links } = resVF;

      if (status == 'RECEIVED') {
        let content = { token: links[0].link, email: loggedinEmail, name: loggedinUser };
        const paramsUpdAgreement = {
          agreementId: agreementId,
          fieldsUpdate: `{"vfSetCode":"${code}","vfSignerLink":"${links[0].link}", "vfMessageCode":"${messages[0].code}", "vfSignerToken":"${links[0].token}", "vfSetStatus":"${status}"}`,
          updateProfile: 0,
        };
        const respUpdate = await fetch('/graphql', {
          method: 'post',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify({ query: queryUpdate, variables: paramsUpdAgreement }),
          credentials: 'include'
        });
        const dataUpd = await respUpdate.json() as {
          data: {
            updateAgreementUser: {
              status: string;
            };
          };
        };
        if (dataUpd.data.updateAgreementUser.status == "success") {
          await sendEmail(loggedinEmail, 'ownerAgreement', content);
          window.location = links[0].link as unknown as Location;
        }
      }
    }

  } else if (data.userEditProfileAgreement.status == "email") {
    showToaster({ messageId: 'emailAlreadyExist', toasterType: 'error', requestMessage: 'emailAlreadyExist', language: 'en' })
  } else if (data.userEditProfileAgreement.status == "notLoggedIn") {
    dispatch(setRuntimeVariable({
      name: 'isAuthenticated',
      value: false,
    }));

    showToaster({ messageId: 'loginUser', toasterType: 'error', requestMessage: 'loginUser', language: 'en' })
    //throw new SubmissionError({ _error: messages.notLoggedIn });
  } else {
    // throw new SubmissionError({ _error: messages.somethingWentWrong });

    showToaster({ messageId: 'reloadPage', toasterType: 'error', requestMessage: 'reloadPage', language: 'en' })
  }
  } catch (error: any) {
    console.error(error);
    showToaster({ messageId: 'catchError', toasterType: 'error', requestMessage: error.message, language: 'en' });
  }

}

export default submit;
