import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createNumberMask, createTextMask } from 'redux-form-input-masks';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from 'react-bootstrap';
import s from './ListPlaceStep1.css';
import cs from '../commonStyle.css';

import SidePanel from './SidePanel';
import FooterButton from './FooterButton';
import ListingDeliveryAddress from './ListingDeliveryAddress';
// Locale
import messages from '../../locale/messages';
import validateStep3 from './validateStep3';
import updateStep3 from './updateStep3';

//images
import infoIcon from '/public/SiteIcons/priceHoverIcon.svg';
import locationIcon from '/public/SiteIcons/locationIdea.svg';
import CommonFormComponent from '../CommonField/CommonFormComponent';
import { normalizePrice } from '../EditProfileForm/normalizePhone';

class PricingPickupAddress extends Component {

  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
    setLat: PropTypes.number,
    setLng: PropTypes.number,
    
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
    };
  }

  componentDidMount() {
    const { valid } = this.props;
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  UNSAFE_componentWillMount() {
    const { valid } = this.props;
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { valid } = nextProps;
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  render() {
    const { handleSubmit, nextPage, previousPage, formPage, step, availableCurrencies,setLat,setLng,listingFields,listId } = this.props;
    const { formatMessage } = this.props.intl;
    const { isDisabled } = this.state;
    

    return (
      <div className={cx(s.stepGrid, 'stepGridRTL')}>
        <SidePanel
          title={formatMessage(messages.step3Heading)}
          landingContent={formatMessage(messages.setPricingText)}
        />
        <form onSubmit={handleSubmit}>
          <div className={s.landingMainContent}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                
                <ListingDeliveryAddress
                listId={listId} />
              </Col>
            </Row>
          </div>
          <FooterButton
            nextPage={nextPage}
            previousPage={previousPage}
            previousPagePath={"pricing"}
            nextPagePath={"discount"}
            formPage={formPage}
            step={step}
            isDisabled={isDisabled}
          />
        </form>
      </div>
    );
  }
}

PricingPickupAddress = reduxForm({
  form: 'ListPlaceStep3', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStep3,
  onSubmit: updateStep3
})(PricingPickupAddress);

const mapState = (state) => ({
  listingFields: state.listingFields.data,
  availableCurrencies: state.currency.availableCurrencies,
});

const mapDispatch = {
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(PricingPickupAddress)));
