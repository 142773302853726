import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import PaymentGatway from './PaymentGateway';
import { restrictUrls } from '../../../helpers/adminPrivileges';

const title = 'Payment Gateway Section';

export default async function action({ store }) {

        let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
        let isSuperAdmin = store.getState().account.privileges.isSuperAdmin;
        const adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
        const privileges = store.getState().listSettings && store.getState().listSettings.privileges;

        if (!isAdminAuthenticated) {
            return { redirect: '/siteadmin/login' };
        }

        // Allow access if superadmin or has required privilege
        if (isSuperAdmin || restrictUrls('/siteadmin/payment-gateway-section', adminPrivileges, privileges)) {
            return {
                title,
                component: <AdminLayout><PaymentGatway title={title}/></AdminLayout>
            }
        }

        // Redirect if not superadmin and doesn't have required privilege
        return { redirect: '/siteadmin' };
};
