import React from 'react';
import { Button } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useState } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './VerificationFallback.css';
import PhoneVerificationModal from '../PhoneVerificationModal/PhoneVerificationModal';
import DocumentUploadID from '../DocumentUploadID/DocumentUploadID';
import messages from '../../locale/messages';
import { resendEmailVerification } from '../../actions/manageUserVerification';

const VerificationFallback = ({
    account,
    children,
    intl: { formatMessage }
}) => {
    const { email } = account;
    const verification = account.verification || {};
    const phoneNumber = account.phoneNumber || '';

    const [activeSection, setActiveSection] = useState('email');

    if (verification.isEmailConfirmed &&
        verification.isIdNumberVerification &&
        phoneNumber &&
        verification.isLicenseVerified &&
        verification.isIdVerification) return children

    return <div className="verification-container">
        <h1>Get approved to drive</h1>
        <p>Since this is a new account, you'll need to provide us with some information before you can book your first trip.</p>

        <section className="verification-section">
            <h2>Verify your email</h2>
            {verification.isEmailConfirmed ? <>
                <p>
                    Your email has been verified.
                </p>
            </> : <>
                <p>
                    We sent a verification link to <strong>{email}</strong>. Click the link in your email to continue to checkout.
                </p>
                <div className="verification-actions">
                    <Button onClick={resendEmailVerification} className="link-button">Resend email</Button>
                </div>
            </>}
        </section>

        <section className="verification-section">
            <h2 onClick={() => setActiveSection(activeSection === 'mobile' ? '' : 'mobile')}
                className="accordion-header">
                Mobile number
                <span className="accordion-icon">{activeSection === 'mobile' ? '−' : '+'}</span>
            </h2>
            {activeSection === 'mobile' && (
                <div className="accordion-content">
                    <PhoneVerificationModal />
                </div>
            )}
        </section>

        <section className="verification-section">
            <h2 onClick={() => setActiveSection(activeSection === 'license' ? '' : 'license')}
                className="accordion-header">
                Driver's license
                <span className="accordion-icon">{activeSection === 'license' ? '−' : '+'}</span>
            </h2>
            {activeSection === 'license' && <div className="accordion-content" style={{ position: 'relative' }}>
                {verification.isLicenseVerified ? <p>
                        Your driver's license has been verified.
                    </p> : (
                    <DocumentUploadID docsTypeId={2} dzInputContainerName='dzInputContainerID' placeholder={formatMessage(messages.userUploadIDDoc)} />
                )}
            </div>}
        </section>

        <section className="verification-section">
            <h2 onClick={() => setActiveSection(activeSection === 'payment' ? '' : 'payment')}
                className="accordion-header">
                Payment method
                <span className="accordion-icon">{activeSection === 'payment' ? '−' : '+'}</span>
            </h2>
            {activeSection === 'payment' &&
                <div className="accordion-content" style={{ position: 'relative' }}>
                    {verification.isLicenseVerified ? <p>
                            Your driver's license has been verified.
                        </p> : (
                        <DocumentUploadID docsTypeId={3} dzInputContainerName='dzInputContainerID' placeholder={formatMessage(messages.userUploadDriverLicense)} />
                    )}
                </div>}
        </section>
    </div>
};

const mapState = (state) => ({
    account: state.account.data,
});

const mapDispatch = {
};

export default compose(
    injectIntl,
    connect(mapState, mapDispatch),
    withStyles(s),
)(VerificationFallback);