import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './AddBlogDetails.css';
import cs from '../../../components/commonStyle.css';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import submit from './submit';
import validate from './validate';
import { graphql, gql, compose } from 'react-apollo';


// Style
import {
  Button,
  Row,
  FormGroup,
  Col,
  ControlLabel
} from 'react-bootstrap';
import { url } from '../../../config.js';
import { formatURL } from '../../../helpers/formatURL';
import fetch from '../../../core/fetch';
import Link from '../../Link';
import getImageListBlogQuery from './getImageListBlogQuery.graphql';

// Translation
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';
import CommonFormComponent from '../../CommonField/CommonFormComponent';

//Image
import arrowIcon from '/public/AdminIcons/backArrow.svg';

class AddBlogDetailsHTML extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = { 
      editorHtml: '',
      idContent:203,
      contentText:''
     } // You can also pass a Quill Delta here
    this.handlePageTitle = this.handlePageTitle.bind(this);
  }


  async handlePageTitle(e) {
    const { change } = this.props;
    if (e.target.value) {
      await change('pageUrl', formatURL(e.target.value));
    } else {
      await change('pageUrl', '');
    }
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    initialValues: PropTypes.object,
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
    getImageListBlogData: PropTypes.shape({
      loading: PropTypes.bool,
      getImageListBlog: PropTypes.array
    }),
    idContent: PropTypes.number,
    contentText: PropTypes.string
  };

  static defaultProps = {
    data: [],
    getImageListBlogData: PropTypes.shape({
      loading: true,
      getImageListBlog:[]
    })

  };

  renderTinyMCE(field){
    let props = Object.assign({}, field);
    delete props.input;
    delete props.meta;

    return <Editor
      {...props}
      value={field.input.content !== '' ? field.input.content : null}
      onBlur={(event, value) => { field.input.onChange(event.target.getContent()) }}
      apiKey='6jj4tsd4nfningjdjpagytilh0tw0y1k4p906reef878kzl9'
      init={{
        height: 500,
        menubar: true,
        plugins: [
          'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
          'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
        ],
        toolbar: 'undo redo | blocks | ' +
          'bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        image_list: props.ImageListBlog,
      }}
    />
    
  }

  render() {
    const { error, handleSubmit, submitting, dispatch, initialValues, title, siteName } = this.props;
    const {idContent, contentText}= this.state;
    const { getImageListBlogData: { getImageListBlog } } = this.props;
    const { formatMessage } = this.props.intl;
    const gobackcss = { padding: '10px' };
    // if (typeof window !== 'undefined' && ReactQuill) {
      return (
        <div className={cx(s.pagecontentWrapper, 'pagecontentWrapperRTL')}>
          <div className={cx(cs.adminContentPadding)}>
            <div className={cx(s.sectionCenter,cs.sectionExpanded)}>
              <div className={cx(cs.textAlignRight, cs.mobileDisplayBlock, 'textAlignLeftRTL', cs.spaceBottom4)}>
                <Link to={'/siteadmin/content-management'} className={cx(cs.siteLinkColor, cs.commonContentText, cs.fontWeightMedium, cs.commomLinkborderBottom, cs.textDecorationNone)}>
                  <img src={arrowIcon} className={cx(cs.backArrowStyle, 'adminGoBackArrowRTL')} />
                  <FormattedMessage {...messages.goBack} />
                </Link>
              </div>
              <div className={cs.commonAdminBorderSection}>
                <h1 className={s.headerTitle}><FormattedMessage {...messages.addPageDetails} /></h1>
                <form onSubmit={handleSubmit(submit)}>
                  {error && <strong>{formatMessage(error)}</strong>}
                  <Row className={cx(cs.spaceTop5)}>
                    <Col lg={6} md={6} xs={12} sm={12}>
                      <FormGroup className={s.space3}>
                        <ControlLabel className={cs.labelTextNew}>
                          <FormattedMessage {...messages.metaTitleLabel} />
                        </ControlLabel>
                        <Field name="metaTitle" type="text" component={CommonFormComponent} label={formatMessage(messages.metaTitleLabel)} inputClass={cx(cs.formControlInput)} />
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} xs={12} sm={12}>
                      <FormGroup className={s.space3}>
                        <ControlLabel className={cs.labelTextNew}>
                          <FormattedMessage {...messages.pageTitleLabel} />
                        </ControlLabel>
                        <Field name="pageTitle" type="text" component={CommonFormComponent} label={formatMessage(messages.pageTitleLabel)} onChange={(event) => this.handlePageTitle(event)} inputClass={cx(cs.formControlInput)} />
                      </FormGroup>
                    </Col>

                    <Col className={cx(cs.spaceTop5)} lg={12} md={12} xs={12} sm={12}>
                      <FormGroup className={s.space3}>
                        <ControlLabel className={cs.labelTextNew}>
                          <FormattedMessage {...messages.metaDescriptionLabel} />
                        </ControlLabel>
                        <Field name="metaDescription" inputClass={s.textareaInput} componentClass={'textarea'} component={CommonFormComponent} label={formatMessage(messages.metaDescriptionLabel)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row className={cx(cs.spaceTop5)}>
                    <Col lg={6} md={6} xs={12} sm={12}>
                      <FormGroup className={s.space3}>
                        <ControlLabel className={cs.labelTextNew}>
                          <FormattedMessage {...messages.pageUrl} />
                        </ControlLabel>
                        <Field name="pageUrl" type="text" isAddon={true} component={CommonFormComponent} suffixLabel={`${url}${formatMessage(messages.pageLabel)}`} label={formatMessage(messages.pageUrl)} inputClass={cx(cs.formControlInput, s.addonInputRadius)} />
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} xs={12} sm={12}>
                    <FormGroup className={s.space3}>
                    <ControlLabel className={cs.labelTextNew}>
                      <FormattedMessage {...messages.footerCategoryLabel} />
                        </ControlLabel>
                        <Field name="footerCategory" component={CommonFormComponent} inputClass={cx(cs.formControlSelect)} label={formatMessage(messages.footerCategoryLabel)}>
                          <option value="">{formatMessage(messages.ChooseFooterCategory)}</option>
                          <option value={siteName}>{siteName}</option>
                          <option value="discover">{formatMessage(messages.discover)}</option>
                          <option value="hosting">{formatMessage(messages.hosting)}</option>
                        </Field>
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  
                  
                  <FormGroup className={cx(s.space3,cs.spaceTop5)}>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <label className={cs.labelTextNew} ><FormattedMessage {...messages.contentLabel} /></label>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Field
                            component={ this.renderTinyMCE }
                            name="content"
                            autoComplete="off"
                            ImageListBlog={getImageListBlog}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className={s.noMargin}>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} className={cx(cs.textAlignRight, 'textAlignLeftRTL')}>
                        <Button className={cx(cs.btnPrimary, cs.btnlarge)} type="submit" disabled={submitting} ><FormattedMessage {...messages.save} /></Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    // } else {
    //   return <textarea />;
    // }
  }

}

AddBlogDetailsHTML = reduxForm({
  form: 'BlogDetails', // a unique name for this form
  validate
})(AddBlogDetailsHTML);



const blogFormSelector = formValueSelector('BlogDetails');

const mapState = (state) => ({
  pageTitle: blogFormSelector(state, 'pageTitle'),
  pageURL: blogFormSelector(state, 'pageUrl'),
  siteName: state.siteSettings.data.siteName,
});

const mapDispatch = {
  change
};

export default compose(
  injectIntl,
  withStyles(s, cs),
  connect(mapState, mapDispatch),
  graphql(getImageListBlogQuery, {
    name: 'getImageListBlogData',
    options: {
    ssr: false,
    fetchPolicy: 'network-only'
    }
  }),
)(AddBlogDetailsHTML);