const createAgreementMutation = `
  mutation CreateAgreementUser {
    createAgreementUser {
      status
      agreementData {
        id
      }
    }
  }
`;

export async function createAgreement(): Promise<number | null> {
  const createResp = await fetch('/graphql', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: createAgreementMutation
    }),
    credentials: 'include'
  });

  const { data } = await createResp.json();
  
  if (data?.createAgreementUser?.status === 'success') {
    return data.createAgreementUser.agreementData.id;
  }
  
  return null;
} 