import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector,change } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { createNumberMask } from 'redux-form-input-masks';
import { graphql, compose } from 'react-apollo';
import moment from 'moment';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {Button,FormGroup,Row,ControlLabel,Col,TabContainer,TabContent,TabPane,Nav, NavItem} from 'react-bootstrap';
import s from './ListPlaceStep1.css';
import cs from '../commonStyle.css';

// query
import getListingExtQuery from './getListingExtQuery.graphql';

// Internal Components
import CustomCheckbox from '../CustomCheckbox';
import PolicyDocumentUpload from '../PolicyDocumentUpload/PolicyDocumentUpload';
import FooterButtonPolicy from './FooterButtonPolicy';
import SidePanel from './SidePanel';
import PolicyReviewListing from './PolicyReviewListing';
import PolicyPaymentReviewListing from './PolicyPaymentReviewListing';
import Loader from '../../components/Loader';

// Images
import { MdInfo } from 'react-icons/md';
import infoIcon from '/public/SiteIcons/priceHoverIcon.svg';
import locationIcon from '/public/SiteIcons/locationIdea.svg';
import CommonFormComponent from '../CommonField/CommonFormComponent';

import updateStep4 from './updateStep4';
import validateStep4 from './validateStep4';

// Helper
import {convert} from '../../helpers/currencyConvertion';

// Locale
import messages from '../../locale/messages';

class FormPolicyInsurance extends Component {

  static propTypes = {
    listId: PropTypes.number.isRequired,
    listingStepsData: PropTypes.object,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
    // ShowlistingExtData: PropTypes.shape({
    //   loading: PropTypes.bool,
    //   ShowListingExt: PropTypes.shape({
    //     year: PropTypes.string,
    //     policyInsurance: PropTypes.string,
    //     carListing: PropTypes.shape({
    //       title: PropTypes.string,
    //     }),
    //     DocVerification: PropTypes.shape({
    //       fileName: PropTypes.string,
    //       verificationType: PropTypes.number,
    //     }),
    //   }),
    // }),
    listingExtData: PropTypes.shape({
      year: PropTypes.string,
      policyInsurance: PropTypes.string,
      policyGPS: PropTypes.string,
      policyRateValue: PropTypes.number,
      policyRateValueOwn: PropTypes.number,
      policySubNet: PropTypes.number,
      policyExtraCost: PropTypes.number,
      policyNet: PropTypes.number,
      policyQTPayment: PropTypes.number,
      policyPlan: PropTypes.string,
      policyExpire: PropTypes.string,
      policyExpireOwn: PropTypes.string,
      policyPaymentOption: PropTypes.number,
    }),
    // listingPolicy: PropTypes.array,
    listingPolicy: PropTypes.shape({
      listYear: PropTypes.number,
      planType: PropTypes.string,
      paymentDate: PropTypes.string,
      expirationDate: PropTypes.string,
      dueFirst: PropTypes.number,
      duesAmount: PropTypes.number,
      policyInsuranceCode: PropTypes.string,
      paymentType: PropTypes.number,
      paymentAmountPayPal: PropTypes.number,
      paymentCost: PropTypes.number,
      paymentSubNet: PropTypes.number,
      paymentNet: PropTypes.number,
      policyInsuranceCode: PropTypes.string,
      paymentCurrencyPayPal: PropTypes.string,
      paymentStatusPayPal: PropTypes.string,
      policyInsuranceStatus: PropTypes.string,
      policyInsuranceDateApproved: PropTypes.string,
    })
  };
  static defaultProps = {
    listingExtData:{
      year: null,
      policyInsurance: 'request',
      policyGPS: 'no',
      policyRateValue: 0,
      policyRateValueOwn: 0,
      policySubNet: 0,
      policyExtraCost: 1810,
      policyNet: 0,
      policyQTPayment: 1,
      policyPlan: 'montly',
      policyExpire: null,
      policyExpireOwn: null,
      policyPaymentOption: 1,
    },
    // listingPolicy:[],
    listingPolicy: {
      listYear: 0,
      planType: null,
      paymentDate: null,
      expirationDate: null,
      dueFirst: 0,
      duesAmount: 0,
      policyInsuranceCode: null,
      paymentType: 0,
      paymentAmountPayPal: 0,
      paymentCost: 0,
      paymentSubNet: 0,
      paymentNet: 0,
      policyInsuranceCode: null,
      paymentCurrencyPayPal: null,
      paymentStatusPayPal: null,
      policyInsuranceStatus: null,
      policyInsuranceDateApproved: null,
    }
  };
  constructor(props) {
    super(props);
    this.state = {
      isDisabledFields:false,
      isDisabled: false,
      policyStep1:true,
      policyStep2:false,
      policyStep3:false,
      policyStep4:false,
      tabsStepper:[
        { label: 'Step 1',completed:false }
      ],
      tabsStepperStep:0,
      tabPolicyCurrent:'policy_upload_doc',
      tabPolicy1:'active',
      tabPolicy2:'',
      tabPolicy3:'',
      tabPolicy4:'',
      policyInsuranceType: 'request',
      setVehicleTitle: null,
      setYear: 0,
      setPolicyDocUploaded: false,
      setPolicyRateValue: 0,
      setPolicySubNet: 0,
      setPolicyExtraCost: 1810,
      setPolicyNet: 0,
      setPolicyNetUSD: 0,
      setPolicyQTPayment: 1,
      setPolicyFirstDues:0,
      setPolicyDues:0,
      setPolicyPaymentOption: 1,
      setPolicyPlan: 'montly',
      setPolicyExpire: moment().add(1, 'month').format('MMM DD, YYYY').toString(),
    },
    this.onUpdatePaymentOption = this.onUpdatePaymentOption.bind(this);
    this.handleCalculatePrice = this.handleCalculatePrice.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.onCheckDocUploaded = this.onCheckDocUploaded.bind(this);
    this.onGetCalculateValue = this.onGetCalculateValue.bind(this);
    this.onHandleConfirm = this.onHandleConfirm.bind(this);
    this.toNextTab = this.toNextTab.bind(this);
    this.toBackTab = this.toBackTab.bind(this);
    this.onSetUploaded = this.onSetUploaded.bind(this);
    this.offSetUploaded = this.offSetUploaded.bind(this);
    this.onSetFormFieldsValues = this.onSetFormFieldsValues.bind(this);
    this.onHandleUpdateStepsOnStepper = this.onHandleUpdateStepsOnStepper.bind(this);
  }

  UNSAFE_componentDidMount() {
    const {formErrors,listingExtData, listingSteps, change,listingPolicy} = this.props;
    // console.log('listingFields: 22 ', listingFields);
    
    // console.log('listingExtData: 11 ', listingExtData);
    // console.log('listingSteps: 11 ', listingSteps);
    // console.log('listingPolicy: 11 ', listingPolicy);
  }

  UNSAFE_componentWillMount() {
    const { formErrors,change,listingStepsData} = this.props;
    const { listingSteps,listingSteps:{listingExtDT,policyPayments}} = this.props;
    const { listingExtData,listingExtData:{year,policyInsurance,policyPlan,policyGPS,policyRateValue,policyRateValueOwn}} = this.props;
    // const { listingPolicy,listingPolicy:{expirationDate,paymentStatusPayPal}} = this.props;
    const { listingPolicy,listingPolicy:{expirationDate,paymentStatusPayPal}} = this.props;
    const { formatMessage } = this.props.intl;
    let policyInsurancePayedAndActive = false,listingExtValues,liData={},liInsurance={};
    
    if (expirationDate != null) {
      // console.log('expirationDate:', expirationDate);
    //   let DTToday=moment(),expirationDateDT=moment(expirationDate); 
    //   let dayDiff=expirationDateDT.to(DTToday);
    //   let dayDiffD=DTToday.isBefore(expirationDateDT);
    //   // policyInsurancePayedAndActive = paymentStatusPayPal =='completed' && dayDiff >0 ? true : false;
    //   policyInsurancePayedAndActive = policyPayments[0].paymentStatusPayPal =='completed' && dayDiffD==true  ? true : false;
    //   this.setState({isDisabledFields: policyInsurancePayedAndActive});
    }
    if(policyPayments) {
      let DTToday=moment(),expirationDateDT=moment(policyPayments[0].expirationDate); 
      let dayDiff=expirationDateDT.to(DTToday);
      let dayDiffD=DTToday.isBefore(expirationDateDT);
      liData=listingExtDT;liInsurance=policyPayments;
      // console.log('test', moment(policyPayments[0].expirationDate).format('YYYY-MM-DD').toString() );
      policyInsurancePayedAndActive = policyPayments[0].paymentStatusPayPal =='completed' && dayDiffD==true  ? true : false;
      this.setState({isDisabledFields: policyInsurancePayedAndActive});
    }
    // }
    
    if( policyInsurancePayedAndActive == true){
      this.setState({
        isDisabledFields: true,
        tabsStepperStep: 3,
        tabPolicyCurrent:'policy_review',
        tabsStepper:[
          { label: formatMessage(messages.policyInsuranceStep1), completed:true },
          { label: formatMessage(messages.policyInsuranceStep2), completed:true },
          { label: formatMessage(messages.policyInsuranceStep3), completed:true },
          { label: formatMessage(messages.policyInsuranceStep4), completed:true },
        ]
      });
      this.onSetFormFieldsValues(liData,liInsurance);
    }else{
      this.setState({
        tabsStepper:[
          { label: formatMessage(messages.policyInsuranceStep1), completed:false },
          { label: formatMessage(messages.policyInsuranceStep2), completed:false },
          { label: formatMessage(messages.policyInsuranceStep3), completed:false },
          { label: formatMessage(messages.policyInsuranceStep4), completed:false },
        ]
      });
      
      if(year !=undefined && listingStepsData !=null){
        const docIsUploaded = listingStepsData.docVerification !=null ? true:false;
        this.setState({
          setYear:Number(year),
          setVehicleTitle:listingStepsData.listing.title,
          setPolicyRateValue:policyRateValue,
          setPolicyPlan:policyPlan,
          setPolicyDocUploaded:docIsUploaded,
          isDisabled:false
        });
        
        this.onGetCalculateValue(policyPlan);
        
      }
    
    }
   
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { formErrors,valid, listingFields,listingSteps,change,listingStepsData } = nextProps;
    const { setVehicleTitle,tabPolicyCurrent,setPolicyDocUploaded,setPolicyRateValue,tabsStepperStep,isDisabledFields }=this.state;
    
    if( isDisabledFields == false){
      if(tabPolicyCurrent ==='policy_upload_doc'){
        this.setState({ isDisabled: setPolicyDocUploaded ? false:true });
        this.onHandleUpdateStepsOnStepper(0,setPolicyDocUploaded);
      }else if(tabPolicyCurrent ==='policy_input_valuation'){
        if(Number(setPolicyRateValue) > 10000 ){
          this.setState({ isDisabled: false,policyStep2:true });
          this.onHandleUpdateStepsOnStepper(1,true);
        }
      }else if( tabPolicyCurrent ==='policy_review'){
        if (valid) {
          this.setState({ isDisabled: false });
        } else {
          this.setState({ isDisabled: true });
        }
      }
      if (formErrors != undefined) {
        
        if( formErrors.values ){
          const {listingExtData} = formErrors.values;
        
          if(listingExtData && listingExtData.policyInsurance){
            const typeInsurance = listingExtData.policyInsurance;
            
            this.setState({policyInsuranceType:typeInsurance});

            if( typeInsurance == 'request' && listingExtData.policyRateValue && tabPolicyCurrent ==='policy_input_valuation'){
              const checkPolicyRateValue =parseFloat(listingExtData.policyRateValue);
              
              if(checkPolicyRateValue > 10000){
                this.setState({ isDisabled: false });
              }else{
                this.setState({ isDisabled: true });
              }
              
              this.setState({setPolicyRateValue:checkPolicyRateValue});
            }
          }
        }
      }
    }
  }
  
  onSetFormFieldsValues(listData,policyData){
  console.log('policyData:', policyData);
  console.log('listData:', listData);
    
  this.setState({
      setYear:policyData[0].listYear,
      setVehicleTitle:policyData[0].listTitle,
      setPolicyRateValue:listData.policyRateValue,
      setPolicyPlan:listData.policyPlan,
      setPolicyExpire:moment(policyData[0].expirationDate).format('MMM DD, YYYY').toString(),
      setPolicyExtraCost:parseFloat(listData.policyExtraCost).toFixed(2),
      setPolicyNet:parseFloat(listData.policyNet).toFixed(2),
      setPolicyNetUSD:parseFloat(policyData[0].paymentAmountPayPal).toFixed(2),
      setPolicySubNet:parseFloat(listData.policySubNet).toFixed(2),
      setPolicyQTPayment:policyData[0].paymentQt,
      setPolicyFirstDues:parseFloat(policyData[0].duesAmount).toFixed(2),
      setPolicyDues:parseFloat(policyData[0].duesAmount).toFixed(2),
    });
    
  }
  onHandleUpdateStepsOnStepper(e,isCompleted) {
    const {tabsStepper}=this.state;
    let tabsStepperNew = tabsStepper;
    tabsStepperNew[e].completed=isCompleted;
    this.setState({tabsStepper: tabsStepperNew});
  }
  onUpdatePaymentOption(e) {
    const {setPolicyPlan}= this.state;
    this.setState({setPolicyPaymentOption: e});
    this.onGetCalculateValue(setPolicyPlan,e);
  }
  onSetUploaded(){
    
    this.setState({ isDisabled: false,setPolicyDocUploaded: true});
  }
  offSetUploaded(){
    
    this.setState({ isDisabled: true,setPolicyDocUploaded: false});
  }
  onCheckDocUploaded=(isUploaded)=>{ 
    this.setState({ 
      isDisabled: isUploaded? false : true,
      setPolicyDocUploaded: isUploaded
    });
  }
  handleTabChange() {
    const { tabPolicyCurrent,setPolicyPlan,setPolicyRateValue}=this.state;

    if (tabPolicyCurrent === 'policy_upload_doc') {
        this.setState({
          isDisabled: Number(setPolicyRateValue) > 10000?false:true,
          tabPolicyCurrent:'policy_input_valuation'
        });
    }
    if (tabPolicyCurrent === 'policy_input_valuation') {
      this.onGetCalculateValue(setPolicyPlan);
      this.setState({tabPolicyCurrent:'policy_coverage'});
    }
    
    if (tabPolicyCurrent === 'policy_coverage') {
      this.setState({ isDisabled: true,
        tabPolicyCurrent:'policy_review'});
    }
  }

  toNextTab(e) {
    const { tabsStepperStep}=this.state;
    e.preventDefault();
    if( tabsStepperStep < 4){ this.setState({tabsStepperStep:Number(tabsStepperStep)+1}); }
    this.handleTabChange();
  }
  
  toBackTab(e) {
    e.preventDefault();
    const { tabPolicyCurrent,setPolicyDocUploaded,setPolicyRateValue,tabsStepperStep,isDisabledFields}=this.state;
    let setBtnDisabled = !isDisabledFields && Number(setPolicyRateValue) > 10000?false:true;
    if( isDisabledFields == true){ setBtnDisabled=false; }
    if (tabPolicyCurrent === 'policy_input_valuation') {
        this.setState({
          tabPolicyCurrent:'policy_upload_doc',
          isDisabled:setPolicyDocUploaded ? false: true
        });
    }
    if (tabPolicyCurrent === 'policy_coverage') {
      this.setState({
        isDisabled: isDisabledFields,
        tabPolicyCurrent:'policy_input_valuation'});
    }
    
    if (tabPolicyCurrent === 'policy_review') {
      this.setState({isDisabled:false,tabPolicyCurrent:'policy_coverage'});
    }
    if( tabsStepperStep > 0){
      this.setState({tabsStepperStep:Number(tabsStepperStep)-1});
    }
  }

  onGetCalculateValue(setPlanType,setPaymentOpt=0){
    const { isDisabledFields }=this.state;
    const { change,listId,currencyRates,listingExtData }=this.props;
    const { formErrors } = this.props;
    const {setYear,setPolicyInsurance,setPolicyRateValue,setPolicyPaymentOption }= this.state;
    
    if( isDisabledFields == false){
      let getPaymentOption = setPaymentOpt ==0 ? setPolicyPaymentOption: setPaymentOpt;
      
      let planTypeExpiredDT = new Date();
      let ratePriceYearly,ratePricePerPlan,ratePlanValue,planTypeRateValue,planTypeRateQTPayment,planTypeExpired,planQTMonth=1;
      let firstDuePayment=0,othersDuePayment=0,duesPayments=0;
      const listRateYearly =[
        {years:[2009,2010],rateValue:7.24},
        {years:[2011,2012],rateValue:6.83},
        {years:[2013,2014],rateValue:6.41},
        {years:[2015,2016],rateValue:5.58},
        {years:[2017,2018],rateValue:3.58},
        {years:[2019,2020,2021,2022,2023],rateValue:3.31},
        {years:[2024,2025],rateValue:3.10},
      ];
      listRateYearly.map((item) => {
        if(item.years.includes(setYear) ){
          ratePlanValue= item.rateValue;
          ratePriceYearly=setPolicyRateValue*ratePlanValue/100;
        }
      });

      let calcNetPrice=0;
      const fixedPriceLegalInsurance=1810;
      const selfExcessPrice=5000000;
      const listRatePerCoverageType=[
        {coverageType:'montly',rateValue:25,monthsQT:1,qtPayment:1},
        {coverageType:'quarterly',rateValue:40,monthsQT:4,qtPayment:1},
        {coverageType:'biannual',rateValue:70,monthsQT:6,qtPayment:1},
        {coverageType:'yearly',rateValue:100,monthsQT:12,qtPayment:6}
      ];
      listRatePerCoverageType.map((item) => {
        if(item.coverageType == setPlanType ){
          planTypeRateValue= item.rateValue;
          planQTMonth= item.monthsQT;
          planTypeRateQTPayment= item.qtPayment;
          planTypeExpired= moment().add(item.monthsQT, 'month').format('MMM DD, YYYY').toString();
          planTypeExpiredDT= moment().add(item.monthsQT, 'month').format('YYYY-MM-DD HH:mm:ss').toString();
          ratePricePerPlan=planTypeRateValue*ratePriceYearly/100;
          calcNetPrice=ratePricePerPlan+fixedPriceLegalInsurance;
          if(setPlanType =='yearly'){
            const getDuePrice =(ratePriceYearly+fixedPriceLegalInsurance)/item.qtPayment; 
            ratePricePerPlan=ratePriceYearly; //ratePriceYearly+fixedPriceLegalInsurance;
            duesPayments=getDuePrice; //ratePriceYearly/item.qtPayment;
            calcNetPrice=ratePriceYearly+fixedPriceLegalInsurance;
          }
        }
      });
      //Find ratePriceYearly
      const returnValues={rateYearly:ratePlanValue,
        priceYearly:ratePriceYearly,
        planRate:planTypeRateValue,
        planQTPayment:planTypeRateQTPayment,
        planSubNet:ratePricePerPlan,
        planExtraCost:fixedPriceLegalInsurance,
        planNet:calcNetPrice,
        planFirstDue:duesPayments,
        planDues:duesPayments,
      };
      
      change('listingExtData.listId', listId );
      change('listingExtData.listYear', setYear );
      change('listingExtData.planType', setPlanType );
      change('listingExtData.expirationDate', planTypeExpiredDT );
      change('listingExtData.rateYearly', ratePlanValue );
      change('listingExtData.rateAmountYearly', ratePriceYearly );
      change('listingExtData.dueFirst', duesPayments );
      change('listingExtData.duesAmount', duesPayments );
      
      change('listingExtData.ratePlan', planTypeRateValue );
      change('listingExtData.rateAmountPlan', ratePricePerPlan );
      change('listingExtData.planQTMonth', planQTMonth );
      change('listingExtData.paymentQt', planTypeRateQTPayment );
      change('listingExtData.duesQT', planTypeRateQTPayment );
      change('listingExtData.paymentType', 1 );
      
      change('listingExtData.policyNet', parseFloat(returnValues.planNet).toFixed(2) );
      change('listingExtData.paymentNet', parseFloat(returnValues.planNet).toFixed(2) );
      change('listingExtData.policySubNet', parseFloat(returnValues.planSubNet).toFixed(2) );
      change('listingExtData.paymentSubNet', parseFloat(returnValues.planSubNet).toFixed(2) );
      change('listingExtData.paymentCost', parseFloat(returnValues.planExtraCost).toFixed(2) );
      change('listingExtData.policyExtraCost', parseFloat(returnValues.planExtraCost).toFixed(2) );
      change('listingExtData.policyQTPayment', Number(returnValues.planQTPayment) );
      change('listingExtData.policyExpire', planTypeExpiredDT);

      let convertedAmount=0,amountExchangeToUSD=parseFloat(returnValues.planNet);
      if(setPlanType =='yearly' && Number(getPaymentOption) ==1){
        amountExchangeToUSD=parseFloat(duesPayments);
      }
      convertedAmount = convert('USD', currencyRates, parseFloat(amountExchangeToUSD).toFixed(2), 'DOP', 'USD');

      change('listingExtData.policyAmountUSD', convertedAmount.toFixed(2));
      this.setState({
        setPolicyExpire:planTypeExpired,
        setPolicyExtraCost:parseFloat(returnValues.planExtraCost).toFixed(2),
        setPolicyNet:parseFloat(returnValues.planNet).toFixed(2),
        setPolicyNetUSD:convertedAmount.toFixed(2),
        setPolicySubNet:parseFloat(returnValues.planSubNet).toFixed(2),
        setPolicyQTPayment:returnValues.planQTPayment,
        setPolicyFirstDues:parseFloat(returnValues.planFirstDue).toFixed(2),
        setPolicyDues:parseFloat(returnValues.planDues).toFixed(2),
        policyStep4:false
      });
    }
  }

  handleCalculatePrice(e){
    const {change}= this.props;
    const setPlanType = e.target.value;
    
    this.setState({setPolicyPlan:setPlanType});
    this.onGetCalculateValue(setPlanType);
  }
  
  onHandleConfirm(e){
    this.setState({ 
      isDisabled: e ? false : true,
      policyStep4:e
    });
  }

  checkboxGroup = ({ input, meta: { touched, error }, children, className, selectClass, name,disabled }) => {
    return (
      <div className={cx(s.checkBoxGrid, cs.paddingBottom4)}>
        <CustomCheckbox
          name={name}
          disabled={disabled}
          className={cx('icheckbox_square-green',s.icheckboxWithBorder,s.isCheckBoxConfirm,cs.displayInlineBlock)}
          checked={input.value == true}
          onChange={event => {
            this.onHandleConfirm(event); return input.onChange(event);
          }}
        />
        <p className={cx(cs.commonMediumText,cs.displayInlineBlock,cs.marginLeftCheckBox,cs.fontWeightNormal, s.checkBoxText, 'signUpCheckBoxTextRTl')}>
          <FormattedMessage {...messages.iAgreePolicyInsurance} />
        </p>
      </div>
    )
  }
  render() {
    const { handleSubmit, nextPage, previousPage, formPage, step,listId } = this.props;
    const { isDisabledFields, isDisabled,policyStep1,policyStep2,policyStep3,policyStep4,tabPolicyCurrent,tabsStepper,tabsStepperStep } = this.state;
    const { formatMessage } = this.props.intl;
    // const { listingPolicy }= this.props;
    const { listingSteps,listingSteps:{listingExtDT,policyPayments}} = this.props;
    console.log('policyPayments:', policyPayments);
    console.log('listingExtDT:', listingExtDT);

    const { policyInsuranceType,setYear,setPolicyRateValue,setPolicySubNet,setPolicyExtraCost,setPolicyNet,setPolicyQTPayment,setPolicyPlan,setPolicyExpire,setPolicyFirstDues,setPolicyDues,setVehicleTitle,setPolicyNetUSD,setPolicyPaymentOption}= this.state;
    
    const priceMaskDOP = createNumberMask({prefix: 'DOP$ ',decimalPlaces: 2,spaceAfterSign: true,allowNegative: true}); 
    
    return (
      <div className={cx(s.stepGrid, 'stepGridRTL')}>
        <SidePanel
          title={formatMessage(messages.step4Heading)}
          landingContent={formatMessage(messages.policyInsurance)}
          showStepper={policyInsuranceType=='request'? true:false}
          StepperSteps={tabsStepper}
          StepperActive={tabsStepperStep}
        />
        {/* {
          loading && <Loader type={"text"} />
        } */}
        
        <form onSubmit={handleSubmit} className={cx(s.landingMainContent,s.landingMainContentSmPadding)}>
            <div className={cx('fade',tabsStepperStep ==0 ?s.showQuestion:s.hideQuestion)}>
              <Row>
                <Col xs={12}>
                  <h3 className={cx(cs.commonContentText, cs.spaceBottom3,cs.fontWeightExtraBold)}><FormattedMessage {...messages.policyGPSOpt} /></h3>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <div className={s.spaceTop2}>
                    <div className={cx(s.checkBoxFlex, s.alignBase)}>
                      <div>
                      <Field name="listingExtData.policyGPS" disabled={isDisabledFields} component="input" type="radio" value="yes" className={cx(s.BookingradioInput,cs.radioSetBorder,cs.radioMarginTop1)} />
                      </div>
                      <div className={cx(s.checkBoxLeft, 'discountRightRTL')}>
                        <span className={cx(cs.commonMediumText)}><FormattedMessage {...messages.policyGPSOpt1} /></span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <div className={cx(s.spaceTop2)}>
                    <div className={s.checkBoxFlex}>
                      <div>
                      <Field name="listingExtData.policyGPS" disabled={isDisabledFields} component="input" type="radio" value="no" className={cx(s.BookingradioInput,cs.radioSetBorder,cs.radioMarginTop1)} />
                      </div>
                      <div className={cx(s.checkBoxLeft, 'discountRightRTL')}>
                        <span className={cx(cs.commonMediumText)}><FormattedMessage {...messages.policyGPSOpt2} /></span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12}>
                  <hr className={cx(cs.listingHorizoltalLine, cs.spaceBottom4, cs.spaceTop4)} />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <h3 className={cx(cs.commonContentText,cs.commonBoldText, cs.spaceBottom3, cs.fontWeightMedium)}><FormattedMessage {...messages.policyInsuranceWhy} /></h3>
                  
                  <div className={cx(cs.commonMediumText, cs.spaceBottom4)}>
                    <FormattedMessage {...messages.policyInsuranceInfo} />
                  </div>
                  
                  <h3 className={cx(cs.commonContentText, cs.spaceBottom3, cs.fontWeightExtraBold)}><FormattedMessage {...messages.policyInsuranceOpt} /></h3>
                  
                  <div className={cx(s.searchToolTip, cs.spaceBottom3)}>
                    <img src={locationIcon} className={'listTipIcon'}/>
                    <span className={cx(s.locationTipCss, cs.commonMediumText)}><FormattedMessage {...messages.policyInsuranceInfo2} /></span>
                  </div>

                  <div className={s.spaceTop3}>
                    <div className={cx(s.checkBoxFlex, s.alignBase)}>
                      <div>
                      <Field name="listingExtData.policyInsurance" disabled={isDisabledFields} component="input" type="radio" value="own" className={cx(s.BookingradioInput,cs.radioSetBorder,cs.radioMarginTop1)} />
                      </div>
                      <div className={cx(s.checkBoxLeft, 'discountRightRTL')}>
                        <span className={cx(cs.commonMediumText)}><FormattedMessage {...messages.policyInsuranceOpt1} /></span>
                        <span className={s.subText}><FormattedMessage {...messages.inputValuationRatePolicyInfo} /></span>
                      </div>
                    </div>
                  </div>
                  
                  <div className={cx(s.space6, s.spaceTop3)}>
                    <div className={s.checkBoxFlex}>
                      <div>
                      <Field name="listingExtData.policyInsurance" disabled={isDisabledFields} component="input" type="radio" value="request" className={cx(s.BookingradioInput,cs.radioSetBorder,cs.radioMarginTop1)} />
                      </div>
                      <div className={cx(s.checkBoxLeft, 'discountRightRTL')}>
                        <span className={cx(cs.commonMediumText)}><FormattedMessage {...messages.policyInsuranceOpt2} /></span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Row className={cx(cs.notMarginLR,policyInsuranceType=='own'?'isHidden2':'isHidden')}>
              <Col lg={12}>
                <p><FormattedMessage {...messages.pleaseUploadOwnPolicy} /></p>
              </Col>
              
              <Col xs={12} sm={12} md={6} lg={6} >
                <FormGroup className={cx(s.noMargin)}>
                  <ControlLabel className={s.landingLabel}>
                    <FormattedMessage {...messages.inputValuationRatePolicy} />
                    <div className={s.infoIconCss}>
                      <img src={infoIcon} className={s.infoIcon} />
                      <div className={cx(s.infoContent, s.basePriceInfo, 'infoContentRTL')}>{formatMessage(messages.inputValuationRatePolicyInfo)}</div>
                    </div>
                  </ControlLabel>
                  <Field
                    name="listingExtData.policyRateValueOwn"
                    type="text"
                    component={CommonFormComponent}
                    label={formatMessage(messages.inputValuationRatePolicy)}
                    inputClass={cx(s.formControlInput, s.jumboSelect, s.formControlInputMaxWidth)}
                    {...priceMaskDOP}
                  />
                </FormGroup>
                <div className={cx(s.searchToolTip, cs.spaceTop2, cs.spaceBottom4)}>
                  <img src={locationIcon} className={'commonIconSpace'} />
                  <span className={cx(s.locationTipCss, cs.commonMediumText)}><FormattedMessage {...messages.inputValuationRatePolicyInfo} /></span>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} >
                <FormGroup className={cx(s.noMargin)}>
                  <ControlLabel className={s.landingLabel}>
                    <FormattedMessage {...messages.policyExpiration} />
                  </ControlLabel>
                  <Field
                    name="listingExtData.policyExpireOwn"
                    type="date"
                    component={CommonFormComponent}
                    label={formatMessage(messages.inputValuationRatePolicy)}
                    inputClass={cx(s.formControlInput, s.jumboSelect, s.formControlInputMaxWidth)}
                  />
                </FormGroup>
                <div className={cx(s.searchToolTip, cs.spaceTop2, cs.spaceBottom4)}>
                  <img src={locationIcon} className={'commonIconSpace'} />
                  <span className={cx(s.locationTipCss, cs.commonMediumText)}><FormattedMessage {...messages.inputExpirationDatePolicy} /></span>
                </div>
              </Col>
              <Col lg={12}>
                <div className={cx(s.uploadVehicleContainer,s.marginTop40)}>
                  <h3 className={cx(cs.commonContentText, cs.spaceBottom3)}><FormattedMessage {...messages.carDocPolicy} /></h3>
                  <FormGroup className={cx(s.formGroup, 'stepPhotoUpload','stepPhotoUploadDoc')}>
                  
                    <PolicyDocumentUpload 
                    onSetUploaded={this.onSetUploaded}
                    offSetUploaded={this.offSetUploaded}
                    placeHolderClassName={'documentPlaceholderDoc'}
                    iconClassName={'photoUploadImgDoc'}
                    dzListPhotoContainer={'listPhotoContainerDoc'} 
                    dzInputContainerName={'dzInputContainerDoc'} 
                    maxFilesUpload={1} 
                    docIdVerification={4} 
                    listId={listId} 
                    placeholder={formatMessage(messages.policyUploadPlaceholder)} />
                  </FormGroup>
                </div>
              </Col>
            </Row>
            
            <Row className={
              cx(cs.notMarginLR,policyInsuranceType=='request'?'isHidden2':'isHidden')
              }>
              <Col lg={12}>
                <TabContainer 
                  id="policy-tabs-center-stepper" 
                  activeKey={tabPolicyCurrent}  
                  defaultActiveKey="policy_upload_doc"
                  // className={cx('navTabsSlideSide')}
                  onSelect={(k) => this.handleTabChange}
                  >
                  <Row className="clearfix">
                     <Col sm={12} className={cx('navStepperNav','isHidden')}>
                        <Nav className={cx(s.NavStepperContainer)}>
                          <NavItem 
                              active={policyStep1}
                              className={cx(s.NavStepContainer)} 
                              key={'id_policy_upload_doc'} 
                              eventKey={'policy_upload_doc'}>
                                
                                <div className={cx(s.NavStepMain)}>
                                  <div className={cx(s.NavStepButton,'NavStepperButton',tabPolicyCurrent==='policy_upload_doc'?s.NavStepButtonSelected:'')}>
                                    <span className={s.NavStepButtonContent}>1</span>
                                  </div>
                                  <div className={cx(s.NavLabelContainer)}>
                                    <span className={cx(s.NavLabel)}><FormattedMessage {...messages.policyInsuranceStep1} /></span>
                                  </div>
                                </div>
                          </NavItem>

                          <NavItem 
                            active={policyStep2}
                            // disabled={policyStep2}
                              className={cx(s.NavStepContainer)} 
                              key={'id_policy_input_valuation'} eventKey={'policy_input_valuation'}>
                                <div className={cx(s.NavConnectorContainer)}><span className={cx(s.NavConnector,'NavStepperConnector')}></span></div>
                                <div className={cx(s.NavStepMain)}>
                                  <div className={cx(s.NavStepButton,'NavStepperButton',tabPolicyCurrent==='policy_input_valuation'?s.NavStepButtonSelected:'')} >
                                    <span className={s.NavStepButtonContent}>2</span>
                                  </div>
                                  <div className={cx(s.NavLabelContainer)}>
                                    <span className={cx(s.NavLabel)}><FormattedMessage {...messages.policyInsuranceStep2} /></span>
                                  </div>
                                </div>
                          </NavItem>

                          <NavItem 
                              active={policyStep3}
                              className={cx(s.NavStepContainer)} 
                              key={'id_policy_coverage'} eventKey={'policy_coverage'}>
                                <div className={cx(s.NavConnectorContainer)}><span className={cx(s.NavConnector,'NavStepperConnector')}></span></div>
                                <div className={cx(s.NavStepMain)}>
                                  <div className={cx(s.NavStepButton,'NavStepperButton',tabPolicyCurrent==='policy_coverage'?s.NavStepButtonSelected:'')}>
                                    <span className={s.NavStepButtonContent}>3</span>
                                  </div>
                                  <div className={cx(s.NavLabelContainer)}>
                                    <span className={cx(s.NavLabel)}><FormattedMessage {...messages.policyInsuranceStep3} /></span>
                                  </div>
                                </div>
                          </NavItem>
                          
                          <NavItem 
                              active={policyStep4}
                              // disabled={policyStep4}
                              className={cx(s.NavStepContainer)} 
                              key={'id_policy_review'} eventKey={'policy_review'}>
                                <div className={cx(s.NavConnectorContainer)}><span className={cx(s.NavConnector,'NavStepperConnector')}></span></div>
                                <div className={cx(s.NavStepMain)}>
                                  <div className={cx(s.NavStepButton,'NavStepperButton',tabPolicyCurrent==='policy_review'?s.NavStepButtonSelected:'')}>
                                    <span className={s.NavStepButtonContent}>4</span>
                                  </div>
                                  <div className={cx(s.NavLabelContainer)}>
                                    <span className={cx(s.NavLabel)}><FormattedMessage {...messages.policyInsuranceStep4} /></span>
                                  </div>
                                </div>
                          </NavItem>
                       </Nav>
                    </Col>

                    <Col sm={12} className={cx(cs.noPadding)}>
                      <TabContent animation>
                        <TabPane key={'panel_policy_upload_doc'} eventKey={'policy_upload_doc'}>
                          <Row>
                            <Col lg={12}>
                              <p><FormattedMessage {...messages.pleaseClickOn} /> <a href={"/get-pdf-policyinsurance?pdfname=a380b1c39b446fa75f840fc6a34a2388.pdf"} target="_blank"><FormattedMessage {...messages.pleaseClickOnLink} /></a> <FormattedMessage {...messages.pleaseClickOn2} /></p>
                            </Col>
                              
                            <Col lg={12}>
                              <div className={cx(s.uploadVehicleContainer,s.marginTop40)}>
                                <h3 className={cx(cs.commonContentText, cs.spaceBottom3)}><FormattedMessage {...messages.carDocPolicy} /></h3>
                                <FormGroup className={cx(s.formGroup, 'stepPhotoUpload','stepPhotoUploadDoc')}>
                                  <PolicyDocumentUpload 
                                    placeHolderClassName={'documentPlaceholderDoc'}
                                    iconClassName={'photoUploadImgDoc'}
                                    dzListPhotoContainer={'listPhotoContainerDoc'} 
                                    dzInputContainerName={'dzInputContainerDoc'} 
                                    maxFilesUpload={1} 
                                    docIdVerification={5} 
                                    listId={listId} 
                                    placeholder={formatMessage(messages.policyUploadPlaceholder)} />
                                </FormGroup>
                              </div>
                            </Col>
                            
                          </Row>
                        </TabPane>

                        <TabPane key={'panel_policy_input_valuation'} eventKey={'policy_input_valuation'}>
                          
                          <Row>
                            <Col xs={12}>
                              <p><FormattedMessage {...messages.pleaseInputRateValueCar} /></p>
                            </Col>
                            
                          </Row>
                          <Row>
                            <Col xs={12} sm={12} md={6} lg={6} >
                              <FormGroup className={cx(s.noMargin)}>
                                <ControlLabel className={s.landingLabel}>
                                  <FormattedMessage {...messages.inputValuationRatePolicy} />
                                  <div className={s.infoIconCss}>
                                    <img src={infoIcon} className={s.infoIcon} />
                                    <div className={cx(s.infoContent, s.basePriceInfo, 'infoContentRTL')}>{formatMessage(messages.inputValuationRatePolicyInfo)}</div>
                                  </div>
                                </ControlLabel>
                                <Field
                                  name="listingExtData.policyRateValue"
                                  type="text"
                                  component={CommonFormComponent}
                                  label={formatMessage(messages.inputValuationRatePolicy)}
                                  inputClass={cx(s.formControlInput, s.jumboSelect, s.formControlInputMaxWidth)}
                                  {...priceMaskDOP}
                                />
                              </FormGroup>
                              <div className={cx(s.searchToolTip, cs.spaceTop2, cs.spaceBottom4)}>
                                <img src={locationIcon} className={'commonIconSpace'} />
                                <span className={cx(s.locationTipCss, cs.commonMediumText)}><FormattedMessage {...messages.inputValuationRatePolicyInfo} /></span>
                              </div>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} >
                              <FormGroup className={cx(s.noMargin)}>
                                <ControlLabel className={s.landingLabel}>
                                  <FormattedMessage {...messages.policyVehicleInfo} />
                                </ControlLabel>
                                <h4>{setVehicleTitle}</h4> 
                              </FormGroup>
                              
                            </Col>
                          </Row>
                          
                          
                        </TabPane>

                        <TabPane key={'panel_policy_coverage'} eventKey={'policy_coverage'}>
                          <Row>
                            <Col xs={6} sm={6} lg={3} md={6}>
                                <div className={s.spaceTop3}>
                                  <div className={cx(s.checkBoxFlex, s.alignBase)}>
                                    <div>
                                      <Field disabled={isDisabledFields} onChange={this.handleCalculatePrice} name="listingExtData.policyPlan" component="input" type="radio" value="montly" className={cx(s.BookingradioInput,s.radioInputInlineMargin,cs.radioSetBorder)} />
                                    </div>
                                    <div className={cx(s.checkBoxLeft, 'discountRightRTL')}>
                                      <span className={cx(cs.commonMediumText)}><FormattedMessage {...messages.policyInsurancePlan1} /></span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} sm={6} lg={3} md={6}>
                                <div className={cx(s.spaceTop3)}>
                                  <div className={s.checkBoxFlex}>
                                    <div>
                                    <Field onChange={this.handleCalculatePrice} name="listingExtData.policyPlan" component="input" type="radio" value="quarterly" className={cx(s.BookingradioInput,s.radioInputInlineMargin,cs.radioSetBorder)} />
                                    </div>
                                    <div className={cx(s.checkBoxLeft, 'discountRightRTL')}>
                                      <span className={cx(cs.commonMediumText)}><FormattedMessage {...messages.policyInsurancePlan2} /></span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} sm={6} lg={3} md={6}>
                                <div className={cx(s.spaceTop3)}>
                                  <div className={s.checkBoxFlex}>
                                    <div>
                                    <Field onChange={this.handleCalculatePrice} name="listingExtData.policyPlan" component="input" type="radio" value="biannual" className={cx(s.BookingradioInput,s.radioInputInlineMargin,cs.radioSetBorder)} />
                                    </div>
                                    <div className={cx(s.checkBoxLeft, 'discountRightRTL')}>
                                      <span className={cx(cs.commonMediumText)}><FormattedMessage {...messages.policyInsurancePlan3} /></span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} sm={6} lg={3} md={6}>
                                <div className={cx(s.spaceTop3)}>
                                  <div className={s.checkBoxFlex}>
                                    <div>
                                    <Field onChange={this.handleCalculatePrice} name="listingExtData.policyPlan" component="input" type="radio" value="yearly" className={cx(s.BookingradioInput,s.radioInputInlineMargin,cs.radioSetBorder)} />
                                    </div>
                                    <div className={cx(s.checkBoxLeft, 'discountRightRTL')}>
                                      <span className={cx(cs.commonMediumText)}><FormattedMessage {...messages.policyInsurancePlan4} /></span>
                                    </div>
                                  </div>
                                </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <PolicyReviewListing 
                                onUpdatePaymentOption={this.onUpdatePaymentOption}
                                setPolicyPaymentOption={setPolicyPaymentOption}
                                setPolicyPlan={setPolicyPlan}
                                setYear={setYear}
                                setPolicyRateValue={setPolicyRateValue}
                                setPolicyQTPayment={setPolicyQTPayment}
                                setPolicyFirstDues={setPolicyFirstDues}
                                setPolicyDues={setPolicyDues}
                                setPolicyExpire={setPolicyExpire}
                                setPolicySubNet={setPolicySubNet}
                                setPolicyExtraCost={setPolicyExtraCost}
                                setPolicyNet={setPolicyNet}
                                setPolicyNetUSD={setPolicyNetUSD}
                              />
                              
                            </Col>
                          </Row>
                        </TabPane>
                        
                        <TabPane key={'panel_policy_review'} eventKey={'policy_review'}>
                          <Row>
                            <Col xs={12}>
                              <PolicyReviewListing 
                                onUpdatePaymentOption={this.onUpdatePaymentOption}
                                setPolicyPaymentOption={setPolicyPaymentOption}
                                setPolicyPlan={setPolicyPlan}
                                setReadyOnlyPaymentOpt={true}
                                setYear={setYear}
                                setPolicyRateValue={setPolicyRateValue}
                                setPolicyQTPayment={setPolicyQTPayment}
                                setPolicyFirstDues={setPolicyFirstDues}
                                setPolicyDues={setPolicyDues}
                                setPolicyExpire={setPolicyExpire}
                                setPolicySubNet={setPolicySubNet}
                                setPolicyExtraCost={setPolicyExtraCost}
                                setPolicyNet={setPolicyNet}
                                setPolicyNetUSD={setPolicyNetUSD}
                              />
                            </Col>
                          </Row>

                          {!isDisabledFields && <Row>
                            <Col xs={12}>
                              <Field disabled={isDisabledFields} name="confirmationPolicyInsurance" component={this.checkboxGroup} />
                            </Col>
                          </Row>}
                          
                          {isDisabledFields && listingExtDT && policyPayments && <Row>
                            <Col xs={12}>
                              <PolicyPaymentReviewListing
                                policyListing={listingExtDT}
                                policyPayment={policyPayments}
                               />
                              
                            </Col>
                          </Row> }
                        </TabPane>

                      </TabContent>
                    </Col>
                  </Row>

                </TabContainer>
              </Col>
            </Row>
            
            {tabPolicyCurrent !='policy_review' && policyInsuranceType ==='request' &&
            <FooterButtonPolicy
              isDisabled={isDisabled}
              nextPage={this.toNextTab}
              previousPage={this.toBackTab}
              exitPage={previousPage}
              nextPagePath={"pricing"}
              previousPagePath={"home"}
              formPage={formPage}
              step={step}
            />
            }
            {tabPolicyCurrent ==='policy_review' && policyInsuranceType ==='request' &&
            <FooterButtonPolicy
              exitOnly={isDisabledFields}
              isDisabled={isDisabled}
              exitPage={previousPage}
              previousPage={this.toBackTab}
              previousPagePath={"home"}
              formPage={formPage}
              isFinish
              type={"submit"}
              step={step}
            />
            }
            {policyInsuranceType ==='own' &&
            <FooterButtonPolicy
              isDisabled={isDisabled}
              exitPage={previousPage}
              previousPage={previousPage}
              previousPagePath={"home"}
              formPage={formPage}
              isFinish
              type={"submit"}
              step={step}
            />
            }
        </form>
        
      </div>
    );
  }
}

FormPolicyInsurance = reduxForm({
  form: 'ListPlaceStep3', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStep4,
  onSubmit: updateStep4
})(FormPolicyInsurance);

const mapState = (state) => ({
  listingSteps: state.location.listingSteps,
  formErrors: state.form.ListPlaceStep3,
  listingFields: state.listingFields.data,
  currencyRates: state.currency.rates
});

const mapDispatch = {
  change
};

export default compose(
injectIntl,
connect(mapState, mapDispatch),
withStyles(s,cs),
// graphql(getListingExtQuery, {
//   name: 'ShowlistingExtData',
//   options: (props) => ({
//     variables: {
//       listid: props.listId,
//     },
//     fetchPolicy: 'network-only',
//     ssr: true
//   })
// }),
)(FormPolicyInsurance);
