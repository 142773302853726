import React from 'react';
import UserLayout from '../../components/Layout/UserLayout';
import Dashboard from './Dashboard';
import { Store } from 'redux';

const title = 'Dashboard';

export default async function action({ store }: {
  store: Store<any>
}) {

    // From Redux Store
    let isAuthenticated = store.getState().runtime.isAuthenticated;

    if (!isAuthenticated) {
      return { redirect: '/login' };
    }

    return {
      title,
      component: <UserLayout>
        <Dashboard title={title} />
      </UserLayout>,
    };
  };
