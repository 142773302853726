import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import moment from 'moment';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// Fetch request
import fetch from '../../../core/fetch';

// Send Email
import { sendEmail } from '../../../core/email/sendEmail';


// Components
import Loader from '../../../components/Loader';
import showToaster from '../../../helpers/toasterMessages/showToaster';
// import Payout from './Payout';
// import Refund from './Refund';
// import CurrencyConverter from '../../CurrencyConverter';

// import ModalForm from './ModalForm';
import CustomPagination from '../../CustomPagination';
import CommonTable from '../../CommonTable/CommonTable';
import TableAction from '../../CommonTable/TableAction';

// graphql
import ContractUpdateQuery from './ContractUpdateQuery.graphql';

// Translation
import messages from '../../../locale/messages';

//Helper
import formatReservationState from '../../../helpers/formatReservationState';

import s from './ContractsOwner.css';
import cs from '../../../components/commonStyle.css';

class ContractsOwner extends React.Component {

  static propTypes = {
    title: PropTypes.string,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.any,
      getTransactionHistory: PropTypes.shape({
        count: PropTypes.number.isRequired,
        contractData: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number.isRequired,
          listId: PropTypes.number.isRequired,
          dealerId: PropTypes.string.isRequired,
          contractStatus: PropTypes.number.isRequired,
          delear_signup_date: PropTypes.string.isRequired,
          client_signup_date: PropTypes.string.isRequired,
          contract_date_signed: PropTypes.string.isRequired,
          dealerData: PropTypes.shape({
            profileId: PropTypes.number.isRequired,
            firstName: PropTypes.string.isRequired,
            userData:PropTypes.shape({
              email: PropTypes.string.isRequired,
            })
          }),
          dealerDataExt: PropTypes.shape({
            identity_id: PropTypes.string.isRequired,
            identity_type: PropTypes.number.isRequired,
            driver_license: PropTypes.string.isRequired,
            isAgreementStatus: PropTypes.number.isRequired,
          }),
          dealerUser: PropTypes.shape({
            email: PropTypes.string.isRequired,
          }),
          
        })),
      }),
    }).isRequired,
    // viewReceiptAdmin: PropTypes.any.isRequired,
  };

  static defaultProps = {
    // loaderRender:false,
    data: {
      loading: true,
      getAllContractListing: {
        count: null,
        contractData: []
      }
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      loaderRender:false,
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0,
      // selectedRefund: [],
      successRefund: [],
      selectedPayout: [],
      successPayout: [],
    };
    this.paginationData = this.paginationData.bind(this);
    this.changeState = this.changeState.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  async handleUpdate(id, status,dealerId, item) {
    const { mutate, searchList, currentPage, getAllContractListing: { refetch }  } = this.props;
    this.setState({ loaderRender: true });
    const { data } = await mutate({
      variables: {
        id: id,
        dealerId: dealerId,
        contractStatus: status,
        isAgreementStatus: status
      },
      // refetchQueries: [{ query: showAllDocumentVerification, variables: { currentPage } }]
    });

    const respRenderAgreement = await fetch('/export-agreement-data/?search='+dealerId, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
    });

    if (data?.userUpdateAgreement?.status === 'success') {
      if( respRenderAgreement){
        let msg = 'Contract Agreement have been ';
        msg += (status == 2) ? 'Approved.' : 'Rejected.';
        let content = {
          name: item.dealerData.firstName,
          verificationStatus: (status == 2) ? 'approved' : 'rejected'
        }
        sendEmail(item.dealerUser.email, 'contractAgreement', content);
        this.setState({ loaderRender: false });
        showToaster({ messageId: 'commonSuccess', toasterType: 'success', requestMessage: msg })
        refetch({ currentPage, searchList });
        
      }
    } else {
      this.setState({ loaderRender: false });
      showToaster({ messageId: 'documentManagementError', toasterType: 'error', requestMessage: data.userUpdateAgreement && data.userUpdateAgreement.errorMessage })
    }
  }
  changeState(type, value) {
    // const { selectedRefund, successRefund, selectedPayout, successPayout } = this.state;
    const { searchList, currentPage, getAllContractListing: { refetch } } = this.props;
    let variables = {};

    // if (type === 'addRefund') {
    //   variables = { selectedRefund: [...selectedRefund, value] };
    // }
    // if (type === 'removeRefund') {
    //   let index = selectedRefund.findIndex(i => i === value);
    //   if (index === -1) return '';
    //   let data = [...selectedRefund];
    //   data.splice(index, 1)
    //   variables = { selectedRefund: data };
    // }
    // if (type === 'successRefund') {
    //   variables = { successRefund: [...successRefund, value] };
    // }

    // if (type === 'addPayout') {
    //   variables = { selectedPayout: [...selectedPayout, value] };
    // }
    // if (type === 'removePayout') {
    //   let index = selectedPayout.findIndex(i => i === value);
    //   if (index === -1) return '';
    //   let data = [...selectedPayout];
    //   data.splice(index, 1)
    //   variables = { selectedPayout: data };
    // }
    // if (type === 'successPayout') {
    //   variables = { successPayout: [...successPayout, value] };
    //   refetch({ currentPage, searchList });
    // }
    this.setState(variables)
  }

  paginationData(currentPage) {
    const { getAllContractListing: { refetch }, changeStateValues, searchList } = this.props;
    let variables = { currentPage, searchList };
    changeStateValues(variables);
    refetch(variables);
  }

  handleClick(searchList) {
    const { getAllContractListing: { refetch }, changeStateValues } = this.props;
    let variables = {
      currentPage: 1,
      searchList: searchList
    };
    changeStateValues(variables);
    refetch(variables);
  }

  handleSearchChange = (e) => {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      searchList: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleClick(self.state.searchList);
      }, 450)
    });
  }

  thead = () => {
    const { formatMessage } = this.props.intl;
    return [
      { data: formatMessage(messages.contractId) },
      { data: formatMessage(messages.codeLabel) },
      // { data: formatMessage(messages.reservation) },
      // { data: formatMessage(messages.reservation) },
      // { data: formatMessage(messages.status) },
      // { data: formatMessage(messages.carNameLabel) },
      { data: formatMessage(messages.ownerDetails) },
      { data: formatMessage(messages.userDriverLicense) },
      { data: formatMessage(messages.dateSigned) },
      { data: formatMessage(messages.labelContract) },
      { data: formatMessage(messages.status) },
      // { data: formatMessage(messages.guestLabel) },
      { data: formatMessage(messages.createdDate) },
    ]
  };

  tbody = () => {
    const { getAllContractListing: { getAllContractListing } } = this.props;
    const { formatMessage } = this.props.intl;
    const { selectedRefund, successRefund, selectedPayout, successPayout } = this.state;

    return getAllContractListing?.contractData.map(value => {
      return {
        id: value?.id,
        data: [
          { data: value.id },
          { data: value.code.toUpperCase() },
          
          // { data: value.reservationId },
          // { data: value.listBooking.confirmationCode },
          // {
          //   data: <div className={cx(cs.displayFlex, cs.alignCenter, cs.spaceBetween)}>
          //     {value?.listBooking.confirmationCode}
          //     <TableAction
          //       showView={true}
          //       link={`/siteadmin/viewreservation/${value.reservationId}/reservation`}
          //     />
          //   </div>
          // },
         
          { data: value.dealerData.firstName+' - '+value.dealerUser.email },
          { data: value.dealerDataExt.driver_license },
          { data: moment(value.delear_signup_date).format('DD-MM-YYYY hh:mm A').toString() },
          { data: <a href={`/get-pdf-agreement?pdfname=agreement_${value.dealerId}.pdf`} target='_blank'>{formatMessage(messages.openContract)}</a> },
          {
            data:
              
              <select value={value.contractStatus} onChange={(e) => this.handleUpdate(value?.id,e.target.value,value?.dealerId, value)}>
                <option value={1}>{formatMessage(messages.messageStatus5)}</option>
                <option value={2}>{formatMessage(messages.messageStatus4)}</option>
              </select>
          },
          // { data: value.contractStatus ==1 ? formatMessage(messages.messageStatus5) : formatMessage(messages.messageStatus4) },
          
          { data: moment(value.createdAt).format('DD-MM-YYYY hh:mm A').toString() },
          // { data: value.createdAt },
          // {
          //   data: <Refund
          //     id={value.id}
          //     reservationState={value.reservationState}
          //     transactionData={value.transaction}
          //     refundData={value.refundStatus}
          //     cancelData={value.cancellationDetails}
          //     selectedRefund={selectedRefund}
          //     changeState={this.changeState}
          //     successRefund={successRefund}
          //   />
          // },
          // {
          //   data: <CurrencyConverter
          //     amount={value.total + value.guestServiceFee}
          //     from={value.currency}
          //   />
          // },
          // {
          //   data: <Payout
          //     hostId={value.hostId}
          //     checkIn={value.checkIn}
          //     id={value.id}
          //     hostPayout={value.hostPayout}
          //     amount={value.total}
          //     currency={value.currency}
          //     hostTransaction={value.hostTransaction}
          //     reservationState={value.reservationState}
          //     cancelData={value.cancellationDetails}
          //     hostData={value.hostData}
          //     hostServiceFee={value.hostServiceFee}
          //     selectedPayout={selectedPayout}
          //     successPayout={successPayout}
          //     changeState={this.changeState}
          //   />
          // },
        ]
      }
    })
  }

  render() {
    const { getAllContractListing: { getAllContractListing }, currentPage, searchList } = this.props;
    // const {loaderRender,loading}=this.setState;
    const { formatMessage } = this.props.intl;

    return (
        
      <div className={cx(s.pagecontentWrapper, 'pagecontentWrapperRTL')}>
      <Loader type={"pagefixed"} show={this.state.loaderRender} />
        <CommonTable
          thead={this.thead}
          tbody={this.tbody}
          title={formatMessage(messages.ownerContracts)}
          // isExport
          // exportLink={`/export-contract-data?type=reservations&search=${searchList}`}
          isSearch
          onSearch={this.handleSearchChange}
        />
        {
          getAllContractListing?.contractData?.length > 0
          && <div>
            <CustomPagination
              total={getAllContractListing.count}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel={formatMessage(messages.panelContracts)}
            />
          </div>
        }
      </div>
    );
  }

}

const mapState = (state) => ({
  completed: state.reservation.completed,
  loading: state.reservation.loading,
});

const mapDispatch = {};

export default compose(injectIntl,
  withStyles(s, cs),
  graphql(ContractUpdateQuery,
    { options: { fetchPolicy: 'network-only' } }),
  connect(mapState, mapDispatch)
)(ContractsOwner);