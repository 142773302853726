import React from 'react';
import { Button } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useState } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './VerificationFallback.css';
import PhoneVerificationModal from '../PhoneVerificationModal/PhoneVerificationModal';
import DocumentUploadID from '../DocumentUploadID/DocumentUploadID';
import messages from '../../locale/messages';
import { resendEmailVerification } from '../../actions/manageUserVerification';

const VerificationFallback = ({
    account,
    children,
    intl: { formatMessage },
    resendEmailVerification
}) => {
    const { email } = account;
    const verification = account.verification || {};
    const phoneNumber = account.phoneNumber || '';

    const [activeSection, setActiveSection] = useState('email');

    if (verification.isEmailConfirmed &&
        verification.isIdNumberVerification &&
        phoneNumber &&
        verification.isLicenseVerified &&
        verification.isIdVerification) return children

    return <div className={s["verification-container"]}>
        <h1><FormattedMessage {...messages.verificationTitle} /></h1>
        <p><FormattedMessage {...messages.verificationSubtitle} /></p>

        <section className={s["verification-section"]}>
            <h2><FormattedMessage {...messages.verifyEmailTitle} /></h2>
            {verification.isEmailConfirmed ? <>
                <p>
                    <FormattedMessage {...messages.emailVerified} />
                </p>
            </> : <>
                <p>
                    <FormattedMessage 
                        {...messages.emailVerificationSent} 
                        values={{ email: <strong>{email}</strong> }}
                    />
                </p>
                <div className={s["verification-actions"]}>
                    <Button onClick={resendEmailVerification} className="link-button">
                        <FormattedMessage {...messages.resendEmail} />
                    </Button>
                </div>
            </>}
        </section>

        <section className={s["verification-section"]}>
            <h2 onClick={() => setActiveSection(activeSection === 'mobile' ? '' : 'mobile')}
                className={s["accordion-header"]}>
                <FormattedMessage {...messages.mobileNumberTitle} />
                <span className={s["accordion-icon"]}>{activeSection === 'mobile' ? '−' : '+'}</span>
            </h2>
            {activeSection === 'mobile' && (
                <div className={s["accordion-content"]}>
                    <PhoneVerificationModal />
                </div>
            )}
        </section>

        <section className={s["verification-section"]}>
            <h2 onClick={() => setActiveSection(activeSection === 'license' ? '' : 'license')}
                className={s["accordion-header"]}>
                <FormattedMessage {...messages.idPassportTitle} />
                <span className={s["accordion-icon"]}>{activeSection === 'license' ? '−' : '+'}</span>
            </h2>
            {activeSection === 'license' && <div className={s["accordion-content"]} style={{ position: 'relative' }}>
                {verification.isLicenseVerified ? <p>
                        <FormattedMessage {...messages.idPassportVerified} />
                    </p> : (
                    <DocumentUploadID docsTypeId={2} dzInputContainerName='dzInputContainerID' placeholder={formatMessage(messages.userUploadIDDoc)} />
                )}
            </div>}
        </section>

        <section className={s["verification-section"]}>
            <h2 onClick={() => setActiveSection(activeSection === 'payment' ? '' : 'payment')}
                className={s["accordion-header"]}>
                <FormattedMessage {...messages.driversLicenseTitle} />
                <span className={s["accordion-icon"]}>{activeSection === 'payment' ? '−' : '+'}</span>
            </h2>
            {activeSection === 'payment' &&
                <div className={s["accordion-content"]} style={{ position: 'relative' }}>
                    {verification.isLicenseVerified ? <p>
                            <FormattedMessage {...messages.driversLicenseVerified} />
                        </p> : (
                        <DocumentUploadID docsTypeId={3} dzInputContainerName='dzInputContainerID' placeholder={formatMessage(messages.userUploadDriverLicense)} />
                    )}
                </div>}
        </section>
    </div>
};

const mapState = (state) => ({
    account: state.account.data,
});

const mapDispatch = {
  resendEmailVerification
};

export default compose(
    injectIntl,
    connect(mapState, mapDispatch),
    withStyles(s),
)(VerificationFallback);