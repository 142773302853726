import React from 'react'
import PropTypes from 'prop-types';
import history from '../../core/history';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './SubnavBar.css';

// Locale
import messages from '../../locale/messages';

// Component
import Link from '../Link';
class MenuComponent extends React.Component {

  static propTypes = {
    account: PropTypes.shape({
      userId: PropTypes.string.isRequired,
      verification: PropTypes.shape({
        isOwner:PropTypes.number
      })
    }).isRequired
  };

  static defaultProps = {
    account: {
      userId:null,
      verification:{
        isOwner:0
      }
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      location: ''
    }
  }

  componentDidMount() {
    if (history.location) {
      this.setState({
        location: history.location.pathname
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (history.location) {
      this.setState({
        location: history.location.pathname
      });
    }
  }

  render() {
    const { location } = this.state;
    const { account } = this.props;
    const isOwnerUser = account?.verification?.isOwner || account?.userProfileData?.isAgreementStatus;
    return (
      <div className={cx(s.progressContainer, 'hidden-xs', 'hidden-print')}>
        <ul className={s.navList}>
          <li className={cx({ [s.active]: location === '/dashboard' })}>
            <Link to={'/dashboard'} className={cx(s.navItem, 'rtlNavItem')}>
              <FormattedMessage {...messages.dashboard} />
            </Link>
          </li>
          <li className={cx({ [s.active]: location === '/inbox' })}>
            <Link to={'/inbox'} className={cx(s.navItem, 'rtlNavItem')}>
              <FormattedMessage {...messages.inbox} />
            </Link>
          </li>
          {isOwnerUser == 1 &&
          <li className={cx({
            [s.active]: location === '/cars' || location === '/reservation/current'
              || location === '/reservation/previous' || location === '/reservation/contracts'
          })}>
            <Link to={'/cars'} className={cx(s.navItem, 'rtlNavItem')}>
              <FormattedMessage {...messages.userYourListing} />
            </Link>
          </li>
          }
          
          <li className={cx({ [s.active]: location === '/trips/current' || location === '/trips/previous' || location === '/trips/contracts' })}>
            <Link to={'/trips/current'} className={cx(s.navItem, 'rtlNavItem')}>
              <FormattedMessage {...messages.userYourReservation} />
            </Link>
          </li>
          <li className={cx({
            [s.active]: location === '/user/edit' || location === '/agreement/overview' || location === '/user/photo' || location.startsWith('/users/show/')
              || location === '/user/verification' || (location === '/user/reviews/about-you' || location === '/user/reviews/you') || location === '/users/show/:profileId?'
          })}>
            <Link to={'/user/edit'} className={cx(s.navItem, 'rtlNavItem')}>
              <FormattedMessage {...messages.profile} />
            </Link>
          </li>
          <li className={cx({
            [s.active]: location === '/user/payout' || location.startsWith('/user/transaction')
              || location === '/users/security' || location === '/user/addpayout'
          })}>
            <Link to={'/user/payout'} className={cx(s.navItem, 'rtlNavItem')}>
              <FormattedMessage {...messages.transactions} />
            </Link>
          </li>

          <li className={cx({ [s.active]: location === '/support' })}>
            <Link to={'#'} className={cx(s.navItem, 'rtlNavItem')}>
              <FormattedMessage {...messages.help} />
            </Link>
          </li>
        </ul>
      </div>
    )
  }
}
const mapState = (state) => ({
  account: state.account.data,
});

const mapDispatch = {
};

export default injectIntl(
  withStyles(s)
  (connect(mapState, mapDispatch)
  (MenuComponent)));