import { gql } from 'react-apollo';

import {
  UPDATE_RESERVATION_STATE_START,
  UPDATE_RESERVATION_STATE_SUCCESS,
  UPDATE_RESERVATION_STATE_ERROR
} from '../../constants';
import { sendEmail } from '../../core/email/sendEmail';
import { decode } from '../../helpers/queryEncryption';
import getAllReservationQuery from '../../components/ManageReservation/getAllReservationQuery.graphql';

import { getVehicleTitle,getVehicleName } from '../../helpers/admGetDataField';

export function updateReservation(reservationId, actionType, userType, threadId, currentPage, searchKey, listIdKey, startDate, endDate, orderBy, dateFilter) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: UPDATE_RESERVATION_STATE_START,
    });

    try {

      let mutation = gql`
          mutation updateReservation(
            $reservationId: Int!, 
            $actionType: String!,
            $threadId: Int
          ){
              updateReservation(
                reservationId: $reservationId,
                actionType: $actionType,
                threadId: $threadId
              ) {
                  status
              }
          }
      `;

      const { data } = await client.mutate({
        mutation,
        variables: {
          reservationId,
          actionType,
          threadId
        },
        refetchQueries: [
          {
            query: getAllReservationQuery,
            variables: {
              userType,
              searchKey,
              listId: listIdKey,
              startDate,
              endDate,
              currentPage,
              orderBy,
              dateFilter
            },
          }
        ]
      });

      if (data.updateReservation.status === '200') {
        let reservationQuery = gql`
          query getReservationData ($reservationId: Int!){
            getItinerary(reservationId: $reservationId){
              id
              confirmationCode
              checkIn
              listData {
                id
                title
                city
              }
              hostData {
                firstName
                userData {
                  email
                }
              }
              guestData {
                firstName
                userData {
                  email
                }
              }
              listExtDT{
                id
                vtype
                vmake
                vmodel
                plate
                engine
                chasis
                year
                color
              }
              messageData {
                id
              }
              reservationState
            }
          }
        `;
        const reservation = await client.query({
          query: reservationQuery,
          variables: {
            reservationId,
          },
          fetchPolicy: 'network-only'
        });
        let content;

        if (reservation.data.getItinerary) {
          let reservationState = reservation.data.getItinerary.reservationState;
          let confirmationCode = reservation.data.getItinerary.confirmationCode;
          let checkIn = reservation.data.getItinerary.checkIn;
          let hostName = reservation.data.getItinerary.hostData.firstName;
          let hostEmail = reservation.data.getItinerary.hostData.userData.email;
          let guestName = reservation.data.getItinerary.guestData.firstName;
          let guestEmail = decode(reservation.data.getItinerary.guestData.userData.email);
          // let listTitle = reservation.data.getItinerary.listData.title;
          let listTitle = reservation.data.getItinerary.listExtDT.length > 0 ? getVehicleName(reservation.data.getItinerary.listExtDT) : reservation.data.getItinerary.listData.title;

          let listCity = reservation.data.getItinerary.listData.city;
          let threadId = reservation.data.getItinerary.messageData.id;
          if (reservationState === 'approved') {
            content = {
              hostName,
              guestName,
              listTitle,
              listCity,
              threadId
            };
            sendEmail(guestEmail, 'bookingConfirmedToGuest', content);
          }
          if (reservationState === 'declined') {
            content = {
              hostName,
              guestName,
              checkIn,
              confirmationCode
            };
            sendEmail(guestEmail, 'bookingDeclinedToGuest', content);
          }

        }

        dispatch({
          type: UPDATE_RESERVATION_STATE_SUCCESS,
        });
      }

    } catch (error) {
      dispatch({
        type: UPDATE_RESERVATION_STATE_ERROR,
        payload: {
          error
        }
      });
      return false;
    }

    return true;
  };
}

export function setUpdateReservation(reservationId, actionType, userType, threadId, currentPage, searchKey, listIdKey, startDate, endDate, orderBy, dateFilter) {

  return async (dispatch, getState, { client }) => {

    try {
      console.log('setUpdateReservation');

      let mutation = gql`
          mutation updateReservation(
            $reservationId: Int!, 
            $actionType: String!,
            $threadId: Int
          ){
              updateReservation(
                reservationId: $reservationId,
                actionType: $actionType,
                threadId: $threadId
              ) {
                  status
              }
          }
      `;

      const { data } = await client.mutate({
        mutation,
        variables: {
          reservationId,
          actionType,
          threadId
        },
        // refetchQueries: [
        //   {
        //     query: getAllReservationQuery,
        //     variables: {
        //       userType,
        //       searchKey,
        //       listId: listIdKey,
        //       startDate,
        //       endDate,
        //       currentPage,
        //       orderBy,
        //       dateFilter
        //     },
        //   }
        // ]
      });

      
      if (data.updateReservation.status === '200') {
        
      
        return 'sucess';
      }

    } catch (error) {
      
      return false;
    }

    return true;
  };
}
export function sendMessageBook(
  threadId,
  threadType,
  content,
  type,
  startDate,
  endDate,
  personCapacity,
  reservationId,
  receiverName,
  senderName,
  receiverType,
  receiverEmail,
  startTime,
  endTime,
  currentPage,
  searchKey,
  listIdKey,
  startDateFilter,
  endDateFilter,
  orderBy,
  dateFilter
){

  return async (dispatch, getState, { client }) => {
    try {
      let mutation = gql`
          mutation sendMessage(
          $threadId: Int!, 
          $content: String, 
          $type: String,
          $startDate: String,
          $endDate: String,
          $personCapacity: Int,
          $reservationId: Int,
          $startTime: Float,
          $endTime: Float,
          ) {
            sendMessage(
            threadId: $threadId, 
            content: $content, 
            type: $type,
            startDate: $startDate,
            endDate: $endDate,
            personCapacity: $personCapacity,
            reservationId: $reservationId,
            startTime: $startTime,
            endTime: $endTime
            ){
              id
              sentBy
              content
              type
              reservationId
              startDate
              endDate
              personCapacity
              createdAt
              status
            }
          }
      `;
      // Send Message
      const { data } = await client.mutate({
        mutation,
        variables: {
          threadId,
          content,
          type,
          startDate,
          endDate,
          personCapacity,
          reservationId,
          startTime,
          endTime
        },
        refetchQueries: [
          {
            query: ThreadItemsQuery,
            variables: {
              threadId,
              threadType
            },
          }
        ]
      });

      if (data?.sendMessage?.status == 'alreadyPerformed') {
        // showToaster({ messageId: 'alreadyPerformedAction', toasterType: 'error' })
        // dispatch({
        //   type: SEND_MESSAGE_ERROR,
        // });
      } else if (data && data.sendMessage && data.sendMessage.status != 'userbanned') {
        // if (reservationId != null && reservationId != undefined) {
        //   dispatch(updateReservation(reservationId, type, threadType, threadId, currentPage, searchKey, listIdKey, startDateFilter, endDateFilter, orderBy, dateFilter));
        // }
        // dispatch({
        //   type: SEND_MESSAGE_SUCCESS,
        // });
        
      }

    } catch (error) {
      return false;
    }
    return true;
  };
}
