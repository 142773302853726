import React from 'react';
import PropTypes from 'prop-types';
import { graphql, gql, compose } from 'react-apollo';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './MediaLibraryManagement.css';
import cp from '../../../components/commonStyle.css';


// Component
import MediaManagement from '../../../components/siteadmin/MediaLibrary/MediaManagement';
import Loader from '../../../components/Loader';

// Query
import getBlogPhotosQuery from './getBlogPhotos.graphql';
import NotFound from '../../notFound/NotFound';


class MediaLibraryManagements extends React.Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        currentPage: PropTypes.number.isRequired,        
        getBlogPhotosData: PropTypes.shape({
            loading: PropTypes.bool.isRequired,
            getBlogPhotos: PropTypes.object.isRequired
        }),
    };

    
    static defaultProps = {
        currentPage:1,
        getBlogPhotosData: {
            loading: true,
            count: null,
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            currentPage:1,
        }
        this.handlePage = this.handlePage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.paginationData = this.paginationData.bind(this);
    };
     
    handlePage(e) {
        this.setState({currentPage: parseFloat(e)});
    }
    paginationData = (setCurrentPage) => {
        const { getBlogPhotosData: { refetch } }=this.props;
        
        refetch({ currentPage:setCurrentPage });
        this.setState({currentPage:setCurrentPage});
        console.log('paginationData');
        // window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth'
        // });
    }
    handleChange(currentPage, size) {
        const { getBlogPhotosData: { refetch } }=this.props;
        refetch({ currentPage:currentPage });
        // window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth'
        // });
    }
    changeState = (variables) => this.setState(variables)

    render() {
        const {title, getBlogPhotosData: {loading, getBlogPhotos} } = this.props;
        if(!loading && !getBlogPhotos){
            return <NotFound />
        }
        if(loading){
            return (
                <>
                <Loader type={"text"} show={loading} />
                </>
            );
        }

        return (
        <>
            <MediaManagement 
                data={getBlogPhotos}
                title={title}
                setChange={this.paginationData}
            />
        </>
        );
    }
}


const mapState = (state) => ({
    initialValues: state.account.data,
});
export default compose(
    withStyles(s,cp),
    graphql(getBlogPhotosQuery, {
        name: 'getBlogPhotosData',
        options: (props) => ({
          variables: {
            currentPage: props.currentPage
          },
          fetchPolicy: 'network-only',
        })
      }),
)(MediaLibraryManagements);