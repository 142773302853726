import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';

import {
  Button,
  Form,
  FormGroup,
  FormControl,
  InputGroup
} from 'react-bootstrap';


import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Trust.css';
import cs from '../../components/commonStyle.css';

import history from '../../core/history';

// Component
import Item from './Item';
import PhoneVerificationModal from '../PhoneVerificationModal';
import CountryList from '../CountryList';

import submit from './submit';
import validate from './validate';

// Helper
import PopulateData from '../../helpers/populateData';
import Loader from '../../components/Loader';

// Redux
import { connect } from 'react-redux';
import { disconnectVerification, resendEmailVerification } from '../../actions/manageUserVerification';

// Images
import infoImage from '/public/SiteIcons/priceHoverIcon.svg';

// Locale
import messages from '../../locale/messages';

class MenuComponent extends React.Component {

  static propTypes = {
    data: PropTypes.shape({
      isEmailConfirmed: PropTypes.bool,
      isFacebookConnected: PropTypes.bool,
      isGoogleConnected: PropTypes.bool,
      isPhoneVerified: PropTypes.bool,
      isIdVerification: PropTypes.number,
      isLicenseVerified: PropTypes.number,
      isIdNumberVerification: PropTypes.number,
    }),
    disconnectVerification: PropTypes.any.isRequired,
    account: PropTypes.shape({
      userId: PropTypes.string.isRequired,
    }).isRequired,
    resendEmailLoading: PropTypes.bool,
    formatMessage: PropTypes.any,
  };

  static defaultProps = {
    data: {
      isEmailConfirmed: false,
      isFacebookConnected: false,
      isGoogleConnected: false,
      isPhoneVerified: false,
      isIdVerification: 0,
      isLicenseVerified: 0,
      isIdNumberVerification: 0,
    },
    resendEmailLoading: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      dateOfBirthData: {},
      countryCode: 'DO',
      country: '+1',
    }
    this.sendConfirmEmail = this.sendConfirmEmail.bind(this);
    this.facebookDisconnect = this.facebookDisconnect.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.googleDisconnect = this.googleDisconnect.bind(this);
    this.documentVerification = this.documentVerification.bind(this);
    this.documentVerificationID = this.documentVerificationID.bind(this);
    this.documentVerificationDriverLicense = this.documentVerificationDriverLicense.bind(this);
  }

  componentDidMount() {
    const { change, initialValues } = this.props;
    let loggedinEmail;
    if (initialValues && initialValues.email) {
      loggedinEmail = initialValues.email;
    }
    change('loggedinEmail', loggedinEmail);

    if (initialValues && initialValues.countryName && initialValues.countryCode) {
      this.setState({
        countryCode: initialValues.countryName,
        country: initialValues.countryCode
      });
    }

  }

  UNSAFE_componentWillReceiveProps() {
    const { change, initialValues } = this.props;
    const { country, countryCode } = this.state;
    let loggedinEmail;
    if (initialValues && initialValues.email) {
      loggedinEmail = initialValues.email;
    }

    change('loggedinEmail', loggedinEmail);

    if (countryCode && country) {
      change('countryCode', countryCode);
      change('dialCode', country);
    }
  }

  UNSAFE_componentWillMount() {
    // let now = new Date();
    // let currentYear = now.getFullYear();
    // let years = PopulateData.generateData(1900, currentYear, "desc");
    // let days = PopulateData.generateData(1, 31);
    // let months = PopulateData.generateData(0, 11);
    // this.setState({
    //   dateOfBirthData: {
    //     years: years,
    //     months: months,
    //     days: days
    //   }
    // });
  }
  handleCountryChange(e, selectedData) {
    this.setState({
      country: selectedData.dialCode,
      countryCode: selectedData.countryCode
    });

  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  sendConfirmEmail() {
    const { resendEmailVerification } = this.props;
    resendEmailVerification();
  }

  facebookDisconnect() {
    const { disconnectVerification, account } = this.props;
    disconnectVerification("facebook", account.userId);
  }

  googleDisconnect() {
    const { disconnectVerification, account } = this.props;
    disconnectVerification("google", account.userId);
  }

  documentVerification() {
    history.push('/document-verification/1/');
  }
  
  documentVerificationID() {
    history.push('/document-verification/2/');
  }
  
  documentVerificationDriverLicense() {
    history.push('/document-verification/3/');
  }


  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;

    return (
      <FormGroup className={cs.spaceBottom4}>
        <label className={cs.spaceBottom8}>{label}</label>
        <FormControl
          {...input}
          className={className}
          componentClass="textarea"
          rows={5}
          placeholder={label}
        >
          {children}
        </FormControl>
        {touched && error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    )
  }

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxLength, isDisabled, inputPaddingClass, infoText, showToolTip }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(cs.spaceBottom4, inputPaddingClass)}>
        <label className={cs.spaceBottom8}>{label}
          {showToolTip &&
            <div className={cx(s.specialPriceIcon, 'specialPriceIconRTL')}>
              <span className={'svgImg'}>
                <img src={infoImage} className={cx(s.faqImage, 'specialpriceRtl')} />
              </span>
              <div className={cx(s.toolTip, s.toolTipRelativeSection, 'toolTipDarkMode', 'toolTipRelativeSectionRTL')}>
                {infoText}
              </div>
            </div>
          }
        </label>
        <FormControl {...input} placeholder={label} type={type} className={className} disabled={isDisabled} maxLength={maxLength} />
        {touched && error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    )
  }

  renderFormControlEmail = ({ input, label, type, meta: { touched, error }, className, disabled, inputPaddingClass }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(cs.spaceBottom4, inputPaddingClass)}>
        <label className={cs.spaceBottom8}>{label}</label>
        <FormControl {...input} placeholder={label} type={type} className={className} disabled={disabled} />
        {touched && error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    )
  }

  renderFormControlSelect = ({ input, meta: { touched, error }, children, className, inputPaddingClass }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(cs.spaceBottom4, inputPaddingClass)}>
        <FormControl componentClass="select" {...input} className={cx(className, 'selectBgImage')} >
          {children}
        </FormControl>
        {touched && error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    )
  }
  renderCountryList({ input, label, meta: { touched, error }, children, className, disabled }) {
    const { formatMessage } = this.props.intl;
    return (
        <FormGroup className={cs.spaceBottom4}>
            <label>{label}</label>
            <CountryList input={input} className={className} disabled={disabled} />
            {touched && error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
        </FormGroup>
    );
  }

  renderFormControlCurrency = ({ input, label, type, meta: { touched, error }, className, country, maxLength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <InputGroup>
          <InputGroup.Addon className={s.addonStyle}>
            {country}
          </InputGroup.Addon>
          <FormControl {...input} placeholder={label} type={type} className={className} maxLength={maxLength} />
        </InputGroup>
        {touched && error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
      </div>
    )
  }
  render() {
    const { data: { isEmailConfirmed, isFacebookConnected, isGoogleConnected, isIdVerification,isLicenseVerified, isIdNumberVerification,isPhoneVerified }, resendEmailLoading } = this.props;
    // const { siteSettingStatus } = this.props;
    const { formatMessage } = this.props.intl;
    const { country, countryCode,siteSettingStatus } = this.state;
    // let displayVerifiedPanel = isEmailConfirmed || isFacebookConnected || isGoogleConnected || isIdVerification || false;
    // let displayUnVerifiedPanel = !isEmailConfirmed || !isFacebookConnected || !isGoogleConnected || !isIdVerification || false;
    // let allUnverified = !isEmailConfirmed && !isFacebookConnected && !isGoogleConnected && !isIdVerification
    let displayVerifiedPanel = isEmailConfirmed || isGoogleConnected || isIdVerification == 1 || false;
    let displayUnVerifiedPanel = !isEmailConfirmed || !isGoogleConnected || isIdVerification != 1 || isLicenseVerified !=1 || isIdNumberVerification !=1 || isPhoneVerified !=1 || false;
    let allUnverified = !isEmailConfirmed && !isGoogleConnected && isIdVerification != 1 && isPhoneVerified !=1 && isLicenseVerified !=1 && isIdNumberVerification != 1;

    // let isPhoneStatus = isPhoneVerified == 0 ? false : true;

    const { error, handleSubmit, submitting, dispatch, loadAccount, base, availableCurrencies, initialValues,values } = this.props;
    let isPhoneStatus = siteSettingStatus && siteSettingStatus.phoneNumberStatus == 3 ? false : true;

    return (
      <div className={cx(cs.commonBorderSection, 'whiteBgColor')}>
        {
          displayVerifiedPanel && <>
            <h4 className={cx(cs.commonTotalText, cs.fontWeightBold, cs.paddingBottom4)}>{formatMessage(messages.verifiedInfo)}</h4>
            <div className={cx(s.cardSecBg)}>
              {
                isEmailConfirmed  && <Item
                  title={formatMessage(messages.emailLabel)}
                  content={formatMessage(messages.verifiedEmail)}
                  isAction={false}
                  isImage={true}
                  name='email'
                />
              }
              {/* {
                isFacebookConnected && <Item
                  title={formatMessage(messages.facebook)}
                  content={formatMessage(messages.facebookInfo)}
                  isAction
                  isLink={false}
                  buttonLabel={formatMessage(messages.disconnect)}
                  handleClick={this.facebookDisconnect}
                  name='facebook'
                />
              } */}
              {
                isGoogleConnected && <Item
                  title={formatMessage(messages.google)}
                  content={formatMessage(messages.connectedGoogleInfo)}
                  isAction
                  isLink={false}
                  buttonLabel={formatMessage(messages.disconnect)}
                  handleClick={this.googleDisconnect}
                  name='google'
                />
              }
              {/* {
                isIdVerification == 1 && <Item
                  title={formatMessage(messages.documents)}
                  content={formatMessage(messages.documentverificaitonDetails)}
                  isImage={true}
                  name='document'
                />
              } */}
              {
                isIdNumberVerification == 1 && <Item
                  title={formatMessage(messages.userIDPassport)}
                  content={formatMessage(messages.documentverificaitonDetails)}
                  isImage={true}
                  name='idnumber'
                />
              }
              {
                isLicenseVerified == 1 && <Item
                  title={formatMessage(messages.userDriverLicense)}
                  content={formatMessage(messages.driverLicenseverificaitonDetails)}
                  isImage={true}
                  name='driverlicense'
                />
              }
            </div>
          </>
        }

        {
          displayUnVerifiedPanel && <>
            <h4 className={cx(cs.commonTotalText, cs.fontWeightBold, { [cs.paddingBottom2]: allUnverified }, { [cs.paddingBottom4]: displayVerifiedPanel })}>{formatMessage(messages.trustAndVerification)}</h4>
            {/* {
              !isEmailConfirmed && !isFacebookConnected && !isGoogleConnected && !isIdVerification &&
              <p className={cx(cs.paddingBottom4, s.marginBottmNone)}><FormattedMessage {...messages.notVerifiedDetails} /></p>
            } */}
            {
              !isEmailConfirmed && !isGoogleConnected && isIdVerification != 1 &&
              <p className={cx(cs.paddingBottom4, s.marginBottmNone)}><FormattedMessage {...messages.notVerifiedDetails} /></p>
            }
            <div className={cx(s.cardSecBg)}>
              {
                (!isEmailConfirmed && !isGoogleConnected) && <Item
                  title={formatMessage(messages.emailLabel)}
                  content={formatMessage(messages.pleaseVerify)}
                  isAction
                  isLink={false}
                  buttonLabel={formatMessage(messages.verify)}
                  handleClick={this.sendConfirmEmail}
                  show={resendEmailLoading}
                  name='email'
                />
              }
              
              {
                !isGoogleConnected && <Item
                  title={formatMessage(messages.google)}
                  content={formatMessage(messages.googleInfo)}
                  isAction
                  isLink
                  buttonLabel={formatMessage(messages.connect)}
                  url={"/login/google"}
                  name='google'
                />
              }
              {/* {
                isIdVerification != 1 && <Item
                  title={formatMessage(messages.documents)}
                  content={formatMessage(messages.documentVerificaitonInfo)}
                  isAction
                  buttonLabel={formatMessage(messages.verify)}
                  handleClick={this.documentVerification}
                  name='document'
                />
              } */}
              {
                isIdNumberVerification != 1 && <Item
                  title={formatMessage(messages.userIDPassport)}
                  content={formatMessage(messages.documentVerificaitonInfo)}
                  isAction
                  buttonLabel={formatMessage(messages.verify)}
                  handleClick={this.documentVerificationID}
                  name='idnumber'
                />
              }
              {
                isLicenseVerified != 1 && <Item
                  title={formatMessage(messages.userDriverLicense)}
                  content={formatMessage(messages.documentVerificaitonInfo)}
                  isAction
                  buttonLabel={formatMessage(messages.verify)}
                  handleClick={this.documentVerificationDriverLicense}
                  name='driverlicense'
                />
              }
              <Loader type={"pagefixed"} show={submitting} />
              
              <div className={cx('inputFocusColor',s.phoneSectionPadding, 'whiteBgColor')}>
                {error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
                <Form onSubmit={handleSubmit(submit)}>
                  <label className={cs.spaceBottom8}>{formatMessage(messages.phoneNumber)}
                    <div className={cx(s.specialPriceIcon, 'specialPriceIconRTL')}>
                      <span className={'svgImg'}>
                        <img src={infoImage} className={cx(s.faqImage, 'specialpriceRtl')} />
                      </span>
                      <div className={cx(s.toolTip, s.toolTipRelativeSection, 'toolTipDarkMode', 'toolTipRelativeSectionRTL')}>
                        {formatMessage(messages.idNumberInfo)}
                      </div>
                    </div>
                  </label>
                 
                  {!isPhoneStatus &&
                    <div className={cx(cs.commonBorderSection, cs.spaceBottom2, 'whiteBgColor', 'addPhoneNumber', 'addPhoneNumberRTL')}>
                      <FormGroup className={cs.spaceBottom4}>
                        <label className={cs.spaceBottom8}><FormattedMessage {...messages.chooseACountry} /></label>
                        <CountryList
                          input={
                            {
                              name: 'countryCode',
                              onChange: this.handleChange,
                              value: countryCode,
                            }
                          }
                          className={cs.formControlSelect}
                          dialCode={false}
                          getSelected={this.handleCountryChange}
                          formName={'EditProfileForm'}
                        />
                      </FormGroup>
                      <div className={cs.spaceBottom4}>
                        <FormGroup>
                          <label className={cs.spaceBottom8}><FormattedMessage {...messages.addAPhoneNumber} /></label>
                          <Field
                            name="phoneNumber"
                            type="text"
                            component={this.renderFormControlCurrency}
                            maxLength={255}
                            label={formatMessage(messages.enterPhoneNumber)}
                            className={cx(cs.formControlInput, 'commonInputPaddingRTL')}
                            onChange={this.handleChange}
                            country={country}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  }
                  {
                    isPhoneStatus && <PhoneVerificationModal />
                  }
                  <p className={cx(cs.commonMediumText, s.paddingBottom4)}>{formatMessage(messages.phoneNumberInfo)}</p>
                  
                  <div className={cs.spaceBottom4}>
                    <FormGroup>
                      <label className={cs.spaceBottom8}><FormattedMessage {...messages.addAPhoneNumber} /></label>
                      <Field
                        name="phoneNumber"
                        type="text"
                        component={this.renderFormControlCurrency}
                        maxLength={255}
                        label={formatMessage(messages.enterPhoneNumber)}
                        className={cx(cs.formControlInput, 'commonInputPaddingRTL')}
                        onChange={this.handleChange}
                        country={country}
                      />
                    </FormGroup>
                  </div>
              
                  <div className={cx(cs.textAlignRight, 'textAlignLeftRTL')}>
                    <Button className={cx(cs.btnPrimary, cs.btnMedium)} type="submit" >
                      {formatMessage(messages.save)}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </>
        }
      </div>
    );
  }
}

MenuComponent = reduxForm({
  form: 'MenuComponent', // a unique name for this form
  validate,
})(MenuComponent);
const selector = formValueSelector('MenuComponent');

const mapState = (state) => ({
  resendEmailLoading: state.loader.resendEmailLoading,
  account: state.account.data,
  initialValues: state.account.data,
  availableCurrencies: state.currency.availableCurrencies,
  base: state.currency.base,
  siteSettingStatus: state.siteSettings.data,
  phoneNumber: selector(state, 'phoneNumber')
});

const mapDispatch = {
  disconnectVerification,
  resendEmailVerification,
  change
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(MenuComponent)));