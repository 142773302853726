import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import Pagination from 'rc-pagination';
import moment from 'moment';
import submit from './submit';
import validate from './validate';
import {
    Button,
    Row,
    FormGroup,
    Col,
    ControlLabel, FormControl
  } from 'react-bootstrap';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './MediaManagement.css';
import cp from '../../../components/commonStyle.css';

// import { deleteBlogDetails, updateBlogStatus } from '../../../actions/siteadmin/deleteBlogDetails';

// icons
import { FaList,FaThLarge } from 'react-icons/fa';

// Translation
import messages from '../../../locale/messages';

// Components
import ItemsMedia from './ItemsMedia';

class MediaManagement extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
   
    setChange: PropTypes.any,
    data: PropTypes.shape({
      blogPhotosData: PropTypes.array,
      currentPage: PropTypes.number,
      count: PropTypes.number,
    }),
    deleteMedia: PropTypes.any,

  };


  constructor(props) {
    super(props);
    this.state = {
  }
    this.handleChange = this.handleChange.bind(this);
}

  handleChange(currentPage, size) {
    const { setChange } = this.props;
    setChange(currentPage);
  }

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxLength, isDisabled, inputPaddingClass, infoText, showToolTip }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(cp.notMarginBottom,inputPaddingClass)}>
        <label className={cx(cp.spaceBottom8,cp.labelInlineSm)}>{label}
          {/* {showToolTip &&
            <div className={cx(s.specialPriceIcon, 'specialPriceIconRTL')}>
              <span className={'svgImg'}>
                <img src={infoImage} className={cx(s.faqImage, 'specialpriceRtl')} />
              </span>
              <div className={cx(s.toolTip, s.toolTipRelativeSection, 'toolTipDarkMode', 'toolTipRelativeSectionRTL')}>
                {infoText}
              </div>
            </div>
          } */}
        </label>
        <FormControl {...input} placeholder={label} type={type} className={className} disabled={isDisabled} maxLength={maxLength} />
        {touched && error && <span className={cp.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    )
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { error,handleSubmit } = this.props;
    const { title } = this.props;
    const { data:{blogPhotosData,currentPage,count} } = this.props;
    
    // console.log('blogPhotosData', blogPhotosData);
    // console.log('currentPage', currentPage);
    return (
      <div>
        <div className={cx(s.pagecontentWrapper, 'pagecontentWrapperRTL')}>
          <h1 className={cx(cp.titleMarginPage,cp.commonTotalText, cp.spaceBottom5, cp.fontWeightBold)}>
            {title}
            <a target={'_blank'} href={'/siteadmin/media-upload/'} className={cx(s.titleAddMediaLibrary)}><FormattedMessage {...messages.AddMediaLibrary}/></a>
          </h1>
          <Row className={cx(cp.rowSMMarginLR)}>
              <div className={cx(cp.mediaLibraryHeaderFilter)}>
                  <form onSubmit={handleSubmit(submit)}>
                      {error && <strong>{formatMessage(error)}</strong>}
                      <Col lg={6} md={6} sm={12} xs={12}>
                          <FaList className={cx(s.iconFilter)}/> 
                          <FaThLarge className={cx(s.iconFilter)}/>
                          
                          <select 
                          className={cx(cp.selectFIlterMediaLibrary,s.formSelect,s.marginLeft3,s.fontSize13,s.heightAuto,'changePadding')} >
                              <option value="0">{formatMessage(messages.fileAll)}</option>
                              <option value="1">{formatMessage(messages.filePicture)}</option>
                              <option value="2">{formatMessage(messages.fileVideo)}</option>
                              <option value="3">{formatMessage(messages.fileDocs)}</option>
                              <option value="4">{formatMessage(messages.fileSpreadSheet)}</option>
                              <option value="5">{formatMessage(messages.filePDF)}</option>
                          </select>
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12}>
                          <Field 
                              name="search"
                              type="text"
                              component={this.renderFormControl}
                              label={formatMessage(messages.search)}
                              className={cx(cp.inputLabelInline,cp.formControlInput, 'commonInputPaddingRTL')}
                              inputPaddingClass={cx(s.paddingRight, 'RTLPaymentFirstNamePadding')}
                          />
                      </Col>
                  </form>
              </div>
          </Row>
          <Row className={cx(cp.notMarginRYL)}>
            <div className={cx(s.containerPhotoLibrary)}>
             <ItemsMedia
                itemsData={blogPhotosData}
             />
            </div>
          </Row>
          <div className={cx(s.paginationPhoto)}>
            <Pagination
                
                className="ant-pagination"
                defaultCurrent={currentPage}
                current={currentPage}
                total={count}
                // defaultPageSize={5}
                pageSize={20}
                onChange={this.handleChange}
                // showTotal={(total, range) => this.renderShowTotal(total, range)}
                // locale={locale}
                
            />
          </div>
        </div>
      </div>
      
    );
  }

}

MediaManagement = reduxForm({form: 'MediaManagement', 
    validate,
})(MediaManagement);
  
const selector = formValueSelector('MediaManagement');

const mapState = (state) => ({});

const mapDispatch = {
  // deleteMedia,
  // updateBlogStatus
};

export default injectIntl(withStyles(s, cp)(connect(mapState, mapDispatch)(MediaManagement)));