import {
    IMPORT_CSV_FILE_ERROR,
    IMPORT_CSV_FILE_START,
    IMPORT_CSV_FILE_SUCCESS
} from "../../../constants";

import { getAdminListingSettings } from '../getAdminListingSettings';
import { getCarType } from "../getCarType";
import makeModelCsvUploaderQuery from './makeModelCsvUploader.graphql';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { setLoaderStart, setLoaderComplete } from "../../../actions/loader/loader";

export const makeModelCsvUploader = ({ categoryList, typeId }) => {
    return async (dispatch, getState, { client }) => {
        try {

            let bothDuplicateValues = [], flag = false, isDuplicate = false;
            dispatch({ type: IMPORT_CSV_FILE_START });


            if (categoryList && categoryList?.length > 3000) {
                showToaster({ messageId: 'limitExceed', toasterType: 'error' });
                return;
            };

            /* Check imported CSV has data. */
            if (categoryList && categoryList.length == 1 && categoryList[0].includes('')) {
                showToaster({ messageId: 'noDataFound', toasterType: 'error' })
                return;
            };

            /* Check first Make value  */
            if ((categoryList && categoryList.length && categoryList[0][0] === '')) {
                showToaster({ messageId: 'checkMakeDetails', toasterType: 'error' })
            };

            dispatch(setLoaderStart('makeModelCsvUploading'));

            /* Collect duplicate data. */
            categoryList?.map((val, index) => {
                let bothDuplicateResult = categoryList.find((item, idx) => index != idx && item.toString() === val.toString());
                if (bothDuplicateResult) {
                    bothDuplicateValues.push(bothDuplicateResult);
                    flag = true;
                }
            });

            /* if Duplicate data is found in importes CSV. */
            if (flag && bothDuplicateValues && bothDuplicateValues.length > 0) {
                bothDuplicateValues = bothDuplicateValues.map(item => item.toString());
                bothDuplicateValues = [...new Set(bothDuplicateValues)];
                isDuplicate = true;
                showToaster({ messageId: 'importDuplicateItems', toasterType: 'error' })
            };

            const { data } = await client.mutate({
                mutation: makeModelCsvUploaderQuery,
                variables: { csvValue: categoryList.filter(item => item.some(val => (val && val.trim() != "") || (val != ""))) }
            });

            if (data?.makeModelCsvUploader) {

                if (data?.makeModelCsvUploader?.status === 400) {
                    showToaster({ messageId: 'commonError', toasterType: 'error', requestMessage: data.makeModelCsvUploader.errorMessage })
                    dispatch({ type: VEHICLE_CATEGORY_CSV_UPLOAD_ERROR });
                    return;
                };

                /* Success status. */
                if (data?.makeModelCsvUploader?.result?.make || data?.makeModelCsvUploader?.result?.model) {
                    showToaster({ messageId: 'importSuccess', toasterType: 'success' });
                }

                /* Duplicate Data. */
                if (data?.makeModelCsvUploader?.result?.duplicateDatas.length > 0 && !isDuplicate) {
                    showToaster({ messageId: 'duplicateFiles', toasterType: 'error' });
                };

                await dispatch(getAdminListingSettings(typeId));
                await dispatch(getCarType());
                dispatch({ type: IMPORT_CSV_FILE_SUCCESS });
                dispatch(setLoaderComplete('makeModelCsvUploading'));
            }

        } catch (error) {
            showToaster({ messageId: 'importCsvFileError', toasterType: 'error' })
            dispatch({ type: IMPORT_CSV_FILE_ERROR })
            dispatch(setLoaderComplete('makeModelCsvUploading'));

        }
    }
}