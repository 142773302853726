import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
// import { graphql, gql, compose } from 'react-apollo';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { TabContainer,TabContent,TabPane,Nav, NavItem, Grid,Row,Col} from 'react-bootstrap';
// import Grid from 'react-bootstrap/lib/Grid';
// import Row from 'react-bootstrap/lib/Row';
// import Col from 'react-bootstrap/lib/Col';

// Components
// import ListNotFound from '../../routes/listNotFound/ListNotFound';
// import NotFound from '../../routes/notFound/NotFound';
import CarTakePhoto from '../CarTakePhoto/CarTakePhoto';
import CarTakePhotoTab from '../CarTakePhotoTab/CarTakePhotoTab';

//Query
// import getListingLocationQuery from './getListingLocationQuery.graphql';

// Locale
import messages from '../../locale/messages';

//Images
// import Arrow from '/public/siteImages/rightSideArrow.svg';
// import arrowIcon from '/public/SiteIcons/paymentArrow.svg';
// import steeringIcon from '/public/SiteIcons/steeringIcon.svg';
// import starIcon from '/public/SiteIcons/star.svg';
// import carFrontSide from '/public/carfrontside.png';
// import Faq from '../Receipt/question.svg';


import s from './ItineraryVehicleStatusPhoto.css';
import cs from '../../components/commonStyle.css';


class ItineraryVehicleStatusPhoto extends React.Component {
  static propTypes = {
    formatMessage: PropTypes.any,
    vehiclePhotoType: PropTypes.number.isRequired,
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      listId: PropTypes.number.isRequired,
      checkIn: PropTypes.string.isRequired,
      checkOut: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
      guestServiceFee: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      idLocation: PropTypes.number.isRequired,
      newDelivery: PropTypes.number.isRequired,
      confirmationCode: PropTypes.number.isRequired,
      reservationState: PropTypes.string.isRequired,
      photoReservationCar:PropTypes.array
    }),
  };

  static defaultProps = {
    data: null,
  };
  constructor(props) {
    super(props);
    this.onHandleTabs = this.onHandleTabs.bind(this);
  }
  onHandleTabs(e){
    console.log('e',e);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  render() {
    const { data } = this.props;
    const { formatMessage } = this.props.intl;
    const { userId } = this.props;
    
    
    if (data === null) {
      return <> <FormattedMessage {...messages.errorMessage} /> </>;
    } else {
      const { data, data: { id,listId,confirmationCode, reservationState,photoReservationCar } } = this.props;
      const { vehiclePhotoType }= this.props;
      let listPhotoCarTitleInterior = 
      [
      {title:'Dashboard levels',type:'dashboard_levels',subtitle:'1-2 Photos',require_photo:2},
      {title:'Center Console',type:'center_console',subtitle:'1-2 Photos',require_photo:2},
      {title:'All Seats',type:'all_seats',subtitle:'2-4 Photos',require_photo:3},
      {title:'All carpets',type:'all_carpets',subtitle:'2-4 Photos',require_photo:4},
      {title:'All door panels',type:'all_doors_panels',subtitle:'2-4 Photos',require_photo:2}
      ];

      let listPhotoCarTitle = 
      [
        {title:'Front windshield',type:'front_windshield',subtitle:'Stand 1 step away',require_photo:2},
        {title:'Front bumper',type:'front_bumper',subtitle:'Stand 3 steps away',require_photo:4},
        {title:'Passenger\'s front quarter',type:'passenger_front_quater',subtitle:'Stand 3 steps away',require_photo:2},
        {title:'Driver\'s front quarter',type:'driver_front_quarter',subtitle:'Stand 3 steps away',require_photo:2},
      
        {title:'Driver\'s front wheel',type:'driver_front_wheel',subtitle:'Stand 1 step away',require_photo:4},
        {title:'Driver\'s front door',type:'driver_front_door',subtitle:'Stand 1 step away',require_photo:4},
        {title:'Driver\'s rear door',type:'driver_rear_door',subtitle:'Stand 1 step away',require_photo:2},
        {title:'Driver\'s rear wheel',type:'driver_rear_wheel',subtitle:'Stand 1 step away',require_photo:3},

        {title:'Rear bumper',type:'rear_bumper',subtitle:'Stand 3 steps away',require_photo:4},
        {title:'Rear windshield',type:'rear_windshield',subtitle:'Stand 1 steps away',require_photo:2},
        {title:'Passenger\'s rear quarter',type:'passenger_rear_quater',subtitle:'Stand 3 steps away',require_photo:2},
        {title:'Driver\'s rear quarter',type:'driver_rear_quarter',subtitle:'Stand 3 steps away',require_photo:4},
      
      
      
      
      {title:'Passenger\'s rear wheel',type:'passenger_rear_wheel',subtitle:'Stand 1 step away',require_photo:3},
      {title:'Passenger\'s rear door',type:'passenger_rear_door',subtitle:'Stand 1 step away',require_photo:3},
      {title:'Passenger\'s front door',type:'passenger_front_door',subtitle:'Stand 1 step away',require_photo:3},
      {title:'Passenger\'s front wheel',type:'passenger_front_wheel',subtitle:'Stand 1 step away',require_photo:3},
      
      ];
      // console.log('listPhotoCarTitle',listPhotoCarTitle);
      return (
        <Grid fluid className={s.container}>
          <Row className={cs.positionRelative}>
            <Col lg={12} sm={12} md={12} xs={12} className={cx(cs.spaceTop5, 'paymentDetailsPadding')}>
              {
                reservationState === "approved" && <h2 className={cx(cs.commonTitleText, cs.paddingBottom1, cs.fontWeightBold)}>
                  <FormattedMessage {...messages.vehiclePhotoStatus} />
                </h2>
              }
              {vehiclePhotoType ==1 && <h3 className={cx(cs.textAlignLeft,cs.commonTotalText,cs.paddingBottom1, cs.fontWeightBold)}>
                  <FormattedMessage {...messages.vehicleReceive} />
              </h3>
              }
              {vehiclePhotoType ==2 && <h3 className={cx(cs.textAlignLeft,cs.commonTotalText, cs.paddingBottom1, cs.fontWeightBold)}>
                  <FormattedMessage {...messages.vehicleReturn} />
              </h3>
              }
              <h4 className={cx(cs.commonContentText, cs.fontWeightNormal)}>
                <FormattedMessage {...messages.confirmationCode} />{' '}
                <span>{'#'}{confirmationCode}</span>
              </h4>
              <hr className={cx(cs.listingHorizoltalLine, cs.spaceBottom4, cs.spaceTop4)} />
            </Col>
            {/* <Col lg={5} sm={12} md={5} xs={12} className={cx(cs.spaceTop5, 'paymentDetailsPadding')}>
              {vehiclePhotoType ==1 && <h3 className={cx(cs.textAlignRight,cs.commonTotalText,cs.paddingBottom1, cs.fontWeightBold)}>
                  <FormattedMessage {...messages.vehicleReceive} />
              </h3>
              }
              {vehiclePhotoType ==2 && <h3 className={cx(cs.textAlignRight,cs.commonTotalText, cs.paddingBottom1, cs.fontWeightBold)}>
                  <FormattedMessage {...messages.vehicleReturn} />
              </h3>
              }
            </Col> */}
          </Row>
          
           <Row>
            <Col lg={12} md={12} sm={12} xs={12} className={cx(cs.spaceTop2)}>
             
              <div className={s.boxSection}>
                <h4 className={cx(cs.commonTotalText, cs.fontWeightBold, cs.paddingBottom3)}>
                  <FormattedMessage {...messages.exteriorCarAreas} />
                </h4>
                <Row>
                  <Col lg={12}>
                    <TabContainer id="left-tabs-example" defaultActiveKey="front_bumper" onSelect={(k) => this.onHandleTabs(k)} >
                      <Row className="clearfix">
                          <Col sm={3} className={cx('tabsTakePhotos')}>
                            <Nav className={cx(s.navCarTakePhotoTabs)} bsStyle="pills" stacked>
                              {
                                listPhotoCarTitle.map((item,key) => {
                                  return (
                                    <NavItem 
                                    className={cx(s.navCarTakePhotoTabItem)} 
                                    key={key+'_nav_'+item.type} eventKey={item.type}>
                                      <span>{key+1}{'.'}{item.title}</span>
                                      <span className={cx(s.carTakePhotoTabSubtitle)}>{item.subtitle}</span>
                                      {/* <div className={cx(s.TabrightArrow)}></div> */}
                                    </NavItem>
                                  )
                                })
                              }
                            </Nav>
                          </Col>
                          <Col sm={9} className={cx(cs.noPadding)}>
                            <TabContent animation>
                            {
                                listPhotoCarTitle.map((item,key) => {
                                  return (
                                    <TabPane key={key+'_panel_'+item.type} eventKey={item.type}>
                                      <CarTakePhotoTab 
                                          key={key+'_carPhotoCP'} 
                                          vehiclePhotoType={vehiclePhotoType} 
                                          idNumber={key+1} 
                                          reservationId={id} 
                                          listId={listId} 
                                          fileType={item.type} 
                                          photoList={photoReservationCar} 
                                          title={item.title} 
                                          subtitle={item.subtitle} 
                                          requirePhotos={item.require_photo} 
                                          />
                                      </TabPane>
                                  )
                                })
                              }
                            </TabContent>
                          </Col>
                        </Row>
                    </TabContainer>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12} className={cx(cs.spaceTop5)}>
              <div className={s.boxSection}>
                <h4 className={cx(cs.commonTotalText, cs.fontWeightBold, cs.paddingBottom3)}>
                  <FormattedMessage {...messages.takePhotoInterior} />
                </h4>
                <Row>
                  <Col lg={12}>
                    <div className={cx(s.itemTakePhotoContainerUI)}>
                      {/*
                        listPhotoCarTitleInterior.map((item,key) => {
                          return (
                              <CarTakePhoto key={key+'_interior'}  vehiclePhotoType={vehiclePhotoType} idNumber={key+1} reservationId={id} listId={listId} fileType={item.type} photoList={photoReservationCar} title={item.title} subtitle={item.subtitle} />
                          )
                      })
                      */
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Grid>
      );
    }
  }
}

const mapState = (state) => ({
  userId: state.account.data.userId,
});

const mapDispatch = {};

// export default compose(
//   injectIntl,
//   withStyles(s, cs),
//   connect(mapState, mapDispatch),
//     // graphql(getListingLocationQuery,
//     //     {
//     //       name: 'getListingLocationData',
//     //       options: (props) => ({
//     //         variables: {
//     //           id: props.idLocation,
//     //         },
//     //         fetchPolicy: 'network-only',
//     //         ssr: true
//     //       })
//     //     }
//     //   ),
//   )(ItineraryVehicleStatusPhoto);

  export default injectIntl(withStyles(s, cs)(connect(mapState, mapDispatch)(ItineraryVehicleStatusPhoto)));