import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import cx from 'classnames';
import DocumentManagement from './DocumentManagementQuery.graphql';
import showAllDocumentVerification from './showAllDocumentVerificationQueryFile.graphql';
import CommonTable from '../../CommonTable/CommonTable';
import FileSingle from './FileSingle';
import CustomPagination from '../../CustomPagination/CustomPagination';
import { sendEmail } from '../../../core/email/sendEmail';
import messages from '../../../locale/messages';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './DocumentVerification.css';

class DocumentVerification extends React.Component {

  static propTypes = {
    data: PropTypes.array,
    title: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0,
    }
  }

  async handleUpdate(id,idUser, status,setStatus,docTypeVerification, item) {
    const { mutate, currentPage } = this.props;
    const { data } = await mutate({
      variables: {
        id: id,
        userId: idUser,
        isIdVerification: status,
        documentStatus: setStatus,
        verificationType:docTypeVerification
      },
      refetchQueries: [{ query: showAllDocumentVerification, variables: { currentPage } }]
    });
    
    if (data?.DocumentManagement?.status === 'success') {
      let msg = 'Documents have been ';
      msg += (status == 'approved') ? 'Approved.' : 'Rejected.';
      let content = {
        name: item.userProfile.at(0).firstName,
        verificationStatus: (status == 'approved') ? 'approved' : 'rejected'
      }
      sendEmail(item.userProfile.at(0).email, 'documentVerification', content);
      showToaster({ messageId: 'commonSuccess', toasterType: 'success', requestMessage: msg })
    } else {
      showToaster({ messageId: 'documentManagementError', toasterType: 'error', requestMessage: data.DocumentManagement && data.DocumentManagement.errorMessage })
    }
  }

  paginationData = (currentPage) => {
    const { showAllDocumentVerification: { refetch }, setStateVariable } = this.props;
    let variables = { currentPage };
    setStateVariable(variables);
    refetch(variables);
  }
  getTypeVerification =(idVerification)=>{
    const { formatMessage } = this.props.intl;
    if(idVerification ==1){
      return formatMessage(messages.verificationTypeDoc);
    }else if(idVerification ==2){
      return formatMessage(messages.verificationTypeIDPassport);
    }else if(idVerification ==3){
      return formatMessage(messages.verificationTypeDriverLicense);
    }
  }
  handleClick = (searchList) => {
    const { showAllDocumentVerification: { refetch }, setStateVariable } = this.props;
    let variables = {
      currentPage: 1,
      searchList: searchList
    };
    this.setState(variables);
    setStateVariable(variables)
    refetch(variables);
  }

  handleSearchChange = (e) => {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      searchList: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleClick(self.state.searchList);
      }, 450)
    });
  }

  thead = () => {
    const { formatMessage } = this.props.intl;
    return [
      { data: formatMessage(messages.sNotoId) },
      { data: formatMessage(messages.userNameLabel) },
      { data: formatMessage(messages.userEmailLabel) },
      { data: formatMessage(messages.verification) },
      { data: formatMessage(messages.requestedFiles) },
      { data: formatMessage(messages.actionLabel) }
    ]
  };

  tbody = () => {
    const { showAllDocumentVerification: { showAllDocumentVerification }, currentPage } = this.props;
    const { formatMessage } = this.props.intl;
    if (showAllDocumentVerification && showAllDocumentVerification.results && showAllDocumentVerification.results.length > 0) return showAllDocumentVerification.results?.map((value, key) => {
        return {
          id: value?.id,
          setClass: 'row_stat_'+value.documentStatus,
          data: [
            { data: value?.userProfile.at(0).profileId },
          {
            data: value?.userProfile.at(0).firstName
          },
          {
            data: value?.userProfile.at(0).email
          },
            { data: this.getTypeVerification(value?.verificationType)  },
            { data: <FileSingle key={'f' + key} data={value} /> },
            {
              data:
                
                <select value={value.documentStatus} onChange={(e) => this.handleUpdate(value?.id,value?.userId, e && e.target && e.target.value,e.target.value,value?.verificationType, value)}>
                  <option value={'pending'}>{formatMessage(messages.messageStatus5)}</option>
                  <option value={'approved'}>{formatMessage(messages.approve)}</option>
                  <option value={'reject'}>{formatMessage(messages.documentReject)}</option>
                </select>
            }
          ]
        }
      // })
    })
  }

  render() {

    const { formatMessage } = this.props.intl;
    const { showAllDocumentVerification: { showAllDocumentVerification }, searchList, currentPage } = this.props;

    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentWrapperRTL')}>
        <CommonTable
          thead={this.thead}
          tbody={this.tbody}
          title={formatMessage(messages.documentVerificationManagement)}
          isSearch
          onSearch={this.handleSearchChange}
        />
        {
          showAllDocumentVerification && showAllDocumentVerification.results && showAllDocumentVerification.results.length > 0
          && <div>
            <CustomPagination
              total={showAllDocumentVerification.count}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel={formatMessage(messages.usersLabel)}
            />
          </div>
        }
      </div>
    );
  }

}

export default compose(
  injectIntl, withStyles(s),
  graphql(DocumentManagement,
    { options: { fetchPolicy: 'network-only' } }),
  graphql(showAllDocumentVerification, {
    name: 'showAllDocumentVerification',
    options: (props) => ({
      fetchPolicy: 'network-only',
      variables: {
        currentPage: props.currentPage,
        searchList: props.searchList,
      },
    })
  }),)(DocumentVerification);