// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
// Redux Form
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { graphql, gql, compose } from 'react-apollo';

// import submit from './submit';
// import validate from './validate';

// Locale
import messages from '../../../locale/messages';

// Redux
import { connect } from 'react-redux';

// Helper
import PopulateData from '../../../helpers/populateData';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './UserDriver.css';
import cs from '../../commonStyle.css';
import {
  Button,
  Form,
  FormGroup,
  FormControl,
  InputGroup
} from 'react-bootstrap';


//Images
import infoImage from '/public/SiteIcons/priceHoverIcon.svg';

class UserDriver extends Component {

  static propTypes = {
      formatMessage: PropTypes.any,
  };

  static defaultProps = {};
  constructor(props) {
    super(props);
  
  }

  componentDidMount() {
    const { change, initialValues } = this.props;
    let loggedinEmail;
    if (initialValues && initialValues.email) {
      loggedinEmail = initialValues.email;
    }
    change('loggedinEmail', loggedinEmail);
    if (initialValues && initialValues.countryName && initialValues.countryCode) {
      
    }

  }

  UNSAFE_componentWillReceiveProps() {
    const { change, initialValues } = this.props;
    
  }

  UNSAFE_componentWillMount() {
    let now = new Date();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleCountryChange(e, selectedData) {

  }

  render() {

    const { error, handleSubmit, submitting, dispatch, loadAccount, base, availableCurrencies, initialValues } = this.props;
    const { formatMessage } = this.props.intl;
    

    return (
      <div className={cx('inputFocusColor', cs.commonBorderSection, 'whiteBgColor')}>
        {error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
        
          <div className={s.displayGrid}>
            <label className={cx(cs.dashBtnPersonal)}>{formatMessage(messages.firstName)}</label>
            <label className={cx(cs.dashBtnPersonal)}>{formatMessage(messages.lastName)}</label>
            <p>{initialValues.firstName}</p>
            <p>{initialValues.lastName}</p>
          </div>
          <div className={s.displayGrid}>
            <label className={cx(cs.dashBtnPersonal)}>{formatMessage(messages.licenseNumber)}</label>
            <label className={cx(cs.dashBtnPersonal)}>{formatMessage(messages.email)}</label>
            <p>{initialValues.userProfileData.driver_license}</p>
            <p>{initialValues.email}</p>
          </div>
      </div>
    )
  }

}

const mapState = (state) => ({
  initialValues: state.account.data,
});
const mapDispatch = {
  change
};

export default compose(
  injectIntl,
  withStyles(s, cs),
  connect(mapState, mapDispatch),
  graphql(gql`
      query getCountries {
          getCountries{
              id
              countryCode
              countryName
              isEnable
              dialCode
          }
      }
  `, {
    options: {
      ssr: false,
      fetchPolicy: 'network-only'
    }
  })
)(UserDriver);