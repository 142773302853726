import React from 'react';
import Layout from '../../components/Layout';

import UserLayout from '../../components/Layout/UserLayout';

import DocumentVerification from './DocumentVerification';

const title = 'DocumentVerification';

export default async function action({ store,params }) {

  // get type docs
  const docVerifyType =  params.docType;

  // From Redux Store
  let isAuthenticated = store.getState().runtime.isAuthenticated;


  if (!isAuthenticated) {
    return { redirect: '/login' };
  }


  let account = store.getState().account;

  if (account) {
    let document = account.data.verification.isIdVerification;
    let idNumberPassport = account.data.verification.isIdNumberVerification;
    let driverLicense = account.data.verification.isLicenseVerified;
    if (document == 1 && idNumberPassport == 1 && driverLicense == 1) {
      return { redirect: '/user/verification' };
    }
  }

  return {
    title,
    component: <UserLayout><DocumentVerification docVerificationType={docVerifyType} title={title} /></UserLayout>,
  };
};


