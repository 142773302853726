import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createNumberMask, createTextMask } from 'redux-form-input-masks';
import { Field,reduxForm,change } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { graphql, gql, compose } from "react-apollo";

// Fetch request
import fetch from "../../core/fetch";

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {Row,Col,ControlLabel,FormGroup,Button} from 'react-bootstrap';
import s from './ListPlaceStep1.css';
import cs from '../commonStyle.css';

// Query
import getListingAddressQuery from './getListingAddressQuery.graphql';

// Components
import PlacesSearch from '../PlacesSearch';

// import PlaceMapSearch from '../PlaceMapSearch/PlaceMapSearch';
import locationIcon from '/public/SiteIcons/locationIdea.svg';
import CommonFormComponent from '../CommonField/CommonFormComponent';
import { normalizePrice } from '../EditProfileForm/normalizePhone';
// import { FaTrash } from 'react-icons/fa';
// import { FiTrash2 } from 'react-icons/fi';
// import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { MdOutlineDeleteSweep,MdOutlineAddPhotoAlternate,MdFormatListBulletedAdd } from "react-icons/md";
// import { AiFillDelete } from "react-icons/ai";

// Locale
import messages from '../../locale/messages';
class ListingDeliveryAddress extends Component {

  static propTypes = {
    listId: PropTypes.number.isRequired,
    initialValues: PropTypes.object,
    isLocationChosen: PropTypes.bool,
    AddrPrice:PropTypes.number,
    formAddressDelivery:PropTypes.shape({
      address:PropTypes.string,
      lat:PropTypes.string,
      lng:PropTypes.string,
      title:PropTypes.string,
    }),
    getListingAddressData: PropTypes.shape({
      // loading: PropTypes.bool,
      getListingAddress: PropTypes.array
    }),
    // // updateLocationStatus: PropTypes.any,
    // // nextPage: PropTypes.any,
    // // isExistingList: PropTypes.bool,
    // updateListingMap: PropTypes.any,
    // mapUpdateLoading: PropTypes.bool
  };

  static defaultProps = {
    getListingAddressData:{
      // loading:true,
      getListingAddress:[]  
    },
  }
  constructor(props) {
    super(props);
    this.state = {
      hideSuggestInput: true,
      AddrPrice:0,
      btnDisabled:true,
      formAddressDelivery:{
        address:null,
        lat:null,
        lng:null,
        title:null
      }
    };
    this.handleOnDelete = this.handleOnDelete.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleUpdteDTForm = this.handleUpdteDTForm.bind(this);
    this.handleUpdteDTFormPrice = this.handleUpdteDTFormPrice.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { isExistingList, isLocationChosen, listId } = this.props;
    if (!isLocationChosen && !isExistingList && !listId) {
      this.setState({ hideSuggestInput: false });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isExistingList, isLocationChosen, listId } = nextProps;
    if (!isLocationChosen && !isExistingList && !listId) {
      this.setState({ hideSuggestInput: false });
    } else {
      this.setState({ hideSuggestInput: true });
    }
  }
  
  renderPlacesSuggest = ({ input, label, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <PlacesSearch
          {...input}
          doUpdateFormSPlace={this.handleUpdteDTForm}
          label={label}
          className={className}
        />
        {touched && error && <span>{formatMessage(error)}</span>}
      </div>
    )
  }

  handleUpdteDTForm(thisValue){
    
    if(thisValue){
      if(thisValue.label){
        
        this.setState({
          formAddressDelivery:{
            address:thisValue.label,
            title:thisValue.label,
            lat:thisValue.location.lat,
            lng:thisValue.location.lng,
          },
          btnDisabled:false 
      });
      }
    }
  }
  
  handleUpdteDTFormPrice(thisValue){
    this.setState({ AddrPrice:thisValue });
  }
  async handleOnClick(){
    const {getListingAddressData, getListingAddressData: { refetch } }=this.props;
    const { mutate,listId } = this.props;
    const {formAddressDelivery,AddrPrice}= this.state;
    
    let variables={};
    
    variables = {
      listid:listId,
      address:formAddressDelivery.address,
      lat:formAddressDelivery.lat,
      lng:formAddressDelivery.lng,
      price:AddrPrice
    };
    
    const { data } = await mutate({
      variables,
      // refetchQueries: [{ query:getContractFile,variables: { typesetting:'contract_cia' } }]
    });
    if(data?.AddListingLocationPrice.status == "success"){
      refetch({ listid:listId });
      // change('formAddressDelivery.address','');
      // change('AddrPrice','');
    }
    
  }
  
  async handleOnDelete(idAddress){
    const {getListingAddressData, getListingAddressData: { refetch } }=this.props;
    const { listId } = this.props;
    
    let mutation = `query DeleteListingLocationPrice ($id: Int!) { DeleteListingLocationPrice (id: $id) { status }}`;
    // let variables = {id:idAddress};
    
    const mapResp = await fetch("/graphql", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: mutation,
        variables: { id:idAddress },
      }),
      credentials: "include",
    });
  
    const checkMapResponse = await mapResp.json();

    
    if(checkMapResponse && checkMapResponse.data?.DeleteListingLocationPrice.status == "success"){
      console.log("get values", checkMapResponse.data);
      refetch({ listid:listId });
    }
    
  }
  render() {
    const { nextPage, previousPage, listId, formPage, step } = this.props;
    const { AddrPrice,btnDisabled } = this.state;
    const { formatMessage } = this.props.intl;
    const { getListingAddressData, getListingAddressData: { getListingAddress } } = this.props;
    const priceMask = createNumberMask({prefix: 'USD$ ',decimalPlaces: 0,spaceAfterSign: true,allowNegative: true,onChange: this.handleUpdteDTFormPrice}); 

    return (
      <div className={cx(s.stepDeliveryAddress)}>
        <Row className={cx(cs.notMarginLR)}>
            <Col xs={12} sm={12} md={7} lg={7} className={cs.noPaddingLeft}>
                <h3 className={cx(cs.commonContentText, cs.spaceBottom3)}><FormattedMessage {...messages.addYourLocation} /></h3>
                <FormGroup className={cx(s.formGroup, 'stepPhotoUpload')}>
                <Field
                    name="formAddressDelivery.address"
                    component={this.renderPlacesSuggest}
                    label={formatMessage(messages.searchLocation)}
                    className={cx(cs.formControlInput, 'commonInputPaddingRTL', s.locationBgIcon, 'stepLocationBgIconRTL')}
                />
                </FormGroup>
            </Col>

            <Col xs={12} sm={12} md={4} lg={4}>
                <FormGroup className={cs.spaceBottom4}>
                    <ControlLabel className={s.landingLabel}>
                    <FormattedMessage {...messages.price} />
                    </ControlLabel>
                    <Field
                      name="AddrPrice"
                      type="text"
                      // onChange={this.handleUpdteDTFormPrice}
                      // onBlur={(e) => this.handleUpdteDTFormPrice(e)} 
                      // onChange={(e) => this.handleUpdteDTFormPrice(e)} 
                      component={CommonFormComponent}
                      label={formatMessage(messages.price)}
                      inputClass={cx(s.formControlInput, s.jumboSelect, s.formControlInputMaxWidth)}
                      
                      {...priceMask}
                    />
                </FormGroup>
                
            </Col>
            <Col xs={12} sm={12} md={1} lg={1} className={cx(cs.noPaddingLeft,cs.noPaddingRight,cs.textAlignRight)}>
              <Button className={cx(cs.spaceTop34,cs.btnPrimary, cs.btnMedium,cs.btnIcon)} 
                type="button" 
                disabled={btnDisabled}
                onClick={this.handleOnClick}
                >
                  <MdFormatListBulletedAdd size={'1.6em'} className={cx(cs.iconBtn)}/>
                </Button>
            </Col>
        </Row>
        <Row className={cx(cs.notMarginLR)}>
          <Col xs={12} sm={12} md={12} lg={12}>
          
            {
              getListingAddress != null && getListingAddress.length > 0 && getListingAddress.map((item,key) => {
                return (
                  <Row className={cx(cs.listingListAddress)} key={key+'_add_pickup'}>
                    <Col xs={12} sm={12} md={1} lg={1}><span >{key+1}</span></Col>
                    <Col xs={12} sm={12} md={7} lg={7}><span >{item.address}</span></Col>
                    <Col xs={12} sm={12} md={3} lg={3}>
                      <span className={cx(cs.itemFloatLeft,cs.itemFluidHalf2)}>USD$ </span>
                      <span className={cx(cs.itemFloatRight,cs.textAlignRight,cs.itemFluidHalf)}>{parseFloat(item.price).toFixed(2)}</span>
                    </Col>
                    <Col xs={12} sm={12} md={1} lg={1} className={cx(cs.textAlignRight,cs.PaddingRight1)}><span onClick={(e)=> this.handleOnDelete(item.id)} className={cx(cs.listingListAddDelete)}>
                      
                      <MdOutlineDeleteSweep size={'1.8em'} className={cx(cs.iconImg,cs.iconBgDanger)}/>
                      
                      {/* {formatMessage(messages.delete)} */}
                      </span></Col>
                  </Row>
                )
              })
            }
          </Col>
        </Row>
      </div>
    );
  }
}

// Photos = reduxForm({
//   form: 'ListPlaceStep2', // a unique name for this form
//   destroyOnUnmount: false,
//   forceUnregisterOnUnmount: true,
//   onSubmit: updateStep2
// })(Photos);

const mapState = (state) => ({
  // photosCount: state.location.photosCount
});
const mapDispatch = {
  change
};

export default compose(
injectIntl,
withStyles(s),
graphql(gql`
    mutation AddListingLocationPrice($listid: Int,$address: String,$lat: String,$lng: String,$price: Float) {
      AddListingLocationPrice( listid: $listid,address: $address,lat:$lat,lng:$lng,price:$price) {
        status
      }
    }
  `),
  graphql(getListingAddressQuery, {
    name: 'getListingAddressData',
    options: (props) => ({
      variables: {
        listid: props.listId
      },
      fetchPolicy: 'network-only',
    })
  }),
connect(mapState, mapDispatch)
)(ListingDeliveryAddress);