
import messages from '../../locale/messages';


const validateStep4 = values => {

	const errors = {};
	// const { listingExtData }=values;
	
	// console.log('values',values);
	
	// if (!listingExtData.policyInsurance) {
	// 	errors.policyInsurance = messages.required;
	// }
	// if (!listingExtData.policyGPS) {
	// 	errors.policyGPS = messages.required;
	// }
	if(!values.confirmationPolicyInsurance){
        errors.confirmationPolicyInsurance = messages.required;
    }

	return errors;
}

export default validateStep4;
