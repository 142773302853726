// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Stepper,Step } from 'react-form-stepper';
import { injectIntl, FormattedMessage } from 'react-intl';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ListPlaceStep1.css';
import cs from '../../components/commonStyle.css';
import cx from 'classnames';
import Logo from '../Logo';

// Locale
import messages from '../../locale/messages';

//Image
import footerImage from '/public/siteImages/stepFooter.svg';

class SidePanel extends Component {

	static propTypes = {
		title: PropTypes.any,
		showStepper: PropTypes.bool,
		StepperSteps: PropTypes.array,
		StepperActive: PropTypes.number,
		landingContent: PropTypes.any,
	};
	static defaultProps = {
		showStepper: false,
		StepperSteps:[],
		StepperActive:1
	};
	render() {
		const { title, landingContent,showStepper,StepperSteps,StepperActive } = this.props;
		const { formatMessage } = this.props.intl;
		return (
			<div>
			<div className={cx(s.leftBg, 'leftBgRTL')} />
				<div className={s.titleWidth}>
					<Logo link={"/"} className={cx(s.brandImg)} />
					<h2 className={cx(cs.spaceBottom2, s.stepSizeCss, cs.fontWeightNormal)}>{title}</h2>
					<h3 className={cx(cs.commonTitleText, cs.fontWeightBold)}>{landingContent}</h3>
				</div>
				{showStepper && StepperSteps &&
					<div className={s.stepperContainer}>
						<Stepper 
							className={cx(s.piStepperContainer)}
							stepClassName={cx(s.tabsStepper)}
							connectorStateColors
							connectorStyleConfig={{size:3,activeBgColor:'#e5565a',completedBgColor:'#a10308'}}
							styleConfig={{ size: '3em',activeBgColor:'#e5565a',completedBgColor:'#a10308',circleFontSize:'1.6rem',labelFontSize:'0.8em' }}
							steps={StepperSteps} 
							activeStep={StepperActive} 
						/>
					</div>
				}
				<div className={s.titleWidth}>
					<img src={footerImage} className={cx(s.stepFooterImage, 'stepFooterImageRTL')} />
				</div>
			</div>
		)
	}
}


export default injectIntl( withStyles(s, cs)(SidePanel));