import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
import { graphql, gql, compose } from 'react-apollo';

// Translation
import { FormattedMessage, injectIntl } from 'react-intl';
import showToaster from '../../../helpers/toasterMessages/showToaster';

import DocumentManagement from './DocumentListingVerificationQuery.graphql';

// Send Email
import { sendEmail } from '../../../core/email/sendEmail';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Link from '../../Link';

import messages from '../../../locale/messages';

//Image
import arrowIcon from '/public/AdminIcons/backArrow.svg';
import s from './ListingVerifiedViewLimit.css';
import cs from '../../../components/commonStyle.css';

class ListingVerifiedViewLimit extends React.Component {

    static propTypes = {
        // data: PropTypes.objectOf,
        data: PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
            description: PropTypes.string,
            isPublished: PropTypes.bool,
            listPhotos: PropTypes.array,
            vehicleData: PropTypes.array,
            listPhotoData: PropTypes.array,
            userInfoOwner: PropTypes.array,
            userAmenities: PropTypes.array,
            status: PropTypes.string,
            lat: PropTypes.number,
            lng: PropTypes.number,
            listingData: PropTypes.shape({
                maxDay: PropTypes.number,
                minDay: PropTypes.number,
            }),
        }),
        title: PropTypes.string.isRequired,
        listId: PropTypes.number,
        // addListToRecommended: PropTypes.any.isRequired,
        // removeListFromRecommended: PropTypes.any.isRequired,
    };

    static defaultProps = {
        // data: {
        //     profileBanStatus: null,
        //     vehicleData: null
        // }
    }

    constructor(props) {
        super(props);
        this.handleUpdate = this.handleUpdate.bind(this);
    }


    async handleUpdate(liId,idUser, status, item) {
        const { mutate, currentPage } = this.props;
        
        // return false;
        const { data } = await mutate({
          variables: {
            id: liId,
            userId: idUser,
            isVerifiedPlateDoc: status,
          },
        //   refetchQueries: [{ query: listingsQuery, variables: { currentPage } }]
        });
    
        if (data?.DocumentListingVerification?.status === 'success') {
          let msg = 'Vehicle Documents have been ';
          msg += (status == 1) ? 'Approved.' : 'Rejected.';
          let content = {
            name: item[0].ownerName,
            verificationStatus: (status == 1) ? 'approved' : 'rejected'
          }
          sendEmail(item[0].ownerEmail, 'documentVerification', content);
          showToaster({ messageId: 'commonSuccess', toasterType: 'success', requestMessage: msg });
          window.location.reload();
        } else {
          // showToaster({ messageId: 'documentManagementError', toasterType: 'error', requestMessage: data.DocumentListingVerification && data.DocumentListingVerification.errorMessage })
        }
    }
    render() {
        const { data, intl, title } = this.props;
        const { formatMessage } = this.props.intl;
        let islistPhotos = data && data.listPhotos;
        let islistPhotoData = data && data.listPhotoData;
        let isUserInfoOwner = data && data.userInfoOwner;
        let setCarFeatures = data && data.userAmenities;
        let vehicleData = data && data.vehicleData;
        let vehicleDocVerifyStatus = 'pending';
        if(vehicleData){
            vehicleDocVerifyStatus = vehicleData[0].isVerifiedPlateDoc == 0 ? 'pending' : 'approved';
        }
        let getCarRules =[],carFeatures;
        if(setCarFeatures){
            
            setCarFeatures.map((item, index) => {
                // console.log('dda',item.listsettings);
                getCarRules.push(item.listsettings.itemName);
            });
            carFeatures = getCarRules.join(', ');
        }
        return (
            <div className={cx(cs.adminContentPadding)}>
                <div className={cs.textLineBreak}>
                    <div className={cx(cs.dFlexContainer, cs.spaceBottom4, cs.mobileDisplayBlock)}>
                        <h1 className={cx(cs.commonTotalText, cs.fontWeightBold)}><FormattedMessage {...messages.carInformationDetails} /></h1>
                        <div className={cx(s.gobackMobileSpace, cs.dFlex, cs.textAlignRight, cs.mobileDisplayBlock, 'textAlignLeftRTL')}>
                            <Link to={"/siteadmin/listings"} className={cx(cs.siteLinkColor, cs.commonContentText, cs.fontWeightMedium, cs.commomLinkborderBottom, cs.textDecorationNone)}>
                                <img src={arrowIcon} className={cx(cs.backArrowStyle, 'adminGoBackArrowRTL')} />
                                <FormattedMessage {...messages.goBack} />
                            </Link>
                        </div>
                    </div>
                    <div className={cx(cs.commonAdminBorderSection, s.noProfilePadding)}>
                        <div className={s.profileHeader}>
                            <div className={cx(cs.dFlex, cs.mobileDisplayBlock)}>
                                <div></div>
                                <div className={cx(cs.commonTotalText, cs.fontWeightBold)}>
                                    {data.title}
                                </div>
                            </div>
                        </div>

                        {
                            data && data.userInfoOwner && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.ownersName} /></div>
                                <div>{data.userInfoOwner[0].ownerName}</div>
                                
                            </div>
                        }
                        {
                            data && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.ownersName} /></div>
                                <div>{data.isPublished ? <FormattedMessage {...messages.publishedLabel} /> : <FormattedMessage {...messages.UNPublished} /> }</div>
                                
                            </div>
                        }
                        {
                            data && data.vehicleData && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.carType} /></div>
                                <div>{data.vehicleData[0].vtype}</div>
                                
                            </div>
                        }
                        {
                            data && data.vehicleData && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.carBrandMake} /></div>
                                <div>{data.vehicleData[0].vmake}</div>
                            </div>
                        }
                        {
                            data && data.vehicleData && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.modelLabel} /></div>
                                <div>{data.vehicleData[0].vmodel}</div>
                            </div>
                        }
                        {
                            data && data.vehicleData && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.year} /></div>
                                <div>{data.vehicleData[0].year}</div>
                            </div>
                        }
                        {
                            data && data.vehicleData && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.vehiclePlate} /></div>
                                <div>{data.vehicleData[0].plate}</div>
                            </div>
                        }
                        {
                            data && data.vehicleData && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.vehicleChasis} /></div>
                                <div>{data.vehicleData[0].chasis}</div>
                            </div>
                        }
                        {
                            data && data.vehicleData && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.carColor} /></div>
                                <div>{data.vehicleData[0].color}</div>
                            </div>
                        }
                        {
                            data && data.vehicleData && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.vehicleEngine} /></div>
                                <div>{data.vehicleData[0].engine}</div>
                            </div>
                        }
                        {
                            data && data.vehicleData && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.carFeatures} /></div>
                                <div>{carFeatures}</div>
                            </div>
                        }
                        {
                            data && data.vehicleData && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.ownerGuide} /></div>
                                <div>{data.description}</div>
                            </div>
                        }
                        
                    </div>
                    <hr></hr>
                    <div className={cx(cs.commonAdminBorderSection, s.noProfilePadding)}>
                        <div className={s.profileHeader}>
                            <div className={cx(cs.dFlex, cs.mobileDisplayBlock)}>
                                <div className={cx(cs.commonTotalText, cs.fontWeightBold)}>
                                    <FormattedMessage {...messages.documentverificaiton} />
                                </div>
                            </div>
                        </div>
                        {data && vehicleData && <div 
                            className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal,s.profileDocViewGrid,'DocVerificationStat'+vehicleDocVerifyStatus)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.vehiclePlate} />
                                    <span className={cs.profileDTBold}>{vehicleData[0].plate}</span>
                                </div>
                                <div>
                                    
                                    {islistPhotos.map((item, index) => {
                                            if(item.isDocVehicle ==2){
                                                return (
                                                    <div key={index}>
                                                        <a className={cx(s.profileDocUploadedLink)} href={'/files/x_large_'+item.name} target={"_blank"}>
                                                            <div className={cx(s.profileDocUploaded)} style={{ backgroundImage: `url(/files/x_medium_${item.name})`}}>
                                                            </div>
                                                        </a>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                                <div>
                                    {vehicleData[0].isVerifiedPlateDoc ==1 &&
                                        <span className={s.profileDocStatusName}>
                                            <FormattedMessage {...messages.messageStatus4} />
                                        </span>
                                    }
                                    
                                    { vehicleData[0].isVerifiedPlateDoc ==0 &&
                                        islistPhotos.map((item, index) => {
                                            if(item.isDocVehicle ==2){
                                                return (
                                                    <div key={index}>
                                                        
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

}


export default compose(
    injectIntl, withStyles(s, cs),
    graphql(DocumentManagement,{ options: { fetchPolicy: 'network-only' } }),

)(ListingVerifiedViewLimit);