import 'dotenv/config';
/* eslint-disable max-len */
export const appEnv = process.env.NODE_ENV || 'development';
export const port = process.env.PORT || 3000;
export const host = process.env.WEBSITE_HOSTNAME || `localhost:${port}`;
export const url = typeof window !== 'undefined' ? window.location.origin : process.env.URL || 'http://localhost:3001';
export const sitename = 'MagnetDo ver. 2.0.1';

export const locales = ['en-US','es']; //, 'es', 'it-IT', 'fr-FR', 'pt-PT', 'ar'

export const databaseUrl = process.env.DATABASE_URL;

export const homelogouploadDir = `http://${url}/files`;

export const getBanneruploadDir = `http://${url}/files`;

export const profilePhotouploadDir = `http://${url}/files`;

export const documentuploadDir = `http://${url}/files`;

export const locationuploadDir = `http://${url}/files`;

export const getPopularLocationDir = `http://${url}/files`;

export const homebanneruploadDir = `http://${url}/files`;
export const getHomebanneruploadDir = `http://${url}/files`;

export const claimImagesUploadDir = `http://${url}/files`;
export const claimImageDir = `http://${url}/files`;

export const faviconUploadDir = `http://${url}/files`;
export const getFavIconDir = `http://${url}/files`;

export const whyHostUploadDir = `http://${url}/files`;
export const getWhyHostDir = `http://${url}/files`;
export const fileuploadDir = `http://${url}/files`;
export const contractuploadDir = process.env.CONTRACT_UPLOAD_DIR || './storages/contract/pdf/';
export const getContractDir = process.env.GET_CONTRACT_UPLOAD_DIR || '/storages/contract/pdf/';
export const contractTemplateuploadDir = process.env.CONTRACT_TEMPLATE_UPLOAD_DIR || './storages/contract/templates/';
export const getContractTemplateDir = process.env.GET_CONTRACT_TEMPLATE_UPLOAD_DIR || './storages/contract/templates/';
export const contractTemplateMergeDir = process.env.CONTRACT_CONVERT_UPLOAD_DIR || './storages/contract/gen/';

export const getTripsDir = process.env.GET_TRIPS_UPLOAD_DIR || '/files/';
export const tripsUploadDir = process.env.TRIPS_UPLOAD_DIR || './images/trips/';

export const apiViaFirmaURL = process.env.VIAFIRMA_URL || 'https://sandbox.viafirma.com/documents/api/v3/';
export const apiViaFirmaUser = process.env.VIAFIRMA_USER || 'user-magnet';
export const apiViaFirmaPass = process.env.VIAFIRMA_PASS || 'secret-password';
export const apiViaFirmaCodeGroup = process.env.VIAFIRMA_GROUP_CODE || 'magnet';
export const apiViaFirmaIDGroupOwner = process.env.VIAFIRMA_ID_GROUP_OWNER || 'owner_magnet_001';
export const apiViaFirmaIDGroupApp = process.env.VIAFIRMA_ID_GROUP_APP || 'app_magnet_001';

export const analytics = {
  // https://analytics.google.com/
  google: {
    trackingId: 'G-LMN5WPT3GE',
  },
};

export const googleMapAPI = 'AIzaSyAd0RVGDhy9FJuhP4vZUXb-cmkFTkidHz8';
export const googleMapServerAPI = process.env.GOOGLE_MAP_SERVER_API;

export const payment = {
  paypal: {
    returnURL: `${url}${process.env.PAYPAL_RETURN_URL}`,
    cancelURL: `${url}${process.env.PAYPAL_CANCEL_URL}`,
    redirectURL: {
      success: `${url}${process.env.PAYPAL_SUCCESS_REDIRECT_URL}`,
      cancel: `${url}${process.env.PAYPAL_CANCEL_URL}`
    },
    returnPIURL: `${url}${process.env.PAYPAL_POLICYINSURANCE_RETURN_URL}`,
    cancelPIURL: `${url}${process.env.PAYPAL_POLICYINSURANCE_CANCEL_URL}`,
    redirectPIURL: {
      success: `${url}${process.env.PAYPAL_POLICYINSURANCE_SUCCESS_REDIRECT_URL}`,
      cancel: `${url}${process.env.PAYPAL_POLICYINSURANCE_CANCEL_URL}`
    },
    versions: {
      versionOne: '/v1',
      versionTwo: '/v2'
    },
    token_url: '/oauth2/token',
    payment_url: '/checkout/orders',
    capture_url: '/capture',
    payout_url: '/payments/payouts',
    refund: '/refund',
    refund_capture: '/payments/captures/'
  },
  stripe: {
    secretKey: process.env.STRIPE_SECRET_KEY,
    publishableKey: process.env.STRIPE_PUBLIC_KEY,
    webhookSecret: process.env.STRIPE_WEBHOOK_SECRET,
    successUrl: `${url}/cars`,
    cancelUrl: `${url}/cars`,
  }
};

// site key for google recaptcha
export const googleCaptcha = {
  sitekey: '6LfrYp0pAAAAABdcHqhY-FWHBlP5sGMvakSdL2pq'
};

export const auth = {
  jwt: { secret: process.env.JWT_SECRET },

  redirectURL: {
    login: process.env.LOGIN_URL || '/dashboard',
    verification: process.env.LOGIN_URL || '/user/verification',
    userbanned: process.env.USER_BANNED_URL || '/userbanned',
    returnURLDeletedUser: process.env.DELETE_USER_URL || '/userbanned'
  },

  // https://developers.facebook.com/ IT IS REMOVED ON THE FUNCTIONING CODE. 
  facebook: {
    returnURL: process.env.FACEBOOK_CLIENT_URL || `${url}/login/facebook/return`,
  },

  // https://cloud.google.com/console/project
  google: {
    returnURL: process.env.GOOGLE_CLIENT_URL || `${url}/login/google/return`,
  }
};
