import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import UsersOwner from './UsersOwner';
import { restrictUrls } from '../../../helpers/adminPrivileges';

const title = 'User Owners';

export default async function action({ store }) {

  // From Redux Store
  let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
  let isSuperAdmin = store.getState().account.privileges.isSuperAdmin;
  let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
  let privileges = store.getState().listSettings && store.getState().listSettings.privileges;

  if (!isAdminAuthenticated) {
    return { redirect: '/siteadmin/login' }
  }

  // Admin restriction - allow access if superadmin or has required privilege
  if (!isSuperAdmin && !restrictUrls('/siteadmin/users-owner', adminPrivileges, privileges)) {
    return { redirect: '/siteadmin' };
  }

  return {
    title,
    component: <AdminLayout><UsersOwner title={title} /></AdminLayout>,
  };
};
