import React from 'react';
import ManageListing from './ManageListing';
import UserLayout from '../../components/Layout/UserLayout';
import { getListingSteps, resetListingSteps } from '../../actions/getListingSteps';

const title = "Manage Listing";

export default async function action({ store }) {

  // From Redux Store
  let isAuthenticated = store.getState().runtime.isAuthenticated;
  let isOwner = 0;
  const data = store.getState().account.data;
  if(data){
    if(data.verification){ isOwner = data.verification.isOwner;}  
  }
  // console.log('debug',data);
  // let isOwner = store.getState().account.verification.isOwner;
  

  store.dispatch(resetListingSteps());
  await store.dispatch(getListingSteps());

  if (!isAuthenticated) {
    return { redirect: '/login' };
  }

  return {
    title,
    component: <UserLayout showSideMenu={'listContainer'}><ManageListing /></UserLayout>,
  };
};
