export function userHasPrivilege(requestId, permittedPrevileges) {
    if(permittedPrevileges && permittedPrevileges.length > 0) {
        return permittedPrevileges.indexOf(requestId) >= 0;
    }
    return false;

}

export function restrictUrls(requestURL, permittedPrevileges, privileges) {
    // Return false if no privileges array provided
    if (!privileges || !Array.isArray(privileges)) {
        return false;
    }

    // Find privilege that contains the requested URL
    let findRequestedUrlId = privileges.find((o) => o && o.permittedUrls 
        && Array.isArray(o.permittedUrls) 
        && o.permittedUrls.length > 0 
        && o.permittedUrls.indexOf(requestURL) >= 0);

    // If no matching privilege found, return false
    if (!findRequestedUrlId) {
        return false;
    }

    // Check if user has the required privilege
    return permittedPrevileges 
        && Array.isArray(permittedPrevileges)
        && permittedPrevileges.length > 0 
        && permittedPrevileges.indexOf(findRequestedUrlId.id) >= 0;
}
