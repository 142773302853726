// Redux Form
import { SubmissionError } from 'redux-form';

import { FormattedMessage, injectIntl } from 'react-intl';

// Fetch request
import fetch from '../../core/fetch';

// Locale
import messages from '../../locale/messages';

// Redux Action
// import { getListingData } from '../../actions/getListing';
import { getListingDataStep4 } from '../../actions/getListingDataStep4';
import { makePaymentPolicy } from '../../actions/policyInsurance/makePaymentPolicy';
import { manageListingSteps } from '../../actions/manageListingSteps';
import { setLoaderStart, setLoaderComplete } from '../../actions/loader/loader';

// For Redirect
import history from '../../core/history';

async function updateStep4(values, dispatch) {

  const listId = values.id;
  
  let listingExtPolicyInsurance = values?.listingExtData;
  let listingExt = values?.listingExtData;
  let listYear, planType, expirationDate, rateYearly, ratePlan, rateAmountYearly, rateAmountPlan,
      dueFirst, duesAmount, paymentQt, duesQT, planQTMonth, paymentSubNet, paymentCost, paymentNet, policyAmountUSD;
  
  if (listingExtPolicyInsurance) {
    listYear = listingExtPolicyInsurance?.listYear;
    planType = listingExtPolicyInsurance?.planType;
    expirationDate = listingExtPolicyInsurance?.expirationDate;
    rateYearly = listingExtPolicyInsurance?.rateYearly;
    ratePlan = listingExtPolicyInsurance?.ratePlan;
    rateAmountYearly = listingExtPolicyInsurance?.rateAmountYearly;
    rateAmountPlan = listingExtPolicyInsurance?.rateAmountPlan;
    dueFirst = listingExtPolicyInsurance?.dueFirst;
    duesAmount = listingExtPolicyInsurance?.duesAmount;
    paymentQt = listingExtPolicyInsurance?.paymentQt;
    duesQT = listingExtPolicyInsurance?.duesQT;
    planQTMonth = listingExtPolicyInsurance?.planQTMonth;
    paymentSubNet = listingExtPolicyInsurance?.paymentSubNet;
    paymentCost = listingExtPolicyInsurance?.paymentCost;
    paymentNet = listingExtPolicyInsurance?.paymentNet;
    policyAmountUSD = listingExtPolicyInsurance?.policyAmountUSD;
  }

  delete listingExt.odometro;
  delete listingExt.odometro_limit;
  delete listingExt.odometro_price;
  delete listingExt.year;

  delete listingExt.listId;
  delete listingExt.listYear;
  delete listingExt.planType;
  delete listingExt.expirationDate;
  delete listingExt.rateYearly;
  delete listingExt.ratePlan;
  delete listingExt.rateAmountYearly;
  delete listingExt.rateAmountPlan;

  delete listingExt.dueFirst;
  delete listingExt.duesAmount;
  delete listingExt.paymentQt;
  delete listingExt.duesQT;
  delete listingExt.planQTMonth;
  delete listingExt.paymentType;
  delete listingExt.paymentSubNet;
  delete listingExt.paymentCost;
  delete listingExt.paymentNet;

  let listingExtData= JSON.stringify(listingExt);
  
  let variables = Object.assign({}, {id: listId, listingExtData});
  



    // return false;  
  dispatch(setLoaderStart('updateListing'));
  const query = `query (
  	$id: Int,
    $listingExtData: String,
  ) {
      updateListingStep4 (
        id: $id,
        listingExtData: $listingExtData
      ) {
        status
      }
    }`;

  const resp = await fetch('/graphql', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: query,
      variables
    }),
    credentials: 'include'
  });

  const { data } = await resp.json();

  if (data && data.updateListingStep4 != undefined) {
    if (data.updateListingStep4.status == "success") {
       await dispatch(makePaymentPolicy(
        listId,
        parseInt(listYear),
        planType,
        expirationDate,
        rateYearly,
        ratePlan,
        rateAmountYearly,
        rateAmountPlan,
        dueFirst,
        duesAmount,
        paymentQt,
        duesQT,
        planQTMonth,
        1,
        paymentSubNet,
        paymentCost,
        paymentNet,
        policyAmountUSD,
        values?.listingExtData?.paymentCurrency
      ));
      await dispatch(manageListingSteps(listId, 4));
      // history.push('/become-a-owner/' + values.id + '/home');
      await dispatch(setLoaderComplete('updateListing'));
      await dispatch(getListingDataStep4(values.id));
    } else if (data.updateListingStep4.status == "notLoggedIn") {
      dispatch(setLoaderComplete('updateListing'));
      throw new SubmissionError({ _error: messages.notLoggedIn });
    } else {
      dispatch(setLoaderComplete('updateListing'));
      throw new SubmissionError({ _error: messages.somethingWentWrong });
    }
  } else {
    dispatch(setLoaderComplete('updateListing'));
    throw new SubmissionError({ _error: messages.somethingWentWrong });
  }

}

export default updateStep4;