import moment from "moment";

export function idIdentityTyep(idType) {
    if (idType === 1) {
        return "de la Tarjeta de Identificacion";
    } else if (idType === 2) {
        return "del Pasaporte";
    } else {
        return " de la Cédula de identidad y electoral";
    }
}

export function genUID(length) {
    let result = "";
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function getVehicleNameTitle(vehicleDT) {
    let setVehicleName = "";
    setVehicleName = vehicleDT?.title ? vehicleDT.title : "Car Title Undefined";
    if (vehicleDT?.vehicleData[0]?.vmake) {
        setVehicleName +=
            " " +
            vehicleDT?.vehicleData[0]?.vmake +
            " " +
            vehicleDT?.vehicleData[0]?.vmodel;
    }
    return setVehicleName;
}

export function getVehicleTitle(vehicleDT) {
    let setVehicleName = "";
    if (vehicleDT?.vehicleData[0]?.vmake) {
        setVehicleName =
            vehicleDT?.vehicleData[0]?.vmake +
            " " +
            vehicleDT?.vehicleData[0]?.vmodel +
            " " +
            vehicleDT?.vehicleData[0]?.year;
    }
    return setVehicleName;
}
export function getVehicleName(vehicleDT) {
    let setVehicleName = "";
    if (vehicleDT[0]?.vmake) {
        setVehicleName =
            vehicleDT[0]?.vmake +
            " " +
            vehicleDT[0]?.vmodel +
            " " +
            vehicleDT[0]?.year;
    }
    return setVehicleName;
}

export function getListingStat(lData) {
    let setListingStatus = "row_unverified";
    if (lData?.listingExtData?.isVerifiedPlateDoc) {
        if (lData?.listingExtData?.isVerifiedPlateDoc == 1) {
            setListingStatus = "row_verified";
        }
    }
    return setListingStatus;
}

export function viaFirmaConvertDataToFields(getFieldsData) {
    let dataFields = [];
    getFieldsData.forEach((item) => {
        for (let key in item) {
            dataFields.push({ key: `${key}`, value: `${item[key]}` });
        }
    });
    return dataFields;
}
export function padSprintIf(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

export function formattingCurrency(amount) {
    const setAmount = parseFloat(amount).toFixed(2);
    const options2 = { style: "currency", currency: "USD" };
    const numberFormat2 = new Intl.NumberFormat(options2);
    return numberFormat2.format(setAmount);
}
export function policyInsuranceStatus(
    expirationDate,
    msNull,
    msActive,
    msExpire
) {
    let returnStatus = msNull;
    if (expirationDate != null) {
        let DTToday = moment(),
            expirationDateDT = moment(expirationDate);
        // let dayDiffD=DTToday.isBefore(expirationDateDT);
        if (DTToday.isBefore(expirationDateDT) == true) {
            returnStatus = msActive;
        } else {
            returnStatus = msExpire;
        }
    }
    return returnStatus;
}
