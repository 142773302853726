import { gql } from 'react-apollo';
import { initialize, change } from 'redux-form';

import {
  GET_LISTING_DATA_STEP4_START,
  GET_LISTING_DATA_STEP4_SUCCESS,
  GET_LISTING_DATA_STEP4_ERROR
} from '../constants';

import { utcTimeFormat } from '../components/ViewListing/ListingDetails/helper';

const query = gql`
  query ($listId:String!, $preview: Boolean) {
    UserListing (listId:$listId, preview: $preview) {
      id
      title
      userId
      listingExtData{
        year
        odometro
        odometro_limit
        odometro_price
        policyInsurance
        policyRateValue
        policyIsVerified
        policyExpire
        policyPlan
        policySubNet
        policyExtraCost
        policyNet
        policyQTPayment
        policyGPS
        policyRateValueOwn
        policyExpireOwn
      }
    }
  }
`;

export function getListingDataStep4(listId) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: GET_LISTING_DATA_STEP4_START,
    });

    try {
      let formValues = null;
      let listingExtData = {};

      // Send Request to get listing data
      const { data } = await client.query({
        query,
        variables: { listId, preview: true },
        fetchPolicy: 'network-only',
      });


      if (data && data.UserListing) {
        // Preparing List data
        // listData = data.UserListing.listingData;
        listingExtData = data.UserListing.listingExtData;
        


        formValues = Object.assign({}, data.UserListing, listingExtData);
        console.log('********* ListPlaceStep4 ********* formValues:', formValues);
        
        dispatch(change('ListPlaceStep4', 'listingExtData', data.UserListing.listingExtData));
        // Reinitialize the form values
        await dispatch(initialize('ListPlaceStep4', formValues));
        
        //await dispatch(initialize('ListPlaceStep3', formValues));
        // Dispatch a success action
        dispatch({
          type: GET_LISTING_DATA_STEP4_SUCCESS,
          step4DataIsLoaded: true,
          isExistingList: true,
        });
      } else {
        dispatch({
          type: GET_LISTING_DATA_STEP4_ERROR,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_LISTING_DATA_STEP4_ERROR,
        payload: {
          error,
        },
      });
      return false;
    }
    return true;
  };
}