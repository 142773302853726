import React from 'react';
import PropTypes from 'prop-types';
// import { graphql, gql, compose } from 'react-apollo';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './UploadMediaGallery.css';

// Component
import UploadMediaGalleryForm from '../../../components/siteadmin/UploadMediaGalleryForm';

import Loader from '../../../components/Loader';

class UploadMediaGallery extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    // data: PropTypes.shape({
    //   loading: PropTypes.bool,
    //   contractsSettings: PropTypes.array,
    // })
  };


  static defaultProps = {
    // data: {
    //     loading: true
    // }
  };

  render() {
    const settingsCollection = {};
    const {   title } = this.props;
    // if (loading) {
    //   return <Loader type={"text"} />;
    // } else {
      
      return <UploadMediaGalleryForm initialValues={settingsCollection} title={title} />
    // }
  }

}

export default 
  withStyles(s)
(UploadMediaGallery);
