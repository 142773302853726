import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { injectIntl } from 'react-intl';
import ShowDocumentListQuery from './ShowListDocument.graphql';
import RemoveDocumentList from './RemoveDocumentList.graphql';
import showToaster from '../../helpers/toasterMessages/showToaster';
import pdfIcon from './pdf_image.png';
import closeIcon from '../../../public/SiteIcons/documentRemove.svg';
import s from './DocumentList.css';
class DocumentList extends Component {
  
  static propTypes = {
    checkDocUploaded: PropTypes.any,
  };

  static defaultProps = {
    data: {
      loading: true,
      showDocumentList: [],
    },
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  async handleClick(id, fileName) {
    const { mutate ,docVerificationId, listId, onRemoveDoc } = this.props;

    const { data } = await mutate({
      RemoveDocumentList,
      variables: { id },
      refetchQueries: [{ query: ShowDocumentListQuery,variables: {listId:listId, verificationType: docVerificationId } }]
    });


    if (data && data.RemoveDocumentList && data.RemoveDocumentList.status == "success") {
      const resp = await fetch('/deleteDocuments', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileName }),
        credentials: 'include',
      });
      onRemoveDoc();
      showToaster({ messageId: 'documentRemoved', toasterType: 'success' })
    }
  }


  render() {
    const { data: { ShowDocumentList } } = this.props;
    let path = "/files/";
    return (
      <div className={cx('row')}>
        {
          ShowDocumentList && ShowDocumentList.map((item, key) => {

            let icon = item.fileType == 'application/pdf' ? pdfIcon : (path + item.fileName);
            return (
              <div key={key} className={cx('col-lg-12 col-md-12 col-sm-6 col-xs-12 text-center')}>
                <div className={s.realtive}>
                  <a href={path + item.fileName} target="_blank">
                    <div className={s.listPhotoCover}>
                      <div className={s.listPhotoMedia}>
                        <img className={s.imgResponsive} src={icon} />
                      </div>
                    </div>
                  </a>
                  <a href="javascript:void(0);" onClick={() => this.handleClick(item.id, item.fileName)} className={cx(s.position, 'positionDocumentRTL')}>
                    <img src={closeIcon} />
                  </a>
                </div>
              </div>
            );

          })
        }
      </div>
    );
  }
}

export default compose(
  injectIntl,
  withStyles(s),
  graphql(ShowDocumentListQuery, {
    options:(props)=> ({
      variables:{
        verificationType:props.docVerificationId,
        listId:props.listId
        }
        ,
      fetchPolicy: 'network-only'
    })
  }),
  graphql(RemoveDocumentList, {
    options: {
      fetchPolicy: 'network-only'
    }
  }),
)(DocumentList);