// General
import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql, gql, compose } from 'react-apollo';
import moment from 'moment';

// Redux
import { connect } from "react-redux";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// query
import userAccountQuery from './userAccountQuery.graphql';
// import getListingExtQuery from './getListingExtQuery.graphql';

// Locale
import messages from "../../locale/messages";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./ShowNotification.css";
import cs from "../commonStyle.css";

// import { resendEmailVerification } from '../../actions/manageUserVerification';
import history from '../../core/history';
import { FaBullseye } from "react-icons/fa";

class ShowNotification extends Component {
  static propTypes = {
    isHasError: PropTypes.bool,
    isShowing: PropTypes.bool,
    listingID: PropTypes.number,
    listingPlateDoc: PropTypes.bool,
    showHeader: PropTypes.bool,
    showPhoneNumber: PropTypes.bool,
    showEmailConfirmed: PropTypes.bool,
    showSubTitle: PropTypes.bool,
    showSimpleHeader: PropTypes.bool,
    showAgreement: PropTypes.bool,
    showVehicleDoc: PropTypes.bool,
    showIDIdentification: PropTypes.bool,
    showDocVerification: PropTypes.bool,
    showDriverLicense: PropTypes.bool,
    userAccountData: PropTypes.shape({
      loading: PropTypes.bool,
      userAccountExt: PropTypes.shape({
        userId: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        phoneNumber: PropTypes.string,
        verification: PropTypes.shape({
          isEmailConfirmed: PropTypes.bool.isRequired,
          isIdNumberVerification: PropTypes.number.isRequired,
          isLicenseVerified: PropTypes.number.isRequired,
          isIdVerification: PropTypes.number,
          isPhotoVerified: PropTypes.number,
        }),
        userProfileData: PropTypes.shape({
          isAgreementSigned: PropTypes.number,
          // isVerifiedPlateDoc: PropTypes.number.isRequired,
          isAgreementStatus: PropTypes.number,
          driver_license_verify: PropTypes.number,
          identity_verify: PropTypes.number,
          identity_id: PropTypes.string,
          driver_license: PropTypes.string,
        }),
        agreementData: PropTypes.array.isRequired
      })
    }),
    ShowListingExtData: PropTypes.shape({
      loading: PropTypes.bool,
      ShowListingExt: PropTypes.shape({
        isVerifiedPlateDoc: PropTypes.number.isRequired,
        isVerifiedPhotos: PropTypes.number.isRequired,
      })
    }),
  };
  static defaultProps = {
    isShowing: false,
    listingID: 0,
    listingPlateDoc: false,
    showHeader: false,
    showPhoneNumber: false,
    showEmailConfirmed: false,
    showSubTitle: false,
    showSimpleHeader: false,
    showAgreement: false,
    showVehicleDoc: false,
    showIDIdentification: false,
    showDocVerification: false,
    showDriverLicense: false,
    userAccountData: {
      loading: true,
      userAccountExt: {
        userId: null,
        email: null,
        displayName: null,
        firstName: null,
        phoneNumber: null,
        verification: {
          isEmailConfirmed: false,
          isIdNumberVerification: 0,
          isLicenseVerified: 0,
          isIdVerification: 0,
          isPhotoVerified: 0,
        },
        userProfileData: {
          isAgreementSigned: 0,
          isAgreementStatus: 0,
          isVerifiedPlateDoc: 0,
          driver_license_verify: 0,
          identity_verify: 0,
          identity_id: null,
          driver_license: null
        },
        agreementData: []
      }
    },
    ShowListingExtData: {
      loading: false,
      ShowListingExt: {
        isVerifiedPlateDoc: 0,
        isVerifiedPhotos: 0,
      }
    },
  };
  constructor(props) {
    super(props);
    this.state = {
      isHasError: true
    }
      ;

    this.goToVerifyEmail = this.goToVerifyEmail.bind(this);
    this.documentVerificationID = this.documentVerificationID.bind(this);
    this.documentAgreement = this.documentAgreement.bind(this);
    this.documentDriverLicense = this.documentDriverLicense.bind(this);
    this.documentVerificationApp = this.documentVerificationApp.bind(this);
    this.documentGoPhoneNumber = this.documentGoPhoneNumber.bind(this);
    this.documentVehiclePlate = this.documentVehiclePlate.bind(this);
    // this.doChangeShowFields = this.doChangeShowFields.bind(this);
  }


  goToVerifyEmail() {
    history.push('/user/verification');
  }

  documentGoPhoneNumber() {
    history.push('/user/personal/');
  }

  documentVerificationApp() {
    history.push('/document-verification/1/');
  }

  documentVerificationID() {
    history.push('/document-verification/2/');
  }
  documentVehiclePlate() {
    const { listingID } = this.props;
    history.push('/become-a-owner/' + listingID + '/photos');
  }

  documentAgreement() {
    history.push('/agreement/view/');
  }
  documentDriverLicense() {
    history.push('/document-verification/3/');
  }
  render() {
    const {
      showHeader,
      showSubTitle,
      showSimpleHeader,
      showAgreement,
      listingPlateDoc
    } = this.props;
    const verification = this.props.account.verification || {};
    const userProfileData = this.props.account.userProfileData || {
      // here until we move this file to typescript
      // isAgreementSigned: number;
      // isAgreementStatus: number;
      // identity_id: string;
      // driver_license: string;
      // phoneNumber: string | null;
    };
    const phoneNumber = this.props.account.phoneNumber || '';

    let agreementStatusMessageType = userProfileData.isAgreementSigned && userProfileData.isAgreementStatus ?
      'agreementInfoReview' : 'missingAgreement';

    if (
      verification.isEmailConfirmed &&
      verification.isIdNumberVerification &&
      phoneNumber &&
      verification.isLicenseVerified &&
      verification.isIdVerification
      // !userProfileData.isOwner &&
      // !userProfileData.isTermnsAccepted
    ) {
      return null
    } else {
      this.props.handleShowError()
    }

    return (
      <div className={cx(s.containerAlertPublish)}>
        {showHeader && <h3 className={cx(s.mbMargin, s.headerText)}>
          <FormattedMessage {...messages.listingAlertPublish} />
        </h3>}
        {showSubTitle && <h4 className={cx(s.mbMargin, s.subtitleText)}>
          <FormattedMessage {...messages.listinSubTitlePublish} />
        </h4>}
        {showSimpleHeader && <h4 className={cx(s.mbMargin, s.simpleHeader)}>
          <FormattedMessage {...messages.listinSubTitlePublish} />
        </h4>}
        {/* field does not exists; Profile photo */}
        {false && !verification.isPhotoVerified && <p onClick={this.goToVerifyEmail}>
          <FormattedMessage {...messages.missingPhotoProfile} />
        </p>}
        {showAgreement && !!userProfileData.isAgreementSigned && <p onClick={this.documentAgreement}>
          <FormattedMessage {...messages[agreementStatusMessageType]} />
        </p>}
        {!verification.isIdVerification && <p onClick={this.documentVerificationApp}>
          <FormattedMessage {...messages.missingIDDocVerification} />
        </p>}
        {!verification.isIdNumberVerification && <p onClick={this.documentVerificationID}>
          <FormattedMessage {...messages.missingIDPassport} />
        </p>}
        {!verification.isLicenseVerified && <p onClick={this.documentDriverLicense}>
          <FormattedMessage {...messages.missingDriverLicense} />
        </p>}
        {!phoneNumber && <p onClick={this.documentGoPhoneNumber}>
          <FormattedMessage {...messages.missingPhoneNumber} />
        </p>}
        {!verification.isEmailConfirmed && <p onClick={this.goToVerifyEmail}>
          <FormattedMessage {...messages.needConfirmEmail} />
        </p>}
        {listingPlateDoc && <p onClick={this.documentVehiclePlate}>
          <FormattedMessage {...messages.missingCarDocUpload} />
        </p>}
      </div>
    );
  }
}
const mapState = (state) => ({
  account: state.account.data,
});
const mapDispatch = {
  // resendEmailVerification
};
export default compose(
  injectIntl,
  withStyles(s, cs),
  connect(mapState, mapDispatch),
)(ShowNotification);
