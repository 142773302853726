import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import cs from '../../components/commonStyle.css';
import s from './VerifiedInfo.css';

//Images
import tickIcon from '/public/siteImages/verifyTick.svg';
import alertIcon from '/public/siteImages/alertIcon.svg';

class AccountItem extends Component {

  static propTypes = {
    itemName: PropTypes.string.isRequired,
    isConfirmed: PropTypes.bool
  };

  render() {
    const { itemName,isConfirmed } = this.props;
    return (
      <div className={s.displayFlex}>
        <h4 className={
          isConfirmed == true ? cx(cs.commonMediumText, cs.fontWeightNormal): cx(s.textAlertNotConfirmed,cs.commonMediumText, cs.fontWeightNormal)
          }>
          {itemName}
        </h4>
        {isConfirmed ? (
          <img src={tickIcon} className={s.tickIcon} />
        ) : (
          <img src={alertIcon} className={cx(s.alertWith, s.tickIcon)} />
        )}
      </div>
    );
  }
}

export default withStyles(s, cs)(AccountItem);