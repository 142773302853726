import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { NavDropdown, MenuItem } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from "react-intl";
import cx from 'classnames';

// Locale
import messages from "../../locale/messages";
import { formatLocale, isRTL } from '../../helpers/formatLocale';
import { setLocale } from '../../actions/intl';

//image
import flagEN from '/public/siteImages/flag-us.svg';
import flagES from '/public/siteImages/flag-es.svg';

import s from './DropDownLanguage.css';

class DropDownLanguage extends React.Component {

  static propTypes = {
    mTitle: PropTypes.string.isRequired,
    mId:PropTypes.string.isRequired,
    mClassName:PropTypes.string,
    setClassName:PropTypes.string,
    showLocaleCode:PropTypes.bool,
  };

  static defaultProps = {
    setClassName:null,
    mClassName:null,
    showLocaleCode:true
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  async handleChange(value) {
    const { setLocale, currentLocale,mClassName } = this.props;
    const isRtlLocale = value && isRTL(value) || false;
    // Adding Bootstrap RTL style link

    
    if (isRtlLocale) {
      // Add "rtl" class name to the body element
      document.body.classList.add(`rtl`);
    } else if (isRTL(currentLocale) && !isRtlLocale) {
      // Remove RTL stylesheet Link element
      let rtlStyleElement = document.getElementById('rtl-style');
      rtlStyleElement.parentNode.removeChild(rtlStyleElement);
      // Remove the RTL classname from the body element
      let bodyElement = document.body;
      bodyElement.className = bodyElement.className.replace(/\brtl\b/g, "");
    }

    // Update the locale
    setLocale({ locale: value });
    // Update Moment locale
    moment.defineLocale(value + '-dup', {
      parentLocale: isRTL(value) ? value : 'en',
      preparse: function (string) {
        return string;
      },
      postformat: function (string) {
        return string;
      }
    });
  }

  render() {
    const { currentLocale,setClassName,showLocaleCode,mClassName, availableLocales,mTitle,mId } = this.props;
    let currentLocaleShort = currentLocale == 'en-US' ? 'en' : currentLocale;
    const navDropdownTitle = (
        <div className={cx(s.itemSelectLanguage)}>
          {currentLocale =='en-US' && <img src={flagEN} className={cx(s.itemFlagIcon)}/>}
          {currentLocale =='es' && <img src={flagES} className={cx(s.itemFlagIcon)}/>}
          {showLocaleCode && <span className={cx(s.itemLanguageLabel,s.itemUpperCase,setClassName)}>{currentLocaleShort}</span>}
        </div>
    );
    
    const navDropdownTitleSm = (
    <div className={cx(s.itemSelectLanguage)}>
      {currentLocale =='en-US' && <img src={flagEN} className={cx(s.itemFlagIcon)}/>}
      {currentLocale =='es' && <img src={flagES} className={cx(s.itemFlagIcon)}/>}
      {showLocaleCode && <span className={cx(s.itemLanguageLabel,s.itemUpperCase,setClassName)}>{currentLocaleShort}</span>}
    </div>
  );

    return(
      <>
      <NavDropdown
        title={navDropdownTitle}
        id={mId}
        className={cx('dropDownLanguageLocale',mClassName,setClassName)}
      >
          <MenuItem eventKey="1" className={cx(s.dpItemLanguage,currentLocale=='en-US'?s.dpItemLanguageActive:'')} onClick={() => this.handleChange('en-US')} >
            <div className={cx(s.itemSelectLanguage,s.itemSelectLanguageSpace)}>
              <img src={flagEN} className={cx(s.itemFlagIcon)}/><span className={cx(s.itemLanguageLabel,s.itemLanguageLabelDark)}>English</span>
            </div>
          </MenuItem>
          <MenuItem eventKey="2" className={cx(s.dpItemLanguage,currentLocale=='es'?s.dpItemLanguageActive:'')} onClick={() => this.handleChange('es')}>
            <div className={cx(s.itemSelectLanguage,s.itemSelectLanguageSpace)}>
              <img src={flagES} className={cx(s.itemFlagIcon)}/><span className={cx(s.itemLanguageLabel,s.itemLanguageLabelDark)}>Español</span>
            </div>
          </MenuItem>
      </NavDropdown>
    </>
    )
   
  }
}

const mapState = state => ({
  availableLocales: state.runtime.availableLocales,
  currentLocale: state.intl.locale
});

const mapDispatch = {
  setLocale
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(DropDownLanguage)));
