import fetch from '../fetch';


export async function agreementDoRender(userId){
    const respRenderAgreement = await fetch('/export-agreement-data/?search='+userId, {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
      });
    
      
    const { redirect, status, errorMessage } = await respRenderAgreement.json();
    if (status == 200 && redirect) {
        window.location = redirect;
    }

    return await {
        status,
        errorMessage,
    }
}

export async function createSetViaFirmaOwner(userId){
    const responseVFOwner = await fetch('/viafirma-create-owner-agreement/?userid='+userId, {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
      });

    return await responseVFOwner.json();
}

export async function createSetViaFirmaApp(bookingId,bodyJson){
    const bodyVFCB = {boptions:JSON.stringify(bodyJson)};
    const responseVFApp = await fetch('/viafirma-create-booking-agreement/?bookingId='+bookingId, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyVFCB),
        credentials: 'include',
      });
    
      return await responseVFApp.json();
}

export async function contractBookDoRender(userId){
    const respContract = await fetch('/export-contract-book/?search='+userId, {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
      });
    
      
    const { redirect, status, errorMessage } = await respContract.json();
    if (status == 200 && redirect) {
        window.location = redirect;
    }

    return await {
        status,
        errorMessage,
    }
}