import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
// import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { Field, reduxForm,change, getFormSyncErrors } from 'redux-form';

import submit from './submit';
import validate from './validate';

// Locale
import messages from '../../locale/messages';

// Redux
import { connect } from 'react-redux';

// Query
// import userAccountQuery from './userAccountQuery.graphql';

// Components
import Loader from '../../components/Loader';
import CustomCheckbox from '../CustomCheckbox';
// import ShowNotification from '../ShowNotification/ShowNotification';
import CommonFormComponent from '../CommonField/CommonFormComponent';


import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './PageTerms.css';
import cs from '../../components/commonStyle.css';
import {
  Button,
  Form,
  FormGroup,
  FormControl,
  InputGroup
} from 'react-bootstrap';

// Images
import arrow from '/public/siteImages/whiteArrow.svg';

const onSubmitFail = (errors) => {
  if (errors) {
    let section = Object.keys(errors);
    if (section && section.length > 0)
      document.getElementById(section[0]).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }
};

class PageTerms extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired,
    isTermnsStatus: PropTypes.bool,
    account: PropTypes.shape({
			userId: PropTypes.string.isRequired,
			email: PropTypes.string.isRequired,
			picture: PropTypes.string,
			displayName: PropTypes.string.isRequired,
			firstName: PropTypes.string.isRequired,
			verification: PropTypes.shape({
				isEmailConfirmed: PropTypes.bool.isRequired,
				isTermnsAccepted: PropTypes.bool.isRequired
			})
		}),
  };

  static defaultProps = {
    isTermnsStatus:false,
    account: {
			email: null,
			displayName: null,
			firstName: null,
			picture: null,
			verification: {
				isEmailConfirmed: false,
        isTermnsAccepted: false
			}
		},
  }

  constructor(props) {
    super(props);
    // this.state={
    //     isHavingError:false,
    //     emailTo:null
    // }
    // this.handleChange = this.handleChange.bind(this);
    // this.handleShowError = this.handleShowError.bind(this);
    // this.handleHideError = this.handleHideError.bind(this);
  }

  checkboxGroup = ({ input, meta: { touched, error }, children, className, selectClass, name }) => {
    return (
      <div className={cx(s.checkBoxGrid, cs.paddingBottom4)}>
        <CustomCheckbox
          name={name}
          className={cx('icheckbox_square-green',s.icheckboxWithBorder)}
          checked={input.value == true}
          onChange={event => {
            return input.onChange(event);
          }}
        />
        <p className={cx(cs.commonMediumText, cs.fontWeightNormal, s.checkBoxText, 'signUpCheckBoxTextRTl')}>
          <FormattedMessage {...messages.iAgreeContract} />
          {/* <a href={"/privacy"} target={'blank'} className={cx(cs.siteLinkColor, cs.textTransformLowerCase)}>&nbsp;<FormattedMessage {...messages.termsOfService} />&nbsp;</a>
          <FormattedMessage {...messages.and} />
          <span>&nbsp;<a href={"/privacy"} target={'blank'} className={cx(cs.siteLinkColor, cs.textTransformLowerCase)}><FormattedMessage {...messages.privacyPolicy} /></a>&nbsp;</span> */}
        </p>
      </div>
    )
  }
  render() {
    const { title, html } = this.props;
    const { formatMessage } = this.props.intl;
    const { loading, isLoaded , error, handleSubmit, submitting,type, account } = this.props;
    const { account:{ verification:{ isTermnsAccepted }}}= this.props;
    let isTermnsAcceptedCheck = isTermnsAccepted == 1 ? true: false; 

    // console.log('account',isTermnsAccepted);
    
    let addClass = 'ql-editor frontend';
    return (
      <div className={s.root}>
        <div className={s.container}>
          <h1>{title}</h1>
          <div className={addClass}
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <div className={cx('inputFocusColor',s.containerTermsService, cs.commonBorderSection, 'whiteBgColor')}>
            <Loader type={"pagefixed"} show={submitting} />
            <Form onSubmit={handleSubmit(submit)}>
              <div className={s.displayGrid}>
                {!isTermnsAcceptedCheck && <Field name="isTermnsAccepted" component={this.checkboxGroup} />}

                {!isTermnsAcceptedCheck && <Button
                    className={cx(cs.btnPrimary, cs.spaceBottom2)}
                    bsSize="large"
                    block type="submit"
                    disabled={submitting}
                >
                    
                    {formatMessage(messages.iAcceptAgreement)}
                    <img src={arrow} className={cx(cs.blueLeftArrow, 'loginArrowRTL')} />
                </Button>}
              </div>
            </Form>
        </div>
        </div>
      </div>
    );
  }
}

PageTerms = reduxForm({
  form: 'PageTerms', // a unique name for this form
  validate,
  onSubmitFail
})(PageTerms);

const mapState = state => ({
  account: state.account.data,
});

// const mapProps = {};
const mapDispatch = {
  change
};
export default injectIntl(
  // graphql(updateClaimRefundMutation, { name: 'updateClaimRefund' })
  (withStyles(s, cs)(connect(mapState, mapDispatch)(PageTerms))
));

// export default 
// injectIntl(
// (withStyles(s,cs)
// (connect(mapState, mapDispatch)
// (
//   (PageTerms));
