import React from 'react';
import { Table, FormControl } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cs from '../../components/commonStyle.css';
import Link from '../Link/Link';
// Locale
import messages from '../../locale/messages';

//Images
import tickIcon from '../../../public/SiteIcons/adminPlusIcon.svg';
import csvIcon from '../../../public/AdminIcons/uploadCSV.svg';

interface CommonTableProps {
    thead: () => Array<{ data: string }>;
    tbody: () => Array<{
        id: string | number;
        data: Array<{ data: React.ReactNode }>;
        setClass?: string;
    }>;
    title: string;
    onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isSearch?: boolean;
    isLink?: boolean;
    isExport?: boolean;
    exportLink?: string;
    redirectionLabel?: string;
    href?: string;
    addAction?: () => void;
    className?: string;
    isHeadingHide?: boolean;
}

const CommonTable: React.FC<CommonTableProps> = ({
    thead,
    tbody,
    title,
    onSearch,
    isSearch,
    isLink,
    isExport,
    exportLink,
    redirectionLabel,
    href,
    addAction,
    className,
    isHeadingHide
}) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const theadData = thead();
    const tbodyData = tbody();

    return (
        <div>
            {!isHeadingHide && <h1 className={cx(cs.commonTotalText, cs.spaceBottom5, cs.fontWeightBold)}>{title}</h1>}
            <div className={cx(cs.dFlexContainer, cs.mobileTableSearch, cs.flexEnd, 'mobileTableSearchRTL')}>
                <div>
                    {isSearch && (
                        <FormControl
                            type="text" 
                            placeholder={formatMessage(messages.search)}
                            onChange={(e) => onSearch && onSearch(e as unknown as React.ChangeEvent<HTMLInputElement>)}
                            className={cx('searchInputControl', 'searchInputControlWidth')}
                        />
                    )}
                </div>
                <div>
                    {isLink && href && (
                        <Link to={href} className={cx(cs.dFlex, cs.spaceBottom5, cs.commonLinkBorder, cs.noTextDecration, cs.commonContentText, cs.fontWeightMedium, cs.siteLinkColor, cs.textNoWrap)}>
                            <img src={tickIcon} className={cs.csvImageSpace} alt="Add" /> {redirectionLabel}
                        </Link>
                    )}
                    {isLink && addAction && (
                        <Link noLink onClick={addAction} className={cx(cs.dFlex, cs.spaceBottom5, cs.commonLinkBorder, cs.noTextDecration, cs.commonContentText, cs.fontWeightMedium, cs.siteLinkColor, cs.textNoWrap)}>
                            <img src={tickIcon} className={cs.csvImageSpace} alt="Add" /> {redirectionLabel}
                        </Link>
                    )}
                    {tbodyData?.length > 0 && isExport && (
                        <a href={exportLink} className={cx(cs.dFlex, cs.spaceBottom5, cs.commonLinkBorder, cs.noTextDecration, cs.commonContentText, cs.fontWeightMedium, cs.siteLinkColor)}>
                            <FormattedMessage {...messages.exportDataIntoCSV} />
                            <img src={csvIcon} className={cs.csvImageSpace} alt="Export" />
                        </a>
                    )}
                </div>
            </div>
            <div className={cx('tableFixHead', 'tableSelect', className)}>
                <Table responsive>
                    <thead>
                        <tr>
                            {theadData?.map((item, index) => (
                                <th scope="col" key={index}>{item.data}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tbodyData && tbodyData.length > 0 ? (
                            tbodyData.map((item) => (
                                <tr key={item.id} className={item.setClass || undefined}>
                                    {item.data.map((value, index) => (
                                        <td data-label={theadData[index].data} key={index}>
                                            {value.data}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={theadData?.length} className={'noRecordsText'}>
                                    <FormattedMessage {...messages.noRecordFound} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default withStyles(cs)(CommonTable);