import React from "react";
import AdminPrivileges from "../../../components/siteadmin/adminPrivilegesTable/AdminPrivilages";




export interface PrivilegesManagmentProps {
    privileges: Array<{
        id: string;
        privilege: string;
        permittedUrls: Array<string>;
    }> | undefined;
}

const PrivilegesManagment: React.FC<PrivilegesManagmentProps> = ({ privileges }) => {
    return (
        <AdminPrivileges
            // @ts-ignore
            privileges={privileges}
        />
    )
}

export default PrivilegesManagment;


