import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { FcBusinessman, FcGoogle } from "react-icons/fc";
import history from '../../core/history';

import AccountItem from './AccountItem';
import NoItem from './NoItem';
import Link from '../Link/Link';
import messages from '../../locale/messages';

import emailIcon from '/public/siteImages/emailVerify.svg';
import driverLicense from '/public/siteImages/driverLicense.svg';
import idPassport from '/public/siteImages/idPassport.svg';
import phoneCall from '/public/siteImages/phoneNumberI.svg';
import arrow from '/public/siteImages/rightSideArrow.svg';

import s from './VerifiedInfo.css';
import cs from '../../components/commonStyle.css';

class VerifiedInfoList extends React.Component {
    static propTypes = {
        items: PropTypes.shape({
            isEmailConfirmed: PropTypes.bool.isRequired,
            isPhoneVerified: PropTypes.bool,
            isFacebookConnected: PropTypes.bool.isRequired,
            isGoogleConnected: PropTypes.bool.isRequired,
            isLicenseVerified: PropTypes.bool,
            isIdVerification: PropTypes.bool,
            isPhotoVerified: PropTypes.bool,
        }),
        account: PropTypes.shape({
            picture: PropTypes.string.isRequired,
        }),
        isLoggedInUser: PropTypes.bool.isRequired,
        isTrustVerfiedPage: PropTypes.bool,
        isUser: PropTypes.bool,
    };

    static defaultProps = {
        items: {
            isEmailConfirmed: false,
            isPhoneVerified: false,
            isFacebookConnected: false,
            isGoogleConnected: false,
            isLicenseVerified: false,
            isIdVerification: false,
            isPhotoVerified: false
        }
    }

    goToVerification = (path) => {
        history.push(path);
    }

    renderVerificationItem = (icon, isConfirmed, messageName, onClick) => (
        <div onClick={onClick} className={cx(s.displayGrid, cs.paddingBottom2)}>
            {icon}
            <AccountItem 
                isConfirmed={isConfirmed} 
                itemName={this.props.intl.formatMessage(messages[messageName])} 
            />
        </div>
    )

    render() {
        const { items, isLoggedInUser, isTrustVerfiedPage, isUser, account } = this.props;
        // const { formatMessage } = this.props.intl;

        if (!items) return null;

        const count = [
            items.isEmailConfirmed,
            items.isGoogleConnected,
            items.isIdVerification === 1
        ].filter(Boolean).length;

        return (
            <>
                {!account?.picture && this.renderVerificationItem(
                    <FcBusinessman size={'30px'} className={cx(cs.iconVerification)}/>,
                    items.isPhotoVerified,
                    'userPhotoConnected',
                    () => this.goToVerification('/user/verification')
                )}
                
                {!items.isEmailConfirmed && !items.isGoogleConnected && this.renderVerificationItem(
                    <img src={emailIcon} className={s.emailTop} />,
                    false,
                    'userEmailConnected',
                    () => this.goToVerification('/user/verification')
                )}

                {items.isPhoneVerified && this.renderVerificationItem(
                    <FcGoogle size={'30px'} className={cx(cs.iconVerification, s.iconSize30)} />,
                    items.isGoogleConnected,
                    'userGoogle'
                )}
                
                {this.renderVerificationItem(
                    <img src={idPassport} className={s.idPassportIcon} />,
                    items.isIdVerification,
                    'userIDPassport',
                    () => this.goToVerification('/document-verification/2/')
                )}
                
                {this.renderVerificationItem(
                    <img src={driverLicense} className={s.idLicenseIcon} />,
                    items.isLicenseVerified,
                    'userDriverLicense',
                    () => this.goToVerification('/document-verification/3/')
                )}
                
                {this.renderVerificationItem(
                    <img src={phoneCall} className={s.phoneIcon} />,
                    items.isPhoneVerified,
                    'userPhoneNumberConnected',
                    () => this.goToVerification('/user/verification')
                )}
                
                {!items.isEmailConfirmed && !items.isGoogleConnected && items.isIdVerification !== 1 && isUser && 
                    <NoItem isLoggedInUser={isLoggedInUser} isTrustVerfiedPage={isTrustVerfiedPage} />
                }
                
                {isLoggedInUser && !isTrustVerfiedPage && count > 0 && count <= 3 && isUser &&
                    <Link to="/user/verification" className={cx(cs.commonContentText, cs.siteLinkColor, cs.fontWeightMedium, cs.commomLinkborderBottom, cs.textDecorationNone)}>
                        <FormattedMessage {...messages.moreVerifications2} />
                        <img src={arrow} className={cx(cs.blueLeftArrow, 'loginArrowRTL')} />
                    </Link>
                }
            </>
        );
    }
}

export default injectIntl(withStyles(s, cs)(VerifiedInfoList));