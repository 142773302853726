// General
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button,Row, Col,Modal,ModalHeader,ModalTitle,ModalBody,ModalFooter} from 'react-bootstrap';
// import { checkImagesBlur,getImageLightness } from '../../helpers/imagesDetection/imagesDetectHelper';
import Webcam from "react-webcam";

// Redux
import { createReservationPhoto } from '../../actions/manageListPhotosLibrary';

// Asset
import defaultPic from '/public/siteImages/defaultPic.jpg';
import carFrontSide from '/public/carfrontside.png';
import imgVehicle from '/public/siteImages/car_sideview/driver_front_door.svg';
import Arrow from '/public/siteImages/rightSideArrow.svg';
import { MdOutlinePhotoCamera } from "react-icons/md";

import cx from 'classnames';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './CarTakePhotoTab.css';
import cs from '../../components/commonStyle.css';
import { genUID } from '../../helpers/admGetDataField';

// Locale
import messages from '../../locale/messages';
// Component 
import Link from '../Link';

class CarTakePhotoTab extends React.Component {
  static propTypes = {
    vehiclePhotoType: PropTypes.number.isRequired,
    createReservationPhoto: PropTypes.any.isRequired,
    formatMessage: PropTypes.any,
    photoList: PropTypes.array,
    idNumber: PropTypes.number,
    reservationId: PropTypes.number,
    listId: PropTypes.number,
    requirePhotos: PropTypes.number,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    fileType: PropTypes.string,
    className: PropTypes.string,
    pic: PropTypes.bool,
    picTook: PropTypes.string,
    picturesList: PropTypes.array,
    // staticImage: PropTypes.bool
  };

  static defaultProps = {
    // source: null,
    picTook: null,
    pic: false,
    picturesList:[],
    savedPhotos:[],
    photoList:[]
    
  }

  constructor(props) {
    super(props);
    this.state = {
      pic:false,
      picturesList:[],
      screenshot: null,
      isModalOpened:false
    }
    this.showCamera = this.showCamera.bind(this);
    this.takePhoto = this.takePhoto.bind(this);
    this.confirmPhoto = this.confirmPhoto.bind(this);
    this.reTakePhoto = this.reTakePhoto.bind(this);
    this.hideCamera = this.hideCamera.bind(this);
    
    this.showCameraModal = this.showCameraModal.bind(this);
    this.closeCameraModal = this.closeCameraModal.bind(this);
  };
  
  showCamera(){
    this.setState({pic:true});
  }
  
  showCameraModal(){
    this.setState({
      isModalOpened:true,
      pic:true
    });
  }
  closeCameraModal(){
    this.setState({
      isModalOpened:false
    });
  }
  hideCamera(){
    this.setState({
      pic:false,
      picTook:null,
      isModalOpened:false
    });
  }
  async takePhoto(e){
    // const blurryScore  = await Blurriness.getBlurrinessAsync(e);
    // const detector = new BlurryDetector();

    // detector.isImageBlurry(e).then(isBlurry => {
    //     if (isBlurry) {
    //         console.log('The image is blurred!');
    //     } else {
    //         console.log('The image is sharp!');
    //     }
    // });
    // console.log('blurryScore',blurryScore);
    this.setState({picTook:e});
    this.setState({pic:false});
  }
  reTakePhoto(e){
    this.setState({
      pic:true,
      picTook:null
    });
  }
  
  async confirmPhoto(e){
    const {picturesList,picTook,savedPhotos}=this.state;
    let addNewPicture = picturesList;  addNewPicture.push(picTook);
    let updateSavedPhotos = savedPhotos;
    
    const filename = genUID(4)+'-'+genUID(5)+'-'+genUID(4);
    const {reservationId,listId,fileType,createReservationPhoto,idNumber,vehiclePhotoType} = this.props; 
    const resp = await fetch('/uploadTripsPhoto', {
        method: "POST", body: JSON.stringify({ file: picTook,filename:filename,fileType:fileType }), credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
    });
    const data = await resp.json();
    updateSavedPhotos.push({
      id: genUID(10),
      name:filename+'.jpg',
      createdAt: new Date(),
      type:fileType,
      photo_type: vehiclePhotoType

    })
    if (data?.status == 'success') {

      const photoReturn = vehiclePhotoType==1 ? 0:1; //vehiclePhotoType--;

      createReservationPhoto(reservationId,listId,filename+'.jpg',fileType,vehiclePhotoType,photoReturn);
      this.setState({savedPhotos:updateSavedPhotos});
      this.setState({picturesList:addNewPicture});
      this.setState({
        picTook:null,
        pic:false,
        isModalOpened:false
      });
      // this.setState({});
    }
    
  }

  async removeImage(image) {
    // const { change, claimImages } = this.props;
    // let updatedClaimImages = [...claimImages];
    // const resp = await fetch('/remove/claim/photos', {
    //     method: "POST", body: JSON.stringify({ filename: image }), credentials: 'include',
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    // });
    // const data = await resp.json();
    // if (data?.status == 200) {
    //     this.setImageLoader(true);
    //     showToaster({ messageId: 'imageRemoved', toasterType: 'success' })
    //     if (Array.isArray(claimImages)) {
    //         let index = claimImages.findIndex(item => item == image);
    //         if (index > -1) updatedClaimImages.splice(index, 1);
    //     }
    //     change('claimImages', updatedClaimImages);
    //     this.setImageLoader(false);
    // }
  }

  componentDidMount() {
		const { photoList,fileType,vehiclePhotoType } = this.props;
    let createSavedPhotos=[];

    if(photoList){
      // savedPhotos != null && savedPhotos.length > 0 && savedPhotos.map((item,key) => {
        photoList.map((item,key) => {
          if(item.type == fileType && item.photo_type == vehiclePhotoType  ){
            createSavedPhotos.push(item);
            console.log('Mounted',item);
          }
        });
    }
    
		this.setState({ savedPhotos: createSavedPhotos });
    
	}

  UNSAFE_componentWillReceiveProps(nextProps) {
		const { pic,photoList,picturesList } = nextProps;
		let savedPhotos = [];

		if (!pic) {
      console.log('update list',photoList);
      // console.log('update list',photoList);
			// rangeStart = new Date(start);
			// dateRange.push(rangeStart);
		// } else if (start && end) {
		// 	rangeStart = new Date(start);
		// 	rangeEnd = new Date(end);

		// 	if (!DateUtils.isSameDay(rangeStart, rangeEnd)) {
		// 		dateRange.push(rangeStart);

		// 		rangeStart = new Date(+rangeStart);

		// 		while (rangeStart < end) {

		// 			dateRange.push(rangeStart);
		// 			var newDate = rangeStart.setDate(rangeStart.getDate() + 1);
		// 			rangeStart = new Date(newDate);
		// 		}
		// 	}
		}else{
      console.log('else update list',photoList);
    }
		
		// this.setState({ savedPhotos });
	}

  render() {
    const {title,subtitle,photoList,fileType,idNumber,requirePhotos,vehiclePhotoType} = this.props;
    
    const {pic, picTook,picturesList,isModalOpened,savedPhotos} = this.state;
    const videoConstraints = { width: 500, height: 500, facingMode: "environment"};
    // let createSavedPictures= photoList;
    return (
      <div className={cx(s.itemTakePhotoUI)}>
        <Row className={cx(s.rowPaddingBottom,s.carTakePhotoBorder)}> 
          <Col lg={12} md={12} xs={12} sm={12} className={cx(cs.textAlignLeft)}>
            <div className={cx(s.containerGridCarTakePhoto)}>
                
                <div className={cx(s.carTakePhotoView)}>
                  {/* <img onClick={this.showCameraModal} src={imgVehicle} className={cx(s.imgCarPhotoSideView)} /> */}
                  <img onClick={this.showCameraModal} className={cx(s.imgCarPhotoSideView)} src={'/images/vehicle_blueprint/SVG/'+fileType+'.svg'} />

                </div>
                <div className={cx(s.carTakePhotoViewInfo)}>
                  <h3 className={cx(s.carTakePhotoViewInfoHeader)}>{title}</h3>
                  <h5 className={cx(s.carTakePhotoViewInfoSubtitle)}>{subtitle}</h5>
                  <p className={cx(s.carTakePhotoViewInfoRequire)}>
                    <FormattedMessage {...messages.carPhotoVerificationRequire} />
                  </p>
                  <p className={cx(s.carTakePhotoViewInfoRequire,s.carTakePhotoViewInfoRequireNotification)}>
                    <FormattedMessage {...messages.carPhotoVerificationRequire2} />
                    {' '+requirePhotos+' '}
                    <FormattedMessage {...messages.carPhotoVerificationRequire3} />
                  </p>
                  <span onClick={this.showCameraModal} className={cx(s.linTakePhoto,cs.siteLinkColor, cs.cursorPointer, cs.textDecorationNone, cs.fontWeightMedium)}>
                    <MdOutlinePhotoCamera size={'30px'} className={cx(cs.iconVerification)}/>
                    <FormattedMessage {...messages.carPhotoClickPhoto} />
                    <img src={Arrow} className={cx(cs.blueLeftArrow, 'loginArrowRTL')} />
                  </span>
                </div>


                {/* <div className={cx(s.bookPhotosAdd,s.rTextAlignLeft)}>
                  {picturesList != null && picturesList.length > 0 && picturesList.map((item,key) => {
                      return (
                      <img key={key} className={cx(s.imgBorder)} src={item} />
                      )
                  })}
                </div> */}
            </div>

          </Col>
          <Col sm={12}>
          <div className={cx(s.containerGridCarPhotos,s.bookPhotoSaved,s.rTextAlignLeft)}>
                  {savedPhotos != null && savedPhotos.length > 0 && savedPhotos.map((item,key) => {
                      return (item.type == fileType && item.photo_type == vehiclePhotoType && <div key={key} className={cx(s.vehicleTookPhotoContent)}>
                            <img  className={cx(s.imgSaved)} src={'/images/trips/small_'+item.name} />
                            <span>{moment(item.createdAt).format('DD-MM-YYYY hh:mm A').toString()}</span>
                          </div>
                      )
                  })}
              </div>
           </Col>
        </Row>
        <Modal key={fileType+'_modal'}  
          dialogClassName="custom-modal"
          bsSize="large" show={isModalOpened} 
          onHide={this.closeCameraModal}>
          <ModalHeader >
            <ModalTitle>
              <FormattedMessage {...messages.carTakeModalHeader} />
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Row className={cx(cs.textAlignCenter)}>
              <Col sm={12}>
                {pic==true && <Webcam
                    id='webcam'
                    className={cx(s.carCameraContent)}
                    audio={false}
                    height={500}
                    screenshotFormat="image/jpeg"
                    width={500}
                    videoConstraints={videoConstraints}
                  >
                    {({ getScreenshot }) => (
                      <Row>
                        <Col sm={12}>
                          <Button
                          className={cx(s.btnDisplayInLine,cs.btnPrimary,cs.spaceTop3, cs.spaceBottom2)}
                            onClick={(e) => {
                              const imageSrc = getScreenshot();
                              this.takePhoto(imageSrc)
                            }}
                          >
                            <FormattedMessage {...messages.CapturePhoto} />
                          </Button>

                          <Button
                            className={cx(s.btnDisplayInLine,cs.btnPrimary,cs.btnCancelTH,cs.spaceTop3, cs.spaceBottom2)}
                            onClick={this.hideCamera}
                          >
                            <FormattedMessage {...messages.cancel} />
                          </Button>
                        </Col>
                      </Row>
                    )}

                  </Webcam>}
                </Col>
            </Row>
            
            {picTook != null &&
              <Row className={cx(cs.textAlignCenter)}>
                <Col sm={12}>
                  <img className={cx(s.carCameraContent)} src={picTook} />
                  <Row>
                    <Button
                      className={cx(s.btnDisplayInLine,cs.btnPrimary,cs.spaceTop3, cs.spaceBottom2)}
                      onClick={this.confirmPhoto}
                    >
                      <FormattedMessage {...messages.confirmPhoto} />
                    </Button>
                    <Button
                      className={cx(s.btnDisplayInLine,cs.btnPrimary,cs.spaceTop3, cs.spaceBottom2)}
                      onClick={this.reTakePhoto}
                    >
                      <FormattedMessage {...messages.reTakePhoto} />
                    </Button>
                    <Button
                      className={cx(s.btnDisplayInLine,cs.btnPrimary,cs.btnCancelTH,cs.spaceTop3, cs.spaceBottom2)}
                      onClick={this.hideCamera}
                    >
                      <FormattedMessage {...messages.cancel} />
                    </Button>
                  </Row>
                </Col>
              </Row>
            }
          </ModalBody>
          {/* <ModalFooter>
            <Button 
              className={cx(s.btnDisplayInLine,cs.btnPrimary,cs.spaceTop3,cs.btnlarge, cs.spaceBottom2)}
              onClick={this.closeCameraModal}>
              <FormattedMessage {...messages.close} />
            </Button>
          </ModalFooter> */}
        </Modal>
      </div>
    );

  }
}

const mapState = (state) => ({
  // maxUploadSize: state.siteSettings.data.maxUploadSize
});

const mapDispatch = {
  createReservationPhoto,
  // removeListPhotos,
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(CarTakePhotoTab)));

