import React from "react";
import AdminLayout from "../../../components/Layout/AdminLayout";
import PolicySettings from './PolicySettings';
import { Store } from "redux";
import { restrictUrls } from "../../../helpers/adminPrivileges";

const title = 'Manage site configurations';

interface StoreState {
  runtime: {
    isAdminAuthenticated: boolean;
    isSuperAdmin: boolean;
  }
  account: {
    privileges: any;
  };
  listSettings: {
    privileges: any;
  };
}

export default async function action({ store }: { store: Store<StoreState> }) {
  let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
  let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
  let privileges = store.getState().listSettings && store.getState().listSettings.privileges;
  let isSuperAdmin = store.getState().account.privileges.isSuperAdmin;

  if (!isAdminAuthenticated) {
    return { redirect: '/siteadmin/login' };
  }

  // Allow access if user is superadmin or has the required privilege
  if (!isSuperAdmin && !restrictUrls('/siteadmin/settings/policy', adminPrivileges, privileges)) {
    return { redirect: '/siteadmin' };
  }

  return {
    title,
    component: <AdminLayout><PolicySettings/></AdminLayout>
  }
}
