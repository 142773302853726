import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import ReceiptContainer from './ReceiptContainer';
import { restrictUrls } from '../../../helpers/adminPrivileges';

const title = 'View Receipt';

export default async function action({ store }) {

    // From Redux Store
    const isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    const isSuperAdmin = store.getState().account.privileges.isSuperAdmin;
    const adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
    const privileges = store.getState().listSettings && store.getState().listSettings.privileges;
    const data = store.getState().reservation.data;

    if (!isAdminAuthenticated) {
      return { redirect: '/siteadmin/login' };
    }

    // Admin restriction
    if (!isSuperAdmin && !restrictUrls('/siteadmin/viewreceipt/', adminPrivileges, privileges)) {
      return { redirect: '/siteadmin' };
    }

    if(!data) {
      return { redirect: '/siteadmin/reservations' };
    }

    return {
      title,
      component: <AdminLayout><ReceiptContainer title={title} data={data} /></AdminLayout>,
    };
  };
