import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import { graphql, gql, compose } from 'react-apollo';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './EditBlogDetails.css';
import cp from '../../../components/commonStyle.css';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import submit from './submit';
import validate from './validate';

import getImageListBlogQuery from './getImageListBlogQuery.graphql';

// Style
import {
  Button,
  Row,
  FormGroup,
  Col,
  ControlLabel
} from 'react-bootstrap';
import { url } from '../../../config.js';
import { formatURL } from '../../../helpers/formatURL';
import fetch from '../../../core/fetch';
import Link from '../../Link';

// Translation
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';
import CommonFormComponent from '../../CommonField/CommonFormComponent';
class EditBlogDetails extends React.Component {

  
  constructor(props) {
    super(props)
    if (typeof window !== 'undefined') {
      this.ReactQuill = require('react-quill')
    }
    this.state = { editorHtml: '' } // You can also pass a Quill Delta here
    this.handlePageTitle = this.handlePageTitle.bind(this);

  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    initialValues: PropTypes.object,
    getImageListBlogData: PropTypes.shape({
      loading: PropTypes.bool,
      getImageListBlog: PropTypes.array
    })
  };

  static defaultProps = {
    data: [],
    getImageListBlogData: PropTypes.shape({
      loading: true,
      getImageListBlog:[]
    })
  };

  async handlePageTitle(e) {
    const { change } = this.props;
    if (e.target.value) {
      await change('pageUrl', formatURL(e.target.value));
    } else {
      await change('pageUrl', '');
    }
  }


  renderTinyMCE(field){
    
    let props = Object.assign({}, field);
    delete props.input;
    delete props.meta;

    return <Editor
      {...props}
      value={field.input.content !== '' ? field.input.content : null}
      onBlur={(event, value) => { field.input.onChange(event.target.getContent()) }}
      apiKey='6jj4tsd4nfningjdjpagytilh0tw0y1k4p906reef878kzl9'
      init={{
        height: 500,
        menubar: true,
        plugins: [
          'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
          'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
        ],
        toolbar: 'undo redo | blocks | ' +
          'bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        image_list: props.ImageListBlog,
        
      }}
    />
    
  }

  render() {
    const { error, handleSubmit, submitting, dispatch, initialValues, title, siteName } = this.props;
    const { parentData } = this.props;
    const { getImageListBlogData: { getImageListBlog } } = this.props;
    // const ReactQuill = this.ReactQuill;
    const { formatMessage } = this.props.intl;
    const gobackcss = { padding: '10px' };
    const initialContent = initialValues?.content;
    
    // if (typeof window !== 'undefined' && ReactQuill) {
      return (
        <div className={cx(s.pagecontentWrapper, 'pagecontentWrapperRTL')}>
          <div className={cx(cp.adminContentPadding)}>
            <div className={cx(s.sectionCenter,cp.sectionExpanded)}>
              <div className={cx(cp.textAlignRight, cp.mobileDisplayBlock, 'textAlignLeftRTL', cp.spaceBottom4)}>
                <Link to={"/siteadmin/content-management"} className={cx(cp.siteLinkColor, cp.commonContentText, cp.fontWeightMedium, cp.commomLinkborderBottom, cp.textDecorationNone)}>
                  <FormattedMessage {...messages.goBack} />
                </Link>
              </div>
              <div className={cp.commonAdminBorderSection}>
                <h1 className={s.headerTitle}> <FormattedMessage {...messages.editPageDetails} /></h1>
                <form onSubmit={handleSubmit(submit)}>
                  {error && <strong>{formatMessage(error)}</strong>}
                  <Row className={cx(cp.spaceTop5)}>
                    <Col lg={6} md={6} xs={12} sm={12}>
                      <FormGroup className={s.space3}>
                        <ControlLabel className={cp.labelTextNew}>
                          <FormattedMessage {...messages.metaTitleLabel} />
                        </ControlLabel>
                        <Field name="metaTitle" type="text" component={CommonFormComponent} label={formatMessage(messages.metaTitleLabel)} inputClass={cx(cp.formControlInput)} />
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} xs={12} sm={12}>
                      <FormGroup className={s.space3}>
                      <ControlLabel className={cp.labelTextNew}>
                        <FormattedMessage {...messages.pageTitleLabel} />
                      </ControlLabel>
                      <Field name="pageTitle" type="text" component={CommonFormComponent} label={formatMessage(messages.pageTitleLabel)} onChange={(event) => this.handlePageTitle(event)} inputClass={cx(cp.formControlInput)} />
                    </FormGroup>
                    </Col>
                    <Col className={cx(cp.spaceTop5)} lg={12} md={12} xs={12} sm={12}>
                      <FormGroup className={s.space3}>
                        <ControlLabel className={cp.labelTextNew}>
                          <FormattedMessage {...messages.metaDescriptionLabel} />
                        </ControlLabel>
                        <Field name="metaDescription" componentClass={'textarea'} className={s.textareaInput} component={CommonFormComponent} label={formatMessage(messages.metaDescriptionLabel)} />
                      </FormGroup>
                      </Col>
                  </Row>
                  <Row className={cx(cp.spaceTop5)}>
                    <Col lg={6} md={6} xs={12} sm={12}>
                      <FormGroup className={s.space3}>
                        <ControlLabel className={cp.labelTextNew}>
                          <FormattedMessage {...messages.pageUrl} />
                        </ControlLabel>
                        <Field name="pageUrl" type="text" component={CommonFormComponent} isAddon={true} suffixLabel={`${url}${formatMessage(messages.pageLabel)}`} label={formatMessage(messages.pageUrl)} inputClass={cx(cp.formControlInput, s.addonInputRadius)} />
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} xs={12} sm={12}>
                      <FormGroup className={s.space3}>
                      <ControlLabel className={cp.labelTextNew}>
                        <FormattedMessage {...messages.footerCategoryLabel} />
                      </ControlLabel>
                      <Field name="footerCategory" className={s.formControlSelect} component={CommonFormComponent} label={formatMessage(messages.footerCategoryLabel)} inputClass={cp.formControlSelect}>
                        <option value="">{formatMessage(messages.ChooseFooterCategory)}</option>
                        <option value={siteName}>{siteName}</option>
                        <option value="discover">{formatMessage(messages.discover)}</option>
                        <option value="hosting">{formatMessage(messages.hosting)}</option>
                      </Field>
                    </FormGroup>
                    </Col>
                  </Row>
                  

                  <FormGroup className={s.space3}>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <label className={cp.labelTextNew} ><FormattedMessage {...messages.contentLabel} /></label>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Field
                            component={ this.renderTinyMCE }
                            name="content"
                            autoComplete="off"
                            initialValue={initialContent}
                            ImageListBlog={getImageListBlog}
                        />
                        {/* <Field name="content" component={this.renderQuill} /> */}
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className={s.noMargin}>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} className={cx(cp.textAlignRight, 'textAlignLeftRTL')}>
                        <Button bsSize="small" className={cx(cp.btnPrimary, cp.btnlarge)} type="submit" disabled={submitting} ><FormattedMessage {...messages.save} /></Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    // } else {
    //   return <textarea />;
    // }
  }

}

EditBlogDetails = reduxForm({
  form: 'BlogDetails', // a unique name for this form
  validate
})(EditBlogDetails);


const blogFormSelector = formValueSelector('BlogDetails');

const mapState = (state) => ({
  pageTitle: blogFormSelector(state, 'pageTitle'),
  parentPage: blogFormSelector(state, 'parentPage'),
  siteName: state.siteSettings.data.siteName,
});

const mapDispatch = {
  change
};

export default compose( 
  injectIntl,
  withStyles(s, cp),
  connect(mapState, mapDispatch),
  graphql(getImageListBlogQuery, {
    name: 'getImageListBlogData',
    options: {
    ssr: false,
    fetchPolicy: 'network-only'
    }
  }),
)(EditBlogDetails);