import 'dotenv/config';
/* eslint-disable max-len */
export const appEnv = process.env.NODE_ENV || 'development';
export const port = process.env.PORT || 3000;
export const host = process.env.WEBSITE_HOSTNAME || `localhost:${port}`;
export const url = typeof window !== 'undefined' ? window.location.origin : process.env.URL || 'http://localhost:3001';
export const sitename = 'MagnetDo ver. 2.0.1';

export const locales = ['en-US','es']; //, 'es', 'it-IT', 'fr-FR', 'pt-PT', 'ar'

export const databaseUrl = process.env.DATABASE_URL;

export const fileuploadDir = process.env.FILEUPLOAD_DIR || './images/upload/';

export const homelogouploadDir = process.env.LOGOUPLOAD_DIR || './images/homeLogo/';

export const banneruploadDir = process.env.BANNER_UPLOAD_DIR || './images/banner/';
export const getBanneruploadDir = process.env.GET_BANNER_UPLOAD_DIR || '/images/banner/';

export const profilePhotouploadDir = process.env.PROFILE_PHOTO_UPLOAD_DIR || './images/avatar/';

export const documentuploadDir = process.env.FILEUPLOAD_DOC_DIR || './images/document/';

export const locationuploadDir = process.env.LOCATION_UPLOAD_DIR || './images/popularLocation/';

export const getPopularLocationDir = process.env.GET_LOCATION_DIR || '/images/popularLocation/';

export const homebanneruploadDir = process.env.HOME_BANNER_UPLOAD_DIR || './images/home/';
export const getHomebanneruploadDir = process.env.GET_HOME_PAGE_BANNER_DIR || '/images/home/';

export const claimImagesUploadDir = process.env.CLAIM_IMAGES_UPLOAD_DIR || './images/claims/';
export const claimImageDir = process.env.CLAIM_IMAGE_DIR || '/images/claims/';

export const faviconUploadDir = process.env.FAVICON_UPLOAD_DIR || './images/favicon/';
export const getFavIconDir = process.env.GET_FAVICON_DIR || '/images/favicon/';

export const whyHostUploadDir = process.env.WHYHOST_UPLOAD_DIR || './images/whyhost/';
export const getWhyHostDir = process.env.GET_WHYHOST_DIR || '/images/whyhost/';

export const logouploadDir = process.env.LOGOUPLOAD_DIR || './images/logo/';
export const getLogoDir = process.env.GET_LOGO_DIR || '/images/logo/';

export const contractuploadDir = process.env.CONTRACT_UPLOAD_DIR || './storages/contract/pdf/';
export const getContractDir = process.env.GET_CONTRACT_UPLOAD_DIR || '/storages/contract/pdf/';
export const contractTemplateuploadDir = process.env.CONTRACT_TEMPLATE_UPLOAD_DIR || './storages/contract/templates/';
export const getContractTemplateDir = process.env.GET_CONTRACT_TEMPLATE_UPLOAD_DIR || './storages/contract/templates/';
export const contractTemplateMergeDir = process.env.CONTRACT_CONVERT_UPLOAD_DIR || './storages/contract/gen/';

export const getTripsDir = process.env.GET_TRIPS_UPLOAD_DIR || '/images/trips/';
export const tripsUploadDir = process.env.TRIPS_UPLOAD_DIR || './images/trips/';

export const apiViaFirmaURL = process.env.VIAFIRMA_URL || 'https://sandbox.viafirma.com/documents/api/v3/';
export const apiViaFirmaUser = process.env.VIAFIRMA_USER || 'user-magnet';
export const apiViaFirmaPass = process.env.VIAFIRMA_PASS || 'secret-password';
export const apiViaFirmaCodeGroup = process.env.VIAFIRMA_GROUP_CODE || 'magnet';
export const apiViaFirmaIDGroupOwner = process.env.VIAFIRMA_ID_GROUP_OWNER || 'owner_magnet_001';
export const apiViaFirmaIDGroupApp = process.env.VIAFIRMA_ID_GROUP_APP || 'app_magnet_001';

export const analytics = {
  // https://analytics.google.com/
  google: {
    trackingId: 'G-LMN5WPT3GE',
  },
};

export const googleMapAPI = 'AIzaSyAd0RVGDhy9FJuhP4vZUXb-cmkFTkidHz8';
export const googleMapServerAPI = process.env.GOOGLE_MAP_SERVER_API;

export const payment = {

  paypal: {
    returnURL: `${url}${process.env.PAYPAL_RETURN_URL}`,
    cancelURL: `${url}${process.env.PAYPAL_CANCEL_URL}`,
    redirectURL: {
      success: `${url}${process.env.PAYPAL_SUCCESS_REDIRECT_URL}`,
      cancel: `${url}${process.env.PAYPAL_CANCEL_URL}`
    },
    returnPIURL: `${url}${process.env.PAYPAL_POLICYINSURANCE_RETURN_URL}`,
    cancelPIURL: `${url}${process.env.PAYPAL_POLICYINSURANCE_CANCEL_URL}`,
    redirectPIURL: {
      success: `${url}${process.env.PAYPAL_POLICYINSURANCE_SUCCESS_REDIRECT_URL}`,
      cancel: `${url}${process.env.PAYPAL_POLICYINSURANCE_CANCEL_URL}`
    },
    versions: {
      versionOne: '/v1',
      versionTwo: '/v2'
    },
    token_url: '/oauth2/token',
    payment_url: '/checkout/orders',
    capture_url: '/capture',
    payout_url: '/payments/payouts',
    refund: '/refund',
    refund_capture: '/payments/captures/'
  },

  stripe: {
    secretKey: process.env.STRIPE_SECRET, /* From ENV */
    publishableKey: 'pk_test_C5ukBJM7qr5P1F8dY4XKhdyp'
  }

};

// site key for google recaptcha
export const googleCaptcha = {
  sitekey: '6LfrYp0pAAAAABdcHqhY-FWHBlP5sGMvakSdL2pq'
};

export const auth = {
  jwt: { secret: process.env.JWT_SECRET },

  redirectURL: {
    login: process.env.LOGIN_URL || '/dashboard',
    verification: process.env.LOGIN_URL || '/user/verification',
    userbanned: process.env.USER_BANNED_URL || '/userbanned',
    returnURLDeletedUser: process.env.DELETE_USER_URL || '/userbanned'
  },

  // https://developers.facebook.com/ IT IS REMOVED ON THE FUNCTIONING CODE. 
  facebook: {
    returnURL: process.env.FACEBOOK_CLIENT_URL || `${url}/login/facebook/return`,
  },

  // https://cloud.google.com/console/project
  google: {
    returnURL: process.env.GOOGLE_CLIENT_URL || `${url}/login/google/return`,
  }
};
