exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".VerifiedInfo-displayGrid-1V97m {\n\tdisplay: grid;\n\tgrid-template-columns: 30px auto;\n}\n\n.VerifiedInfo-displayFlex-3UoR5 {\n\tdisplay: -webkit-box;\n\tdisplay: -ms-flexbox;\n\tdisplay: flex;\n\t-webkit-box-align: center;\n\t    -ms-flex-align: center;\n\t        align-items: center;\n\t-webkit-box-pack: justify;\n\t    -ms-flex-pack: justify;\n\t        justify-content: space-between;\n}\n\n.VerifiedInfo-infoPadding-w8nYF {\n\tpadding: 0px 24px 24px 24px;\n}\n\n.VerifiedInfo-tickIcon-1z93x {\n\twidth: 100%;\n\tmax-width: 12px;\n}\n\n.VerifiedInfo-googleIcon-1F7Ji{\n\twidth: 20px;\n}\n\n.VerifiedInfo-fbIcon-1FFtY{\n\twidth: 15px;\n}\n\n.VerifiedInfo-emailTop-1ie9O{\n\tposition: relative;\n\ttop: -2px;\n\twidth: 24px;\n}\n\n.VerifiedInfo-alertWith-1impS{\n\tmax-width: 18px !important;\n}\n\n.VerifiedInfo-phoneIcon-1f5pO{\n\twidth: 30px;\n    margin-left: -5px;\n\tmargin-top: -2px;\n}\n\n.VerifiedInfo-textAlertNotConfirmed-2ctS0{\n\tcolor: #ab1e1e;\n\tcursor: pointer;\n}\n\n.VerifiedInfo-textAlertNotConfirmed-2ctS0:hover{\n\ttext-decoration: underline;\n}\n\n.VerifiedInfo-idPassportIcon-3d6wJ{\n\twidth: 30px;\n    margin-left: -4px;\n    margin-top: -10px;\n}\n\n.VerifiedInfo-idLicenseIcon-3Jd66{\n    width: 28px;\n    margin-left: -4px;\n    margin-top: 0px;\n}\n\n.VerifiedInfo-iconSize30-4-E_W{\n\twidth: 30px;\n    height: 30px;\n}", ""]);

// exports
exports.locals = {
	"displayGrid": "VerifiedInfo-displayGrid-1V97m",
	"displayFlex": "VerifiedInfo-displayFlex-3UoR5",
	"infoPadding": "VerifiedInfo-infoPadding-w8nYF",
	"tickIcon": "VerifiedInfo-tickIcon-1z93x",
	"googleIcon": "VerifiedInfo-googleIcon-1F7Ji",
	"fbIcon": "VerifiedInfo-fbIcon-1FFtY",
	"emailTop": "VerifiedInfo-emailTop-1ie9O",
	"alertWith": "VerifiedInfo-alertWith-1impS",
	"phoneIcon": "VerifiedInfo-phoneIcon-1f5pO",
	"textAlertNotConfirmed": "VerifiedInfo-textAlertNotConfirmed-2ctS0",
	"idPassportIcon": "VerifiedInfo-idPassportIcon-3d6wJ",
	"idLicenseIcon": "VerifiedInfo-idLicenseIcon-3Jd66",
	"iconSize30": "VerifiedInfo-iconSize30-4-E_W"
};