import React from 'react';
import PropTypes from 'prop-types';
import { graphql, gql, compose } from 'react-apollo';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ContractsSettings.css';

// Component
import ContractsSettingsForm from '../../../components/siteadmin/ContractsSettingsForm';
import Loader from '../../../components/Loader';

class ContractsSettings extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      contractsSettings: PropTypes.array,
    })
  };


  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     loading: true
  //   }
  // }

  static defaultProps = {
    data: {
        loading: true
    }
  };
  // componentDidMount() {
  //   const isBrowser = typeof window !== 'undefined';
  //   isBrowser && this.setState({
  //     loading: false
  //   });
  // }


  render() {
    const settingsCollection = {};
    const {  data, data: { loading, contractsSettings }, title } = this.props;
    if (loading) {
      return <Loader type={"text"} />;
    } else {
      contractsSettings.map((item, key) => {
        settingsCollection[item.name] = item.value;
      });
      return <ContractsSettingsForm initialValues={settingsCollection} title={title} />
    }
  }

}

export default compose(
  withStyles(s),
  graphql(gql`
   {
    contractsSettings {
        name
        value
      }
  }
      `),
)(ContractsSettings);
