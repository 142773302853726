import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import ContractsListOwner from './ContractsListOwner';


const title = 'Contracts';

export default async function action({ store }) {

    // From Redux Store
    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;

    let isSuperAdmin = store.getState().runtime.isSuperAdmin

    if (!isAdminAuthenticated || !isSuperAdmin) {
      return { redirect: '/siteadmin/login' };
    }

    return {
      title,
      component: <AdminLayout><ContractsListOwner setContractType={2} title={title} /></AdminLayout>,
    };
  };
