import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../ViewMessage.css';

// Component
import GuestActions from './GuestActions';
import HostActions from './HostActions';

class ActionBlock extends Component {
  static propTypes = {
    threadType: PropTypes.string.isRequired,
    actionType: PropTypes.string.isRequired,
    threadId: PropTypes.number.isRequired,
    listId: PropTypes.number.isRequired,
    contractId: PropTypes.number,
    contractVFStatus: PropTypes.string,
    contractVFLink: PropTypes.string,
    emailHost: PropTypes.string,
    // HostName: PropTypes.string,
    reservationId: PropTypes.number,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    personCapacity: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    hostDisplayName: PropTypes.string.isRequired,
    guestDisplayName: PropTypes.string.isRequired
  };

  static defaultProps = {
    actionType: null
  }

  render() {
    const { threadType, actionType, threadId, listId, startDate, endDate, personCapacity, createdAt,contractId,contractVFStatus,contractVFLink } = this.props;
    const { emailHost,hostDisplayName, guestDisplayName, reservationId, guestEmail, title, listPublishStatus, startTime, endTime } = this.props;

    if (actionType != null) {
      if (threadType === 'owner') {
        return <HostActions
          actionType={actionType}
          threadId={threadId}
          reservationId={reservationId}
          contractId={contractId}
          contractVFStatus={contractVFStatus}
          contractVFLink={contractVFLink}
          threadType={threadType}
          startDate={startDate}
          endDate={endDate}
          personCapacity={personCapacity}
          createdAt={createdAt}
          guestDisplayName={guestDisplayName}
          hostDisplayName={hostDisplayName}
          guestEmail={guestEmail}
          emailHost={emailHost}
          title={title}
          listPublishStatus={listPublishStatus}
          startTime={startTime}
          endTime={endTime}
        />
      } else {
        return <GuestActions
          actionType={actionType}
          threadId={threadId}
          listId={listId}
          startDate={startDate}
          endDate={endDate}
          personCapacity={personCapacity}
          reservationId={reservationId}
          hostDisplayName={hostDisplayName}
          createdAt={createdAt}
          listPublishStatus={listPublishStatus}
          startTime={startTime}
          endTime={endTime}
        />
      }
    } else {
      return <div />
    }
  }
}

export default withStyles(s)(ActionBlock);

