// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';

// Redux
import { connect } from 'react-redux';
// Translation
import { injectIntl, FormattedMessage } from 'react-intl';
// Locale
import messages from '../../locale/messages';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ExistingPage.css';
import cs from '../commonStyle.css';

// query
import userAccountQuery from './userAccountQuery.graphql';
import getListingExtQuery from './getListingExtQuery.graphql';

// actions
// import { loadAccount } from '../../actions/account';

// Component
import Loader from '../Loader';
import ShowNotification from '../ShowNotification';

// Component
import Avatar from '../Avatar';
// Redux action
import { ManagePublishStatus } from '../../actions/Listing/ManagePublishStatus';
//Image
import sayIcon from '/public/SiteIcons/sayIcon.png';
import footerImage from '/public/siteImages/stepFooter.svg';
import stepOne from '/public/SiteIcons/editStepOne.svg';
import arrowIcon from '/public/SiteIcons/stepEditArrow.svg';
import stepTwo from '/public/SiteIcons/editStepTwo.svg';
import tickIcon from '/public/SiteIcons/stepCompletedIcon.svg';
import stepThree from '/public/SiteIcons/editStepThree.svg';
import { BsShieldLock } from "react-icons/bs";
import Link from '../Link/Link';
// import userAccount from '../../data/queries/userAccount';

class ExistingPage1 extends Component {
  static propTypes = {
    userAccountData: PropTypes.shape({
      loading: PropTypes.bool,
      userAccountExt: PropTypes.shape({
        verification: PropTypes.shape({
          isEmailConfirmed: PropTypes.bool,
          isIdVerification: PropTypes.number,
          isIdNumberVerification:PropTypes.number,
          isPhoneVerified:PropTypes.bool,
          isPhotoVerified:PropTypes.number,
        }),
        userProfileData: PropTypes.shape({
          isAgreementSigned: PropTypes.number.isRequired,
          isAgreementStatus: PropTypes.number.isRequired,
          driver_license_verify:PropTypes.number.isRequired,
          identity_verify:PropTypes.number.isRequired,
        })
      })
    }),
    
    guestDisplayName: PropTypes.string,
    listingSteps: PropTypes.shape({
      step1: PropTypes.string.isRequired,
      step2: PropTypes.string.isRequired,
      step3: PropTypes.string.isRequired,
      step4: PropTypes.string.isRequired,
      listing: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isReady: PropTypes.bool.isRequired,
        isPublished: PropTypes.bool.isRequired,
        // listingExt: PropTypes.object,
      }),
      user: PropTypes.shape({
        userBanStatus: PropTypes.number,
      }),
    }),
    nextPage: PropTypes.any.isRequired,
    stepsLoading: PropTypes.bool,
    ManagePublishStatus: PropTypes.any.isRequired,
    publishListLoading: PropTypes.bool,
    
    userData: PropTypes.shape({
      firstName: PropTypes.string.isRequired
    }).isRequired
  };
  static defaultProps = {
    listingSteps: {
      step1: "inactive",
      step2: "inactive",
      step3: "inactive",
      step4: "inactive",
      listing: {
        id: 0,
        isReady: false,
        isPublished: false,
      },
      // listingExt: {
      //   isVerifiedPlateDocid: 0,
      //   isVerifiedPhotos: 0,
      // },
      user: {
        userBanStatus: 0,
      }
    },
    photosCount: 0,
    stepsLoading: false,
    publishListLoading: false,
    userData: {
      firstName: ''
    },
    userAccountData: {
      loading: true,
      userAccountExt: {
        verification:{
          isEmailConfirmed:false,
          isIdVerification:0,
          isIdNumberVerification:0,
          isPhoneVerified:false,
          isPhotoVerified:0
        },
        userProfileData:{
          isAgreementSigned:0,
          isAgreementStatus:0,
          driver_license_verify:0,
          identity_verify:0,
        }
      }
    },
    // ShowListingExtData:{
    //   loading: false,
    //   ShowListingExt:{
    //       isVerifiedPlateDoc:0,
    //       isVerifiedPhotos:0,
    //     }
    // },
  };
  
  constructor(props) {
    super(props);
    this.state = {
      listingNotAllowPublish:true,
      isHavingError:false
    }
    this.handleShowError = this.handleShowError.bind(this);
    this.handleHideError = this.handleHideError.bind(this);
  };
  
  handleShowError(){
    this.setState({isHavingError: true});
  }
  
  handleHideError(){
    this.setState({isHavingError: false});
  }

  render()  {
    const { nextPage,listingSteps,photosCount, stepsLoading, account, publishListLoading, guestDisplayName, userData, listId,initialValues } = this.props;
    
    const { userAccountData, userAccountData: { userAccountExt } } = this.props;
    // const { ShowListingExtData, ShowListingExtData: { ShowListingExt } } = this.props;
    
		
    const { formatMessage } = this.props.intl;
    let isNotificationVisible = true,isNotificationAgreement=true,notAllowToPublish = true,isNotificationPlateDocs=true,isNotificationDriverLicense=true,isNotificationIDPassport=true,isNotificationPhotoProfile=true;
    
    
    if (stepsLoading) {
      return <Loader type={"text"} />
    }
    
    const { listingSteps: { listing: { id, isReady, isPublished, user } } } = this.props;
      
    if (userAccountExt) {
      
      if (userAccountExt.userProfileData.isAgreementSigned == 1 && userAccountExt.userProfileData.isAgreementStatus ==2 ) {
        isNotificationAgreement=false;
      }
      if( userAccountExt.userProfileData.driver_license_verify == 1){
        isNotificationDriverLicense = false;
      }
      if( userAccountExt.userProfileData.identity_verify == 1){
        isNotificationIDPassport = false;
      }
      if( userAccountExt.verification.isPhotoVerified == 1){
        isNotificationPhotoProfile = false;
      }

    }

    if (listingSteps) {
      if(listingSteps.listingExtDT){
        if( listingSteps.listingExtDT.isVerifiedPlateDoc == 1){
          isNotificationPlateDocs = false;
        }
      }
    }

    if( isNotificationAgreement == false && isNotificationPlateDocs == false && isNotificationIDPassport ==false && isNotificationPhotoProfile == false && isNotificationDriverLicense== false){
      isNotificationVisible=false; notAllowToPublish=false;
    }
    let userDelete = user && user.userDeletedAt;
    let isShowButton = false, stepOneCircle = false, stepTwoCircle = false, stepThreeCircle = false, stepFourCircle = false;

    if (!userDelete) {
      isShowButton = true;
    } else {
      isShowButton = false;
    }

    let userBanStatusValue;
    if (user) {
      const { listingSteps: { listing: { user: { userBanStatus } } } } = this.props;
      userBanStatusValue = userBanStatus;
    }
    const { isHavingError } = this.state;
    const { listingSteps: { step1, step2, step3, step4 } } = this.props;
    const { ManagePublishStatus } = this.props;
    let isPhotoAvailable = false;
    if (photosCount > 0) {
      isPhotoAvailable = true;
    }
    // console.log('check listingSteps', listingSteps);
    if (step1 == 'completed') {
      stepOneCircle = true;
    }
    if (step2 == 'completed' && isPhotoAvailable) {
      stepTwoCircle = true;
    }
    if (step3 == 'completed') {
      stepThreeCircle = true;
    }
    if (step4 == 'completed') {
      stepFourCircle = true;
    }

    let isAdmin = false;
    if (!account) {
      isAdmin = true;
    }
    let setHavingError = true;
    
    if(isNotificationPlateDocs == false && isNotificationAgreement == false){
      setHavingError=false;
    }
    
    
    return (

      <div className={cx(s.stepGrid, 'stepGridRTL')}>
        <div className={cx(s.leftBg, cs.textAlignCenter)}>
          <div className={s.userRight}>
            <Avatar
              isUser
              title={guestDisplayName}
              className={s.profileImage}
              width={'120'}
              height={'120'}
            />
          </div>
          <h3 className={cx(cs.commonTitleText, cs.fontWeightMedium, cs.spaceTop3)}><img src={sayIcon} className={s.sayCss} /> <FormattedMessage {...messages.hiText} />, <span className={s.userNameColor}>{!isAdmin && userData.firstName} {isAdmin && <FormattedMessage {...messages.admin} />}!</span></h3>
          <p className={cx(cs.commonTotalText, cs.spaceTop3, cs.spaceBottom6)}><FormattedMessage {...messages.onboardText} /></p>
          <div className={cx(s.containerBGWhiteMid)}>
            
            <ShowNotification 
              handleHideError={this.handleHideError}
              handleShowError={this.handleShowError}
              showHeader={true}
              showSubTitle={true}
              showAgreement={true}
              showVehicleDoc={true}
              showIDIdentification={true}
              showDriverLicense={true}
              listingPlateDoc={isNotificationPlateDocs}
              listingID={listId}
              />
          </div>
          <img src={footerImage} className={s.stepFooterImage} />
        </div>
        <div className={s.rightPadding}>
          <div className={cx(cs.commonSubTitleText, cs.fontWeightMedium, cs.paddingBottom2)}><FormattedMessage {...messages.step1HeadingNew} /></div>
          <div className={cx(s.contentSection, cs.spaceBottom5, 'contentSectionRTL', cs.spaceTop3)}>
            <div className={s.flexCenter}>
              <div className={s.imgSection}>
                <img src={stepOne} />
                {step1 == "completed" && <img src={tickIcon} className={cx(s.tickPosition, 'tickPositionStepRTL')} />}
              </div>
              <div className={cx(s.contentLeft, 'contentLeftStepRTL')}>
                <h3 className={cx(cs.spaceBottom1, cs.commonContentText, cs.fontWeightMedium)}><FormattedMessage {...messages.step1SubHeading} /></h3>
                <p className={cx(cs.commonMediumText)}><FormattedMessage {...messages.step1HeadingContent} /></p>
              </div>
            </div>
            {
              step1 == "active" && <Link to={`/become-a-owner/${listId}/map`} className={cx(s.modalCaptionLink, 'modalCaptionLinkStepRTL')}>
                <FormattedMessage {...messages.continue} /> <img src={arrowIcon} className={'stepEditArrow'} />
              </Link>
            }
            {
              step1 == "completed" && <Link to={`/become-a-owner/${listId}/car`} className={cx(s.modalCaptionLink, 'modalCaptionLinkStepRTL')}>
                <FormattedMessage {...messages.editLabel} /> <img src={arrowIcon} className={'stepEditArrow'} />
              </Link>
            }
          </div>
          <div className={cx(cs.commonSubTitleText, cs.fontWeightMedium, cs.paddingBottom2)}><FormattedMessage {...messages.step2Heading} /></div>
          <div className={cx(s.contentSection, cs.spaceBottom5, 'contentSectionRTL', cs.spaceTop3)}>
            <div className={s.flexCenter}>
              <div className={s.imgSection}>
                {step2 == "completed" && isPhotoAvailable && <img src={tickIcon} className={cx(s.tickPosition, 'tickPositionStepRTL')} />}
                <img src={stepTwo} />
              </div>
              <div className={cx(s.contentLeft, 'contentLeftStepRTL')}>
                <h3 className={cx(cs.spaceBottom1, cs.commonContentText, cs.fontWeightMedium)}><FormattedMessage {...messages.step2SubHeading} /></h3>
                <p className={cx(cs.commonMediumText)}><FormattedMessage {...messages.step2HeadingContent} /></p>
              </div>
            </div>
            {
              step2 == "active" && <Link to={`/become-a-owner/${listId}/photos`} className={cx(s.button, s.modalCaptionLink, 'modalCaptionLinkStepRTL')} >
                <FormattedMessage {...messages.continue} /> <img src={arrowIcon} className={'stepEditArrow'} />
              </Link>
            }
            {
              step2 == "completed" && !isPhotoAvailable && <Link to={`/become-a-owner/${listId}/photos`} className={cx(s.button, s.modalCaptionLink, 'modalCaptionLinkStepRTL')} >
                <FormattedMessage {...messages.continue} /> <img src={arrowIcon} className={'stepEditArrow'} />
              </Link>
            }
            {
              step2 == "completed" && isPhotoAvailable && <Link to={`/become-a-owner/${listId}/photos`} className={cx(s.modalCaptionLink, 'modalCaptionLinkStepRTL')} >
                <FormattedMessage {...messages.editLabel} /> <img src={arrowIcon} className={'stepEditArrow'} />
              </Link>
            }
          </div>
          <div className={cx(cs.commonSubTitleText, cs.fontWeightMedium, cs.paddingBottom2)}><FormattedMessage {...messages.step3Heading} /></div>
          <div className={cx(s.contentSection, cs.spaceBottom5, 'contentSectionRTL', cs.spaceTop3)}>
            <div className={s.flexCenter}>
              <div className={s.imgSection}>
                <img src={stepThree} />
                {step3 == "completed" && <img src={tickIcon} className={cx(s.tickPosition, 'tickPositionStepRTL')} />}
              </div>
              <div className={cx(s.contentLeft, 'contentLeftStepRTL')}>
                <h3 className={cx(cs.spaceBottom1, cs.commonContentText, cs.fontWeightMedium)}><FormattedMessage {...messages.step3SubHeading} /></h3>
                <p className={cx(cs.commonMediumText)}><FormattedMessage {...messages.step3HeadingContent} /></p>
              </div>
            </div>
            {
              step3 == "active" && <Link to={`/become-a-owner/${listId}/car-rules`} className={cx(s.modalCaptionLink, 'modalCaptionLinkStepRTL')} >
                <FormattedMessage {...messages.continue} /> <img src={arrowIcon} className={'stepEditArrow'} />
              </Link>
            }
            {
              step3 == "completed" && <Link to={`/become-a-owner/${listId}/car-rules`} className={cx(s.modalCaptionLink, 'modalCaptionLinkStepRTL')} >
                <FormattedMessage {...messages.editLabel} /> <img src={arrowIcon} className={'stepEditArrow'} />
              </Link>
            }
          </div>
          {/*  Step 4 */}
          <div className={cx(cs.commonSubTitleText, cs.fontWeightMedium, cs.paddingBottom2)}><FormattedMessage {...messages.step4Heading} /></div>
          <div className={cx(s.contentSection, cs.spaceBottom5, 'contentSectionRTL', cs.spaceTop3)}>
            <div className={s.flexCenter}>
              <div className={s.imgSection}>
                <BsShieldLock size={'34px'} />
                {step4 == "completed" && <img src={tickIcon} className={cx(s.tickPosition, 'tickPositionStepRTL')} />}
              </div>
              <div className={cx(s.contentLeft, 'contentLeftStepRTL')}>
                <h3 className={cx(cs.spaceBottom1, cs.commonContentText, cs.fontWeightMedium)}><FormattedMessage {...messages.step4SubHeading} /></h3>
                <p className={cx(cs.commonMediumText)}><FormattedMessage {...messages.step4HeadingContent} /></p>
              </div>
            </div>
            {
              step4 == "active" && <Link to={`/become-a-owner/${listId}/policy-insurance`} className={cx(s.modalCaptionLink, 'modalCaptionLinkStepRTL')} >
                <FormattedMessage {...messages.continue} /> <img src={arrowIcon} className={'stepEditArrow'} />
              </Link>
            }
            {
              step4 == "completed" && <Link to={`/become-a-owner/${listId}/policy-insurance`} className={cx(s.modalCaptionLink, 'modalCaptionLinkStepRTL')} >
                <FormattedMessage {...messages.editLabel} /> <img src={arrowIcon} className={'stepEditArrow'} />
              </Link>
            }
          </div>
          <div className={cx(s.exitFooter, 'exitFooterRTL')}>
            {
              listingSteps && isReady && !isPublished && !userBanStatusValue && isShowButton && <div>
               
               {setHavingError == false && <h3 className={cx(s.mbMargin)}>
                  <FormattedMessage {...messages.readyToPublish} />
                </h3>}
                <div className={s.spaceBetween}>
                  <a target="_blank" href={"/cars/" + id + "/preview"} className={cx(s.modalCaptionLink, 'modalCaptionLinkStepRTL')}>
                    <FormattedMessage {...messages.previewListing} /> <img src={arrowIcon} className={'stepEditArrow'} />
                  </a>
                  <div className={s.displayInline}>
                    <Loader
                      disabled={setHavingError}
                      type={"button"}
                      className={cx(s.btnPrimary)}
                      handleClick={() => ManagePublishStatus(id, 'publish')}
                      show={publishListLoading}
                      label={formatMessage(messages.publishNow)}
                    />
                  </div>

                </div>
              </div>
            }
            {
              listingSteps && isReady && isPublished && !userBanStatusValue && isShowButton && <div>
                <h3 className={cx(s.mbMargin)}>
                  <FormattedMessage {...messages.listingPublished} />
                </h3>
                <div className={s.spaceBetween}>
                  <a target="_blank" href={"/cars/" + id + "/preview"} className={cx(s.modalCaptionLink, 'modalCaptionLinkStepRTL')}><FormattedMessage {...messages.previewListing} /> <img src={arrowIcon} className={'stepEditArrow'} /></a>
                  <div className={s.displayInline}>
                    <Loader
                      type={"button"}
                      className={cx(s.btnPrimary)}
                      handleClick={() => ManagePublishStatus(id, 'unPublish')}
                      show={publishListLoading}
                      label={formatMessage(messages.unPublishNow)}
                    />
                  </div>
                </div>
              </div>
            }


            {
              userBanStatusValue == true && isShowButton && <span className={cs.displayinlineBlock}>
                <a target="_blank" href={"/cars/" + id + "/preview"} className={cx(s.modalCaptionLink, 'modalCaptionLinkStepRTL')}>
                  <FormattedMessage {...messages.previewListing} /> <img src={arrowIcon} className={'stepEditArrow'} />
                </a>
              </span>
            }
          </div>
        </div>
      </div>
    );
  }
}
const mapState = (state) => ({
  listingSteps: state.location.listingSteps,
  stepsLoading: state.location.stepsLoading,
  initialValues: state.account.data,
  account: state.account.data,
  publishListLoading: state.location.publishListLoading,
  userData: state.account.data,
});
const mapDispatch = {
  ManagePublishStatus,
  // loadAccount
};
export default compose(
  injectIntl,
  connect(mapState, mapDispatch),
  withStyles(s, cs),
  graphql(userAccountQuery, {
    name: 'userAccountData',
    options: {
      ssr: false,
      fetchPolicy: 'network-only'
    }
  }),
)(ExistingPage1);
