import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
// import { graphql, gql, compose } from 'react-apollo';

// import Webcam from "react-webcam";
import moment from 'moment';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/lib/Button';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

// Components
import Avatar from '../Avatar';
import ListItem from '../ViewListing/ListingDetails/ListItem';
import ViewMessageQuick from '../ViewMessageQuick/ViewMessageQuick';
import CurrencyConverter from '../CurrencyConverter';
import ListCoverPhoto from '../ListCoverPhoto';
import Link from '../Link';
import ListNotFound from '../../routes/listNotFound/ListNotFound';
import NotFound from '../../routes/notFound/NotFound';
// import CarTakePhoto from '../CarTakePhoto/CarTakePhoto';
import { generateTime } from '../Receipt/helper';
import { checkValue } from '../ViewListing/ListingDetails/helper';
import { formatTime } from '../../helpers/formatting';

//Query
// import getListingLocationQuery from './getListingLocationQuery.graphql';

// Locale
import messages from '../../locale/messages';

//Images
import Arrow from '/public/siteImages/rightSideArrow.svg';
import arrowIcon from '/public/SiteIcons/paymentArrow.svg';
import steeringIcon from '/public/SiteIcons/steeringIcon.svg';
import starIcon from '/public/SiteIcons/star.svg';
import carFrontSide from '/public/carfrontside.png';
import Faq from '../Receipt/question.svg';

import s from './Itinerary.css';
import cs from '../../components/commonStyle.css';

class ItineraryView extends React.Component {
  static propTypes = {
    formatMessage: PropTypes.any,
    // pic: PropTypes.bool,
    // picTook: PropTypes.string,
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      listId: PropTypes.number.isRequired,
      checkIn: PropTypes.string.isRequired,
      checkOut: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
      guestServiceFee: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      idLocation: PropTypes.number.isRequired,
      newDelivery: PropTypes.number.isRequired,
      confirmationCode: PropTypes.number.isRequired,
      reservationState: PropTypes.string.isRequired,
      listData: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        street: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        zipcode: PropTypes.string.isRequired,
        reviewsCount: PropTypes.number.isRequired,
        reviewsStarRating: PropTypes.number,
        listingData: PropTypes.shape({
          checkInStart: PropTypes.string.isRequired,
          checkInEnd: PropTypes.string.isRequired
        }),
        coverPhoto: PropTypes.number,
        listPhotos: PropTypes.arrayOf({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired
        }),
        vehicleData: PropTypes.array,
        houseRules: PropTypes.arrayOf(PropTypes.shape({
          listsettings: PropTypes.shape({
            itemName: PropTypes.string.isRequired,
            isEnable: PropTypes.number,
            settingsType: PropTypes.shape({
              typeName: PropTypes.string,
            })
          })
        })),
      }),
      // listExtDT: PropTypes.array,
      // carFeatures: PropTypes.array,
      deliveryLocation: PropTypes.array,
      contractViaFirmaDT: PropTypes.array,
      // houseRule: PropTypes.array,
      // messageData: PropTypes.shape({
      //   id: PropTypes.number.isRequired
      // }),
      contractData: PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        vfSetCode: PropTypes.string,
        vfSignerLink: PropTypes.string,
        vfSetStatus: PropTypes.string,
        vfRenterLink: PropTypes.string,
      }),
      hostData: PropTypes.shape({
        profileId: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        picture: PropTypes.string.isRequired
      }),
      
    }),
    // getListingLocationData: PropTypes.shape({
    //   getListingLocation: PropTypes.shape({
    //     address: PropTypes.string,
    //     lat: PropTypes.number,
    //     lng: PropTypes.number
    //   })
    // }),
  };

  static defaultProps = {
    data: null,
  };

  
  
  render() {
    const { data } = this.props;
    const { formatMessage } = this.props.intl;
    const { userId } = this.props;
  
    
    if (data === null) {
      return(
        <> 
          <span>{' '}</span>  
          {window.location.reload()}
          </>
      );
      
    //  return <> <FormattedMessage {...messages.errorMessage} />  </>;
    } else if (!data.listData) {
      return <ListNotFound />;
    } else if (data.paymentState !== "completed") {
      return <NotFound />
    } else {
      const { data, data: { startTime, endTime, listTitle, id, listId, checkIn, checkOut, total, guestServiceFee, currency, confirmationCode, reservationState, hostId, guestId, basePrice, delivery, discount, discountType, dayDifference, newDelivery,idLocation,deliveryLocation,contractViaFirmaDT } } = this.props;
      const { data: { contractData: { code,vfSetCode,vfSignerLink,vfSetStatus,vfRenterLink } } } = this.props;
      // const { data: { contractViaFirma: { contractId,reservationId,setCode,status } } } = this.props;
      const { data: { hostData: { profileId, firstName, picture, createdAt } } } = this.props;
      const { data: { listData: { title, description, street, city, state, country, zipcode } } } = this.props;
      const { data: { listData: { coverPhoto, listPhotos, reviewsCount, reviewsStarRating, settingsData,houseRules, transmission,vehicleData } } } = this.props;
      const { data: { listData: { listingData: { checkInStart, checkInEnd } } } } = this.props;
      const { data: {  securityDeposit, bookingSpecialPricing } } = this.props;
      // const { getListingLocationData, getListingLocationData: { getListingLocation } } = this.props;

      let streetDelivery='',urlOpenMap='';
      if(idLocation > 0){
        urlOpenMap = 'https://www.google.com/maps/@'+deliveryLocation[0]?.lat+','+deliveryLocation[0]?.lng+',25z?entry=ttu';
        streetDelivery =deliveryLocation[0]?.address;
      }

      let carType, transmissionLabel, isSpecialPricingAssinged, checkInDate, checkOutDate, formattedStartTime, formattedEndTime;
      let checkInTime, checkOutTime, isAverage = 0, dayPrice = 0, isDayTotal = 0;

      let vehicleType = ""; //listExtDT[0]?.vtype;
      let vehicleInfo = title;  
      let vehiclePlate = ""; //listExtDT[0]?.plate;

      if(vehicleData){
        vehicleType=vehicleData[0]?.vtype;
        vehiclePlate=vehicleData[0]?.plate;
      }
      let setHouseRules=[],isItineraryBlocked=true;
      if(houseRules){
        // console.log('houseRule',houseRule);
        // houseRules = checkValue(houseRule, []);
      }
      if(contractViaFirmaDT){
        // console.log('contractViaFirmaDT',contractViaFirmaDT);
        contractViaFirmaDT && contractViaFirmaDT.map((item, index) => {
          
          if(item.status == 'RESPONSED'){ 
            isItineraryBlocked=false; console.log('contractViaFirmaDT ',item);
           }
        });
      }
      carType = settingsData && settingsData.length > 0 && settingsData[0].listsettings.itemName;
      transmission == '1' ? transmissionLabel = 'Automatic' : transmissionLabel = 'Manual';
      isSpecialPricingAssinged = (bookingSpecialPricing && bookingSpecialPricing.length > 0) ? true : false;
      checkInDate = checkIn ? moment(checkIn).utc().format('ddd, MMM DD, YYYY ') : '';
      checkOutDate = checkOut ? moment(checkOut).utc().format('ddd, MMM DD, YYYY ') : '';

      if (isSpecialPricingAssinged) {
        bookingSpecialPricing && bookingSpecialPricing.map((item, index) => {
          dayPrice = dayPrice + Number(item.isSpecialPrice);
        });
      } else {
        dayPrice = basePrice * dayDifference;
      }


      if (checkInStart === 'Flexible') {
        checkInTime = formatMessage(messages.flexibleCheckIn);
      } else {
        checkInTime = generateTime(checkInStart);
      }

      if (checkInEnd === 'Flexible') {
        checkOutTime = formatMessage(messages.flexibleCheckOut);
      } else {
        checkOutTime = generateTime(checkInEnd);
      }

      let subTotal = basePrice * dayDifference;
      let subTotalWithSecurityDeposit = total + guestServiceFee + securityDeposit;
      let starRatingValue = 0;
      if (reviewsCount > 0 && reviewsStarRating > 0) {
        starRatingValue = Math.round(reviewsStarRating / reviewsCount)
      }

      let isHost = false; let userType='renter';
      if (userId === hostId) {
        isHost = true; userType='owner';
      }

      formattedStartTime = formatTime(startTime);
      formattedEndTime = formatTime(endTime);

      let joinedDate = createdAt != null ? moment(createdAt).format("MMM, YYYY") : '';

      isAverage = Number(dayPrice) / Number(dayDifference);
      isDayTotal = isAverage.toFixed(2) * dayDifference;
      dayPrice = isDayTotal;
      
      
      return (
        <Grid fluid className={s.container}>
          {isItineraryBlocked && <div className={cx(s.itineraryContractContainer)}>
          <Row className={cs.positionRelative}>
              <Col lg={10} sm={12} md={10} xs={12} className={cx(cs.spaceTop5, 'paymentDetailsPadding')}>
                {
                  reservationState === "approved" && <h2 className={cx(cs.commonTitleText, cs.paddingBottom1, cs.fontWeightBold)}>
                    <FormattedMessage {...messages.itinerayWaitingSignature} />
                  </h2>
                }
                <h4 className={cx(cs.commonContentText, cs.fontWeightNormal,cs.spaceTop2)}>
                  <FormattedMessage {...messages.confirmationCode} />{' '}
                  <span>{'#'}{confirmationCode}</span>
                </h4>
                <a href={vfRenterLink} target="_blank"><FormattedMessage {...messages.itinerayLinkSignature} /></a>
                <hr className={cx(cs.listingHorizoltalLine, cs.spaceBottom4, cs.spaceTop4)} />
              
                
                <div className={s.boxSection}>
                  <h4 className={cx(cs.commonTotalText, cs.fontWeightBold, cs.paddingBottom3)}>
                    <FormattedMessage {...messages.tripDetails} />
                  </h4>
                  <div className={s.displayFlexDate}>
                    <h5 className={cx(cs.commonContentText, cs.fontWeightBold)}>
                      <FormattedMessage {...messages.checkIn} />
                      <span className={cx(cs.commonMediumText, cs.fontWeightNormal, cs.displayBlock, cs.paddingBottom1, cs.paddingTop2)}>{checkInDate}</span>
                      <span className={cx(cs.commonMediumText, cs.fontWeightNormal)}>{formattedStartTime}</span>
                    </h5>
                    <img src={arrowIcon} className={cx(cs.dateArrowMargin, 'commonDateArrowRTLRotate')} />
                    <h5 className={cx(cs.commonContentText, cs.fontWeightBold)}>
                      <FormattedMessage {...messages.checkOut} />
                      <span className={cx(cs.commonMediumText, cs.fontWeightNormal, cs.displayBlock, cs.paddingBottom1, cs.paddingTop2)}>{checkOutDate}</span>
                      <span className={cx(cs.commonMediumText, cs.fontWeightNormal)}>{formattedEndTime}</span>
                    </h5>
                  </div>
                  
                </div>
              </Col>
            </Row>
            
          </div>
          }
          {!isItineraryBlocked &&
          <div className={cx(s.itineraryContainer)}>
            <Row className={cs.positionRelative}>
              <Col lg={7} sm={12} md={7} xs={12} className={cx(cs.spaceTop5, 'paymentDetailsPadding')}>
                {
                  reservationState === "approved" && <h2 className={cx(cs.commonTitleText, cs.paddingBottom1, cs.fontWeightBold)}>
                    <FormattedMessage {...messages.itinerayTitle} />
                  </h2>
                }
                {
                  reservationState != "approved" && <h2 className={cx(cs.commonTitleText, cs.paddingBottom1, cs.fontWeightBold)}>
                    <FormattedMessage {...messages.pendingApproval} />
                  </h2>
                }
                <h4 className={cx(cs.commonContentText, cs.fontWeightNormal,cs.spaceTop2)}>
                  <FormattedMessage {...messages.confirmationCode} />{' '}
                  <span>{'#'}{confirmationCode}</span>
                </h4>
                <hr className={cx(cs.listingHorizoltalLine, cs.spaceBottom4, cs.spaceTop4)} />
              
                
                <div className={s.boxSection}>
                  <h4 className={cx(cs.commonTotalText, cs.fontWeightBold, cs.paddingBottom3)}>
                    <FormattedMessage {...messages.tripDetails} />
                  </h4>
                  <div className={s.displayFlexDate}>
                    <h5 className={cx(cs.commonContentText, cs.fontWeightBold)}>
                      <FormattedMessage {...messages.checkIn} />
                      <span className={cx(cs.commonMediumText, cs.fontWeightNormal, cs.displayBlock, cs.paddingBottom1, cs.paddingTop2)}>{checkInDate}</span>
                      <span className={cx(cs.commonMediumText, cs.fontWeightNormal)}>{formattedStartTime}</span>
                    </h5>
                    <img src={arrowIcon} className={cx(cs.dateArrowMargin, 'commonDateArrowRTLRotate')} />
                    <h5 className={cx(cs.commonContentText, cs.fontWeightBold)}>
                      <FormattedMessage {...messages.checkOut} />
                      <span className={cx(cs.commonMediumText, cs.fontWeightNormal, cs.displayBlock, cs.paddingBottom1, cs.paddingTop2)}>{checkOutDate}</span>
                      <span className={cx(cs.commonMediumText, cs.fontWeightNormal)}>{formattedEndTime}</span>
                    </h5>
                  </div>
                  <hr className={cx(cs.listingHorizoltalLine, cs.spaceBottom3, cs.spaceTop3)} />
                  <>
                
                    {idLocation == 0 && <div className={cx(cs.spaceTop4)}><h4 className={cx(cs.commonContentText, cs.fontWeightBold, cs.paddingBottom2)}>
                      <FormattedMessage {...messages.location} />
                    </h4>
                    
                    <h6 className={cx(cs.commonMediumText, cs.fontWeightNormal, cs.paddingBottom1)}>
                      <span>{street}, </span>
                      <span>{city}, {state}, {zipcode}</span>
                      <span>{' '}{country}.</span>
                    </h6>
                    </div>}
                    
                    {idLocation > 0 && <div className={cx(cs.spaceTop4)}>
                    <h4 className={cx(cs.commonContentText, cs.fontWeightBold, cs.paddingBottom2)}>
                      <FormattedMessage {...messages.pickupLocation} />
                    </h4>
                    
                    <a href={urlOpenMap} target={'_blank'} className={cx(cs.siteLinkColor, cs.commomLinkborderBottom, cs.textDecorationNone, cs.fontWeightMedium)}>
                      {streetDelivery}
                      <img src={Arrow} className={cx(cs.blueLeftArrow, 'loginArrowRTL')} />
                    </a>
                    </div>}
                  </>
                  <hr className={cx(cs.listingHorizoltalLine, cs.spaceBottom3, cs.spaceTop3)} />
                  <>
                  <div className={cx(s.displayFlexDate,cs.sMarginBottom15)}>
                    <h5 className={cx(cs.commonContentText, cs.fontWeightBold)}>
                      <FormattedMessage {...messages.rentContract} />
                        <a href={`/get-pdf-agreement?pdfname=contract_${code}_ot.pdf`} target={'_blank'} className={cx(s.codeContract,cs.siteLinkColor, cs.commomLinkborderBottom, cs.textDecorationNone, cs.fontWeightMedium)}>
                        {code}
                        <img src={Arrow} className={cx(cs.blueLeftArrow, 'loginArrowRTL')} />
                      </a>
                    </h5>

                  </div>
                  </>
                  <hr className={cx(cs.listingHorizoltalLine, cs.spaceBottom3, cs.spaceTop3)} />
                  <>
                  <div className={cx(s.displayFlexDate,cs.sMarginBottom15)}>
                    <h5 className={cx(cs.commonContentText, cs.fontWeightBold)}>
                      <FormattedMessage {...messages.totalPaid} />
                      <span className={cx(cs.commonMediumText, cs.fontWeightNormal, cs.displayBlock, cs.paddingBottom1, cs.paddingTop2)}><CurrencyConverter
                      amount={subTotalWithSecurityDeposit}
                      from={currency}
                    /></span>
                    <a href={"/users/trips/receipt/" + id} target={"_blank"} className={cx(cs.spaceTop1, cs.textDecorationNone, cs.siteLinkColor, cs.displayBlock, cs.commomLinkborderBottom,cs.fontWeightMedium)}>
                      <FormattedMessage {...messages.viewReceipt} />
                      <img src={Arrow} className={cx(cs.blueLeftArrow, 'loginArrowRTL')} />
                    </a>
                    </h5>
                  </div>
                  </>
                  <hr className={cx(cs.listingHorizoltalLine, cs.spaceBottom3, cs.spaceTop3)} />
                  <>
                  <div className={cx(s.displayFlexDate,cs.sMarginBottom15)}>
                    <h5 className={cx(cs.commonContentText, cs.fontWeightBold)}>
                      <FormattedMessage {...messages.licensePlate} />
                      <span className={cx(cs.commonMediumText, cs.fontWeightNormal, cs.displayBlock, cs.paddingBottom1, cs.paddingTop2)}>{vehiclePlate}</span>
                      
                    </h5>
                  </div>
                  </>
                  <hr className={cx(cs.listingHorizoltalLine, cs.spaceBottom3, cs.spaceTop3)} />
                  
                  <>
                    <div className={isItineraryBlocked == true ? cx(s.displayItineraryPhotos,s.isBlocked):cx(s.displayItineraryPhotos)}>
                      <div className={cx(s.displayFlexDate)}>
                        <h5 className={cx(cs.commonContentText, cs.fontWeightBold)}>
                          <FormattedMessage {...messages.vehiclePhotoStatus} />
                        </h5>
                      </div>
                      <div className={cx(s.displayFlexDate,cs.sMarginBottom15)}>
                        <h5 className={cx(cs.commonContentText, cs.fontWeightBold)}>
                          <a href={"/users/trip-vehicle-status/1/" + id} target={"_blank"} className={cx(cs.spaceTop3, cs.textDecorationNone, cs.siteLinkColor, cs.displayBlock,cs.fontWeightMedium)}>
                          <FormattedMessage {...messages.vehicleReceive} />
                            <img src={Arrow} className={cx(cs.blueLeftArrow, 'loginArrowRTL')} />
                          </a>
                        </h5>
                        <h5 className={cx(cs.commonContentText, cs.fontWeightBold)}>
                          
                          <a href={"/users/trip-vehicle-status/2/" + id} target={"_blank"} className={cx(cs.spaceTop3, cs.textDecorationNone, cs.siteLinkColor, cs.displayBlock,cs.fontWeightMedium)}>
                          <FormattedMessage {...messages.vehicleReturn} />
                            <img src={Arrow} className={cx(cs.blueLeftArrow, 'loginArrowRTL')} />
                          </a>
                        </h5>
                      </div>
                    </div>
                  </>
                </div>
              </Col>
              <Col lg={5} md={5} sm={12} xs={12} className={cx(cs.spaceTop7, cs.paymentSticky)}>
                <div className={cx(s.imgBgColor, s.boxSection)}>
                
                  <div className={cx(cs.positionRelative, cs.displayBlock)}>
                    <a href={"/cars/" + listId} target={"_blank"}>
                      <ListCoverPhoto
                        className={cx(s.imageContent, cs.spaceBottom1)}
                        coverPhoto={coverPhoto}
                        listPhotos={listPhotos}
                        photoType={"x_medium"}
                        bgImage
                      />
                    </a>
                    <div className={cx(cs.positionAvatar, 'positionAvatarRTL', s.zIndex)}>
                      <Avatar
                        source={picture}
                        title={firstName}
                        className={cx(cs.profileAvatarLink, cs.profileAvatarLinkSmall)}
                        withLink
                        width={44}
                        height={44}
                        profileId={profileId}
                        linkClassName={cs.displayInlineBlock}
                      />
                    </div>
                  </div>
                  <>
                    <div className={cx(s.displayFlex, cs.spaceTop3)}>
                      <img src={steeringIcon} className={'commonIconSpace'} />
                      <h6 className={cx(cs.commonSmallText, cs.fontWeightNormal)}>
                        <span>{vehicleType}</span>
                        <span className={cs.dotCss}></span>
                        <span>{transmissionLabel}</span>
                      </h6>
                    </div>
                    <a href={"/cars/" + listId} target={"_blank"} className={cx(cs.spaceTop1, cs.commonContentText, cs.siteTextColor, cs.displayBlock, cs.fontWeightBold)}>
                      {vehicleInfo}
                    </a>
                    {starRatingValue > 0 && <div className={cx(cs.spaceTop1, cs.commonContentText, s.displayFlex)}>
                      <img src={starIcon} className={'searchHeaderIcon'} /> <span>{starRatingValue}</span>
                    </div>}
                  </>

                  <>
                    {!isHost && <>
                        <hr className={cx(cs.listingHorizoltalLine, cs.spaceBottom3, cs.spaceTop3)} />  
                        <div className={cx(s.avatarImage, cs.paddingBottom4)}>
                          <Avatar
                            source={picture}
                            height={80}
                            width={80}
                            className={cs.profileAvatarLink}
                            withLink
                            profileId={profileId}
                          />
                          <div className={cx(s.textSection, 'viewListingTextSectionRTL')}>
                            <h5 className={cx(cs.commonSubTitleText, cs.fontWeightBold, cs.paddingBottom1)}>
                              <FormattedMessage {...messages.hostedBy} />{' '}
                              <a href={"/users/show/" + profileId} target={'_blank'} className={cx(cs.reviewTitleLink, cs.commonContentText)}>
                                <span className={cs.siteLinkColor}>{firstName}</span>
                              </a>
                            </h5>
                            <h6 className={cx(cs.commonContentText, cs.fontWeightNormal, cs.paddingBottom1)}><FormattedMessage {...messages.joinedIn} /> {joinedDate}</h6>
                            
                          </div>
                        </div>
                      </>
                    }
                  </>
                </div>
              </Col>
            </Row>
            <Row>
              {/* <Col lg={12} md={12} sm={12} xs={12} className={cx(cs.spaceTop5)}>
                {
                  houseRules?.length > 0 && <>
                    <ListItem
                      itemList={houseRules}
                      label={formatMessage(messages.houseRules)}
                      showLabel={formatMessage(messages.showMore)}
                      hideLabel={formatMessage(messages.closeAllLabel)}
                    />
                  </>
                }
              </Col> */}
              <Col lg={12} md={12} sm={12} xs={12} className={cx(cs.spaceTop5)}>
                
                <div className={s.boxSection}>
                  {
                    houseRules?.length > 0 && <>
                      <ListItem
                        itemList={houseRules}
                        count={2}
                        label={formatMessage(messages.houseRules)}
                        showLabel={formatMessage(messages.showMore)}
                        hideLabel={formatMessage(messages.closeAllLabel)}
                      />
                    </>
                  }
                  <h5 className={cx(cs.commonSubTitleText, cs.fontWeightBold, cs.paddingBottom3)}>
                    <FormattedMessage {...messages.ownerGuide} />
                  </h5>
                  <p>{description}</p>
                </div>
              </Col>
            </Row>
            <Row>
              {/* <Col lg={12} md={12} sm={12} xs={12} className={cx(cs.spaceTop5)}>
                <div className={s.boxSection}>
                  <h5 className={cx(cs.commonSubTitleText, cs.fontWeightBold, cs.paddingBottom3)}>
                    <FormattedMessage {...messages.messageHistroy} />
                  </h5>
                  <ViewMessageQuick threadId={messageData.id} userType={userType} />
                </div>
              </Col> */}
            </Row>
          </div>
          }
        </Grid>
      );
    }
  }
}

const mapState = (state) => ({
  userId: state.account.data.userId,
});

const mapDispatch = {};

export default injectIntl(withStyles(s, cs)(connect(mapState, mapDispatch)(ItineraryView)));