


import React from "react";
import { Store } from "redux";
import { restrictUrls } from "../../../helpers/adminPrivileges";
import AdminLayout from "../../../components/Layout/AdminLayout";
import PrivilegesManagment from "./privilegesManagment";

const title = 'Manage site configurations';

interface StoreState {
  runtime: {
    isAdminAuthenticated: boolean;
    isSuperAdmin: boolean;
  }
  account: {
    privileges: any;
  };
  listSettings: {
    privileges: any;
  };
}
export default async function action({ store }: { store: Store<StoreState> }) {
    let privileges = store.getState().listSettings && store.getState().listSettings.privileges;
    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    let isSuperAdmin = store.getState().runtime.isSuperAdmin;

    if (!isAdminAuthenticated) {
      return { redirect: '/siteadmin/login' };
    }

    // Only allow superadmin access to this page
    if (!isSuperAdmin) {
      return { redirect: '/siteadmin' };
    }

    return {
        title,
        component: (
            <AdminLayout>
                <PrivilegesManagment privileges={privileges} />
            </AdminLayout>
        )
    };
}
