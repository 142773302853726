import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { createNumberMask, createTextMask } from "redux-form-input-masks";
import { Multiselect } from "react-widgets";
import { injectIntl, FormattedMessage } from "react-intl";
import { graphql, gql, compose } from "react-apollo";
import { connect } from "react-redux";
import { Row, FormGroup, Col, ControlLabel } from "react-bootstrap";

// Query
import vehicleMakeQuery from "./vehicleMakeQuery.graphql";
import vehicleModelQuery from "./vehicleModelQuery.graphql";
import vehicleTypeQuery from "./vehicleType.graphql";
import getVehicleGasQUery from "./getVehicleGasQuery.graphql";
import getAllVehiclePlateChasisQuery from "./getAllVehiclePlateChasisQuery.graphql";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import s from "./ListPlaceStep1.css";
import cs from "../commonStyle.css";


import SidePanel from "./SidePanel";
import FooterButton from "./FooterButton";

import update from "./update";
import messages from "../../locale/messages";
import validate from "./validate";
import CommonFormComponent from "../CommonField/CommonFormComponent";

class Page2 extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    listId: PropTypes.number,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
    listingExtData: PropTypes.shape({
      brand: PropTypes.number,
      model: PropTypes.number,
      color: PropTypes.string,
      chasis: PropTypes.string,
      plate: PropTypes.string,
      engine: PropTypes.string,
      year: PropTypes.string,
      gas: PropTypes.number,
      mpg: PropTypes.number,
      isLocked: PropTypes.number,
      odometro: PropTypes.string,
      odometro_limit: PropTypes.string,
      odometro_price: PropTypes.string,
    }),
    // ShowListingExt: PropTypes.shape({
    //   isLocked: PropTypes.number,
    //   chasis: PropTypes.string,
    //   engine: PropTypes.string,
    //   odometro: PropTypes.string,
    //   odometro_limit: PropTypes.string,
    //   odometro_price: PropTypes.string
    // }),
    vehicleTypeData: PropTypes.shape({
      getVehicleType: PropTypes.array,
    }),
    vehicleMakeData: PropTypes.shape({
      getVehicleMake: PropTypes.array,
    }),
    vehicleModelData: PropTypes.shape({
      getVehicleModel: PropTypes.array,
    }),
    getVehicleGasData: PropTypes.shape({
      getVehicleGas: PropTypes.array,
    }),
    getAllVehiclePlateChasisData: PropTypes.shape({
      getAllVehiclePlateChasis: PropTypes.array,
    }),
  };
  static defaultProps = {
    vehicleTypeData: {
      getVehicleType: [],
    },
    vehicleMakeData: {
      getVehicleMake: [],
    },
    vehicleModelData: {
      getVehicleModel: [],
    },
  };
  constructor(props) {
    super(props);
    (this.state = {
      listid: 0,
      isDisabled: true,
      selectedGas: [],
      listingExtData: {
        brand: null,
        model: null,
        color: "Azul",
        chasis: "",
        plate: "",
        mpg: 0,
        gas: 0,
        engine: "V6",
        year: parseInt(new Date().getFullYear()) - 12,
        isLocked: 0,
        odometro: "",
        odometro_limit: "",
        odometro_price: "",
      },
      carType: [],
      year: [],
      make: [],
      odometer: [],
      colorList: [
        "Azul",
        "Azul Agua",
        "Azul Cielo",
        "Azul claro",
        "Azul Marino",
        "Azul/Grís",
        "Beige",
        "Blanco",
        "Blanco perla",
        "Bronce",
        "Champang",
        "Crema",
        "Dorado",
        "Gris",
        "Gris oscuro",
        "Gris Plata",
        "Ladrillo",
        "Marrón",
        "Morado /Gris",
        "Naranja",
        "Negro",
        "Negro/Crema",
        "Negro/Gris",
        "Negro/Gris",
        "Negro/Rojo",
        "Plata",
        "Rojo",
        "Rojo",
        "Esacarlata",
        "Rojo",
        "Vino",
        "Rojo/Dorado",
        "Rosado",
        "Silver",
        "Terracota",
        "Verde",
        "Verde/Gris",
        "Vino",
      ],
      engineList: ["V6", "V2T", "Hybrido", "Electrico"],
      isDisabledFields: true,
      isModelValue: [],
      makeIdValue: 1,
      modelIdValue: 0,
      vehicleMakeName: null,
      vehicleModelName: null,
    }),
    this.handleChange = this.handleChange.bind(this);
    this.changeModelType = this.changeModelType.bind(this);
    this.onChangeVehicleType = this.onChangeVehicleType.bind(this);
    this.onChangeMakeType = this.onChangeMakeType.bind(this);
    this.handleMakeChange = this.handleMakeChange.bind(this);
    this.onChangeModelType = this.onChangeModelType.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.doRenderYear = this.doRenderYear.bind(this);
    this.onCheckPlateDuplicate = this.onCheckPlateDuplicate.bind(this);
    this.onCheckChasisDuplicate = this.onCheckChasisDuplicate.bind(this);
    this.changeFormValueExt = this.changeFormValueExt.bind(this);
    this.handleGasChange = this.handleGasChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { listingFields, listingExtData, change } = this.props;
    console.log('listingFields:', listingFields);
    const {
      vehicleMakeData,
      vehicleMakeData: { getVehicleMake },
    } = this.props;
    const {
      vehicleModelData,
      vehicleModelData: { getVehicleModel },
    } = this.props;
    const {
      vehicleTypeData,
      vehicleTypeData: { getVehicleType },
    } = this.props;
    const {
      getVehicleGasData,
      getVehicleGasData: { getVehicleGas },
    } = this.props;

    if (listingFields != undefined) {
      this.setState({
        carType: listingFields.carType,
        year: listingFields.year,
        odometer: listingFields.odometer,
      });
    }
  }

  componentDidMount() {
    const { valid, makeName, listingSteps } = this.props;
    const { listingFields, listingExtData, change } = this.props;
    if (listingSteps && listingSteps.listingExtDT) {
      const listingExtValues = listingSteps.listingExtDT;
      console.log(listingSteps)
      change("mpg", listingExtValues.mpg);
      change("gas", listingExtValues.gas);
      change("chasis", listingExtValues.chasis);
      change("plate", listingExtValues.plate);
        }
    let ModelValues =
      listingFields &&
      listingFields.model &&
      listingFields.model.filter((o) => o.makeType == makeName);

    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
    if (listingFields != undefined) {
      this.setState({
        isModelValue: ModelValues,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { valid, listingFields, makeName } = nextProps;
    const {
      vehicleMakeData,
      vehicleMakeData: { getVehicleMake },
    } = this.props;

    // if(getVehicleMake){
    // console.log('getVehicleMake -->',getVehicleMake);
    // }

    let ModelValues =
      listingFields &&
      listingFields.model &&
      listingFields.model.filter((o) => o.makeType == makeName);
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }

    if (listingFields != undefined) {
      this.setState({
        carType: listingFields.carType,
        year: listingFields.year,
        odometer: listingFields.odometer,
        isModelValue: ModelValues,
      });
    }
  }

  changeFormValueExt() {}
  changeModelType(e) {
    const { change, listingFields } = this.props;
    let newValue = e.target.value;
    let ModelValue =
      listingFields.model &&
      listingFields.model.filter((o) => o.makeType == newValue);
    this.setState({
      isModelValue: ModelValue,
      makeValue: newValue,
      isTrimValue: [],
    });

    if (ModelValue && ModelValue.length > 0) {
      change("model", ModelValue && ModelValue[0].id);
    } else {
      change("model", "");
    }
  }
  onChangeModelType(e) {
    const { change, listingFields } = this.props;
    let newValue = e.target.value;
    this.setState({
      modelIdValue: newValue,
    });
    if (newValue) {
      change("model", newValue);
      change("listingExtData.model", newValue);
    }
  }
  handleGasChange(e, selectedData) {
    let selectedGas;
    if (selectedData) {
      // Handle selection
      selectedGas = selectedData.dataItem.id;
    } else {
      // Handle deselection
      selectedGas = '';
    }

    this.setState(prevState => {
      // Get current selected gas values
      const currentGas = prevState.selectedGas || [];
      
      // If deselecting, remove the value
      if (!selectedGas) {
        const newGas = currentGas.filter(g => g !== selectedGas);
        return {
          ...prevState,
          selectedGas: newGas,
        };
      }

      // If already have 2 selections, remove oldest one
      let newGas;
      if (currentGas.length >= 2) {
        newGas = [currentGas[1], selectedGas];
      } else {
        newGas = [...currentGas, selectedGas];
      }

      return {
        ...prevState,
        selectedGas: newGas,
      };
    }, () => {
      // Update form values after state has been updated
      const { change } = this.props;
      change('gas', this.state.selectedGas);
      change('selectedGas', this.state.selectedGas);
    });
  }

  onChangeMakeType(e) {
    const {
      change,
      listingFields,
      makeIdValue,
      vehicleModelData,
      vehicleModelData: { refetch },
    } = this.props;
    let newValue = e.target.value;
    this.setState({
      makeIdValue: newValue,
      isTrimValue: [],
    });
    if (newValue) {
      refetch({ make_id: newValue });
      change("make", newValue);
      change("listingExtData.brand", newValue);
    }
  }
  onChangeVehicleType(e) {
    const { change, listingFields } = this.props;
  }
  onCheckChasisDuplicate(e) {
    const { change, listingFields, listId } = this.props;
    const {
      getAllVehiclePlateChasisData,
      getAllVehiclePlateChasisData: { getAllVehiclePlateChasis },
    } = this.props;
    let carId = listId == NaN ? 0 : listId;
    if (getAllVehiclePlateChasis) {
      change("chasis_duplicate", 0);
      getAllVehiclePlateChasis != null &&
        getAllVehiclePlateChasis.length > 0 &&
        getAllVehiclePlateChasis.map((item) => {
          if (item.listid != carId && item.chasis !=null) {
            if (item.chasis.toString().toUpperCase() == e.target.value.toString().toUpperCase()
            ) {
              change("chasis_duplicate", 1);
            }
          }
        });
    }
  }
  onCheckPlateDuplicate(e) {
    const { change, listingFields, listId } = this.props;
    const {
      getAllVehiclePlateChasisData,
      getAllVehiclePlateChasisData: { getAllVehiclePlateChasis },
    } = this.props;
    let carId = listId == NaN ? 0 : listId;

    if (getAllVehiclePlateChasis) {
      change("plate_duplicate", 0);
      getAllVehiclePlateChasis != null &&
        getAllVehiclePlateChasis.length > 0 &&
        getAllVehiclePlateChasis.map((item) => {
          if (item.listid != carId && item.plate != null) {
            if (
              item.plate.toString().toUpperCase() ==
              e.target.value.toString().toUpperCase()
            ) {
              change("plate_duplicate", 1);
            }
          }
        });
    }
  }
  handleMakeChange(e, selectedData) {
    this.setState({
      make: selectedData.id,
    });
  }
  handleModelChange(e, selectedData) {
    this.setState({
      modelIdValue: selectedData.id,
      vehicleModelName: selectedData.title,
    });
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  doRenderYear() {
    let thisYear = new Date().getFullYear();
    let r12Year = parseFloat(thisYear) - 12;
    let listYear = [];

    for (let index = 0; index < 13; index++) {
      r12Year++;
      listYear.push({ label: r12Year, value: r12Year });
    }
    return listYear;
  }
  render() {
    const {
      handleSubmit,
      previousPage,
      nextPage,
      existingList,
      formPage,
      step,
      listingSteps,
    } = this.props;
    const {
      isDisabled,
      isDisabledFields,
      carType,
      listid,
      make,
      makeIdValue,
      vehicleMakeName,
      vehicleModelName,
      modelIdValue,
      isModelValue,
      year,
      colorList,
      engineList,
      odometer,
      listingExtData,
      selectedGas,
    } = this.state;
    const {
      vehicleMakeData,
      vehicleMakeData: { getVehicleMake },
    } = this.props;
    const {
      vehicleModelData,
      vehicleModelData: { getVehicleModel },
    } = this.props;
    const {
      vehicleTypeData,
      vehicleTypeData: { getVehicleType },
    } = this.props;
    const {
      getVehicleGasData,
      getVehicleGasData: { getVehicleGas },
    } = this.props;

    const priceMask = createNumberMask({
      prefix: "",
      decimalPlaces: 2,
      spaceAfterSign: true,
      allowNegative: true,
    });
    const mpgMask = createNumberMask({
      suffix: " per miles",
      spaceAfterSign: true,
      decimalPlaces: 0,
      // stringValue: true,
      // onChange: value => console.log(value),
    });

    let setFieldDisable = false;
    const optYear = this.doRenderYear();
    const { formatMessage } = this.props.intl;
    let path = "index";
    if (existingList) {
      path = "home";
    }
    return (
      <div className={cx(s.stepGrid, "stepGridRTL")}>
        <SidePanel
          title={formatMessage(messages.step1HeadingNew)}
          landingContent={formatMessage(messages.whatKindOfPlaceListing)}
        />
        <div>
          <form onSubmit={handleSubmit}>
            <div className={s.landingMainContent}>
              <FormGroup className={s.formGroup}>
                <ControlLabel className={s.landingLabel}>
                  <FormattedMessage {...messages.whatGuestHave} />
                </ControlLabel>
                <Field
                  isDisabled={setFieldDisable}
                  name="listingExtData.type"
                  component={CommonFormComponent}
                  onChange={this.onChangeVehicleType}
                  inputClass={cx(
                    s.formControlSelect,
                    s.jumboSelect,
                    s.marginBottompageTwo
                  )}
                >
                  {getVehicleType != null &&
                    getVehicleType.length > 0 &&
                    getVehicleType.map((item) => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item.title}
                        </option>
                      );
                    })}
                </Field>
              </FormGroup>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.whatTypeOfProperty} />
                    </ControlLabel>
                    <Field
                      isDisabled={setFieldDisable}
                      name="listingExtData.brand"
                      component={CommonFormComponent}
                      onChange={this.onChangeMakeType}
                      inputClass={cx(
                        s.formControlSelect,
                        s.jumboSelect,
                        s.marginBottompageTwo
                      )}
                    >
                      {getVehicleMake != null &&
                        getVehicleMake.length > 0 &&
                        getVehicleMake.map((item) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.title}
                            </option>
                          );
                        })}
                    </Field>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.modelLabel} />
                    </ControlLabel>
                    <Field
                      name="listingExtData.model"
                      isDisabled={setFieldDisable}
                      onChange={this.onChangeModelType}
                      component={CommonFormComponent}
                      inputClass={cx(
                        s.formControlSelect,
                        s.jumboSelect,
                        s.marginBottompageTwo
                      )}
                    >
                      {getVehicleModel != null &&
                        getVehicleModel.length > 0 &&
                        getVehicleModel.map((item) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.title}
                            </option>
                          );
                        })}
                    </Field>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup className={cs.spaceBottom4}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.vehicleColor} />
                    </ControlLabel>
                    <Field
                      name="listingExtData.color"
                      isDisabled={setFieldDisable}
                      component={CommonFormComponent}
                      inputClass={cx(
                        s.formControlSelect,
                        s.jumboSelect,
                        s.marginBottompageTwo
                      )}
                    >
                      {colorList.map((value, key) => {
                        return (
                          <option value={value} key={key}>
                            {value}
                          </option>
                        );
                      })}
                    </Field>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup className={cs.spaceBottom4}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.year} />
                    </ControlLabel>
                    <Field
                      isDisabled={setFieldDisable}
                      name="listingExtData.year"
                      component={CommonFormComponent}
                      value={listingExtData?.year}
                      inputClass={cx(
                        s.formControlSelect,
                        s.jumboSelect,
                        s.marginBottompageTwo
                      )}
                    >
                      {optYear.map((value, key) => {
                        return (
                          <option value={value.value} key={key}>
                            {value.label}
                          </option>
                        );
                      })}
                    </Field>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup className={cs.spaceBottom4}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.vehicleChasis} />
                    </ControlLabel>
                    <Field
                      name="chasis"
                      isDisabled={setFieldDisable}
                      type="text"
                      onBlur={(e) => this.onCheckChasisDuplicate(e)}
                      component={CommonFormComponent}
                      label={formatMessage(messages.vehicleChasis)}
                      inputClass={cx(
                        cs.formControlInput,
                        "commonInputPaddingRTL"
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup className={cs.spaceBottom4}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.vehicleEngine} />
                    </ControlLabel>
                    <Field
                      name="listingExtData.engine"
                      isDisabled={setFieldDisable}
                      component={CommonFormComponent}
                      inputClass={cx(
                        s.formControlSelect,
                        s.jumboSelect,
                        s.marginBottompageTwo
                      )}
                    >
                      {engineList.map((value, key) => {
                        return (
                          <option value={value} key={key}>
                            {value}
                          </option>
                        );
                      })}
                    </Field>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup className={cx(cs.spaceBottom4)}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.vehiclePlate} />
                    </ControlLabel>
                    <Field
                      name="plate"
                      isDisabled={setFieldDisable}
                      type="text"
                      onBlur={(e) => this.onCheckPlateDuplicate(e)}
                      component={CommonFormComponent}
                      label={formatMessage(messages.vehiclePlate)}
                      inputClass={cx(
                        cs.formControlInput,
                        "commonInputPaddingRTL"
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup className={cx(cs.spaceBottom4)}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.isPersonalHome} />
                    </ControlLabel>
                    <Field
                      name="transmission"
                      isDisabled={setFieldDisable}
                      component={CommonFormComponent}
                      inputClass={cx(
                        s.formControlSelect,
                        s.jumboSelect,
                        s.marginBottompageTwo
                      )}
                    >
                      <option value="1">
                        {formatMessage(messages.Automatic)}
                      </option>
                      <option value="2">
                        {formatMessage(messages.Manuall)}
                      </option>
                    </Field>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup className={cx(cs.spaceBottom4)}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.mpgLabel} />
                    </ControlLabel>
                    <Field
                      name="mpg"
                      isDisabled={setFieldDisable}
                      type="text"
                      component={CommonFormComponent}
                      label={formatMessage(messages.mpgLabel)}
                      inputClass={cx(
                        cs.formControlInput,
                        "commonInputPaddingRTL"
                      )}
                      {...mpgMask}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup className={cx(cs.spaceBottom4)}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.carFuel} />
                    </ControlLabel>
                    <Field
                      name="gas"
                      isDisabled={setFieldDisable}
                      component={props => 
                        <Multiselect
                          {...props.input}
                          data={getVehicleGas || []}
                          valueField="id"
                          textField="title"
                          onChange={this.handleGasChange}
                          value={(this.state.selectedGas || []).map(gasId => {
                            const gas = (getVehicleGas || []).find(g => g && g.id === gasId);
                            return gas ? gas.title : '';
                          })}
                        />
                      }
                      className={cx(
                        s.formControlSelect,
                        s.jumboSelect,
                         "commonInputPaddingRTL",
                        s.marginBottompageTwo
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Field
                component={CommonFormComponent}
                name="chasis_duplicate"
                type="hidden"
              />
              <Field
                component={CommonFormComponent}
                name="plate_duplicate—"
                type="hidden"
              />
            </div>
            <FooterButton
              isDisabled={isDisabled}
              nextPage={nextPage}
              previousPage={previousPage}
              nextPagePath={"location"}
              previousPagePath={path}
              formPage={formPage}
              step={step}
            />
          </form>
        </div>
      </div>
    );
  }
}

Page2 = reduxForm({
  form: "ListPlaceStep1", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmit: update,
})(Page2);

const selector = formValueSelector("ListPlaceStep1"); // <-- same as form name

const mapState = (state) => ({
  existingList: state.location.isExistingList,
  listingFields: state.listingFields.data,
  listingSteps: state.location.listingSteps,
});

const mapDispatch = {
  change,
};

export default compose(
  injectIntl,
  withStyles(s, cs),
  graphql(vehicleMakeQuery, {
    name: "vehicleMakeData",
    options: {
      ssr: false,
      fetchPolicy: "network-only",
    },
  }),
  graphql(vehicleModelQuery, {
    name: "vehicleModelData",
    options: (props) => ({
      variables: {
        make_id: props.makeIdValue,
      },
      fetchPolicy: "network-only",
    }),
  }),
  graphql(vehicleTypeQuery, {
    name: "vehicleTypeData",
    options: {
      ssr: false,
      fetchPolicy: "network-only",
    },
  }),
  graphql(getVehicleGasQUery, {
    name: "getVehicleGasData",
    options: {
      ssr: false,
      fetchPolicy: "network-only",
    },
  }),
  graphql(getAllVehiclePlateChasisQuery, {
    name: "getAllVehiclePlateChasisData",
    options: {
      ssr: false,
      fetchPolicy: "network-only",
    },
  }),
  connect(mapState, mapDispatch)
)(Page2);
