import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './SocialLoginRegister.css';
import cs from '../../components/commonStyle.css';
import { FormattedMessage, injectIntl } from 'react-intl';
// Locale
import messages from '../../locale/messages';
import showToaster from '../../helpers/toasterMessages/showToaster';

//Images
import googleIcon from '/public/SiteIcons/googleIcon.svg';
import { FaGoogle } from "react-icons/fa6";


class SocialLoginRegister extends Component {
  static propTypes = {
    formatMessage: PropTypes.any,
    btnSignUpDisabled: PropTypes.bool.isRequired,
    refer: PropTypes.string
  };

  static defaultProps = {
    btnSignUpDisabled: true,
  };

  handleClick(event) {
    const { isAdmin } = this.props;
    console.log(isAdmin, 'isAdminnn')
    if (isAdmin) {
      showToaster({ messageId: 'isAdmin', toasterType: 'error' })
      event.preventDefault();
    }
  }

  render() {
    const { refer,btnSignUpDisabled } = this.props;
    let FbURL = '/login/facebook';
    let GoogleURL = '/login/google';
    if (refer) {
      FbURL = '/login/facebook?refer=' + refer;
      GoogleURL = '/login/google?refer=' + refer;
    }

    return (
      <div className={cx(s.displayFlex, s.center)}>
        {/* <a className={cx(s.displayFlex, cs.siteTextColor, cs.commonMediumText, s.marginRight, cs.textDecorationNone, 'socialLoginMarginRightRTL')} href={FbURL}>
          <img src={faceBookIcon} className={'commonIconSpace'} />
          <FormattedMessage {...messages.facebook} />
        </a> */}
        
        {btnSignUpDisabled == true && <a className={
          cx('btn', 'btn-lg',' btn-default', 'btn-block',cs.btnPrimary,s.btnSignUpLinkDisabled)} 
          onClick={ (event) => event.preventDefault() }
          href={"#"}>
          <FaGoogle size={'1.1em'} className={cx(cs.btnIconSm)}/>
          <FormattedMessage {...messages.continueSignUp} />
        </a>}

        {btnSignUpDisabled == false && <a className={
          cx('btn', 'btn-lg',' btn-default', 'btn-block',cs.btnPrimary)} 
          onClick={(event) => this.handleClick(event)} 
          href={GoogleURL}>
          <FaGoogle size={'1.1em'} className={cx(cs.btnIconSm)}/>
          <FormattedMessage {...messages.continueSignUp} />
        </a>}
      </div>
    );
  }
}

const mapState = state => ({
  isAdmin: state.runtime && state.runtime.isAdminAuthenticated,
});

const mapDispatch = {
};

export default injectIntl(withStyles(s, cs)(connect(mapState, mapDispatch)(SocialLoginRegister)));