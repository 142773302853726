import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { graphql ,gql} from 'react-apollo';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

import s from './VerifiedInfo.css';
import cs from '../../components/commonStyle.css';
import VerifiedInfoList from './VerifiedInfoList';
import Loader from '../Loader';
import messages from '../../locale/messages';

class VerifiedInfo extends React.Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    verifiedInfoData: PropTypes.shape({
      loading: PropTypes.bool,
      getUserVerifiedInfo: PropTypes.object,
    }),
    userId: PropTypes.string,
    account: PropTypes.shape({
      userId: PropTypes.string,
    }),
    isTrustVerfiedPage: PropTypes.bool,
    isUser: PropTypes.bool,
  };

  static defaultProps = {
    verifiedInfoData: {
      loading: true,
    },
    account: {
      userId: null,
    },
  };

  renderContent() {
    const { verifiedInfoData, account, userId, isTrustVerfiedPage, isUser } = this.props;
    const { loading, getUserVerifiedInfo } = verifiedInfoData;

    if (loading) {
      return <Loader type="text" />;
    }

    const isLoggedInUser = account && account.userId === userId;

    return (
      <VerifiedInfoList
        items={getUserVerifiedInfo}
        isLoggedInUser={isLoggedInUser}
        isTrustVerfiedPage={isTrustVerfiedPage}
        isUser={isUser}
        account={account}
      />
    );
  }

  render() {
    const { intl } = this.props;

    return (
      <div className={s.infoPadding}>
        <h5 className={cx(cs.commonContentText, cs.fontWeightBold, cs.paddingBottom3)}>
          {intl.formatMessage(messages.trustAndVerification)}
        </h5>
        {this.renderContent()}
      </div>
    );
  }
}

const mapState = (state) => ({
  account: state.account.data,
});

const getUserVerifiedInfoQuery = gql`
  query getUserVerifiedInfo($userId: String!) {
    getUserVerifiedInfo(userId: $userId) {
      id
      isEmailConfirmed
      isFacebookConnected
      isGoogleConnected
      isIdVerification
      isLicenseVerified
      isIdNumberVerification
      isPhoneVerified
      isPhotoVerified
      status
    }
  }
`;

export default compose(
  injectIntl,
  withStyles(s, cs),
  connect(mapState),
  graphql(getUserVerifiedInfoQuery, {
    name: 'verifiedInfoData',
    options: (props) => ({
      variables: {
        userId: props.userId,
      },
      fetchPolicy: 'network-only',
    }),
  })
)(VerifiedInfo);