import React from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import {
	Grid,
	Button
} from 'react-bootstrap';
import s from './ListPlaceStep1.css';
import cs from '../../components/commonStyle.css';
import Avatar from '../Avatar';
import history from '../../core/history';
import messages from '../../locale/messages';
import arrowIcon from '/public/AdminIcons/backArrow.svg';
import footerImage from '/public/siteImages/onboardFooterImage.svg';
import ShowNotification from '../ShowNotification/ShowNotification';


interface ListIntroProps {
	userData?: {
		firstName: string;
		userProfileData: {
			isAgreementSigned: number;
		}
	};
	listingFields?: any;
	nextPage: (page: string) => void;
}

const ListIntro: React.FC<ListIntroProps & InjectedFormProps<{}, ListIntroProps>> = ({ userData, nextPage }) => {
	const isUserAgreementNotSigned = !userData?.userProfileData?.isAgreementSigned;

	const navigateToAgreement = () => {
		history.push('/agreement/view/');
	};

	const renderAgreementInfo = () => (
		<div className={cx(cs.contentLiNotification)}>
			<div className={cx(cs.titleContainer)}>
				<h3><FormattedMessage {...messages.infoStatAgreement} /></h3>
			</div>
			<Button className={cx(s.button, s.btnPrimary)} onClick={navigateToAgreement}>
				<FormattedMessage {...messages.signUpAgreement} />
				<img src={arrowIcon} className={cx('arrowIconListRTL', 'saveArrowCss')} alt="Arrow Icon" />    
			</Button>
            <ShowNotification />
		</div>
	);

	const renderLetsGoButton = () => (
		<div>
			<p className={cx(cs.commonTotalText, cs.spaceTop3, cs.spaceBottom6)}><FormattedMessage {...messages.onboardText} /></p> 
			<Button className={cx(s.button, s.btnPrimary)} onClick={() => { nextPage('car'); }}>
				<FormattedMessage {...messages.LetsgoText} /> <img src={arrowIcon} className={cx('arrowIconListRTL', 'saveArrowCss')} alt="Arrow Icon" />
			</Button>
		</div>
	);


	return (
		<div className={cx(cs.textAlignCenter)}>
			<Grid fluid className={cx(s.listIntroContainers, s.listIntroBgSection)}>
				<div className={s.userRight}>
					<Avatar
						isUser
						title={userData?.firstName}
						className={s.profileImage}
						width={120}
						height={120}
					/>
				</div>
				<h3 className={cx(cs.commonTitleText, cs.fontWeightBold, cs.spaceTop3)}>
					<FormattedMessage {...messages.hiText} />,
					<span className={s.userNameColor}> {userData?.firstName}!</span>
				</h3>
				{isUserAgreementNotSigned ? renderAgreementInfo() : renderLetsGoButton()}
			</Grid>
			<img src={footerImage} className={cx(s.footerImageOnboard)} alt="Footer Image" />
		</div>
	);
};

const ListIntroForm = reduxForm<{}, ListIntroProps>({
	form: 'ListPlaceStep1',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true
})(ListIntro as React.ComponentType<ListIntroProps & InjectedFormProps<{}, ListIntroProps>>);


const mapStateToProps = (state: any) => ({
	userData: state.account.data,
	listingFields: state.listingFields.data
});

const ConnectedListIntro = connect<
	Pick<ListIntroProps, 'userData' | 'listingFields'>, 
	{}, 
	Omit<ListIntroProps, 'userData' | 'listingFields'>,
	{
		account: {
			data: any;
		};
		listingFields: {
			data: any; 
		};
	}
>(mapStateToProps)(ListIntroForm);

export { ConnectedListIntro as ListIntro };