import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { graphql, gql, compose } from 'react-apollo';

// Translation
import { FormattedMessage, injectIntl } from 'react-intl';
import showToaster from '../../../helpers/toasterMessages/showToaster';

import DocumentManagement from './DocumentManagementQuery.graphql';

// Send Email
import { sendEmail } from '../../../core/email/sendEmail';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Link from '../../Link';
import Avatar from '../../Avatar';
import messages from '../../../locale/messages';

//Image
import arrowIcon from '/public/AdminIcons/backArrow.svg';
import s from './ProfileVerifiedView.css';
import cs from '../../../components/commonStyle.css';

const query = gql`query($profileId: Int, $isUser: Boolean) {
    showUserProfile(profileId: $profileId, isUser: $isUser) {
      userId
      profileId
      firstName
      lastName
      dateOfBirth
      gender
      phoneNumber
      preferredLanguage
      preferredCurrency
      location
      info
      createdAt
      picture
      reviewsCount
      country
      profileBanStatus{
        email
      }
      userVerifiedInfo{
        isEmailConfirmed
        isIdVerification
        isGoogleConnected
        isFacebookConnected
        isLicenseVerified
        isPhoneVerified
        isPhotoVerified
      }
    }
  }`;

class ProfileVerifiedView extends React.Component {

    static propTypes = {
        data: PropTypes.object,
        title: PropTypes.string.isRequired,
        // addListToRecommended: PropTypes.any.isRequired,
        // removeListFromRecommended: PropTypes.any.isRequired,
    };

    static defaultProps = {
        data: {
            profileBanStatus: null,
            userVerifiedInfo: null
        }
    }

    constructor(props) {
        super(props);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    async handleUpdate(id, idUser, status, setStatus, docTypeVerification, userfirstName, userEmail) {

        const { mutate } = this.props;
        const { data } = await mutate({
            variables: {
                id: id,
                userId: idUser,
                isIdVerification: status,
                documentStatus: setStatus,
                verificationType: docTypeVerification
            },
            //   refetchQueries: [{ query: showAllDocumentVerification, variables: { currentPage } }]
        });

        if (data?.DocumentManagement?.status === 'success') {
            let msg = 'Documents have been ';
            msg += (status == 'approved') ? 'Approved.' : 'Rejected.';
            let content = {
                name: userfirstName,
                verificationStatus: (status == 'approved') ? 'approved' : 'rejected'
            }
            sendEmail(userEmail, 'documentVerification', content);
            showToaster({ messageId: 'commonSuccess', toasterType: 'success', requestMessage: msg });
            window.location.reload();
        } else {
            showToaster({ messageId: 'documentManagementError', toasterType: 'error', requestMessage: data.DocumentManagement && data.DocumentManagement.errorMessage })
        }
    }

    render() {
        const { data, intl, title } = this.props;
        const { formatMessage } = this.props.intl;
        let isVerifiedInfo = data && data.userVerifiedInfo;
        let isUserProfileExt = data && data.UserProfileExt;
        let isDocumentVerification = data && data.DocumentVerification;
        let identityVerify = 'empty';
        if (isUserProfileExt) {
            identityVerify = isUserProfileExt.identity_verify == 1 ? 'approved' : 'declined';
        }
        let isDocIdPassport, isDocLicense, isDocPhotoProfile;
        if (isDocumentVerification) {
            isDocumentVerification.map((item, index) => {
                if (item.verificationType == 2) {
                    isDocIdPassport = item[index];
                } else if (item.verificationType == 3) {
                    isDocLicense = item[index];
                    // console.log('isDocLicense ==>',item);
                }
            });
        }

        // let isVerify = (data && data.userVerifiedInfo) && (data.userVerifiedInfo.isEmailConfirmed || data.userVerifiedInfo.isGoogleConnected || data.userVerifiedInfo.isIdVerification || data.userVerifiedInfo.isFacebookConnected || data.userVerifiedInfo.isPhoneVerified) ? true : false;

        let isVerify = (data && data.userVerifiedInfo) && (data.userVerifiedInfo.isEmailConfirmed || data.userVerifiedInfo.isGoogleConnected || data.userVerifiedInfo.isPhotoVerified == 1 || data.userVerifiedInfo.isIdVerification == 1 || data.userVerifiedInfo.isLicenseVerified == 1 || data.userVerifiedInfo.isPhoneVerified) ? true : false;

        let isEmail, isGoogle, isDocument, isDriverLicense, isPhotoProfile, isFacebook, isSMS;

        if (isVerifiedInfo && data.userVerifiedInfo.isEmailConfirmed == true) {
            isEmail = "Email";
        }
        if (isVerifiedInfo && data.userVerifiedInfo.isGoogleConnected == true) {
            isGoogle = "Google";
        }

        // if (isVerifiedInfo && data.userVerifiedInfo.isFacebookConnected == true) {
        //     isFacebook = "Facebook";
        // }

        if (isVerifiedInfo && data.userVerifiedInfo.isIdVerification == 1) {
            isDocument = "ID Number";
        }

        if (isVerifiedInfo && data.userVerifiedInfo.isLicenseVerified == 1) {
            isDriverLicense = "Driver License";
        }
        if (isVerifiedInfo && data.userVerifiedInfo.isPhotoVerified == 1) {
            isPhotoProfile = "Profile Photo";
        }

        if (isVerifiedInfo && data.userVerifiedInfo.isPhoneVerified == true) {
            isSMS = "Phone number";
        }
        let language;

        if (data.preferredLanguage == "id") {
            language = "Bahasa Indonesia"
        } else if (data.preferredLanguage == "ms") {
            language = "Bahasa Melayu"
        } else if (data.preferredLanguage == "ca") {
            language = "Català"
        } else if (data.preferredLanguage == "da") {
            language = "Dansk"
        } else if (data.preferredLanguage == "de") {
            language = "Deutsch"
        } else if (data.preferredLanguage == "en") {
            language = "English"
        } else if (data.preferredLanguage == "es") {
            language = "Español"
        } else if (data.preferredLanguage == "el") {
            language = "Eλληνικά"
        } else if (data.preferredLanguage == "fr") {
            language = "Français"
        } else if (data.preferredLanguage == "it") {
            language = "Italiano"
        } else if (data.preferredLanguage == "hu") {
            language = "Magyar"
        } else if (data.preferredLanguage == "nl") {
            language = "Nederlands"
        } else if (data.preferredLanguage == "no") {
            language = "Norsk"
        } else if (data.preferredLanguage == "pl") {
            language = "Polski"
        } else if (data.preferredLanguage == "pt") {
            language = "Português"
        } else if (data.preferredLanguage == "fi") {
            language = "Suomi"
        } else if (data.preferredLanguage == "sv") {
            language = "Svenska"
        } else if (data.preferredLanguage == "tr") {
            language = "Türkçe"
        } else if (data.preferredLanguage == "is") {
            language = "Íslenska"
        } else if (data.preferredLanguage == "cs") {
            language = "Čeština"
        } else if (data.preferredLanguage == "ru") {
            language = "Русский"
        } else if (data.preferredLanguage == "th") {
            language = "ภาษาไทย"
        } else if (data.preferredLanguage == "zh") {
            language = "中文 (简体)"
        } else if (data.preferredLanguage == "zh-TW") {
            language = "中文 (繁體)"
        } else if (data.preferredLanguage == "ja") {
            language = "日本語"
        } else {
            language = "한국어"
        }

        const dateOfBirth = moment(data.dateOfBirth, 'MM-YYYY-DD').format('MM/DD/YYYY');
        console.log('debug', data);
        return (
            <div className={cx(cs.adminContentPadding)}>
                <div className={cs.textLineBreak}>
                    <div className={cx(cs.dFlexContainer, cs.spaceBottom4, cs.mobileDisplayBlock)}>
                        <h1 className={cx(cs.commonTotalText, cs.fontWeightBold)}><FormattedMessage {...messages.viewProfile} /></h1>
                        <div className={cx(s.gobackMobileSpace, cs.dFlex, cs.textAlignRight, cs.mobileDisplayBlock, 'textAlignLeftRTL')}>
                            <Link to={"/siteadmin/users"} className={cx(cs.siteLinkColor, cs.commonContentText, cs.fontWeightMedium, cs.commomLinkborderBottom, cs.textDecorationNone)}>
                                <img src={arrowIcon} className={cx(cs.backArrowStyle, 'adminGoBackArrowRTL')} />
                                <FormattedMessage {...messages.goBack} />
                            </Link>
                        </div>
                    </div>
                    <div className={cx(cs.commonAdminBorderSection, s.noProfilePadding)}>
                        <div className={s.profileHeader}>
                            <div className={cx(cs.dFlex, cs.mobileDisplayBlock)}>
                                <div>
                                    {
                                        data && data.picture && <span>
                                            <img
                                                src={"/files/" + data.picture}
                                                className={cx(s.profileAvatar, 'adminUserProfilePicRTL')}
                                            />
                                        </span>
                                    }
                                    {
                                        !data.picture && <span>
                                            <Avatar
                                                isUser
                                                height={80}
                                                width={80}
                                                className={cx(s.profileAvatar, 'adminUserProfilePicRTL')}
                                            />
                                        </span>
                                    }
                                </div>
                                <div className={cx(cs.commonTotalText, cs.fontWeightBold)}>
                                    {data.firstName}{' '}{data.lastName}
                                </div>
                            </div>
                        </div>

                        {
                            data && data.dateOfBirth && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.birthdayLabel} /></div>
                                <div>{dateOfBirth}</div>
                            </div>
                        }
                        {
                            data && data.gender && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.gender} /></div>
                                <div>{data.gender}</div>
                            </div>
                        }
                        {
                            data && data.profileBanStatus.email && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.emailLabel} /></div>
                                <div>{data.profileBanStatus.email}</div>
                            </div>
                        }
                        {
                            data && data.phoneNumber && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.phoneNumber} /></div>
                                <div>{data.phoneNumber}</div>
                            </div>
                        }
                        {
                            data && isUserProfileExt.identity_id && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.userIdentifyIDNumber} /></div>
                                <div>{isUserProfileExt.identity_id}</div>
                            </div>
                        }
                        {
                            data && isUserProfileExt.driver_license && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.userDriverLicense} /></div>
                                <div>{isUserProfileExt.driver_license}</div>
                            </div>
                        }
                        {
                            data && isUserProfileExt.address && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.address} /></div>
                                <div>{isUserProfileExt.address}</div>
                            </div>
                        }
                        {
                            data && isUserProfileExt.city && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.userCity} /></div>
                                <div>{isUserProfileExt.city}</div>
                            </div>
                        }
                        {
                            data && isUserProfileExt.province && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.userProvince} /></div>
                                <div>{isUserProfileExt.province}</div>
                            </div>
                        }
                        {/* {
                            data && data.phoneNumber && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.phoneNumber} /></div>
                                <div>{data.phoneNumber}</div>
                            </div>
                        } */}

                        {
                            data && data.info && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.bioInfo} /></div>
                                <div>{data.info}</div>
                            </div>
                        }
                        {
                            data && data.location && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.location} /></div>
                                <div>{data.location}</div>
                            </div>
                        }
                        {
                            isVerify && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileViewGrid)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.verification} /></div>
                                {
                                    <div>
                                        <span>{isEmail}</span>
                                        {isGoogle && isEmail && <span>,{' '}</span>}
                                        <span>{isGoogle}</span>
                                        {isDocument && (isGoogle || isEmail) && <span>, {' '}</span>}
                                        <span>{isDocument}, {' '}</span>
                                        <span>{isDriverLicense}, {' '}</span>
                                        <span>{isPhotoProfile} </span>
                                        {/* {isFacebook && isFacebook && <span>, {' '}</span>}
                                        <span>{isFacebook}</span> */}
                                        {isSMS && (isGoogle || isEmail || isDocument) && <span>, {' '}</span>}
                                        <span>{isSMS} </span>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <hr></hr>
                    <div className={cx(cs.commonAdminBorderSection, s.noProfilePadding)}>
                        <div className={s.profileHeader}>
                            <div className={cx(cs.dFlex, cs.mobileDisplayBlock)}>

                                <div className={cx(cs.commonTotalText, cs.fontWeightBold)}>
                                    <FormattedMessage {...messages.documentverificaiton} />
                                </div>
                            </div>
                        </div>
                        {
                            data && data.picture && data.userVerifiedInfo && <div
                                className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileDocViewGrid, 'DocVerificationStat' + data.userVerifiedInfo.isPhotoVerified)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.userPhotoConnected} /></div>
                                <div>
                                    {data && data.picture && <span className={cx(s.profileAvatarView)}>
                                        <img
                                            src={"/files/" + data.picture}
                                            className={cx(s.profileAvatarMd, 'adminUserProfilePicRTL')}
                                        />
                                    </span>}
                                </div>
                                <div>
                                    {data.userVerifiedInfo.isPhotoVerified == 1 &&
                                        <span className={s.profileDocStatusName}>
                                            <FormattedMessage {...messages.messageStatus4} />
                                        </span>
                                    }

                                    {data && data.picture && data.userVerifiedInfo.isPhotoVerified == 0 &&

                                        <div >
                                            <select className={s.selectDocStatus} value={'pending'} onChange={(e) => this.handleUpdate(1, data.userId, e && e.target && e.target.value, e.target.value, 4, data.firstName, data.profileBanStatus.email)}>
                                                <option value={'pending'}>{formatMessage(messages.messageStatus5)}</option>
                                                <option value={'approved'}>{formatMessage(messages.approve)}</option>
                                                <option value={'reject'}>{formatMessage(messages.documentReject)}</option>
                                            </select>
                                        </div>

                                    }
                                </div>
                            </div>
                        }
                        {
                            data && isUserProfileExt.identity_id != null && isDocumentVerification && <div
                                className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileDocViewGrid, 'DocVerificationStat' + identityVerify)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.userIDPassport} />
                                    <span className={s.profileDTBold}>{isUserProfileExt.identity_id}</span>
                                </div>
                                <div>
                                    {isDocumentVerification.map((item, index) => {
                                        if (item.verificationType == 2) {
                                            return (
                                                <div key={index}>
                                                    <a className={cx(s.profileDocUploadedLink)} href={'/files/' + item.fileName} target={"_blank"}><div className={cx(s.profileDocUploaded)} style={{ backgroundImage: `url(/files/${item.fileName})` }}>
                                                    </div>
                                                    </a>
                                                </div>
                                            )
                                        }
                                    })
                                    }
                                </div>
                                <div>
                                    {isUserProfileExt.identity_verify == 1 &&
                                        <span className={s.profileDocStatusName}>
                                            <FormattedMessage {...messages.messageStatus4} />
                                        </span>
                                    }

                                    {isUserProfileExt.identity_verify == 0 &&
                                        isDocumentVerification.map((item, index) => {
                                            if (item.verificationType == 2) {
                                                return (
                                                    <div key={index}>
                                                        <select className={s.selectDocStatus} value={'pending'} onChange={(e) => this.handleUpdate(item?.id, item?.userId, e && e.target && e.target.value, e.target.value, item?.verificationType, data.firstName, data.profileBanStatus.email)}>
                                                            <option value={'pending'}>{formatMessage(messages.messageStatus5)}</option>
                                                            <option value={'approved'}>{formatMessage(messages.approve)}</option>
                                                            <option value={'reject'}>{formatMessage(messages.documentReject)}</option>
                                                        </select>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        }
                        {
                            data && isDocumentVerification && isUserProfileExt.driver_license != null && <div className={cx(s.dataListItem, cs.commonContentText, cs.fontWeightNormal, s.profileDocViewGrid, 'DocVerificationStat' + isUserProfileExt.driver_license_verify)}>
                                <div className={cs.fontWeightMedium}><FormattedMessage {...messages.userDriverLicense} />
                                    <span className={s.profileDTBold}>{isUserProfileExt.driver_license}</span>
                                </div>
                                <div>
                                    {isDocumentVerification.map((item, index) => {
                                        if (item.verificationType == 3) {
                                            return (
                                                <div key={index}>
                                                    <a className={cx(s.profileDocUploadedLink)} href={'/files/' + item.fileName} target={"_blank"}>
                                                        <div className={cx(s.profileDocUploaded)} style={{ backgroundImage: `url(/files/${item.fileName})` }}>
                                                        </div>
                                                    </a>
                                                </div>
                                            )
                                        }
                                    })
                                    }
                                </div>
                                <div>
                                    {isUserProfileExt.driver_license_verify == 1 &&
                                        <span className={s.profileDocStatusName}>
                                            <FormattedMessage {...messages.messageStatus4} />
                                        </span>
                                    }
                                    {isUserProfileExt.driver_license_verify == 0 &&
                                        isDocumentVerification.map((item, index) => {
                                            if (item.verificationType == 3) {
                                                return (
                                                    <div key={index}>

                                                        <select className={s.selectDocStatus} value={'pending'} onChange={(e) => this.handleUpdate(item?.id, item?.userId, e && e.target && e.target.value, e.target.value, item?.verificationType, data.firstName, data.profileBanStatus.email)}>
                                                            <option value={'pending'}>{formatMessage(messages.messageStatus5)}</option>
                                                            <option value={'approved'}>{formatMessage(messages.approve)}</option>
                                                            <option value={'reject'}>{formatMessage(messages.documentReject)}</option>

                                                        </select>
                                                    </div>
                                                )
                                            }
                                        })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

}


export default compose(
    injectIntl, withStyles(s, cs),
    graphql(DocumentManagement, { options: { fetchPolicy: 'network-only' } }),

)(ProfileVerifiedView);