import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Grid, Row, FormGroup, Col, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Verification.css';
import { loadAccount } from '../../../actions/account';
import messages from '../../../locale/messages';
import refreshIcon from '/public/SiteIcons/Refresh.svg';

class EmailVerification extends Component {
	static propTypes = {
		guestEmail: PropTypes.string.isRequired,
		resendEmailVerification: PropTypes.func.isRequired,
		nextPage: PropTypes.func.isRequired,
		loadAccount: PropTypes.func.isRequired,
		account: PropTypes.object,
		intl: PropTypes.object.isRequired,
	};

	handleClick = async () => {
		const { nextPage, loadAccount, account } = this.props;
		await loadAccount();
		if (account?.verification?.isEmailConfirmed) {
			nextPage('payment');
		}
	}

	render() {
		const { guestEmail, resendEmailVerification, intl: { formatMessage } } = this.props;
		const toolTipText = formatMessage(messages.refreshToolTip);

		return (
			<Grid>
				<Row>
					<div className={s.pageContainer}>
						<div className={s.activationStepPanel}>
							<div className={s.panelBody}>
								<h3 className={s.heading}><FormattedMessage {...messages.checkEmail} /></h3>
								<FormGroup className={s.formGroup}>
									<FormControl value={guestEmail} className={cx(s.formControlInput, s.textCenter)} disabled />
								</FormGroup>
								<div className={cx(s.textLead, s.space4)}>
									<FormattedMessage {...messages.verificationInfo1} /> <FormattedMessage {...messages.verificationInfo2} />
								</div>
								<Col xs={12} sm={12} md={12} lg={12}>
									<Button className={cx(s.button, s.btnPrimary)} onClick={resendEmailVerification}>
										<FormattedMessage {...messages.resendEmail} />
									</Button>
									<div className={s.specialPriceIcon}>
										<span className={s.iconSection}>
											<OverlayTrigger
												overlay={<Tooltip className={s.tooltip}>{toolTipText}</Tooltip>}
												placement="top"
												delayShow={300}
												delayHide={150}
											>
												<img src={refreshIcon} className={cx(s.refreshImage, 'refreshImageRTL')} onClick={this.handleClick} alt="Refresh" />
											</OverlayTrigger>
										</span>
									</div>
								</Col>
							</div>
						</div>
					</div>
				</Row>
			</Grid>
		);
	}
}

const mapState = (state) => ({
	account: state.account.data,
});

const mapDispatch = {
	loadAccount
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(EmailVerification)));