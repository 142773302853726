import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage, useIntl } from "react-intl";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import PasswordStrengthBar from "react-password-strength-bar";
import CommonFormComponent from "../CommonField/CommonFormComponent";
import { graphql, gql, compose } from "react-apollo";
import submit from "./submit";
import validate from "./validate";
import messages from "../../locale/messages";
import { connect } from "react-redux";
import userAccountQuery from "./userAccountQuery.graphql";
import PopulateData from "../../helpers/populateData";
import Loader from "../Loader/Loader";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import s from "./EditProfileForm.css";
import cs from "../../components/commonStyle.css";
import {
  Button,
  Form,
  FormGroup,
  FormControl,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { updateUserPassword } from "../../actions/userManagerSecurity";
import CountryList from "../CountryList/CountryList";
import history from "../../core/history";
import infoImage from "/public/SiteIcons/priceHoverIcon.svg";
import ShowPassword from "/public/SiteIcons/pswVisible.svg";
import HidePassword from "/public/SiteIcons/pwdHidden.svg";

const EditProfileForm = (props) => {
  const {
    loadAccount,
    updateUserPassword,
    registeredType,
    allConfirmed,
    loadingUpdatePassword,
    userAccountData,
    error,
    handleSubmit,
    submitting,
    initialValues,
    change,
  } = props;

  const [state, setState] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
    dateOfBirthData: {},
    countryCode: "DO",
    country_code: "DO",
    country: "+1",
    newPassword: "",
    confirmPassword: "",
    setOldPassword: null,
    showConfirmDialog: false,
  });

  const intl = useIntl();

  const formatMessage = (id) => {
    if (!messages[id]) return "";
    return intl.formatMessage(messages[id]);
  };

  useEffect(() => {
    let now = new Date();
    let currentYear = now.getFullYear();
    let years = PopulateData.generateData(1900, currentYear, "desc");
    let days = PopulateData.generateData(1, 31);
    let months = PopulateData.generateData(0, 11);
    setState((prevState) => ({
      ...prevState,
      dateOfBirthData: {
        years: years,
        months: months,
        days: days,
      },
    }));
  }, []);

  useEffect(() => {
    if (initialValues && initialValues.email) {
      const { email, countryName, countryCode } = initialValues;

      const {
        province,
        driver_license,
        address,
        city,
        identity_id,
        identity_type,
        country_code,
      } = initialValues.userProfileData || {};

      change("loggedinEmail", email);
      change("province", province);
      change("driver_license", driver_license);
      change("address", address);
      change("city", city);
      change("identity_id", identity_id);
      change("identity_type", identity_type);

      if (country_code) {
        setState((prevState) => ({
          ...prevState,
          country_code,
        }));
      }

      if (countryName && countryCode) {
        setState((prevState) => ({
          ...prevState,
          countryCode: countryName,
          country: countryCode,
        }));
      }
    }
  }, [initialValues, change]);

  const renderCountryList = ({
    input,
    label,
    meta: { touched, error },
    children,
    className,
    disabled,
  }) => {
    return (
      <FormGroup className={cs.spaceBottom4}>
        <label>{label}</label>
        <CountryList input={input} className={className} disabled={disabled} />
        {touched && error && (
          <span className={cs.errorMessage}>{formatMessage(error)}</span>
        )}
      </FormGroup>
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeUpdateNewPassword = async () => {
    const { newPassword, confirmPassword, oldPassword } = state;
    setState((prevState) => ({ ...prevState, loadingUpdatePassword: true }));
    const respUpdatePassword = await updateUserPassword(
      oldPassword,
      newPassword,
      confirmPassword,
      registeredType,
    );
    if (respUpdatePassword) {
      setState((prevState) => ({
        ...prevState,
        loadingUpdatePassword: false,
        newPassword: "",
        confirmPassword: "",
        oldPassword: "",
      }));
    }
  };


const renderFormControlPassword = useCallback(({
  input,
  label,
  type,
  meta: { touched, error },
  showPassword,
  placeholder,
  className,
}) => {
  return (
    <FormGroup className={cx(cs.spaceBottom1, cs.positionRelative)}>
      <label>{label}</label>
      <FormControl
        {...input}
        type={showPassword ? input : type}
        className={className}
        placeholder={placeholder}
        maxLength={25}
        autoComplete={"off"}
      />
      {type === "password" && (
        <span
          onClick={() => handleChange({
            target: {
              name: input.name,
              value: !showPassword,
            },
          })}
          className={cx(cs.passwordIcon, "passwordIconRTL")}
        >
          {showPassword ? (
            <img src={ShowPassword} alt="Show Password" />
          ) : (
            <img src={HidePassword} alt="Hide Password" />
          )}
        </span>
      )}
      {touched && error && (
        <span className={cs.errorMessage}>{formatMessage(error)}</span>
      )}
    </FormGroup>
    );
  }, []);

  const documentDriverLicense = () => {
    history.push("/document-verification/3/");
  };

  const documentIdPassport = () => {
    history.push("/document-verification/2/");
  };

  const handleInputEmpty = (e) => {
    const { value } = e.target;
    if (value.trim() === "") {
      e.target.value = "";
    }
  };

  const {
    dateOfBirthData,
    newPassword,
    confirmPassword,
    showConfirmDialog,
  } = state;

  let idVerifiedStatus = 0,
    isLicenseVerifiedStatus = false,
    allConfirmedInfo = false;

  const { loading, userAccountExt } = userAccountData || {};

  if (userAccountExt) {
    const { verification } = userAccountExt;
    allConfirmedInfo =
      verification.isEmailConfirmed &&
      verification.isIdNumberVerification === 1 &&
      verification.isLicenseVerified === 1;

    idVerifiedStatus = verification.isIdNumberVerification;
    isLicenseVerifiedStatus = verification.isLicenseVerified !== 0;
  }

  const handleSubmitWithConfirmation = (event) => {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, showConfirmDialog: true }));
  };

  const handleConfirmSubmit = () => {
    setState((prevState) => ({ ...prevState, showConfirmDialog: false }));
    handleSubmit(submit)();
  };

  const handleCancelSubmit = () => {
    setState((prevState) => ({ ...prevState, showConfirmDialog: false }));
  };

  return (
    <div
      className={cx("inputFocusColor", cs.commonBorderSection, "whiteBgColor")}
    >
      <Loader type={"pagefixed"} show={submitting} />
      {loading && <Loader type={"text"} />}
      {!loading && (
        <Form onSubmit={handleSubmitWithConfirmation}>
          <Field
            name="allConfirmed"
            type="hidden"
            component={CommonFormComponent}
          />
          <div className={s.displayGrid}>
            <Field
              name="firstName"
              type="text"
              component={renderFormControl}
              label={formatMessage("firstName")}
              placeholder={formatMessage("firstName")}
              className={
                !allConfirmedInfo
                  ? cx(cs.formControlInput, "commonInputPaddingRTL")
                  : cx(
                    s.iFieldLocked,
                    cs.formControlInput,
                    "commonInputPaddingRTL",
                  )
              }
              inputPaddingClass={cx(
                s.paddingRight,
                "RTLPaymentFirstNamePadding",
              )}
              infoText={formatMessage("lastNameInfo")}
              showToolTip
              maxLength={30}
            />
            <Field
              name="lastName"
              type="text"
              component={renderFormControl}
              label={formatMessage("lastName")}
              placeholder={formatMessage("lastName")}
              className={
                !allConfirmedInfo
                  ? cx(cs.formControlInput, "commonInputPaddingRTL")
                  : cx(
                    s.iFieldLocked,
                    cs.formControlInput,
                    "commonInputPaddingRTL",
                  )
              }
              inputPaddingClass={cx(s.paddingLeft, "RTLPaymentLastNamePadding")}
              maxLength={30}
            />
          </div>
          <div className={s.displayGrid}>
            <div>
              <Field
                name="gender"
                className={cs.formControlSelect}
                label={formatMessage("genderLabel")}
                inputPaddingClass={cx(
                  s.paddingRight,
                  "RTLPaymentFirstNamePadding",
                )}
                component={renderFormControlSelect}
              >
                <option value="">{formatMessage("gender")}</option>
                <option value="Male">{formatMessage("genderMale")}</option>
                <option value="Female">{formatMessage("genderFemale")}</option>
                <option value="Other">{formatMessage("genderOther")}</option>
              </Field>
            </div>

            <div className={cx(s.paddingLeft, "RTLPaymentLastNamePadding")}>
              <label className={cs.spaceBottom8}>
                {formatMessage("dateOfBirth")}
              </label>
              <div className={s.dateGridSection}>
                <Field
                  name="month"
                  className={cx(cs.formControlSelect, s.editSelectPadding)}
                  component={renderFormControlSelect}
                >
                  <option value="">{formatMessage("month")}</option>
                  {dateOfBirthData &&
                    dateOfBirthData.months &&
                    dateOfBirthData.months.length > 0 &&
                    dateOfBirthData.months.map((item, key) => {
                      return (
                        <option key={key} value={item}>
                          {item + 1}
                        </option>
                      );
                    })}
                </Field>
                <Field
                  name="day"
                  className={cx(cs.formControlSelect, s.editSelectPadding)}
                  inputPaddingClass={s.datePadding}
                  component={renderFormControlSelect}
                >
                  <option value="">{formatMessage("transferDate")}</option>
                  {dateOfBirthData &&
                    dateOfBirthData.days &&
                    dateOfBirthData.days.length > 0 &&
                    dateOfBirthData.days.map((item, key) => {
                      return (
                        <option key={key} value={item}>
                          {item}
                        </option>
                      );
                    })}
                </Field>
                <Field
                  name="year"
                  className={cx(cs.formControlSelect, s.editSelectPadding)}
                  component={renderFormControlSelect}
                >
                  <option value="">{formatMessage("year")}</option>
                  {dateOfBirthData &&
                    dateOfBirthData.years &&
                    dateOfBirthData.years.length > 0 &&
                    dateOfBirthData.years.map((item, key) => {
                      return (
                        <option key={key} value={item}>
                          {item}
                        </option>
                      );
                    })}
                </Field>
              </div>
            </div>
          </div>

          <div className={s.displayGrid}>
            <Field
              name="email"
              type="text"
              component={renderFormControlEmail}
              label={formatMessage("emailLabel")}
              className={cx(
                cs.formControlInput,
                s.editProfileEmail,
                "commonInputPaddingRTL",
              )}
              disabled={true}
              inputPaddingClass={cx(
                s.paddingRight,
                "RTLPaymentFirstNamePadding",
              )}
            />

            <div>
              <label className={cs.spaceBottom8}>
                {formatMessage("chooseLanguage")}
              </label>
              <Field
                name="preferredLanguage"
                className={cs.formControlSelect}
                inputPaddingClass={cx(
                  s.paddingRight,
                  "RTLPaymentFirstNamePadding",
                )}
                component={renderFormControlSelect}
              >
                <option value="en-US">{"English"}</option>
                <option value="es">{"Español"}</option>
              </Field>
            </div>
          </div>

          <div className={s.contentUpdatePassword}>
            <h3 className={cx(cs.commonTotalText, cs.spaceBottom4)}>
              <FormattedMessage {...messages.changePassword} />
            </h3>

            {error && <strong>{error}</strong>}

            {initialValues && registeredType === "email" && (
              <Field
                name="oldPassword"
                type="password"
                className={cx(
                  cs.formControlInput,
                  cs.spaceBottom2,
                  "passwordInputRTL",
                  s.formControlpassWord,
                )}
                component={renderFormControlPassword}
                onChange={handleChange}
                label={formatMessage("oldPassword")}
                showPassword={state.oldPassword}
                placeholder={formatMessage("enterOldPassword")}
              />
            )}
            <div className={cx(s.displayGrid, s.paddingTopGrid)}>
              <div className={cx(s.paddingField)}>
                <Field
                  name="newPassword"
                  type="password"
                  component={renderFormControlPassword}
                  onChange={handleChange}
                  className={cx(
                    cs.formControlInput,
                    s.paddingRight,
                    "passwordInputRTL",
                    s.formControlpassWord,
                  )}
                  showPassword={state.newPassword}
                  label={formatMessage("newPassword")}
                  placeholder={formatMessage("newPassword")}
                />
                <PasswordStrengthBar
                  className={cx(s.fieldPassProgressBar)}
                  password={newPassword}
                />
              </div>
              <div className={cx(s.paddingField)}>
                <Field
                  name="confirmPassword"
                  type="password"
                  className={cx(
                    cs.formControlInput,
                    "passwordInputRTL",
                    s.formControlpassWord,
                  )}
                  component={renderFormControlPassword}
                  onChange={handleChange}
                  showPassword={state.confirmPassword}
                  label={formatMessage("confirmNewPassword")}
                  placeholder={formatMessage("confirmNewPassword")}
                />
                <PasswordStrengthBar
                  className={cx(s.fieldPassProgressBar)}
                  password={confirmPassword}
                />
              </div>
            </div>
            <div className={cx(cs.textAlignRight, "textAlignLeftRTL")}>
              <Button
                className={cs.btnPrimary}
                type="button"
                onClick={handleChangeUpdateNewPassword}
                disabled={loadingUpdatePassword}
              >
                <FormattedMessage {...messages.updatePassword} />
              </Button>
            </div>
          </div>

          <div className={cx(cs.contentSplitBorderTop)}></div>
          {!idVerifiedStatus && (
            <p onClick={documentIdPassport} className={cx(cs.isNotVerified)}>
              {formatMessage("userIDNotVerified")}
            </p>
          )}
          <div
            className={
              !idVerifiedStatus
                ? cx(s.displayGrid, cs.isFieldNoVerified)
                : cx(s.displayGrid)
            }
          >
            <div>
              <Field
                name="identity_type"
                className={
                  !idVerifiedStatus
                    ? cx(cs.formControlSelect)
                    : cx(s.iFieldLocked, cs.formControlSelect)
                }
                label={formatMessage("userIdentifyIDType")}
                inputPaddingClass={cx(
                  s.paddingRight,
                  "RTLPaymentFirstNamePadding",
                )}
                component={renderFormControlSelect}
                infoText={formatMessage("typeNumberInfo")}
                showToolTip
                isDisabled={!idVerifiedStatus ? false : true}
              >
                <option value="2">{formatMessage("userPassportID")}</option>
                <option value="3">{formatMessage("userCedula")}</option>
              </Field>
            </div>

            <div className={cx(s.paddingLeft, "RTLPaymentLastNamePadding")}>
              <Field
                name="identity_id"
                type="text"
                component={renderFormControl}
                label={formatMessage("userIdentifyIDNumber")}
                className={
                  !idVerifiedStatus
                    ? cx(cs.formControlInput, "commonInputPaddingRTL")
                    : cx(
                      s.iFieldLocked,
                      cs.formControlInput,
                      "commonInputPaddingRTL",
                    )
                }
                inputPaddingClass={cx(
                  s.paddingRight,
                  "RTLPaymentFirstNamePadding",
                )}
                infoText={formatMessage("idNumberInfo")}
                showToolTip
                isDisabled={!idVerifiedStatus ? false : true}
              />
            </div>
          </div>
          {!isLicenseVerifiedStatus && (
            <p onClick={documentDriverLicense} className={cx(cs.isNotVerified)}>
              {formatMessage("userLicenseNotVerified")}
            </p>
          )}
          <div
            className={
              !isLicenseVerifiedStatus
                ? cx(s.displayGrid, cs.isFieldNoVerified)
                : cx(s.displayGrid)
            }
          >
            <Field
              name="driver_license"
              type="text"
              onChange={handleInputEmpty}
              component={renderFormControl}
              label={formatMessage("userDriverLicense")}
              className={
                !isLicenseVerifiedStatus
                  ? cx(cs.formControlInput, "commonInputPaddingRTL")
                  : cx(
                    s.iFieldLocked,
                    cs.formControlInput,
                    "commonInputPaddingRTL",
                  )
              }
              inputPaddingClass={cx(
                s.paddingRight,
                "RTLPaymentFirstNamePadding",
              )}
              infoText={formatMessage("driverLicenseInfo")}
              showToolTip
              isDisabled={!isLicenseVerifiedStatus ? false : true}
            />
            <Field
              name="country_code"
              component={renderCountryList}
              label={formatMessage("country")}
              className={cs.formControlSelect}
            />
          </div>

          <div className={s.displayGrid}>
            <Field
              name="province"
              type="text"
              component={renderFormControl}
              label={formatMessage("userProvince")}
              className={cx(cs.formControlInput, "commonInputPaddingRTL")}
              inputPaddingClass={cx(
                s.paddingRight,
                "RTLPaymentFirstNamePadding",
              )}
            />
            <Field
              name="city"
              type="text"
              component={renderFormControl}
              label={formatMessage("userCity")}
              className={cx(cs.formControlInput, "commonInputPaddingRTL")}
              inputPaddingClass={cx(
                s.paddingRight,
                "RTLPaymentFirstNamePadding",
              )}
            />
          </div>

          <Field
            name="address"
            component={renderFormControlTextArea}
            label={formatMessage("userAddress")}
            className={cx(cs.formControlInput, "commonInputPaddingRTL")}
          />
          <div className={cx(cs.contentSplitBorderBottom)}></div>
          <Field
            name="info"
            component={renderFormControlTextArea}
            label={formatMessage("info")}
            className={cx(cs.formControlInput, "commonInputPaddingRTL")}
          />
          <p className={cx(cs.commonMediumText, s.paddingBottom4)}>
            {formatMessage("DescribeProfileInfo")}
          </p>
          <div className={cx(cs.textAlignRight, "textAlignLeftRTL")}>
            <Button
              className={cx(cs.btnPrimary, cs.btnMedium)}
              type="submit"
              disabled={submitting}
            >
              {formatMessage("save")}
            </Button>
          </div>
        </Form>
      )}

      <Modal show={showConfirmDialog} onHide={handleCancelSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{"Confirm Document Change"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            "You're about to change your lisence/ID number, please consider this will need to be verified again."
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelSubmit}>
            {"Cancel"}
          </Button>
          <Button variant="primary" onClick={handleConfirmSubmit}>
            {"Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};


const renderFormControlTextArea = ({
  input,
  label,
  meta: { touched, error },
  children,
  className,
  isDisabled,
}) => {
  const intl = useIntl();

  const formatMessage = (id) => {
    if (!messages[id]) return "";
    return intl.formatMessage(messages[id]);
  };
  return (
    <FormGroup className={cs.spaceBottom4}>
      <label className={cs.spaceBottom8}>{label}</label>
      <FormControl
        {...input}
        className={className}
        componentClass="textarea"
        rows={5}
        placeholder={label}
        disabled={isDisabled}
      >
        {children}
      </FormControl>
      {touched && error && (
        <span className={cs.errorMessage}>{formatMessage(error)}</span>
      )}
    </FormGroup>
  );
};


const renderFormControl = ({
  input,
  label,
  type,
  meta: { touched, error },
  className,
  maxLength,
  isDisabled,
  inputPaddingClass,
  infoText,
  showToolTip,
}) => {
  const intl = useIntl();

  const formatMessage = (id) => {
    if (!messages[id]) return "";
    return intl.formatMessage(messages[id]);
  };
  return (
    <FormGroup className={cx(cs.spaceBottom4, inputPaddingClass)}>
      <label className={cs.spaceBottom8}>
        {label}
        {showToolTip && (
          <div className={cx(s.specialPriceIcon, "specialPriceIconRTL")}>
            <span className={"svgImg"}>
              <img
                src={infoImage}
                className={cx(s.faqImage, "specialpriceRtl")}
                alt="Info"
              />
            </span>
            <div
              className={cx(
                s.toolTip,
                s.toolTipRelativeSection,
                "toolTipDarkMode",
                "toolTipRelativeSectionRTL",
              )}
            >
              {infoText}
            </div>
          </div>
        )}
      </label>
      <FormControl
        {...input}
        placeholder={label}
        type={type}
        className={className}
        disabled={isDisabled}
        maxLength={maxLength}
      />
      {touched && error && (
        <span className={cs.errorMessage}>{formatMessage(error)}</span>
      )}
    </FormGroup>
  );
};

const renderFormControlEmail = ({
  input,
  label,
  type,
  meta: { touched, error },
  className,
  disabled,
  inputPaddingClass,
}) => {
  const intl = useIntl();

  const formatMessage = (id) => {
    if (!messages[id]) return "";
    return intl.formatMessage(messages[id]);
  };
  return (
    <FormGroup className={cx(cs.spaceBottom4, inputPaddingClass)}>
      <label className={cs.spaceBottom8}>{label}</label>
      <FormControl
        {...input}
        placeholder={label}
        type={type}
        className={className}
        disabled={disabled}
      />
      {touched && error && (
        <span className={cs.errorMessage}>{formatMessage(error)}</span>
      )}
    </FormGroup>
  );
};

const renderFormControlSelect = ({
  input,
  label,
  meta: { touched, error },
  children,
  className,
  isDisabled,
  inputPaddingClass,
  infoText,
  showToolTip,
}) => {
  const intl = useIntl();

  const formatMessage = (id) => {
    if (!messages[id]) return "";
    return intl.formatMessage(messages[id]);
  };
  return (
    <FormGroup className={cx(cs.spaceBottom4, inputPaddingClass)}>
      {label && (
        <label className={cs.spaceBottom8}>
          {label}
          {showToolTip && (
            <div className={cx(s.specialPriceIcon, "specialPriceIconRTL")}>
              <span className={"svgImg"}>
                <img
                  src={infoImage}
                  className={cx(s.faqImage, "specialpriceRtl")}
                  alt="Info"
                />
              </span>
              <div
                className={cx(
                  s.toolTip,
                  s.toolTipRelativeSection,
                  "toolTipDarkMode",
                  "toolTipRelativeSectionRTL",
                )}
              >
                {infoText}
              </div>
            </div>
          )}
        </label>
      )}
      <FormControl
        disabled={isDisabled}
        componentClass="select"
        {...input}
        className={cx(className, "selectBgImage")}
      >
        {children}
      </FormControl>
      {touched && error && (
        <span className={cs.errorMessage}>{formatMessage(error)}</span>
      )}
    </FormGroup>
  );
};


EditProfileForm.propTypes = {
  loadAccount: PropTypes.func,
  updateUserPassword: PropTypes.any.isRequired,
  formatMessage: PropTypes.func,
  registeredType: PropTypes.string,
  allConfirmed: PropTypes.bool,
  loadingUpdatePassword: PropTypes.bool,
  userAccountData: PropTypes.shape({
    loading: PropTypes.bool,
    userAccountExt: PropTypes.shape({
      email: PropTypes.string,
      displayName: PropTypes.string,
      firstName: PropTypes.string,
      verification: PropTypes.shape({
        isEmailConfirmed: PropTypes.bool,
        isIdNumberVerification: PropTypes.number,
        isLicenseVerified: PropTypes.number,
        isIdVerification: PropTypes.number,
      }),
      userProfileData: PropTypes.shape({
        isAgreementSigned: PropTypes.number,
        driver_license_verify: PropTypes.number,
        identity_verify: PropTypes.number,
        identity_id: PropTypes.string,
        driver_license: PropTypes.string,
      }),
    }),
  }),
};

EditProfileForm.defaultProps = {
  registeredType: "email",
  allConfirmed: false,
  loadingUpdatePassword: false,
  userAccountData: {
    loading: true,
    userAccountExt: {
      email: null,
      displayName: null,
      firstName: null,
      verification: {
        isEmailConfirmed: false,
        isIdNumberVerification: 0,
        isLicenseVerified: 0,
        isIdVerification: 0,
      },
      userProfileData: {
        isAgreementSigned: 0,
        driver_license_verify: 0,
        identity_verify: 0,
        identity_id: null,
        driver_license: null,
      },
    },
  },
};

const mapState = (state) => ({
  initialValues: state.account.data,
  availableCurrencies: state.currency.availableCurrencies,
  base: state.currency.base,
  siteSettingStatus: state.siteSettings.data,
  registeredType: state.account.data.userData.type,
});

const mapDispatch = {
  change,
  updateUserPassword,
};

export default compose(
  injectIntl,
  withStyles(s, cs),
  connect(mapState, mapDispatch),
  graphql(
    gql`
      query getCountries {
        getCountries {
          id
          countryCode
          countryName
          isEnable
          dialCode
        }
      }
    `,
    {
      options: {
        ssr: false,
        fetchPolicy: "network-only",
      },
    },
  ),
  graphql(userAccountQuery, {
    name: "userAccountData",
    options: {
      ssr: false,
      fetchPolicy: "network-only",
    },
  }),
  reduxForm({
    form: "EditProfileForm",
    validate,
  }),
)(EditProfileForm);
