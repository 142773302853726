import { gql } from 'react-apollo';
import moment from 'moment';

import {
  POLICY_INSURANCE_PAYMENT_START,
  POLICY_INSURANCE_PAYMENT_SUCCESS,
  POLICY_INSURANCE_PAYMENT_ERROR
} from '../../constants';

import { sendPayment,sendPaymentPolicyInsurance } from '../../core/payment/sendPayment';

import { convert } from '../../helpers/currencyConvertion';
import { processStripePayment } from '../../core/payment/stripe/processStripePayment';
// import { startTimeData, endTimeData } from '../../helpers/formatting';
import showToaster from '../../helpers/toasterMessages/showToaster'

export function makePaymentPolicy(
  listId,
  listYear,
  planType,
  expirationDate,
  rateYearly,
  ratePlan,
  rateAmountYearly,
  rateAmountPlan,
  dueFirst,
  duesAmount,
  paymentQt,
  duesQT,
  planQTMonth,
  paymentType,
  paymentSubNet,
  paymentCost,
  paymentNet,
  policyAmountUSD
) {

  return async (dispatch, getState, { client }) => {
    
    dispatch({
      type: POLICY_INSURANCE_PAYMENT_START,
      payload: {
        paymentLoading: true
      }
    });

    try {

      const mutation = gql`
        mutation createPolicyInsurance(
          $listId: Int, 
          $listYear: Int,
          $planType: String,
          $expirationDate: String,
          $rateYearly: Float,
          $ratePlan: Float,
          $rateAmountYearly: Float,
          $rateAmountPlan: Float,
          $dueFirst: Float,
          $duesAmount: Float,
          $paymentQt: Int,
          $duesQT: Int,
          $planQTMonth: Int,
          $paymentType: Int,
          $paymentSubNet: Float,
          $paymentCost: Float,
          $paymentNet: Float
        ){
            createPolicyInsurance(
              listId: $listId,
              listYear: $listYear,
              planType: $planType,
              expirationDate: $expirationDate,
              rateYearly: $rateYearly,
              ratePlan: $ratePlan,
              rateAmountYearly: $rateAmountYearly,
              rateAmountPlan: $rateAmountPlan,
              dueFirst: $dueFirst,
              duesAmount: $duesAmount,
              paymentQt: $paymentQt,
              duesQT: $duesQT,
              planQTMonth: $planQTMonth,
              paymentType: $paymentType,
              paymentSubNet: $paymentSubNet,
              paymentCost: $paymentCost,
              paymentNet: $paymentNet
            ) {
                id
                listId,
                listYear,
                listTitle,
                planType,
                expirationDate,
                rateYearly,
                ratePlan,
                rateAmountYearly,
                rateAmountPlan,
                dueFirst,
                duesAmount,
                paymentQt,
                duesQT,
                planQTMonth,
                paymentType,
                paymentSubNet,
                paymentCost,
                paymentNet,
                policyInsuranceCode,
                policyInsuranceStatus,
                createdAt,
                status,
            }
        }
      `;

      const { data } = await client.mutate({
        mutation,
        variables: {
          listId,
          listYear,
          planType,
          expirationDate,
          rateYearly,
          ratePlan,
          rateAmountYearly,
          rateAmountPlan,
          dueFirst,
          duesAmount,
          paymentQt,
          duesQT,
          planQTMonth,
          paymentType,
          paymentSubNet,
          paymentCost,
          paymentNet
        }
      })

      if (data?.createPolicyInsurance) {
        
        let policyInsuranceId, amount, rates, currentCurrency, baseCurrency, convertedAmount = 0, reservationDetails,ipTitle,paymentCurrency='USD';
        policyInsuranceId = data?.createPolicyInsurance?.id;
        ipTitle = data?.createPolicyInsurance?.listTitle;
        // amount = paymentNet;
        // rates = getState().currency.rates;
        // currentCurrency = (getState().currency.to) ? getState().currency.to : getState().currency.base;
        // baseCurrency = getState().currency.base;
        

        if (paymentType == 1) {
          // convertedAmount = convert(baseCurrency, rates, amount, 'DOP', paymentCurrency);
          const { status, errorMessage } = await sendPaymentPolicyInsurance(policyInsuranceId, parseFloat(policyAmountUSD).toFixed(2), paymentCurrency, ipTitle);
          if (status == 200) {
            dispatch({
              type: POLICY_INSURANCE_PAYMENT_SUCCESS,
              payload: { paymentLoading: false }
            });
          } else {
            if (status == 422) {
              showToaster({ messageId: 'invalidCurrency', toasterType: 'error' })
            }
            else {
              errorMessage ? showToaster({ messageId: 'checkStatus', toasterType: 'error', requestMessage: errorMessage }) : '';
            }
            dispatch({
              type: POLICY_INSURANCE_PAYMENT_ERROR,
              payload: { paymentLoading: false }
            });
          }

        } else {
          // convertedAmount = convert(baseCurrency, rates, amount, currency, currentCurrency);
          // reservationDetails = {
          //   reservationId,
          //   listId,
          //   hostId,
          //   guestId,
          //   guestEmail,
          //   title,
          //   amount: convertedAmount.toFixed(2),
          //   currency: currentCurrency
          // };

          // const { status, errorMessage, paymentIntentSecret } = await processStripePayment(
          //   'reservation',
          //   null,
          //   reservationDetails,
          //   paymentMethodId
          // );

          // if (status === 200) {
          //   dispatch({
          //     type: BOOKING_PAYMENT_SUCCESS,
          //     payload: { paymentLoading: true }
          //   });

          //   return {
          //     status
          //   }

          // } else {
          //   errorMessage ? showToaster({ messageId: 'failedError', toasterType: 'error', requestMessage: errorMessage }) : '';
          //   dispatch({
          //     type: BOOKING_PAYMENT_ERROR,
          //     payload: { paymentLoading: false }
          //   });

          //   return {
          //     status,
          //     paymentIntentSecret,
          //     reservationId
          //   }
          // }
        }
      }
    } catch (error) {
      dispatch({
        type: POLICY_INSURANCE_PAYMENT_ERROR,
        payload: {
          error,
          paymentLoading: false
        }
      });
      return false;
    }

    return true;
  };
}