import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DropzoneComponent from 'react-dropzone-component';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {Row,Col} from 'react-bootstrap';

//compose
import { graphql, gql, compose } from 'react-apollo';

// Component
import DocumentList from '../DocumentList';
import showToaster from '../../helpers/toasterMessages/showToaster';

//Image
import PictureImage from '/public/SiteIcons/photoUpload.svg';

// Style
import s from '!isomorphic-style-loader!css-loader!./filepicker.css';

const query = gql`query ShowDocumentList($verificationType: Int,$listId: Int) {
    ShowDocumentList(
      verificationType: $verificationType,
      listId: $listId
    ) {
        id
        userId,
        fileName,
        fileType
    }
  }`;

class PolicyDocumentUpload extends Component {

  static propTypes = {
    listId: PropTypes.number.isRequired,
    // checkDocUploaded: PropTypes.any,
    docIdVerification: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.addedfile = this.addedfile.bind(this);
    this.complete = this.complete.bind(this);
    this.doUpdateChange = this.doUpdateChange.bind(this);
    this.dropzone = null;
  }

  componentDidMount() {
    const isBrowser = typeof window !== 'undefined';
    const isDocument = typeof document !== undefined;
    if (isBrowser && isDocument) {
      document.querySelector(".dz-hidden-input").style.visibility = 'visible';
      document.querySelector(".dz-hidden-input").style.opacity = '0';
      document.querySelector(".dz-hidden-input").style.height = '100%';
      document.querySelector(".dz-hidden-input").style.width = '100%';
      document.querySelector(".dz-hidden-input").style.cursor = 'pointer';
    }
  }
  doUpdateChange(setValue){
    
    // const { checkDocUploaded}=this.props;
    // console.log('checkDocUploaded:', checkDocUploaded);
    // console.log('doUpdateChange --> setValue:', setValue);
    // checkDocUploaded(setValue);
  }
  async complete(file) {
    const { mutate,listId,docIdVerification } = this.props;
    let variables = {};
    if (file && file.xhr) {
      const { files } = JSON.parse(file.xhr.response);
      let fileName = files[0].filename;
      let fileType = files[0].mimetype;
      variables = {
        fileName,
        fileType,
        listId,
        verificationType:docIdVerification
      };
      const { data } = await mutate({
        variables,
        refetchQueries: [
          {
            query: query,
            variables: {listId:listId, verificationType: docIdVerification }
          }
        ]
        
      });

      if (data?.uploadDocument) {
        if (data?.uploadDocument?.status === 'success') {
          this.props.onSetUploaded;
          showToaster({ messageId: 'uploadDocument', toasterType: 'success' })
        } else {
          showToaster({ messageId: 'uploadDocumentError', toasterType: 'error' })
        }
      }
    }
    this.dropzone.removeFile(file);
  }

  async addedfile(file) {

    const { maxUploadSize } = this.props;

    let filetypes = ['application/pdf']

    if (file?.size > (1024 * 1024 * parseInt(maxUploadSize))) {
      this.dropzone.removeFile(file);
      showToaster({ messageId: 'maximumUploadSize', toasterType: 'error' })
      return;
    }

    if (!filetypes.includes(file.type)) {
      showToaster({ messageId: 'invalidImageFile', toasterType: 'error' })
      this.dropzone.removeFile(file);
      return;
    }
  }

  render() {
    const { placeholder, listId,docIdVerification } = this.props;
    const djsConfig = {
      dictDefaultMessage: '',
      addRemoveLinks: false,
      maxFilesize: 10,
      maxFiles: 1,
      acceptedFiles: 'application/pdf',
      hiddenInputContainer: '.dzInputContainer'
    };
    const componentConfig = {
      iconFiletypes: ['.pdf'],
      postUrl: '/documents'
    };
    const eventHandlers = {
      init: dz => this.dropzone = dz,
      complete: this.complete,
      addedfile: this.addedfile
    };

    return (
      <div className={cx('listPhotoContainer')}>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className={cx('dzInputContainer')}>
              <DropzoneComponent
                config={componentConfig}
                eventHandlers={eventHandlers}
                djsConfig={djsConfig}
              >
                <img src={PictureImage} className={'photoUploadImg'} alt='PictureImage' />
                <span className={cx('documentPlaceholder')}>{placeholder}</span>
              </DropzoneComponent>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <DocumentList 
            onHaveDoc={this.props.onSetUploaded} 
            onRemoveDoc={this.props.offSetUploaded} 
            listId={listId} 
            docVerificationId={docIdVerification}/>
          </Col>
        </Row>
      </div>
    );
  }

}

const mapState = (state) => ({
  maxUploadSize: state.siteSettings.data.maxUploadSize
});

const mapDispatch = {

};

export default compose(withStyles(s),

  graphql(gql`mutation uploadDocument($fileName: String,$fileType: String,$listId: Int,$verificationType: Int){
     uploadDocument(
       fileName: $fileName,
       fileType: $fileType,
       listId: $listId,
       verificationType: $verificationType
     ) {    
         fileName
         fileType
         status        
        }
 }`
  ),
  (connect(mapState, mapDispatch)))(PolicyDocumentUpload);

