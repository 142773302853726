import React, { useEffect, useState } from 'react';
import CommonTable from '../../CommonTable/CommonTable';
import AdminPrivilageModal from '../adminPrivilagesModal/AdminPrivilageModal';
import { PrivilegesManagmentProps } from '../../../routes/siteadmin/privilegesManagment/privilegesManagment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './adminPrivilegesTable.css';
import cs from '../../../components/commonStyle.css';
import cx from 'classnames';
import { injectIntl, IntlShape } from "react-intl";
import { compose, gql } from 'react-apollo';
import { connect } from 'react-redux';
import TableAction from '../../CommonTable/TableAction';



const CREATE_PRIVILEGE = `
    mutation createPrivilege($privilege: String!, $permittedUrls: [String]!) {
        createPrivilege(privilege: $privilege, permittedUrls: $permittedUrls) {
            id
            privilege
        }
    }
`;


const AdminPrivileges: React.FC<PrivilegesManagmentProps> = ({ privileges }) => {
    const [showModal, setShowModal] = useState(false);
    const [currentPrivileges, setCurrentPrivileges] = useState<{id?: string, privilege: string, permittedUrls: string[]}[]>([]);
    const [selectedPrivilege, setSelectedPrivilege] = useState<{id?: number, privilege: string, permittedUrls: string[]} | null>(null);

    const handleCreateNew = () => {
        setSelectedPrivilege(null);
        setShowModal(true);
    };

    const handleEdit = (privilege: any) => {
        setSelectedPrivilege(privilege);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedPrivilege(null);
        setShowModal(false);
    };
    
    useEffect(() => {
        if (privileges) {
            setCurrentPrivileges(privileges);
        }
    }, [privileges]);


    const handleUpdate = async ({id, permittedUrls }: { id: number, permittedUrls: string[] }) => {
        try {
            const UPDATE_PRIVILEGE = `
                mutation updatePrivilege($id: Int!, $permittedUrls: [String]!) {
                    createPrivilege(id: $id, permittedUrls: $permittedUrls) {
                        id
                        permittedUrls
                    }
                }
            `;

            console.log(id, permittedUrls);

            const response = await fetch('/graphql', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query: UPDATE_PRIVILEGE,
                    variables: {
                        id,
                        permittedUrls
                    }
                }),
                credentials: 'include'
            });

            const { data } = await response.json();
            console.log(data);

            if (data.errors) {
                throw new Error(data.errors[0].message);
            }

        } catch (error) {
            console.error('Error updating privilege:', error);
            throw new Error('Failed to update privilege');
        }
    }



    const handleCreate = async ({ privilege, permittedUrls }: { privilege: string, permittedUrls: string[] }) => {
        try {
            const response = await fetch('/graphql', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query: CREATE_PRIVILEGE,
                    variables: {
                        id: undefined,
                        privilege,
                        permittedUrls
                    }
                }),
                credentials: 'include'
            });
            const { data } = await response.json();
        } catch (error) {
            console.error('Error creating privilege:', error);
            throw new Error('Failed to create privilege');
        }
    }

    const tbody = currentPrivileges?.map((privilege) => ({
        id: privilege.id,
        data: [
            { data: privilege.id },
            { data: (
                <div className={cx(cs.displayFlex, cs.alignCenter, cs.spaceBetween)}>
                    {privilege.privilege}
                    <TableAction
                        showEdit={true}
                        editAction={() => handleEdit(privilege)}
                    />
                </div>
            )},
            { 
                data: (
                    <div>
                        {privilege.permittedUrls.map((url: string, index: number) => (
                            <div key={index}>{url}</div>
                        ))}
                    </div>
                )
            }
        ]
    }));

    const thead = () => {
        return [
            { data: 'ID' },
            { data: 'Privilege' },
            { data: 'Permitted URLs' }
        ];
    };

    return (
        <div className={cx(s.pagecontentWrapper)}>
            <AdminPrivilageModal 
                // @ts-ignore
                showModal={showModal}
                // @ts-ignore 
                onClose={handleCloseModal}
                // @ts-ignore
                privilege={selectedPrivilege}
                // @ts-ignore
                createPrivilege={handleCreate}
                // @ts-ignore
                updatePrivilege={handleUpdate}
                // @ts-ignore
                intl={null}
            />
            <CommonTable
                thead={thead}
                tbody={()=>tbody}
                title="Privileges Management"
                addAction={handleCreateNew}
                isLink={true}
                redirectionLabel="Add New Privilege"
            />
        </div>
    );
};

const mapState = (state: any) => ({});

const mapDispatch = {};

export default compose(
    injectIntl,
    connect(mapState, mapDispatch),
    withStyles(s, cs)
)(AdminPrivileges as React.ComponentType<any>);
