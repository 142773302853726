import React, { ComponentType, useEffect, useRef, useState } from 'react';
import { compose } from 'react-apollo';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { Field, reduxForm, change, InjectedFormProps, FormSubmitHandler, WrappedFieldProps, } from 'redux-form';
import submit from './submit';
import {
    Button,
    Col,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './AgreementsPreview.css';
import cs from '../../components/commonStyle.css'
import cx from 'classnames';
import CommonFormComponent from '../CommonField/CommonFormComponent';
import messages from '../../locale/messages';
import arrow from '/public/siteImages/whiteArrow.svg';
import ShowNotification from '../ShowNotification/ShowNotification';
import moment from 'moment';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import { Account } from '../../types';

type AgreementsPreviewProps = {
    account: Account;
    intl: IntlShape;
} & InjectedFormProps<{}, {}, string>

const AgreementsPreview: React.FC<AgreementsPreviewProps> = ({ account, intl: { formatMessage }, handleSubmit, change }) => {
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const flags = useRef({
        agreementStatus: 0,
        agreementDealerSigned: '',
        agreementAccepted: '',
        agreementNum: '',
        agreementSign: false,
        userIdFile: '',
        showDocFile: false,
    });
    const userAccountExt = account?.userData;
    const userProfileData = account?.userProfileData
    const isAgreementSigned = !!userProfileData?.isAgreementSigned;
    const agreement = account?.agreementData?.[0];

    useEffect(() => {
        if (account && account.email) {
            change('loggedinEmail', account.email);
            change('loggedinUser', account.displayName);
        }
        if (userAccountExt) {
            const agreementId = agreement?.id;
            change('agreementId', agreementId);
        }
        if (agreement) {
            flags.current.agreementNum = agreement?.code ? agreement.code.toUpperCase() : '';
            flags.current.agreementDealerSigned = agreement?.delear_signup_date ? moment(agreement.delear_signup_date).format('DD-MM-YYYY hh:mm A').toString() : '';
            flags.current.agreementStatus = userProfileData?.isAgreementStatus;
            flags.current.agreementSign = userProfileData?.isAgreementSigned ? true : false;
            flags.current.userIdFile = account?.userId;
            flags.current.showDocFile = true;
        }
    }, [account])


    const checkboxGroup = ({ input, name }: WrappedFieldProps & { name: string }) => {
        return (
            <div className={cx(s.checkBoxGrid, cs.paddingBottom4)}>
                <CustomCheckbox
                    name={name}
                    className={cx('icheckbox_square-green', s.icheckboxWithBorder)}
                    checked={acceptedTerms}
                    onChange={setAcceptedTerms}
                />
                <p className={cx(cs.commonMediumText, cs.fontWeightNormal, s.checkBoxText, 'signUpCheckBoxTextRTl')}>
                    <FormattedMessage {...messages.iAgreeContract} />
                    <a href={"/privacy"} target={'blank'} className={cx(cs.siteLinkColor, cs.textTransformLowerCase)}>&nbsp;<FormattedMessage {...messages.termsOfService} />&nbsp;</a>
                    <FormattedMessage {...messages.and} />
                    <span>&nbsp;<a href={"/privacy"} target={'blank'} className={cx(cs.siteLinkColor, cs.textTransformLowerCase)}><FormattedMessage {...messages.privacyPolicy} /></a>&nbsp;</span>
                </p>
            </div>
        )
    }
    return (
        <Col xs={12} sm={12} md={12} lg={12} className={cs.spaceTop6}>
            <div className={cx(cs.tripsReservationPanel, 'youcarsBg', 'bgBlack')}>
                <h2 className={cx(cs.commonTotalText, cs.fontWeightBold, cs.paddingBottom4)}>{formatMessage(messages.overviewAgreement)}</h2>
                {account && !isAgreementSigned && <h4 className={cx(cs.commonTotalText, cs.fontWeightLight, cs.paddingBottom4)}>
                    {formatMessage(messages.signUpAgreementInfo)}
                </h4>}
                {showAlert && <div>
                    <h4 className={cx(cs.agreementHeaderTop, cs.agreementTitleNotification, cs.fontWeightLight, cs.paddingBottom4)}>
                        {formatMessage(messages.infoStatAgreementMissed)}
                    </h4>
                </div>}
                {/* @ts-expect-error */}
                <ShowNotification handleShowError={() => setShowAlert(true)} showAgreement />
                {!showAlert && <div>
                    <form onSubmit={handleSubmit(submit as unknown as FormSubmitHandler<{}, {}, string>)} className={cx('noScroll', 'inputFocusColor')}>
                        <div className={cx(s.containerAgreement)}>
                            {!flags.current.agreementSign && <p className={cx(s.agreementInfo)}>{formatMessage(messages.infoClickAgreement)}</p>}
                            {account && flags.current.agreementSign && account?.userProfileData?.isAgreementStatus && <div className={cx(s.containerStatReview)}>
                                <p>{account.displayName}, {formatMessage(messages.agreementInfoPending)}</p>
                                <p>{formatMessage(messages.agreementInfoPending2)} <strong>{flags.current.agreementNum}</strong> {formatMessage(messages.agreementInfoPending3)}
                                    <strong> {flags.current.agreementDealerSigned}</strong>.</p>
                            </div>}
                            {account && isAgreementSigned && account?.userProfileData?.isAgreementStatus === 2 && <div className={cx(s.containerStatReview)}>
                                <p>{account.displayName}, {formatMessage(messages.agreementInfoAccepted)}</p>
                                <p>{formatMessage(messages.agreementInfoAccepted2)} <strong>{flags.current.agreementNum}</strong> {formatMessage(messages.agreementInfoAccepted3)}
                                    <strong> {flags.current.agreementAccepted}</strong>.</p>
                            </div>}
                            {account && !!flags.current.showDocFile && <a className={cx(s.agreementPDFView)} target='_blank' href={"/get-pdf-agreement/?pdfname=agreement_" + account.userId + ".pdf"}>
                                <img className={cx(s.agreementView)} src={"/get-img-agreement/?fileid=agreement_" + account.userId + ".jpeg"} />
                            </a>}
                            <Field component={CommonFormComponent} name="loggedinEmail" type="hidden" />
                            <Field component={CommonFormComponent} name="loggedinUser" type="hidden" />
                            <Field component={CommonFormComponent} name="agreementId" type="hidden" />
                            {account && !isAgreementSigned && <Field name="iAgreeTerms" component={checkboxGroup} />}
                        </div>
                        {account && !isAgreementSigned && <Button
                            className={cx(cs.btnPrimary, cs.spaceBottom2)}
                            bsSize="large"
                            block type="submit"
                            disabled={showAlert && !acceptedTerms}
                        >
                            {formatMessage(messages.signLabel)}
                            <img src={arrow} className={cx(cs.blueLeftArrow, 'loginArrowRTL')} />
                        </Button>}
                    </form>
                </div>}
            </div>
        </Col>)
};

const AgreementsPreviewForm = reduxForm({
    form: 'AgreementsPreview',
    onSubmitFail: () => { },
    initialValues: {},
    destroyOnUnmount: false
})(AgreementsPreview as ComponentType<InjectedFormProps<any, any>>);

const mapState = (state: any) => ({
    account: state.account.data,
});

const mapDispatch = {
    change
};

const AgreementsPreviewWithData = compose(
    injectIntl,
    connect(mapState, mapDispatch),
    withStyles(s, cs)
)(AgreementsPreviewForm);

export { AgreementsPreviewWithData as AgreementsPreview };
