import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
// Redux
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
// Style
import {
  Button,
  Row,
  FormGroup,
  Col,
  ControlLabel,
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './UploadMediaGalleryForm.css';
import cs from '../../../components/commonStyle.css';
import CommonFormComponent from '../../CommonField/CommonFormComponent';

import MediaGalleryUpload from './MediaGalleryUpload';


import validate from './validate';
import messages from '../../../locale/messages';

class UploadMediaGalleryForm extends Component {

  static propTypes = {
    initialValues: PropTypes.object,
    title: PropTypes.string.isRequired
  };

  render() {
    const { error, dispatch, initialValues } = this.props;
    const { formatMessage } = this.props.intl;
    

    return (
      <div className={cx(cs.adminContentPadding)}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <h1 className={cx(cs.commonTotalText, cs.fontWeightBold, cs.spaceBottom12)}><FormattedMessage {...messages.AddMediaLibrary} /></h1>
            <div>
              <form >
                {error && <strong>{formatMessage(error)}</strong>}
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} className={cs.spaceBottom3}>
                    <div className={cx(cs.adminBorderWithoutPadding)}>
                      {/* <div className={cx(s.cardHeader)}>
                        <p className={cx(cs.commonSubTitleText, cs.fontWeightBold)}><FormattedMessage {...messages.contractInternal} /></p>
                      </div> */}
                      <div className={cx(s.cardBodyStyle)}>
                        {/* <div className={cs.spaceBottom12}>
                          <div className={cx(cs.commonContentText, cs.spaceBottom2, cs.fontWeightMedium)}>
                            <FormattedMessage {...messages.contractInternalInfo} />
                          </div>
                        </div> */}
                        <div>
                          <MediaGalleryUpload 
                              className={cx(s.doUploadContractCia)}
                              placeholder={formatMessage(messages.documentUploadPlaceholder)}
                              doUploadConfigType="contract_cia"
                              doUploadFileName={initialValues.contract_cia}
                          > 
                          </MediaGalleryUpload>
                          
                          {/* <Field name="contract_cia" type="text" component={CommonFormComponent} disabled={true} inputClass={cx(cs.formControlInput)} /> */}

                          {/* <ControlLabel className={s.labelTextNew}>
                              <FormattedMessage {...messages.contract_cia} />
                          </ControlLabel> */}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </div >
    );
  }

}

UploadMediaGalleryForm = reduxForm({
  form: 'UploadMediaGalleryForm', // a unique name for this form
  validate
})(UploadMediaGalleryForm);

const selector = formValueSelector('UploadMediaGalleryForm'); // <-- same as form name

// const mapState = (state) => ({
//   availableCurrencies: state.currency.availableCurrencies,
//   base: state.currency.base,
//   currentLocale: state.intl.locale,
//   currency: selector(state, 'currency'),
//   guestType: selector(state, 'guestType'),
//   hostType: selector(state, 'hostType'),
// });

const mapDispatch = {
};

export default injectIntl(withStyles(s, cs)(UploadMediaGalleryForm));