exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RegisterForm-displayGridDate-3jXcU {\n    display: grid;\n    grid-template-columns: 32% 36% 32%;\n}\n\n.RegisterForm-displayForm-3JWCi{\n    display: block;\n    position: relative;\n    overflow: hidden;\n    height: 645px;\n     -webkit-transition: height 0.5s linear;\n          -o-transition: height 0.5s linear;\n             transition: height 0.5s linear;             \n}\n\n.RegisterForm-textSizeRSignUp-3wWM4{\n    font-size: 17px;\n}\n\n.RegisterForm-textHidden-3Z3Xc{\n    display: none !important;\n}\n\n.RegisterForm-textSocialMedia-2YP2V{\n    display: block;\n    text-align: center;\n    cursor: pointer;\n}\n\n.RegisterForm-displayFormSocial-hzKJL{\n    display: block;\n    position: relative;\n    overflow: hidden;\n    height: 145px;\n    margin: 8px 0;\n    padding-top: 10px;\n     -webkit-transition: height 0.5s linear;\n          -o-transition: height 0.5s linear;\n             transition: height 0.5s linear;             \n}\n\n.RegisterForm-displayForm-3JWCi.RegisterForm-isHidden-2o4kt,.RegisterForm-displayFormSocial-hzKJL.RegisterForm-isHidden-2o4kt{\n    height: 0px;\n    margin: 8px 0;\n    padding-top: 10px;\n    -webkit-transition: height 0.5s linear;\n         -o-transition: height 0.5s linear;\n            transition: height 0.5s linear;\n}\n\n.RegisterForm-datePadding-3uW4m {\n    padding: 0px 8px;\n}\n\n.RegisterForm-checkBoxGrid-hzfz- {\n    display: grid;\n    grid-template-columns: 24px auto;\n}\n\n.RegisterForm-checkBoxText-3uSks {\n    padding-left: 7px;\n}\n\n@media screen and (max-width: 767px) {\n    .RegisterForm-displayGridDate-3jXcU {\n        grid-template-columns: 100%;\n    }\n\n    .RegisterForm-datePadding-3uW4m {\n        padding: 0px;\n    }\n}", ""]);

// exports
exports.locals = {
	"displayGridDate": "RegisterForm-displayGridDate-3jXcU",
	"displayForm": "RegisterForm-displayForm-3JWCi",
	"textSizeRSignUp": "RegisterForm-textSizeRSignUp-3wWM4",
	"textHidden": "RegisterForm-textHidden-3Z3Xc",
	"textSocialMedia": "RegisterForm-textSocialMedia-2YP2V",
	"displayFormSocial": "RegisterForm-displayFormSocial-hzKJL",
	"isHidden": "RegisterForm-isHidden-2o4kt",
	"datePadding": "RegisterForm-datePadding-3uW4m",
	"checkBoxGrid": "RegisterForm-checkBoxGrid-hzfz-",
	"checkBoxText": "RegisterForm-checkBoxText-3uSks"
};