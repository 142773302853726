import siteSettingsIcon from '/public/AdminIcons/siteSettingIcon.svg'
import manageUserIcon from '/public/AdminIcons/manageUser.svg'
import manageCarsIcon from '/public/AdminIcons/manageCars.svg'
import manageReservationIcon from '/public/AdminIcons/manageReservation.svg'
import manageSecurityIcon from '/public/AdminIcons/securityDeposit.svg'
import reviewsManagementIcon from '/public/AdminIcons/reviewsManagement.svg'
import servicesFeeIcon from '/public/AdminIcons/manageReviewFees.svg'
import documentVerificationIcon from '/public/AdminIcons/documentVerification.svg'

import messageIcon from '/public/AdminIcons/messageicon.svg'
import reportManagementIcon from '/public/AdminIcons/reportIcon.svg'
import paymentGateIcon from '/public/AdminIcons/paymentGateway.svg'
import manageCurrencyIcon from '/public/AdminIcons/manageCurrency.svg'
import settingsIcon from '/public/AdminIcons/searchSettings.svg'
import passwordIcon from '/public/AdminIcons/changePassword.svg'
import ownerPageIcon from '/public/AdminIcons/ownerPage.svg'
import siteConfigureIcon from '/public/AdminIcons/siteConfigureIcon.svg'
import payOutManagementIcon from '/public/AdminIcons/payoutIcon.svg'
import contentIcon from '/public/AdminIcons/contentManagementIcon.svg'
import staticContentIcon from '/public/AdminIcons/staticContentIcon.svg'
import manageAdminsIcon from '/public/AdminIcons/manageAdmin.svg'
import arrowIcon from '/public/AdminIcons/subArrowIcon.svg'
import iconAllContracts from '/public/AdminIcons/iconAllContracts_b.svg'
import { MdPhotoLibrary} from 'react-icons/md';
import messages from '../locale/messages';



//TODO: even if we have a way to add new Privileges/PrivialagesUrls theres no way to update this rules, so when admin user creates a new privilaege. it will need to update this section
const menuItems = [
    {
        key: 'siteSettings',
        menuLocation: "/siteadmin/settings/site", 
        icon: siteSettingsIcon,
        privilegeId: 1
    },
    {
        key: 'managePolicyConfig',
        menuLocation: "/siteadmin/settings/policy",
        icon: manageCarsIcon,
        privilegeId: 27,
        isSuperAdmin: true
    },
    {
        key: 'managePrivileges',
        menuLocation: "/siteadmin/privileges",
        icon: siteConfigureIcon,
        isSuperAdmin: true
    },
    {
        key: 'manageSiteConfig',
        menuLocation: "/siteadmin/settings/config",
        icon: siteConfigureIcon,
        isSuperAdmin: true
    },
    {
        key: 'manageUser',
        menuLocation: "/siteadmin/users",
        icon: manageUserIcon,
        privilegeId: 2
    },
    {
        key: 'manageUserOwner',
        menuLocation: "/siteadmin/users-owner",
        icon: manageUserIcon,
        privilegeId: 23
    },
    {
        key: 'manageListing',
        menuLocation: "/siteadmin/listings",
        icon: manageCarsIcon,
        privilegeId: 3
    },
    {
        key: 'manageReservations',
        menuLocation: "/siteadmin/reservations",
        icon: manageReservationIcon,
        privilegeId: 4
    },
    {
        key: 'manageSecurityDeposit',
        menuLocation: "/siteadmin/manage-security-deposit",
        icon: manageSecurityIcon,
        privilegeId: 20
    },
    {
        key: 'reviewManagement',
        menuLocation: "/siteadmin/user-reviews",
        icon: reviewsManagementIcon,
        privilegeId: 5
    },
    {
        key: 'manageServiceFee',
        menuLocation: "/siteadmin/settings/servicefees",
        icon: servicesFeeIcon,
        privilegeId: 7
    },
    {
        key: 'mediaLibrary',
        menuLocation: "/siteadmin/media-library",
        icon: MdPhotoLibrary,
        privilegeId: 22,
        isIconLib: true
    },
    {
        key: 'documentverificaiton',
        menuLocation: "/siteadmin/document",
        icon: documentVerificationIcon,
        privilegeId: 8
    },
    {
        key: 'messages',
        menuLocation: "/siteadmin/messages",
        icon: messageIcon,
        privilegeId: 9
    },
    {
        key: 'reportManagement',
        menuLocation: "/siteadmin/reportUser",
        icon: reportManagementIcon,
        privilegeId: 10
    },
    {
        key: 'payOutManagement',
        menuLocation: "/siteadmin/payout",
        icon: payOutManagementIcon,
        privilegeId: 11
    },
    {
        key: 'managePaymentGateWay',
        menuLocation: "/siteadmin/payment-gateway-section",
        icon: paymentGateIcon,
        privilegeId: 12
    },
    {
        key: 'searchSettings',
        menuLocation: "/siteadmin/settings/search",
        icon: settingsIcon,
        privilegeId: 14
    },
    {
        key: 'manageCurrency',
        menuLocation: "/siteadmin/currency",
        icon: manageCurrencyIcon,
        isSuperAdmin: true
    },
    {
        key: 'manageAdmins',
        menuLocation: "/siteadmin/admins",
        icon: manageAdminsIcon,
        isSuperAdmin: true,
        subMenuData: [
            {
                key: 'manageAdminUsers',
                menuLocation: "/siteadmin/admin-users",
                icon: manageAdminsIcon,
                isSuperAdmin: true
            },
            {
                key: 'manageAdminRoles', 
                menuLocation: "/siteadmin/admin-roles",
                icon: manageAdminsIcon,
                isSuperAdmin: true
            }
        ]
    },
    {
        key: 'changePasswordLabel',
        menuLocation: "/siteadmin/change/admin",
        icon: passwordIcon,
        isSuperAdmin: true
    },
    {
        key: 'whyHostPage',
        menuLocation: "/siteadmin/why-host",
        icon: ownerPageIcon,
        privilegeId: 16
    },
    {
        key: 'contentManagementLabel',
        menuLocation: "/siteadmin/content-management",
        icon: contentIcon,
        privilegeId: 18
    },
    {
        key: 'staticContentManagement',
        menuLocation: "/siteadmin/staticpage/management",
        icon: staticContentIcon,
        privilegeId: 19
    }
];
export function getAdminSideMenu(formatMessage, location, adminPrivileges) {
    const { isSuperAdmin, permittedUrls = [] } = adminPrivileges;
    
    // Filter menu items based on admin privileges
    const filteredItems = menuItems.filter(item => {
        // Super admin can access everything
        if (isSuperAdmin) {
            console.log(`[${item.key}] Allowed - Super admin access`);
            return true;
        }

        // Check if permittedUrls is defined and not empty
        if (!permittedUrls || permittedUrls.length === 0) {
            console.log(`[${item.key}] No permitted URLs defined`);
            return false;
        }

        // Check if menu location matches any permitted URLs
        const hasPermission = permittedUrls.some(url => {
            const isPermitted = item.menuLocation.includes(url) || url.includes(item.menuLocation);
            console.log(`[${item.key}] Checking permission for URL ${url}: ${isPermitted}`);
            return isPermitted;
        });

        console.log(`[${item.key}] Permission check result: ${hasPermission}`);
        return hasPermission;
    });

    // Map filtered items to final menu format
    return filteredItems.map(item => {
        const menuItem = {
            key: item.key,
            menuLocation: item.menuLocation,
            menuName: formatMessage(messages[item.key || 'No Msg Key Found'] || messages.default),
            activeLocation: location === item.menuLocation,
            icon: item.icon,
            iconAltText: item.icon,
            isValidatePrivilege: !!item.privilegeId,
            privilegeId: item.privilegeId,
            isSuperAdmin: item.isSuperAdmin,
            subMenuData: item?.subMenuData || []
        };
        
        console.log(`[${item.key}] Mapped menu item:`, menuItem);
        return menuItem;
    });
}