import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Dashboard.css';
import Dashboard from '../../components/Dashboard/Dashboard';

function DashboardPage({ title }: {
  title: string
}) {

    return <Dashboard />;
}

export default withStyles(s)(DashboardPage);
