import React from 'react';
import { compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './AgreementContainer.css';
import { AgreementsPreview } from '../../components/AgreementsPreview/AgreementsPreview';

interface AgreementContainerProps {
  type: string;
}

const AgreementContainer: React.FC<AgreementContainerProps> = ({ type }) => {
  // @ts-expect-error
  return <AgreementsPreview type={type} />;
};

export default compose(withStyles(s))(AgreementContainer);