import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DropzoneComponent from 'react-dropzone-component';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {Row, Col} from 'react-bootstrap';
import { graphql, gql, compose } from 'react-apollo';
import DocumentList from '../DocumentList';
import showToaster from '../../helpers/toasterMessages/showToaster';
import PictureImage from '/public/SiteIcons/photoUpload.svg';
import s from '!isomorphic-style-loader!css-loader!./filepicker.css';

const query = gql`query ShowDocumentList($verificationType: Int,$listId: Int) {
    ShowDocumentList(
      verificationType: $verificationType,
      listId: $listId
    ) {
        id
        userId,
        fileName,
        fileType
    }
  }`;

class PolicyDocumentUpload extends Component {

  static propTypes = {
    listId: PropTypes.number.isRequired,
    docIdVerification: PropTypes.number.isRequired,
    onSetUploaded: PropTypes.func.isRequired,
    offSetUploaded: PropTypes.func.isRequired,
    hasDocument: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.addedfile = this.addedfile.bind(this);
    this.complete = this.complete.bind(this);
    this.dropzone = null;
  }

  async complete(file) {
    const { mutate, listId, docIdVerification, onSetUploaded } = this.props;
    
    if (this.dropzone.files.length > 1) {
      const filesToRemove = this.dropzone.files.slice(0, -1);
      filesToRemove.forEach(f => this.dropzone.removeFile(f));
    }

    let variables = {};
    if (file && file.xhr) {
      const { files } = JSON.parse(file.xhr.response);
      let fileName = files[0].filename;
      let fileType = files[0].mimetype;
      variables = {
        fileName,
        fileType,
        listId,
        verificationType: docIdVerification
      };
      
      try {
        const { data } = await mutate({
          variables,
          refetchQueries: [
            {
              query: query,
              variables: {listId: listId, verificationType: docIdVerification}
            }
          ]
        });

        if (data?.uploadDocument) {
          if (data?.uploadDocument?.status === 'success') {
            onSetUploaded();
            showToaster({ messageId: 'uploadDocument', toasterType: 'success' });
          } else {
            showToaster({ messageId: 'uploadDocumentError', toasterType: 'error' });
          }
        }
      } catch (error) {
        console.error('Upload error:', error);
        showToaster({ messageId: 'uploadDocumentError', toasterType: 'error' });
      }
    }
    
    this.dropzone.removeFile(file);
  }

  async addedfile(file) {
    const { maxUploadSize } = this.props;

    if (this.dropzone.files.length > 1) {
      const existingFiles = this.dropzone.files.slice(0, -1);
      existingFiles.forEach(f => this.dropzone.removeFile(f));
    }

    let filetypes = ['application/pdf'];

    if (file?.size > (1024 * 1024 * parseInt(maxUploadSize))) {
      this.dropzone.removeFile(file);
      showToaster({ messageId: 'maximumUploadSize', toasterType: 'error' });
      return;
    }

    if (!filetypes.includes(file.type)) {
      showToaster({ messageId: 'invalidImageFile', toasterType: 'error' });
      this.dropzone.removeFile(file);
      return;
    }
  }

  render() {
    const { placeholder, listId, docIdVerification, hasDocument } = this.props;
    const djsConfig = {
      dictDefaultMessage: '',
      addRemoveLinks: false,
      maxFilesize: 10,
      maxFiles: 1,
      acceptedFiles: 'application/pdf',
      hiddenInputContainer: '.dzInputContainer',
      autoProcessQueue: true
    };
    const componentConfig = {
      iconFiletypes: ['.pdf'],
      postUrl: '/documents'
    };
    const eventHandlers = {
      init: dz => this.dropzone = dz,
      complete: this.complete,
      addedfile: this.addedfile
    };

    return (
      <div className={cx('listPhotoContainer')}>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className={cx('dzInputContainer')}>
              {!hasDocument ? (
                <DropzoneComponent
                  config={componentConfig}
                  eventHandlers={eventHandlers}
                  djsConfig={djsConfig}
                >
                  <img src={PictureImage} className={'photoUploadImg'} alt='PictureImage' />
                  <span className={cx('documentPlaceholder')}>{placeholder}</span>
                </DropzoneComponent>
              ) : (
                <div className={cx('documentSuccessContainer')}>
                  <span className={cx('documentSuccessText')}>
                    File has been uploaded successfully
                  </span>
                </div>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <DocumentList 
              onHaveDoc={this.props.onSetUploaded} 
              onRemoveDoc={this.props.offSetUploaded} 
              listId={listId} 
              docVerificationId={docIdVerification}/>
          </Col>
        </Row>
      </div>
    );
  }

}

const mapState = (state) => ({
  maxUploadSize: state.siteSettings.data.maxUploadSize
});

const mapDispatch = {

};

export default compose(withStyles(s),

  graphql(gql`mutation uploadDocument($fileName: String,$fileType: String,$listId: Int,$verificationType: Int){
     uploadDocument(
       fileName: $fileName,
       fileType: $fileType,
       listId: $listId,
       verificationType: $verificationType
     ) {    
         fileName
         fileType
         status        
        }
 }`
  ),
  (connect(mapState, mapDispatch)))(PolicyDocumentUpload);

