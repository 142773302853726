import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import moment from 'moment';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// Components
// import Payout from './Payout';
// import Refund from './Refund';
// import CurrencyConverter from '../../CurrencyConverter';

// import ModalForm from './ModalForm';
import CustomPagination from '../../CustomPagination';
import CommonTable from '../../CommonTable/CommonTable';
import TableAction from '../../CommonTable/TableAction';

// Translation
import messages from '../../../locale/messages';
//Helper
import formatReservationState from '../../../helpers/formatReservationState';

import s from './ContractsManagement.css';
import cs from '../../../components/commonStyle.css';

class ContractsManagement extends React.Component {

  static propTypes = {
    title: PropTypes.string,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.any,
      getTransactionHistory: PropTypes.shape({
        count: PropTypes.number.isRequired,
        contractData: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number.isRequired,
          listId: PropTypes.number.isRequired,
          dealerId: PropTypes.string.isRequired,
          clientId: PropTypes.string.isRequired,
          reservationId: PropTypes.string.isRequired,
          // checkIn: PropTypes.string.isRequired,
          // checkOut: PropTypes.string.isRequired,
          // guestServiceFee: PropTypes.number.isRequired,
          // hostServiceFee: PropTypes.number.isRequired,
          // total: PropTypes.number.isRequired,
          // currency: PropTypes.string.isRequired,
          // reservationState: PropTypes.string.isRequired,
          listData: PropTypes.shape({
            title: PropTypes.string.isRequired
          }),
          listBooking: PropTypes.shape({
            confirmationCode: PropTypes.string.isRequired
          }),
          dealerData: PropTypes.shape({
            profileId: PropTypes.number.isRequired,
            firstName: PropTypes.string.isRequired
          }),
          // hostPayout: PropTypes.shape({
          //   id: PropTypes.number.isRequired,
          //   payEmail: PropTypes.string.isRequired,
          //   methodId: PropTypes.number.isRequired,
          //   currency: PropTypes.string.isRequired,
          //   last4Digits: PropTypes.number
          // }),
          // hostTransaction: PropTypes.shape({
          //   id: PropTypes.number.isRequired,
          // }),
          clientData: PropTypes.shape({
            profileId: PropTypes.number.isRequired,
            firstName: PropTypes.string.isRequired
          }),
          // transaction: PropTypes.shape({
          //   payerEmail: PropTypes.string.isRequired,
          //   paymentType: PropTypes.string.isRequired,
          //   total: PropTypes.number.isRequired,
          //   currency: PropTypes.string.isRequired,
          //   paymentMethodId: PropTypes.number
          // }),
          // refundStatus: PropTypes.shape({
          //   id: PropTypes.number.isRequired,
          //   receiverEmail: PropTypes.string.isRequired,
          //   total: PropTypes.number.isRequired,
          //   currency: PropTypes.string.isRequired
          // }),
          // cancellationDetails: PropTypes.shape({
          //   refundToGuest: PropTypes.number.isRequired,
          //   payoutToHost: PropTypes.number.isRequired,
          //   total: PropTypes.number.isRequired,
          //   currency: PropTypes.string.isRequired,
          //   guestServiceFee: PropTypes.number.isRequired,
          //   hostServiceFee: PropTypes.number.isRequired,
          // }),
        })),
      }),
    }).isRequired,
    // viewReceiptAdmin: PropTypes.any.isRequired,
  };

  static defaultProps = {
    data: {
      loading: true,
      getAllContractListing: {
        count: null,
        contractData: []
      }
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0,
      // selectedRefund: [],
      successRefund: [],
      selectedPayout: [],
      successPayout: [],
    };
    this.paginationData = this.paginationData.bind(this);
    this.changeState = this.changeState.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  changeState(type, value) {
    // const { selectedRefund, successRefund, selectedPayout, successPayout } = this.state;
    const { searchList, currentPage, getAllContractListing: { refetch } } = this.props;
    let variables = {};

    // if (type === 'addRefund') {
    //   variables = { selectedRefund: [...selectedRefund, value] };
    // }
    // if (type === 'removeRefund') {
    //   let index = selectedRefund.findIndex(i => i === value);
    //   if (index === -1) return '';
    //   let data = [...selectedRefund];
    //   data.splice(index, 1)
    //   variables = { selectedRefund: data };
    // }
    // if (type === 'successRefund') {
    //   variables = { successRefund: [...successRefund, value] };
    // }

    // if (type === 'addPayout') {
    //   variables = { selectedPayout: [...selectedPayout, value] };
    // }
    // if (type === 'removePayout') {
    //   let index = selectedPayout.findIndex(i => i === value);
    //   if (index === -1) return '';
    //   let data = [...selectedPayout];
    //   data.splice(index, 1)
    //   variables = { selectedPayout: data };
    // }
    // if (type === 'successPayout') {
    //   variables = { successPayout: [...successPayout, value] };
    //   refetch({ currentPage, searchList });
    // }
    this.setState(variables)
  }

  paginationData(currentPage) {
    const { getAllContractListing: { refetch }, changeStateValues, searchList } = this.props;
    let variables = { currentPage, searchList };
    changeStateValues(variables);
    refetch(variables);
  }

  handleClick(searchList) {
    const { getAllContractListing: { refetch }, changeStateValues } = this.props;
    let variables = {
      currentPage: 1,
      searchList: searchList
    };
    changeStateValues(variables);
    refetch(variables);
  }

  handleSearchChange = (e) => {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      searchList: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleClick(self.state.searchList);
      }, 450)
    });
  }

  thead = () => {
    const { formatMessage } = this.props.intl;
    return [
      { data: formatMessage(messages.contractId) },
      { data: formatMessage(messages.codeLabel) },
      { data: formatMessage(messages.reservation) },
      // { data: formatMessage(messages.reservation) },
      // { data: formatMessage(messages.status) },
      { data: formatMessage(messages.carNameLabel) },
      { data: formatMessage(messages.ownerDetails) },
      { data: formatMessage(messages.guestLabel) },
      { data: formatMessage(messages.createdDate) },
    ]
  };

  tbody = () => {
    const { getAllContractListing: { getAllContractListing } } = this.props;
    const { formatMessage } = this.props.intl;
    const { selectedRefund, successRefund, selectedPayout, successPayout } = this.state;

    return getAllContractListing?.contractData.map(value => {
      return {
        id: value?.id,
        data: [
          { data: value.id },
          { data: value.code },
          
          // { data: value.reservationId },
          // { data: value.listBooking.confirmationCode },
          {
            data: <div className={cx(cs.displayFlex, cs.alignCenter, cs.spaceBetween)}>
              {value?.listBooking.confirmationCode}
              <TableAction
                showView={true}
                link={`/siteadmin/viewreservation/${value.reservationId}/reservation`}
              />
            </div>
          },
          // { data: <span>{formatReservationState(value.reservationState)}</span> },
          {
            data: (value.code || value.listData) ? <a href={"/cars/" + value.listId} target='_blank'>
              {value.code ? value.code : value.listData.title}
            </a> : <>{formatMessage(messages.carNameIsMissing)}</>
          },
          { data: value.dealerData.firstName },
          { data: value.clientData.firstName },
          { data: <a href={`/get-pdf-agreement?pdfname=contract_${value.code}_ot.pdf`} target='_blank'>{moment(value.createdAt).format('DD-MM-YYYY hh:mm A').toString() }</a> },
          // { data: value.createdAt },
          // {
          //   data: <Refund
          //     id={value.id}
          //     reservationState={value.reservationState}
          //     transactionData={value.transaction}
          //     refundData={value.refundStatus}
          //     cancelData={value.cancellationDetails}
          //     selectedRefund={selectedRefund}
          //     changeState={this.changeState}
          //     successRefund={successRefund}
          //   />
          // },
          // {
          //   data: <CurrencyConverter
          //     amount={value.total + value.guestServiceFee}
          //     from={value.currency}
          //   />
          // },
          // {
          //   data: <Payout
          //     hostId={value.hostId}
          //     checkIn={value.checkIn}
          //     id={value.id}
          //     hostPayout={value.hostPayout}
          //     amount={value.total}
          //     currency={value.currency}
          //     hostTransaction={value.hostTransaction}
          //     reservationState={value.reservationState}
          //     cancelData={value.cancellationDetails}
          //     hostData={value.hostData}
          //     hostServiceFee={value.hostServiceFee}
          //     selectedPayout={selectedPayout}
          //     successPayout={successPayout}
          //     changeState={this.changeState}
          //   />
          // },
        ]
      }
    })
  }

  render() {
    const { getAllContractListing: { getAllContractListing }, currentPage, searchList } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentWrapperRTL')}>
        
        <CommonTable
          thead={this.thead}
          tbody={this.tbody}
          title={formatMessage(messages.panelContracts)}
          // isExport
          // exportLink={`/export-contract-data?type=reservations&search=${searchList}`}
          isSearch
          onSearch={this.handleSearchChange}
        />
        {
          getAllContractListing?.contractData?.length > 0
          && <div>
            <CustomPagination
              total={getAllContractListing.count}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel={formatMessage(messages.panelContracts)}
            />
          </div>
        }
      </div>
    );
  }

}

const mapState = (state) => ({
  completed: state.reservation.completed,
  loading: state.reservation.loading,
});

const mapDispatch = {};

export default compose(injectIntl,
  withStyles(s, cs),
  connect(mapState, mapDispatch)
)(ContractsManagement);