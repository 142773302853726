import { locales } from '../config';

export function convert(base, rates, amount, from, to) {
    // Ensure all parameters have default values
    const baseRate = base || 'USD';
    const conversionRates = (!rates || Object.keys(rates).length === 0) ? { USD: 1 } : rates;
    
    const fromCurrency = from || baseRate;
    const toCurrency = to || baseRate;
    const safeAmount = parseFloat(amount) || 0;

    // Convert to base currency first if not already
    const amountInBase = (fromCurrency === baseRate) 
        ? safeAmount 
        : safeAmount / (conversionRates[fromCurrency] || 1);

    // Then convert from base to target currency
    const result = (toCurrency === baseRate) 
        ? amountInBase 
        : amountInBase * (conversionRates[toCurrency] || 1);

    return isNaN(result) ? 0 : result;
}

export function showCurrencySymbol(currency, locale) {
    const defaultValue = 0;
    const convertCurrency = currency || 'USD';
    const useLocale = locale || locales[0] || 'en-US';  // Ensure locale has fallback

    try {
        const symbol = defaultValue.toLocaleString(useLocale, { 
            style: 'currency', 
            currency: convertCurrency, 
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
        }).replace(/\d+([,.]\d+)?/g, "").trim() || '';

        return symbol === convertCurrency ? '' : `${symbol} `;
    } catch (error) {
        console.warn(`Currency symbol conversion failed: ${error.message}`);
        return '';
    }
}

export function getCurrencySymbol(currency, locale) {
    const convertCurrency = currency || 'USD';
    const useLocale = locale || locales[0] || 'en-US';  // Ensure locale has fallback

    try {
        return (0).toLocaleString(useLocale, { 
            style: 'currency', 
            currency: convertCurrency, 
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
        }).replace(/\d+([,.]\d+)?/g, "").trim() || '';
    } catch (error) {
        console.warn(`Currency symbol conversion failed: ${error.message}`);
        return '';
    }
}